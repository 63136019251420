/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { InputNumber } from "primereact";
import { InputText } from "primereact/inputtext";
import SpecialNameService from "services/special-name";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    key: "",
    price: 0,
  };

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
  }, [data]);

  const onChange = (name, value) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if(details.key.includes(" ")) {
        setLoading(false);
        showToast(toast, "warn", "Keywords must not have spaces");
        return;
      }
      if (data) {
        await SpecialNameService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else
        await SpecialNameService.create({
          body: {
            ...details,
          },
        });
      setLoading(false);
      showToast(toast, "success", "File saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Key</label>
        <InputText
          id="name"
          value={details.key}
          onChange={(e) => onChange("key", e.target.value)}
          required
          autoFocus
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Price</label>
        <InputNumber
          onChange={(e) => onChange("price", e.value)}
          value={details.price}
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
