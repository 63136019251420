/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useImperativeHandle, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { downloadFile, showToast } from "utils/common";
import { forwardRef } from "react";
import QuotaService from "services/quotas";
import VUpload from "components/v-upload";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber, Panel } from "primereact";
import { InputSwitch } from "primereact/inputswitch";
import CategoryFilterDetails from "components/v-filter";
import { Button } from "primereact/button";
import VDialog from "components/v-dialog";
import ImportOptions from "components/v-import-options";
import { FileUpload } from "primereact/fileupload";
import { QUOTAS_TYPE } from "utils/enum";

const Details = (props, ref) => {
  const {
    data,
    reload,
    toast,
    onCancel,
    setLoading,
    categoryFilters,
    getCategoryFilters,
  } = props;

  const emptyData = {
    content: [],
    name: "",
    link: "",
    image: "",
    status: "enable",
    start_date: "",
    end_date: "",
    category_filter_id: null,
    order: 0,
    is_prioritize: false,
    special_date: null,
    icon: null,
    color: null,
    background_color: null,
    background_image: "",
    title: null,
    color_mobile: null,
    background_image_mobile: null,
    type: QUOTAS_TYPE.QUOTAS,
  };

  const [details, setDetails] = useState(emptyData);
  const refDetailCategory = useRef(null);
  const refDialogDetail = useRef(null);
  const refDetail = useRef(null);
  const fileRef = useRef(null);

  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };
  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data)
      setDetails({
        ...emptyData,
        ...data,
        start_date: data.start_date ? new Date(data.start_date) : new Date(),
        end_date: data.end_date ? new Date(data.end_date) : new Date(),
        special_date: data.special_date ? new Date(data.special_date) : null,
      });
  }, [data]);

  const onChange = (name, value, index?: number) => {
    let _details = { ...details };
    if (name === "content") {
      _details[`${name}`] = _details[`${name}`].map((el, i) =>
        index === i
          ? {
              name: value,
            }
          : el
      );
    } else {
      _details[`${name}`] = value;
    }
    setDetails(_details);
  };

  const handleAddOptions = () => {
    setDetails({
      ...details,
      content: details.content.concat({
        name: "",
      }),
    });
  };

  const handleSubIndexOptions = (index: number) => {
    setDetails({
      ...details,
      content: details.content.filter((el, ind) => index !== ind),
    });
  };

  const handleImportOptions = () => {
    refDialogDetail.current.show();
  };

  const handleImport = (data) => {
    setDetails({
      ...details,
      content: data?.map((op) => ({ name: op })),
    });
  };

  const submit = async () => {
    try {
      setLoading(true);
      let category = null;
      if (!details.category_filter_id) {
        category = await refDetailCategory.current.submit();
        getCategoryFilters();
      }
      if (data) {
        await QuotaService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      } else
        await QuotaService.create({
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      setLoading(false);
      showToast(toast, "success", "File saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const importExcelFunc = (e) => {
    const file = e.files[0];
    import("xlsx")
      .then((xlsx) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const wb = xlsx.read(e.target.result, { type: "array" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data: any = xlsx.utils.sheet_to_json(ws, { header: 1 });
          // Prepare DataTable
          // const cols: any = data[0];
          data.shift();
          // let _importedCols = cols.map((col) => ({
          //   field: col.toLowerCase(),
          //   header: toCapitalize(col),
          // }));
          let dataImport = [];
          data
            .map((i, index) => {
              if (i.length > 0) {
                let rowArray = i?.map((e) => {
                  return details.special_date
                    ? { keyword: e instanceof String ? e?.trim() : e }
                    : { name: e instanceof String ? e?.trim() : e };
                });
                dataImport = [...dataImport, ...rowArray];
              }
            })
            .filter(Boolean);
          setDetails({
            ...details,
            content: [...details.content, ...dataImport],
          });
        };

        reader.readAsArrayBuffer(file);
      })
      .finally(e.options.clear());

    showToast(toast, "success", "File imported!");
  };

  const cancelOptions = {
    label: "Cancel",
    icon: "pi pi-times",
    className: "p-button-danger",
  };

  return (
    <div className="grid">
      <div className="field col-12">
        <label htmlFor="status">Filter</label>
        <Dropdown
          filter
          showClear
          value={details.category_filter_id}
          options={categoryFilters.map((item) => ({
            label: item.name,
            value: item._id,
          }))}
          optionLabel="label"
          optionValue="value"
          placeholder="Select filter"
          onChange={(e) => onChange("category_filter_id", e.value)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange("name", e.target.value)}
          required
          autoFocus
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="status">Type</label>
        <Dropdown
          defaultValue={details.type}
          value={details.type}
          options={Object.keys(QUOTAS_TYPE).map((k) => ({
            label: k,
            value: QUOTAS_TYPE[k],
          }))}
          onChange={(e) => onChange("type", e.value)}
          optionLabel="label"
          placeholder="Select type"
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="status">Status</label>
        <Dropdown
          defaultValue={details.status}
          value={details.status}
          options={[
            {
              label: "ENABLE",
              value: "enable",
            },
            {
              label: "DISABLE",
              value: "disable",
            },
          ]}
          onChange={(e) => onChange("status", e.value)}
          optionLabel="label"
          placeholder="Select status"
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="order">Order</label>
        <InputNumber
          value={details.order}
          onChange={(e) => onChange("order", e.value)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="link">Link</label>
        <InputText
          id="link"
          value={details.link}
          onChange={(e) => onChange("link", e.target.value)}
          required
          autoFocus
        />
      </div>

      <div className="field col-12 md:col-6">
        <label className="startDate">Start Date</label>
        <Calendar
          className="m-1"
          id="startDate"
          placeholder="Start Date"
          value={details.start_date}
          onChange={(e) => onChange("start_date", e.value)}
          showTime
          showSeconds
          showButtonBar
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="endDate">End Date</label>
        <Calendar
          className="m-1"
          id="endDate"
          placeholder="Start Date"
          value={details.end_date}
          onChange={(e) => onChange("end_date", e.value)}
          showTime
          showSeconds
          showButtonBar
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="endDate">Prioritize</label>
        <InputSwitch
          checked={details.is_prioritize}
          onChange={(e) => onChange("is_prioritize", e.value)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label className="specialDate">Special Date</label>
        <Calendar
          className="m-1"
          id="specialDate"
          dateFormat="dd/mm"
          placeholder="Special Date"
          value={details.special_date}
          onChange={(e) => onChange("special_date", e.value)}
          showButtonBar
        />
      </div>

      {details.special_date && (
        <>
          <div className="field col-12 md:col-6">
            <label htmlFor="title">Title</label>
            <InputText
              id="title"
              value={details.title}
              onChange={(e) => onChange("title", e.target.value)}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label>Icon</label>
            <VUpload
              urlFile={details.icon}
              setUrlFile={(file) => onChange("icon", file)}
            />
          </div>
        </>
      )}

      {details.special_date && (
        <>
          <div className="field col-12 md:col-6">
            <label htmlFor="text_color">Text color &nbsp; &nbsp;</label>
            <input
              type="color"
              id="head"
              name="head"
              value={details.color}
              onChange={(e) => onChange("color", e.target.value)}
            />
            <InputText
              id="color"
              value={details.color}
              onChange={(e) => onChange("color", e.target.value)}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="name">Background color &nbsp; &nbsp;</label>
            <input
              type="color"
              id="head"
              name="head"
              value={details.background_color}
              onChange={(e) => onChange("background_color", e.target.value)}
            />
            <InputText
              id="background_color"
              value={details.background_color}
              onChange={(e) => onChange("background_color", e.target.value)}
            />
          </div>
        </>
      )}
      <div className="field col-12 md:col-6">
        <label>Image</label>
        <VUpload
          urlFile={details.image}
          setUrlFile={(file) => onChange("image", file)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label>Background Image</label>
        <VUpload
          urlFile={details.background_image}
          setUrlFile={(file) => onChange("background_image", file)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="text_color">Text color &nbsp; &nbsp;</label>
        <input
          type="color"
          id="head"
          name="head"
          value={details.color}
          onChange={(e) => onChange("color", e.target.value)}
        />
        <InputText
          id="color"
          value={details.color}
          onChange={(e) => onChange("color", e.target.value)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label>Background Image Mobile</label>
        <VUpload
          urlFile={details.background_image_mobile}
          setUrlFile={(file) => onChange("background_image_mobile", file)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="text_color">Text color mobile &nbsp; &nbsp;</label>
        <input
          type="color"
          id="head"
          name="head"
          value={details.color_mobile}
          onChange={(e) => onChange("color_mobile", e.target.value)}
        />
        <InputText
          id="color"
          value={details.color_mobile}
          onChange={(e) => onChange("color_mobile", e.target.value)}
        />
      </div>
      <div className="field col-12">
        <label htmlFor="content">Content</label>
        <div className="flex col-12 align-items-center">
          Option:
          <div className="flex ml-2">
            <Button
              label="+"
              className="p-button-primary py-1"
              onClick={() => handleAddOptions()}
            />
          </div>
          <Button
            label="Import"
            style={{
              width: "100px",
            }}
            className="p-button-primary py-1 ml-1 flex justify-content-center"
            onClick={() => handleImportOptions()}
          />
          <div className="flex align-items-center justify-content-center py-2">
            <FileUpload
              ref={fileRef}
              chooseOptions={{
                label: "Import Excel",
                icon: "pi pi-file-excel",
                className: "p-button-success",
              }}
              mode="basic"
              name="demo[]"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              className="ml-2"
              uploadHandler={importExcelFunc}
              customUpload={true}
              cancelOptions={cancelOptions}
            />
          </div>
          <div className="flex align-items-center justify-content-center ml-2">
            <Button
              label="Download Template"
              icon="pi pi-download"
              className="p-button-help"
              onClick={() =>
                downloadFile(
                  "admin/85cf42fe-25ec-4191-8b0b-e2aadbe0dcdd.xlsx",
                  "Import_Ban_Keywords.xlsx"
                )
              }
            />
          </div>
        </div>
        {details.content?.map((el, index) => (
          <div className="flex mb-2">
            <InputTextarea
              id="content"
              value={el.name}
              onChange={(e) => onChange("content", e.target.value, index)}
              required
              autoFocus
            />
            <div>
              <Button
                label="-"
                className="p-button-primary py-1 ml-1"
                onClick={() => handleSubIndexOptions(index)}
              />
            </div>
          </div>
        ))}
      </div>
      {!details.category_filter_id && (
        <div className="field col-12">
          <Panel header="Filter" toggleable className="my-2" collapsed={false}>
            <div className="grid">
              <div className="field col-12">
                <CategoryFilterDetails ref={refDetailCategory} />
              </div>
            </div>
          </Panel>
        </div>
      )}
      <VDialog
        ref={refDialogDetail}
        header="Options"
        onSubmit={onSumitDialogDetail}
      >
        <ImportOptions
          ref={refDetail}
          setDataImport={handleImport}
          onCancel={onCancelDialogDetail}
        />
      </VDialog>
    </div>
  );
};

export default forwardRef(Details);
