/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import actionPostsService from "services/action-posts";
import VUpload from "components/v-upload";
import { Dropdown } from "primereact/dropdown";
import { InputNumber, Panel } from "primereact";
import CategoryFilterDetails from "components/v-filter";
import { COMMON_STATUS, CATEGORY_POST } from "utils/enum";
import CategoryPostService from "services/category-posts";

const Details = (props, ref) => {
  const {
    data,
    reload,
    toast,
    onCancel,
    setLoading,
    categoryFilters,
    getCategoryFilters,
  } = props;

  const emptyData = {
    image: "",
    icon: "",
    name: "",
    type: "free",
    price: 0,
    color: "",
    bg_color: "",
    status: COMMON_STATUS.ENABLE,
    category_filter_id: null,
    category_id: null,
    order: 0,
  };

  const refDetailCategory = useRef(null);
  const [details, setDetails] = useState(emptyData);
  const [categories, setCategories] = useState([]);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
  }, [data]);

  const onChange = (name, value) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);

      let category = null;
      if (!details.category_filter_id) {
        category = await refDetailCategory.current.submit();
        getCategoryFilters();
      }

      if (data) {
        await actionPostsService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      } else
        await actionPostsService.create({
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      setLoading(false);
      showToast(toast, "success", "File saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  useEffect(() => {
    if (data) setDetails(data);
    getCategories();
  }, [data]);

  const getCategories = () => {
    CategoryPostService.getCategories({
      query: {
        category: CATEGORY_POST.ACTION,
      },
    }).then((res: any) => {
      if (res && res.categories) {
        const data = [...res.categories].map((i) => ({
          label: i.name.toString(),
          value: i._id,
        }));
        setCategories(data);
      }
    });
  };

  return (
    <div className="grid">
      <div className="field col-12">
        <label htmlFor="status">Filter</label>
        <Dropdown
          filter
          showClear
          value={details.category_filter_id}
          options={categoryFilters.map((item) => ({
            label: item.name,
            value: item._id,
          }))}
          optionLabel="label"
          optionValue="value"
          placeholder="Select filter"
          onChange={(e) => onChange("category_filter_id", e.value)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange("name", e.target.value)}
          required
          autoFocus
        />
      </div>
      <div className="field col-12  md:col-6">
        <label htmlFor="name">Category</label>
        <Dropdown
          value={details.category_id}
          options={categories}
          onChange={(e) => onChange("category_id", e.value)}
          optionLabel="label"
          placeholder="Select category"
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="order">Order</label>
        <InputNumber
          value={details.order}
          onChange={(e) => onChange("order", e.value)}
        />
      </div>

      <div className="field col-6">
        <label htmlFor="name">Price</label>
        <InputNumber
          id="name"
          value={details.price}
          onChange={(e) => onChange("price", e.value)}
          required
          autoFocus
        />
      </div>

      <div className="field col-6">
        <label htmlFor="name">Color</label>
        <input
          type="color"
          id="head"
          name="head"
          value={details.color}
          onChange={(e) => onChange("color", e.target.value)}
        />
        <InputText
          id="name"
          value={details.color}
          onChange={(e) => onChange("color", e.target.value)}
          required
        />
      </div>

      <div className="field col-6">
        <label htmlFor="name">Background Color</label>
        <input
          type="color"
          id="head"
          name="head"
          value={details.bg_color}
          onChange={(e) => onChange("bg_color", e.target.value)}
        />
        <InputText
          id="name"
          value={details.bg_color}
          onChange={(e) => onChange("bg_color", e.target.value)}
          required
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Type</label>
        <Dropdown
          defaultValue={details.type}
          value={details.type}
          options={[
            {
              label: "FREE",
              value: "free",
            },
            {
              label: "FEE",
              value: "fee",
            },
          ]}
          onChange={(e) => onChange("type", e.value)}
          optionLabel="label"
          placeholder="Select type"
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Status</label>
        <Dropdown
          defaultValue={details.status}
          value={details.status}
          options={[
            {
              label: "ENABLE",
              value: "enable",
            },
            {
              label: "DISABLE",
              value: "disable",
            },
          ]}
          onChange={(e) => onChange("status", e.value)}
          optionLabel="label"
          placeholder="Select status"
        />
      </div>

      <div className="field col-12">
        <VUpload
          urlFile={details.image}
          setUrlFile={(file) => onChange("image", file)}
        />
      </div>

      <div className="field col-12">
        <VUpload
          urlFile={details.icon}
          setUrlFile={(file) => onChange("icon", file)}
        />
      </div>

      {!details.category_filter_id && (
        <div className="field col-12">
          <Panel header="Filter" toggleable className="my-2" collapsed={false}>
            <div className="grid">
              <div className="field col-12">
                <CategoryFilterDetails ref={refDetailCategory} />
              </div>
            </div>
          </Panel>
        </div>
      )}
    </div>
  );
};

export default forwardRef(Details);
