/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { getUrlImage, showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import KycService from "services/kycs";
import { Image } from "primereact/image";
import VEditor from "components/v-editor";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    status: "pending_verified",
    note: "",
    type: "",
    front: "",
    back: "",
    profile_VDB: "",
    appStore_CHPlay: "",
    video_intro: "",
    img_verifed: "",
    object: "",
    idKyc: "",
  };

  const optionStatus = [
    {
      label: "Pending Verified",
      value: "pending_verified",
    },
    {
      label: "Verified",
      value: "verified",
    },
    {
      label: "Declined Verified",
      value: "declined_verified",
    },
  ];

  const [details, setDetails] = useState(emptyData);
  const [readOnly, setReadOnly] = useState(false);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
    if (data.status && data.status !== "pending_verified") {
      setReadOnly(true);
    }
  }, [data]);

  const onChangeEditor = (e, name) => {
    setDetails((prev) => ({
      ...prev,
      [name]: e,
    }));
  };

  const onDropdownChange = (e, name) => {
    const val = e.value || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      if (details.status === "declined_verified" && !details.note) {
        return showToast(toast, "warn", "Please enter note!");
      }

      setLoading(true);
      if (data) {
        await KycService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "User saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const isImage = (url) => {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg|mov)$/.test(url);
  };

  const srcIsVideo = (src: any) => {
    const type = src.split(".")[src.split(".").length - 1];
    switch (type.toLowerCase()) {
      case "m4v":
      case "avi":
      case "mpg":
      case "mp4":
      case "mov":
      case "quicktime":
        // etc
        return true;
    }
    return false;
  };

  return (
    <div className="grid">
      <div className="field col-12">
        {Object.keys(data?.value_verify)?.map((value, i) => {
          if (srcIsVideo(data.value_verify[value])) {
            return (
              <div key={i} className="grid mt-3 flex-column">
                <span className="font-semibold block mb-3">- {value}:</span>
                <div style={{ maxWidth: "500px" }}>
                  <video
                    src={getUrlImage(data.value_verify[value])}
                    width="100%"
                    height="auto"
                    autoPlay
                    loop
                  >
                    Your browser does not support HTML video.
                  </video>
                </div>
              </div>
            );
          } else if (!isImage(data.value_verify[value])) {
            return (
              <div key={i} className="grid mt-3">
                <span className="font-semibold">- {value}:</span>
                <div className="ml-4">{data.value_verify[value]}</div>
              </div>
            );
          } else {
            let imgs = data?.value_verify[value];
            if (!Array.isArray(imgs)) {
              imgs = [imgs];
            }

            return (
              <div key={i} className="grid mt-3">
                {imgs.map((at, index) => (
                  <div key={index} className="">
                    <span className="font-semibold block mb-3">- {value}:</span>
                    <Image
                      src={getUrlImage(at)}
                      width="200"
                      height="auto"
                      preview
                    />
                  </div>
                ))}
              </div>
            );
          }
        })}
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Status</label>
        <Dropdown
          value={details.status}
          options={optionStatus}
          onChange={(e) => onDropdownChange(e, "status")}
          optionLabel="label"
          placeholder="Select status"
          disabled={readOnly}
        />
      </div>

      <div className="field col-12">
        <label htmlFor="description">Note</label>
        {/* <Editor
          id="note"
          className="h-12rem"
          value={details.note}
          onTextChange={(e) => onChangeEditor(e, "note")}
        /> */}
        <VEditor
          id="note"
          className="h-12rem"
          value={details.note}
          onChange={(e) => {
            onChangeEditor(e?.html, "note");
          }}
          haveData={details.note ? true : false}
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
