import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact";
import VUpload from "components/v-upload";
import SettingService from "services/settings";

const View = () => {
  const emptyData = {
    logo_light: "",
    logo_dark: "",
    logo_mobile_light: "",
    logo_mobile_dark: "",
    logo_loading: ""
  };

  const [state, setState] = useState<any>(emptyData);
  const toast = useRef(null);

  const onChange = async (value, name) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
    await SettingService.updateSetting({
      body: {
        name: "logos",
        value: {
          ...state,
          [name]: value,
        },
      },
    });
  };

  const getData = async () => {
    try {
      const res: any = await SettingService.getSettingsByName({
        params: {
          name: "logos",
        },
      });
      if (res) {
        const { setting } = res;
        if (setting) {
          const value = setting["value"] ? setting["value"] : {};
          setState(value);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="grid">
      <Toast ref={toast} />

      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>Logo</h4>
          </div>
          <div className="grid">
            <div className="col-6">
              <div className="flex justify-content-center font-bold">
                Logo Light
              </div>
              <VUpload
                urlFile={state.logo_light}
                setUrlFile={(file) => onChange(file, "logo_light")}
              />
            </div>
            <div className="col-6">
              <div className="flex justify-content-center font-bold">
                Logo Dark
              </div>
              <VUpload
                urlFile={state.logo_dark}
                setUrlFile={(file) => onChange(file, "logo_dark")}
              />
            </div>
          </div>
          <div className="grid">
            <div className="col-6">
              <div className="flex justify-content-center font-bold">
                Logo Mobile Light
              </div>
              <VUpload
                urlFile={state.logo_mobile_light}
                setUrlFile={(file) => onChange(file, "logo_mobile_light")}
              />
            </div>
            <div className="col-6">
              <div className="flex justify-content-center font-bold">
                Logo Mobile Dark
              </div>
              <VUpload
                urlFile={state.logo_mobile_dark}
                setUrlFile={(file) => onChange(file, "logo_mobile_dark")}
              />
            </div>
          </div>
          <div className="grid mt-3">
            <div className="col-6">
              <div className="flex justify-content-center font-bold">
                Logo Loading
              </div>
              <VUpload
                urlFile={state.logo_loading}
                setUrlFile={(file) => onChange(file, "logo_loading")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
