import { CKEditor } from "ckeditor4-react";
import { useEffect, useState } from "react";

interface IProps {
  onChange?: any;
  label?: string;
  data?: any;
  errMessage?: string;
  [key: string]: any;
}

const View = ({
  className,
  ref,
  label,
  disabled = false,
  value = null,
  required = false,
  placeholder = "",
  defaultValue = null,
  haveData,
  onChange = () => {},
  ...props
}: IProps) => {
  const [content, setContent] = useState(null);

  const onEditorStateChange = (data) => {
    const value = data.editor.getData();
    if (value) {
      onChange({ html: value });
    }
  };

  useEffect(() => {
    if (value) {
      setContent(value);
    }
  }, [value]);

  return (
    <div className="v-editor">
      {content && (
        <CKEditor
          config={{
            placeholder: "xxxx",
            removePlugins: "sourcearea",
            extraPlugins: "colorbutton, justify, uploadimage",
            versionCheck: false,
          }}
          type={"classic"}
          initData={content}
          data={content}
          onChange={onEditorStateChange}
          readOnly={disabled}
        />
      )}
      {!content && !haveData && (
        <CKEditor
          initData=""
          data=""
          onChange={onEditorStateChange}
          config={{
            versionCheck: false,
          }}
        />
      )}
    </div>
  );
};

export default View;
