import { request, parseErrorResponse } from "./request";
import qs from "query-string";
import { IRequestData } from "./s3-request";

const getAll = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/menus`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getAllAdmin = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/menus/menus-admin`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const search = (req: any) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/menus/search?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const create = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post(`admin/menus`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });
  
const deleted = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .delete("admin/menus/" + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getById = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .get("admin/menus/" + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const update = (req: { params: { id: string }; body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .put("admin/menus/" + req.params.id, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getTreeMenu = (req: IRequestData) =>
  new Promise((rs, rj) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/menus/tree/${req.params.role}?${query} `)
      .then((res) => {
        const { data } = res.data;
        if (data) rs(data);
        else {
          rj(new Error("Something went error"));
        }
      })
      .catch(parseErrorResponse)
      .then(rj);
  });

const filterTreeMenu = (req: any) =>
  new Promise((rs, rj) => {
    const query = qs.stringify(req);
    request()
      .get(`admin/menus/tree-filter?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) rs(data);
        else {
          rj(new Error("Something went error"));
        }
      })
      .catch(parseErrorResponse)
      .then(rj);
  });

const MenuService = {
  search,
  getAll,
  getAllAdmin,
  create,
  deleted,
  getById,
  update,
  getTreeMenu,
  filterTreeMenu,
};

export default MenuService;
