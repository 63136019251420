/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import VAffilate from "components/v-affiliate";
import VReward from "components/v-reward";
import { Panel } from "primereact/panel";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import ProductService from "services/products";
import { showToast } from "utils/common";

const ProductReward = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const defaultState = {
    _id: "",
    reward: {
      codes: [],
      stocks: [],
      tickets: [],
      vouchers: [],
    },
    affiliates: {},
  };

  const [product, setProduct] = useState(defaultState);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data)
      setProduct({
        ...data,
        info_code: {
          ...(data.info_code || {}),
          start_date: data.info_code && new Date(data?.info_code.start_date),
          end_date: data.info_code && new Date(data?.info_code.end_date),
        },
      });
  }, [data]);

  const submit = async () => {
    try {
      setLoading(true);
      await ProductService.updateProducts({
        params: { id: product._id },
        body: {
          ...product,
        },
      });
      setLoading(false);
      showToast(toast, "success", "Success!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", "errors");
    }
  };

  const onChange = (value, name, field = null) => {
    let _details = { ...product };
    if (field) {
      _details[field][`${name}`] = value;
    } else {
      _details[`${name}`] = value;
    }
    setProduct(_details);
  };

  return (
    <>
      <Panel header="Reward" toggleable className="my-2" collapsed={true}>
        <div className="grid">
          <div className="field col-12">
            <VReward
              reward={product.reward}
              setReward={(reward) => onChange(reward, "reward")}
            />
          </div>
        </div>
      </Panel>
      <Panel header="Affilate" toggleable className="my-2" collapsed={true}>
        <div className="grid">
          <div className="field col-12">
            <VAffilate
              affiliates={product.affiliates}
              setAffiliates={(a) => onChange(a, "affiliates")}
            />
          </div>
        </div>
      </Panel>
    </>
  );
};

export default forwardRef(ProductReward);
