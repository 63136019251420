import { Button, Toast } from "primereact";

import React, { useEffect, useRef, useState } from "react";
import SettingService from "services/settings";
import { showToast } from "utils/common";
import VUpload from "components/v-upload";
import { InputText } from "primereact/inputtext";

const View = () => {
  const [state, setState] = useState({
    key: "",
    background: "",
  });
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res: any = await SettingService.getSettingsByName({
      params: {
        name: "shop-settings",
      },
    });
    if (res && res.setting) {
      setState(res.setting.value);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await SettingService.updateSetting({
        body: {
          name: "shop-settings",
          value: state,
        },
      });
      setLoading(false);
      getData();
      showToast(toast, "success", "Update success!");
    } catch (error) {
      showToast(toast, "error", error.errors);
      setLoading(false);
    }
  };

  const handleCancel = async () => {
    try {
      getData();
    } catch (error) {}
  };

  const changeHandler = (name: string, value: string) => {
    setState((state) => ({
      ...state,
      [name]: value,
    }));
  };

  return (
    <div className="grid">
      <Toast ref={toast} />

      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>Shop settings</h4>
          </div>
          <div>
            <label htmlFor="" className="mr-2">
              Key
            </label>
            <InputText
              value={state.key}
              onChange={(e) => changeHandler("key", e.target.value)}
            />
            <VUpload
              label="Background"
              urlFile={state.background}
              setUrlFile={(e) => changeHandler("background", e)}
            />
          </div>
          <Button
            onClick={() => {}}
            icon="bx bxs-add-to-queue"
            className="p-button-rounded p-button-success mb-3"
            aria-label="Search"
          />
          <div className="">
            <Button
              label="Cancel"
              className="p-button-danger mr-2"
              loading={loading}
              onClick={handleCancel}
            />
            <Button label="Submit" loading={loading} onClick={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(View, comparisonFn);
