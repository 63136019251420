/* eslint-disable react-hooks/exhaustive-deps */
// import { setUser } from 'modules/users/users.reducers';
import { MasterContext } from "contexts/MasterContext";
import React, { useEffect } from "react";
import { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import UserService from "../services/users";
import { ROLE } from "utils/enum";

const withAuth = (WrapperComponent: any) => {
  return function Auth(props: JSX.IntrinsicAttributes) {
    const history = useHistory();
    const location = useLocation();
    const { setAuth } = useContext(MasterContext);
    const PAGE_NOT_AUTH = ["login", "signup", "forgot-password"];

    useEffect(() => {
      const didMount = async () => {
        const token = localStorage.getItem("token_auth");
        if (!token) {
          history.push("/login");
        } else {
          UserService.getUserAuth()
            .then((data: any) => {
              const { user } = data;
              if (
                user &&
                (user.role === ROLE.ADMIN || user.role === ROLE.SUB_ADMIN)
              ) {
                setAuth(user);
                if (
                  !PAGE_NOT_AUTH.some((url) => location.pathname.includes(url))
                ) {
                  return;
                } else history.push("/");
                history.push("/");
              } else {
                localStorage.removeItem("token_auth");
                history.push("/login");
              }
            })
            .catch(() => {
              localStorage.removeItem("token_auth");
              history.push("/login");
            });
        }
      };

      didMount();
    }, []);

    return <WrapperComponent {...props} />;
  };
};

export default withAuth;
