/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { formatLocalTime, getUrlImage, showToast } from "utils/common";
import VDialog from "components/v-dialog";
import VConfirm from "components/v-confirm";
import Details from "./components/Details";
import debounce from "lodash.debounce";
import MenuService from "services/menus";
import CategoryService from "services/categories";
import { MENU_ROLE, MENU_STATUS, MENU_TYPE, MENU_TYPE_VIEW } from "utils/enum";
import { CATEGORY_GROUP_KEY } from "utils/constant";
import { TreeTable } from "primereact/treetable";
import { Dropdown } from "primereact/dropdown";
import CategoryFilterService from "services/category-filters";
import StatusEditorCell from "components/v-edit-cell/StatusEditorCell";
import NumberEditorCell from "components/v-edit-cell/NumberEditorCell";
import { Panel } from "primereact/panel";
import EditProfileBanners from "../users/components/edit-profile-banners";
import AuthService from "services/auth";
import { MasterContext } from "contexts/MasterContext";

const Crud = () => {
  const {general} = useContext(MasterContext);
  const [data, setData] = useState([]);  
  const [treeData, setTreeData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [detail, setDetail] = useState(null);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    status: MENU_STATUS.ENABLE,
    type_view: null,
    type: null,
    position: null,
    category_filter_id: null,
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
    position: null,
  });
  const [menusCategory, setMenusCategory] = useState([]);
  const [treeMenus, setTreeMenus] = useState([]);
  const [parentMenu, setParentMenu] = useState({});
  const [userId, setUserId] = useState("");

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);
  const refDetail = useRef(null);
  const refDialogEditBanner = useRef(null);
  const refEditBanner = useRef(null);
  const [categoryFilters, setCategoryFilters] = useState([]);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  useEffect(() => {
    loadTreeMenus();
    loadMenusCategory();
    getCategoryFilters();
  }, []);

  useEffect(() => {
    if (data) {
      const mapAll = allData.map((e: any) => ({
        ...e,
        key: e._id != null ? String(e._id) : null,
        keyVal: e.key,
        parent_id: e.parent_id ? e.parent_id : null,
      }));

      const mapList = data.map((e: any) => ({
        ...e,
        key: e._id != null ? String(e._id) : null,
        keyVal: e.key,
        parent_id: e.parent_id ? e.parent_id : null,
      }));
      const tree = mapList.filter(
        (e: any) =>
          e.parent_id == null ||
          mapAll?.find(
            (item) =>
              String(item._id) !== String(e.parent_id) && e.parent_id !== null
          )
      );
      tree.forEach((el: any) => {
        const items = getItems(mapAll, el._id);
        if (items && items != null && items.length > 0) {
          el.children = items;
          el.leaf = false;
        }
      });

      if (tree.length === 0 && mapList) {
        const treeChild = mapList.map((el: any) => {
          const items = getItems(mapAll, el._id);
          if (items && items != null && items.length > 0) {
            el.children = items;
            el.leaf = false;
          }
          return el;
        });
        setTreeData([...treeChild]);
      } else {
        setTreeData([...tree]);
      }
    } else setTreeData([]);
  }, [data, allData]);

  const getCategoryFilters = async () => {
    try {
      const res: any = await CategoryFilterService.getAll();
      if (res) {
        setCategoryFilters(res);
      }
    } catch (error) {}
  };

  const loadMenusCategory = () => {
    CategoryService.getByGroup({
      query: { group: CATEGORY_GROUP_KEY.MENU },
    }).then((res: any) => {
      if (res) {
        setMenusCategory(res);
      }
    });
  };

  const loadTreeMenus = () => {
    MenuService.getTreeMenu({ params: { role: MENU_ROLE.USER } }).then(
      (res: any) => {
        if (res) {
          setTreeMenus(res);
        }
      }
    );
  };

  const loadData = async () => {
    try {
      const res: any = await MenuService.search({
        query: {
          ...lazyParams,
          ...globalFilter,
          page: 1,
          limit: 9999,
          role: MENU_ROLE.USER,
        },
      });
      if (res && res.docs) {
        setData(res.docs);
        setAllData(res.docs);
      } else {
        setData([]);
        setAllData([]);
      }
    } catch (error) {
      setData([]);
      setAllData([]);
    }
  };

  const getItems = (mapList: any, parent_id: string) => {
    let items = mapList?.filter((e: any) => e.parent_id === parent_id);
    // items.forEach((el: any) => {
    //   const items = getItems(mapList, el._id);
    //   if (items && items != null && items.length > 0) {
    //     el.children = items;
    //     el.leaf = false;
    //   }
    // });
    return items;
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  const onTreeSearch = (keySearch) => {
    let search = keySearch.trim();
    let filterData = allData.filter((e) =>
      e.name.toUpperCase().includes(search.toUpperCase())
    );
    setData(filterData);
  };

  //CREATE OR NEW
  const openNew = () => {
    setDetail(null);
    setParentMenu({});
    refDialogDetail.current.show();
  };
  const openNewChild = (data) => {
    setDetail({ parent_id: data?._id });
    setParentMenu({ id: data?._id, name: data?.name });
    refDialogDetail.current.show();
  };
  const editProduct = (data) => {
    setDetail({ ...data, key: data.keyVal });
    setParentMenu({});
    refDialogDetail.current.show();
  };
  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };
  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };
  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  // Banners
  const editProfileBanners = async (data: any) => {
    setUserId(data.user_id);
    refDialogEditBanner.current.show();
  };
  const onSubmitDialogBanner = () => {
    refEditBanner.current.submit();
  };
  const onCancelDialogBanner = () => {
    refDialogEditBanner.current.close();
  };
  const setLoadingEditBanner = (flg) => {
    refDialogEditBanner.current.setLoading(flg);
  };

  // DELETE
  const confirmDelete = (product) => {
    setDetail(product);
    refDialogDelete.current.show();
  };
  const handleDelete = async () => {
    try {
      await MenuService.deleted({
        params: {
          id: detail._id,
        },
      });
      const _data = data.filter((val) => val._id !== detail._id);
      setData(_data);
      refDialogDelete.current.close();
      setDetail(null);
      showToast(toast, "success", "User Deleted");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const onChangeStatus = async (options, rowData, value) => {
    let updateRow;
    let newData = data.map((e, index) => {
      if (e._id === rowData?._id) {
        e[options.field] = value;
        updateRow = e;
      }
      return e;
    });
    setData(newData);

    if (updateRow) {
      await MenuService.update({
        params: { id: updateRow._id },
        body: {
          ...updateRow,
        },
      });
    }
  };

  const onFiltergroup = async (e) => {
    if (e?.filter) {
      const res: any = await CategoryService.search({
        query: {
          group: CATEGORY_GROUP_KEY.MENU,
          search: e?.filter,
          page: 1,
          limit: 10,
        },
      });
      if (res && res?.docs) {
        const newData = res?.docs?.filter(
          (el) => !menusCategory?.find((item) => item?._id === el?._id)
        );
        setMenusCategory((menusCategory || []).concat(newData));
      }
    }
  };

  const onChangeOrder = debounce(onChangeStatus, 700);
  const onSearchFilterGroup = debounce(onFiltergroup, 700);

  // const orderEditor = (options) => {
  //   return <NumberEditor options={options} onChange={onChangeOrder} />;
  // };

  // const statusEditor = (options) => {
  //   return <StatusEditor options={options} onChange={onChangeStatus} />;
  // };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled={true}
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const postOrigin = general?.post_origin;

  const handleLoginSocial = async (userId: string) => {
    try {
      const userToken = await AuthService.getUserToken({
            userId: userId,
      });
      if (userToken) {
        return window.open(
          `${postOrigin || "http://social.canvanex.com"}?token=${userToken}`
        );
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-plus"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => openNewChild(rowData)}
        />
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editProduct(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning"
          onClick={() => confirmDelete(rowData)}
        />
        <Button
          icon="pi pi-user"
          className="p-button-rounded p-button-sm p-button-warning mr-2"
          onClick={() => editProfileBanners(rowData)}
        />
        <Button
          icon="pi pi-sign-in"
          className="p-button-rounded p-button-sm mr-2"
          onClick={() => handleLoginSocial(rowData.user_id)}
        />
      </div>
    );
  };

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="grid px-2 align-items-center">
        <div className="col-12 md:col-4">
          <span className="block p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e: any) => onTreeSearch(e.target.value)}
              placeholder="Search..."
              className="w-full"
            />
          </span>{" "}
        </div>
        <div className="col-12 md:col-4">
          <Dropdown
            value={globalFilter.position}
            options={menusCategory}
            onChange={(e) => onSearch("position", e.target.value)}
            optionLabel="name"
            filterBy="name"
            optionValue="key"
            filter
            onFilter={(e) => onSearchFilterGroup(e)}
            placeholder="Select group"
            showClear
            className="w-full"
          />
        </div>
        <div className="col-12 md:col-4">
          <Dropdown
            filter
            showClear
            value={globalFilter.category_filter_id}
            options={categoryFilters.map((item) => ({
              label: item.name,
              value: item._id,
            }))}
            optionLabel="label"
            optionValue="value"
            placeholder="Select filter"
            onChange={(e) => onSearch("category_filter_id", e.value)}
            className="w-full"
          />
        </div>
        {/* <Calendar
          className="m-1"
          id="time24"
          placeholder="Start Date"
          value={globalFilter.start}
          onChange={(e: any) => onSearch("start", e.value)}
          showTime
          showSeconds
          showButtonBar
        />{" "}
        <Calendar
          className="m-1"
          id="time24"
          placeholder="End Date"
          value={globalFilter.end}
          onChange={(e: any) => onSearch("end", e.value)}
          showTime
          showSeconds
          showButtonBar
        /> */}
        <div className="col-12 md:col-4">
          <Dropdown
            value={globalFilter.status}
            options={Object.keys(MENU_STATUS).map((k) => ({
              label: k,
              value: MENU_STATUS[k],
            }))}
            onChange={(e: any) => onSearch("status", e.value)}
            optionLabel="label"
            placeholder="Status"
            className={"w-full"}
            showClear
          />
        </div>
        <div className="col-12 md:col-4">
          <Dropdown
            value={globalFilter.type}
            options={Object.keys(MENU_TYPE).map((k) => ({
              label: k,
              value: MENU_TYPE[k],
            }))}
            onChange={(e: any) => onSearch("type", e.value)}
            optionLabel="label"
            placeholder="Type"
            className={"w-full"}
            showClear
          />
        </div>
        <div className="col-12 md:col-4">
          <Dropdown
            value={globalFilter.type_view}
            options={Object.keys(MENU_TYPE_VIEW).map((k) => ({
              label: k,
              value: MENU_TYPE_VIEW[k],
            }))}
            onChange={(e: any) => onSearch("type_view", e.value)}
            optionLabel="label"
            placeholder="Type view"
            className={"w-full"}
            showClear
          />
        </div>
      </div>
    </Panel>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <TreeTable
            ref={dt}
            value={treeData}
            header={header}
            lazy
            first={lazyParams.first}
            rows={lazyParams.rows}
            resizableColumns
            columnResizeMode="expand"
            scrollable
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onPage={onPage}
            onSort={onSort}
          >
            <Column
              body={actionBodyTemplate}
              style={{ flexGrow: 1, width: "16%" }}
            ></Column>
            <Column
              sortable
              expander
              field="name"
              header="Name"
              body={(rowData) => <span>{rowData.name}</span>}
              style={{ flexGrow: 1, width: "25%" }}
            ></Column>
            <Column
              body={iconBody}
              header="Icon"
              style={{ flexGrow: 1, width: "8%" }}
            ></Column>
            <Column
              field="type"
              header="Type"
              body={(rowData) => <span>{rowData.type}</span>}
              style={{ flexGrow: 1, width: "8%" }}
            ></Column>
            {/* <Column
              field="type_view"
              header="Type View"
              body={(rowData) => <span>{rowData.type_view}</span>}
              style={{ flexGrow: 1, width: "8%" }}
            ></Column> */}
            <Column
              // editor={orderEditor}
              field="order"
              sortable
              header="Order"
              body={(rowData, options) => (
                <NumberEditorCell
                  value={rowData.order}
                  options={options}
                  onChange={onChangeOrder}
                />
              )}
              style={{ flexGrow: 1, width: "8%" }}
            ></Column>
            <Column
              // editor={statusEditor}
              field="status"
              header="Status"
              style={{ flexGrow: 1, width: "15%" }}
              // body={(rowData) => (
              //   <span
              //     className={`v-badge status-${rowData.status.toLowerCase()}`}
              //   >
              //     {rowData.status}
              //   </span>
              // )}
              body={(rowData, options) => (
                <StatusEditorCell
                  value={rowData.status}
                  options={options}
                  rowData={rowData}
                  onChange={onChangeStatus}
                />
              )}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "6%" }}
              field="createdAt"
              header="Created At"
              sortable
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>

            <Column
              style={{ flexGrow: 1, flexBasis: "6%" }}
              field="updatedAt"
              header="Updated At"
              sortable
              body={(rowData) => formatLocalTime(rowData.updatedAt)}
            ></Column>
          </TreeTable>

          <VDialog
            ref={refDialogDetail}
            header="Menu Details"
            onSubmit={onSumitDialogDetail}
          >
            <Details
              ref={refDetail}
              data={detail}
              toast={toast}
              treeMenus={treeMenus}
              parentMenu={parentMenu}
              categoryFilters={categoryFilters}
              menusCategory={menusCategory}
              setMenusCategory={setMenusCategory}
              reload={loadData}
              loadTreeMenus={loadTreeMenus}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
              getCategoryFilters={getCategoryFilters}
            />
          </VDialog>

          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />

          <VDialog
            ref={refDialogEditBanner}
            header="Edit profile banners"
            onSubmit={onSubmitDialogBanner}
          >
            <EditProfileBanners
              userId={userId}
              ref={refEditBanner}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingEditBanner}
              onCancel={onCancelDialogBanner}
            />
          </VDialog>
        </div>
      </div>
    </div>
  );
};

const iconBody = (rowData: any) => {
  return (
    <>
      {rowData.img_icon ? (
        <div>
          <img
            src={`${getUrlImage(rowData.img_icon)}`}
            alt={rowData.img_icon}
            style={{ width: "20px" }}
          />
        </div>
      ) : (
        <React.Fragment>
          <i style={{ textAlign: "center" }} className={rowData.icon}>
            &nbsp;
          </i>
        </React.Fragment>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Crud, comparisonFn);
