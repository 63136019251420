/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useContext,
} from "react";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import StockService from "services/stocks";
import { MasterContext } from "contexts/MasterContext";
import VUpload from "components/v-upload";
import { MultiSelect } from "primereact/multiselect";
import VUserDropdown from "components/v-user";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const applyfor = [
    {
      name: "Shop",
      key: "shop",
    },
    {
      name: "Wallet",
      key: "wallet",
    },
    {
      name: "Donate",
      key: "donate",
    },
  ];

  const emptyData = {
    user_id: null,
    name: "",
    contract: "",
    symbol: "",
    supply: 0,
    logo: "",
    status: "enable",
    type: "normal",
    fee_transfer: {
      stock: "vndb",
      percent: 0,
    },
    fee_swap: {
      stock: "vndb",
      percent: 0,
    },
    fee_withdraw: {
      stock: "vndb",
      percent: 0,
    },
    sites: [],
    is_withdraw: false,
    is_deposit: false,
    is_swap: false,
    pairs: [],
    is_advertisement: false,
    web: "",
    VAT: 0,
    is_cv: false,
    is_qrmart: false,
    is_p2p: false,
    is_spot: false,
    locked: {
      profit: {
        status: false,
        percent: 0,
      },
      deposit: {
        status: false,
        percent: 0,
      },
      swap: {
        status: false,
        percent: 0,
      },
      revenue_system: {
        status: false,
        percent: 0,
      },
      revenue_campaign: {
        status: false,
        percent: 0,
      },
      trust_project_commission: {
        status: false,
        percent: 0,
      },
    },
    applyForStocks: [],
  };

  const { stocks, sites } = useContext(MasterContext);
  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
  }, [data]);

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || e.value || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const changeApplyStocks = (value: Array<string>) => {
    setDetails((state) => ({
      ...state,
      applyForStocks: value,
    }));
  };

  const onChangeFee = (type, name, value) => {
    setDetails({
      ...data,
      [type]: {
        ...data[type],
        [name]: value,
      },
    });
  };

  const onChangeLock = (type, name, value) => {
    setDetails((data) => ({
      ...data,
      locked: {
        ...data.locked,
        [type]: {
          ...data.locked[type],
          [name]: value,
        },
      },
    }));
  };

  const onDropdownChange = (e, name) => {
    const val = e.value || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const onInputCheckChange = (e, name) => {
    const val = e.value || false;
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await StockService.updateCryptos({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else {
        await StockService.createCryptos({
          body: {
            ...details,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Stock saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="col-12">
        <VUpload
          urlFile={details.logo}
          setUrlFile={(file) => {
            setDetails((prev) => ({
              ...prev,
              logo: file,
            }));
          }}
        />
      </div>
      <div className="field col-12">
        <label htmlFor="name">User</label>
        <VUserDropdown
          value={details.user_id}
          setValue={(e) =>
            onInputChange(
              {
                value: e,
              },
              "user_id"
            )
          }
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="name">Sites</label>
        <MultiSelect
          filter
          value={details.sites}
          options={sites}
          optionLabel="name"
          optionValue="domain"
          onChange={(e) => onInputChange(e, "sites")}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="type">Type</label>
        <Dropdown
          value={details.type}
          options={[
            {
              label: "Normal",
              value: "normal",
            },
            {
              label: "Reward",
              value: "reward",
            },
          ]}
          onChange={(e) => onDropdownChange(e, "type")}
          optionLabel="label"
          placeholder="Select type"
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onInputChange(e, "name")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="symbol">Symbol</label>
        <InputText
          id="symbol"
          value={details.symbol}
          onChange={(e) => onInputChange(e, "symbol")}
          required
          autoFocus
        />
      </div>

      <div className="field col-12 md:col-12">
        <label htmlFor="contract">Contract</label>
        <InputText
          id="contract"
          value={details.contract}
          onChange={(e) => onInputChange(e, "contract")}
          required
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Status</label>
        <Dropdown
          value={details.status}
          options={[
            { label: "Enable", value: "enable" },
            { label: "Disable", value: "disable" },
            { label: "Pending", value: "pending" },
          ]}
          onChange={(e) => onDropdownChange(e, "status")}
          optionLabel="label"
          placeholder="Select status"
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="web">Website</label>
        <InputText
          id="web"
          value={details.web}
          onChange={(e) => onInputChange(e, "web")}
          required
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Fee Swap (Stocks)</label>
        <Dropdown
          value={details.fee_swap.stock}
          options={stocks}
          onChange={(e) => onChangeFee("fee_swap", "stock", e.value)}
          optionLabel="symbol"
          optionValue="symbol"
          placeholder="Stocks"
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Percent</label>
        <InputNumber
          id="fee_swap"
          prefix="%"
          value={details.fee_swap.percent}
          mode="decimal"
          minFractionDigits={2}
          onChange={(e) => onChangeFee("fee_swap", "percent", e.value)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Fee Transfer (Stocks)</label>
        <Dropdown
          value={details.fee_transfer.stock}
          options={stocks}
          onChange={(e) => onChangeFee("fee_transfer", "stock", e.value)}
          optionLabel="symbol"
          optionValue="symbol"
          placeholder="Stocks"
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Percent</label>
        <InputNumber
          id="fee_transfer"
          prefix="%"
          value={details.fee_transfer.percent}
          mode="decimal"
          minFractionDigits={2}
          onChange={(e) => onChangeFee("fee_transfer", "percent", e.value)}
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Fee Withdraw (Stocks)</label>
        <Dropdown
          value={details.fee_withdraw.stock}
          options={stocks}
          onChange={(e) => onChangeFee("fee_withdraw", "stock", e.value)}
          optionLabel="symbol"
          optionValue="symbol"
          placeholder="Stocks"
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Percent</label>
        <InputNumber
          id="fee_withdraw"
          prefix="%"
          value={details.fee_withdraw.percent}
          mode="decimal"
          minFractionDigits={2}
          onChange={(e) => onChangeFee("fee_withdraw", "percent", e.value)}
        />
      </div>

      {Object.keys(details.locked).map((key) => {
        return (
          <>
            <div className="field col-8 md:col-9">
              <label htmlFor="description">Percent Lock ({key})</label>
              <InputNumber
                prefix="%"
                value={details.locked[key].percent}
                mode="decimal"
                minFractionDigits={2}
                onChange={(e) => onChangeLock(key, "percent", e.value)}
                max={100}
              />
            </div>
            <div className="field col-4 md:col-3">
              <label htmlFor="swap">Lock ({key})</label>
              <br />
              <InputSwitch
                checked={details.locked[key].status}
                onChange={(e) => onChangeLock(key, "status", e.value)}
              />
            </div>
          </>
        );
      })}

      {/* On off option */}
      <div className="field col-6 md:col-3">
        <label htmlFor="swap">Swap</label>
        <br />
        <InputSwitch
          checked={details.is_swap}
          onChange={(e) => onInputCheckChange(e, "is_swap")}
        />
      </div>
      <div className="field col-6 md:col-3">
        <label htmlFor="id_region">Withdraw</label>
        <br />
        <InputSwitch
          checked={details.is_withdraw}
          onChange={(e) => onInputCheckChange(e, "is_withdraw")}
        />
      </div>
      <div className="field col-6 md:col-3">
        <label htmlFor="id_province">Deposit</label>
        <br />
        <InputSwitch
          checked={details.is_deposit}
          onChange={(e) => onInputCheckChange(e, "is_deposit")}
        />
      </div>
      <div className="field col-6 md:col-3">
        <label htmlFor="id_province">Advertisement</label>
        <br />
        <InputSwitch
          checked={details.is_advertisement}
          onChange={(e) => onInputCheckChange(e, "is_advertisement")}
        />
      </div>

      <div className="field col-6 md:col-3">
        <label htmlFor="is_swap">Spot</label>
        <br />
        <InputSwitch
          checked={details.is_spot}
          onChange={(e) => onInputCheckChange(e, "is_spot")}
        />
      </div>
      <div className="field col-6 md:col-3">
        <label htmlFor="is_claim">P2P</label>
        <br />
        <InputSwitch
          checked={details.is_p2p}
          onChange={(e) => onInputCheckChange(e, "is_p2p")}
        />
      </div>
      <div className="field col-6 md:col-3">
        <label htmlFor="is_deposit">CV</label>
        <br />
        <InputSwitch
          checked={details.is_cv}
          onChange={(e) => onInputCheckChange(e, "is_cv")}
        />
      </div>
      <div className="field col-6 md:col-3">
        <label htmlFor="is_withdraw">QR Mart</label>
        <br />
        <InputSwitch
          checked={details.is_qrmart}
          onChange={(e) => onInputCheckChange(e, "is_qrmart")}
        />
      </div>
      <div className="feild col-12">
        <label htmlFor="">Apply for</label>
        <div>
          <MultiSelect
            className="w-full"
            filter
            value={details.applyForStocks}
            onChange={(e) => changeApplyStocks(e.value)}
            options={applyfor}
            optionLabel="name"
            placeholder="Choose"
            optionValue="key"
          />
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Details);
