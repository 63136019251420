/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import KycService from "services/kycs";
import { Image } from "primereact/image";
import {
  formatLocalTime,
  formatNumber,
  getUrlImage,
  showToast,
} from "utils/common";
import Details from "./components/Details";
import VDialog from "components/v-dialog";
import VConfirm from "components/v-confirm";
import { Calendar } from "primereact/calendar";
import debounce from "lodash.debounce";
import { MultiSelect } from "primereact";
import PaymentService from "services/payments";
import VUserDropdown from "components/v-user";
import StatusEditorCell from "components/v-edit-cell/StatusEditorCell";
import { Panel } from "primereact/panel";

const STATUS = [
  { name: "Pending", value: "pending" },
  { name: "Success", value: "success" },
  { name: "Declined", value: "error" },
];

const STOCKS = [
  { name: "VND", value: "vnd" },
  { name: "USDT", value: "usdt" },
];

const View = () => {
  const [, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState(null);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    status: ["pending"],
    currency: ["vnd"],
    type: "deposit",
    userId: null,
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [sum, setSum] = useState([]);

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);
  const refDetail = useRef(null);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await PaymentService.search({
        query: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
        },
      });
      if (res && res.content) {
        setData(res.content);
        setSum(res.sum || []);
        setTotalRecords(res.total);
      } else setData([]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  //CREATE OR NEW
  const openNew = () => {
    setDetail(null);
    refDialogDetail.current.show();
  };
  const editProduct = (data) => {
    setDetail({ ...data });
    refDialogDetail.current.show();
  };
  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };
  const onSumitDialogDetail = () => {
    refDetail.current.submit("update");
  };
  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  // DELETE
  const confirmDelete = (product) => {
    setDetail(product);
    refDialogDelete.current.show();
  };
  const handleDelete = async () => {
    try {
      await KycService.deleteKYC({
        params: {
          id: detail._id,
        },
      });
      const _data = data.filter((val) => val._id !== detail._id);
      setData(_data);
      refDialogDelete.current.close();
      setDetail(null);
      showToast(toast, "success", "Kyc Deleted");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const onChangeStatus = async (options, rowData, value) => {
    let updateRow;
    let newData = data.map((e, index) => {
      if (e._id === rowData._id) {
        e[options.field] = value;
        updateRow = e;
      }
      return e;
    });
    setData(newData);

    if (updateRow) {
      await PaymentService.updatePayment({
        params: { id: updateRow._id },
        body: {
          ...updateRow,
        },
      });
    }
  };

  // const statusEditor = (options) => {
  //   return <StatusEditor options={options} onChange={onChangeStatus} />;
  // };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
            disabled
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editProduct(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning"
          onClick={() => confirmDelete(rowData)}
          disabled
        />
      </div>
    );
  };
  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="grid px-2 align-items-center">
        <div className="col-12 md:col-4">
          <span className="block p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e: any) => onSearch("search", e.target.value)}
              placeholder="Search..."
              className="w-full"
            />
          </span>{" "}
        </div>
        <div className="col-12 md:col-4">
          <Calendar
            className="w-full"
            id="time24"
            placeholder="Start Date"
            value={globalFilter.start}
            onChange={(e: any) => onSearch("start", e.value)}
            showTime
            showSeconds
            showButtonBar
          />{" "}
        </div>
        <div className="col-12 md:col-4">
          <Calendar
            className="w-full"
            id="time24"
            placeholder="End Date"
            value={globalFilter.end}
            onChange={(e: any) => onSearch("end", e.value)}
            showTime
            showSeconds
            showButtonBar
          />
        </div>
        <div className="col-12 md:col-4">
          <MultiSelect
            value={globalFilter.status}
            options={STATUS}
            onChange={(e: any) => onSearch("status", e.value)}
            optionLabel="name"
            placeholder="Status"
            maxSelectedLabels={3}
            className={"w-full"}
          />
        </div>
        <div className="col-12 md:col-4">
          <MultiSelect
            value={globalFilter.currency}
            options={STOCKS}
            onChange={(e: any) => onSearch("currency", e.value)}
            optionLabel="name"
            optionValue="value"
            placeholder="Currency"
            maxSelectedLabels={3}
            className={"w-full"}
          />
        </div>
        <div className="col-12 md:col-4">
          <VUserDropdown
            value={globalFilter.userId}
            setValue={(v) => onSearch("userId", v)}
            className="w-full"
          />
        </div>
      </div>
    </Panel>
  );

  const footer = (
    <div>
      {sum.map((s) => {
        return (
          <div>
            <span>
              {" "}
              {s._id} : {formatNumber(Math.abs(s.totalAmount))}
            </span>
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={data}
            header={header}
            footer={footer}
            emptyMessage="No data found."
            dataKey="id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            //panigate
            paginator
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 20, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            //sort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onSort}
          >
            <Column
              frozen
              alignFrozen="left"
              body={actionBodyTemplate}
              style={{ flexGrow: 1, flexBasis: "160px" }}
            ></Column>
            <Column
              field="Proof"
              header="Proof"
              frozen
              body={(rowData) =>
                rowData.proof && (
                  <Image
                    src={getUrlImage(rowData.proof)}
                    alt="Image Text"
                    height="50"
                    width="50"
                    preview
                  />
                )
              }
              style={{ flexGrow: 1, flexBasis: "80px" }}
            ></Column>
            <Column
              frozen
              field="refer_code"
              header="Id"
              style={{ flexGrow: 1, flexBasis: "150px" }}
              body={(rowData) => (
                <span>{rowData.user_id?.refer_code || ""}</span>
              )}
            ></Column>
            <Column
              field="email"
              header="Email"
              style={{ flexGrow: 1, flexBasis: "350px" }}
              body={(rowData) => <span>{rowData.user_id?.email || ""}</span>}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="stock"
              header="Stock"
              sortable
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="amount"
              header="Amount"
              sortable
              body={(rowData) => formatNumber(rowData.amount)}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="amount_received"
              header="Amount received"
              sortable
              body={(rowData) => formatNumber(rowData.amount_received)}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="VAT"
              header="VAT"
              sortable
              body={(rowData) => formatNumber(rowData.VAT)}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="content"
              header="Content"
              sortable
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="network"
              header="Network"
              sortable
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="currency"
              header="Currency"
              sortable
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "300px" }}
              field="bank"
              header="Bank"
              body={(rowData) =>
                rowData.bank
                  ? `${rowData.bank.bank_name}-${rowData.bank.account_name}-${rowData.bank.account_number}`
                  : `N/A`
              }
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "100px" }}
              field="address"
              header="Address"
            ></Column>
            <Column
              // editor={statusEditor}
              style={{ flexGrow: 1, flexBasis: "180px" }}
              field="status"
              header="Status"
              sortable
              // body={(rowData) => (
              //   <span
              //     className={`v-badge status-${rowData.status.toLowerCase()}`}
              //   >
              //     {rowData.status}
              //   </span>
              // )}
              body={(rowData, options) => (
                <StatusEditorCell
                  value={rowData.status}
                  options={options}
                  rowData={rowData}
                  onChange={onChangeStatus}
                />
              )}
            ></Column>

            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="createdAt"
              header="Created At"
              sortable
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>
          </DataTable>

          <VDialog
            ref={refDialogDetail}
            header="Payment Details"
            onSubmit={onSumitDialogDetail}
          >
            <Details
              ref={refDetail}
              data={detail}
              toast={toast}
              statuses={STATUS}
              reload={loadData}
              setLoadingSaveDetail={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
            />
          </VDialog>

          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(View, comparisonFn);
