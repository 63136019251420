import { Card } from "primereact/card";
import { forwardRef } from "react";
import { useState } from "react";
import { useImperativeHandle } from "react";
import { showToast } from "utils/common";
import EventsService from "services/events";
import { InputNumber } from "primereact/inputnumber";

const Roles = (props, ref) => {
  const { event, toast, onCancel, setLoading } = props;
  
  const [data, setData] = useState(event);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submitHandler();
    },
  }));

  const onInputChange = (e, name) => {
    setData({
      ...data,
      event: {
        ...data?.event,
        [name]: Number(e.value)
      }
    })
  }

  const submitHandler = async () => {
    try {
      setLoading(true);
      await EventsService.updateEventById({
        params: {
          id: data?._id,
        },
        body: {
          ...data?.event,
        },
      });
      onCancel();
      showToast(toast, "success", "Successfully!");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };


  return (
    <div>
      <Card header={<h5>Edit View</h5>}>
        <div className="grid">
          <div className="field col-12">
            <label htmlFor="name">Care Abouts</label>
            <InputNumber
              id="ca"
              value={data?.event?.ca}
              onChange={(e) => onInputChange(e, "ca")}
              required
              autoFocus
            />
          </div>
          <div className="field col-12">
            <label htmlFor="name">Participations</label>
            <InputNumber
              id="pa"
              value={data?.event?.pa}
              onChange={(e) => onInputChange(e, "pa")}
              required
              autoFocus
            />
          </div>
          <div className="field col-12">
            <label htmlFor="name">Share</label>
            <InputNumber
              id="sh"
              value={data?.event?.sh}
              onChange={(e) => onInputChange(e, "sh")}
              required
              autoFocus
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default forwardRef(Roles);
