/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useImperativeHandle } from "react";
import { getUrlImage, showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";

import PostCommentService from "services/post-comments";
import { Image } from "primereact/image";

const emptyData = null;

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;
  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data)
      setDetails({
        ...data,
      });
  }, [data]);

  const onChange = (name, value) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };
  const submit = async () => {
    try {
      setLoading(true);
      await PostCommentService.update({
        params: {
          id: data._id,
        },
        body: {
          admin_status: details.admin_status,
          post_privacy: details.post_privacy,
        },
      });
      setLoading(false);
      showToast(toast, "success", "Success!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <Toast ref={toast} />
      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>Post Comment Detail</h4>
          </div>

          <div className="grid">
            <div className="field col-12">
              <Dropdown
                defaultValue={details?.admin_status}
                value={details?.admin_status}
                options={[
                  {
                    label: "ENABLE",
                    value: "enable",
                  },
                  {
                    label: "DISABLE",
                    value: "disable",
                  },
                ]}
                onChange={(e) => onChange("admin_status", e.value)}
                optionLabel="label"
                placeholder="Select status"
              />
            </div>
            {details && (
              <div className="field col-12">
                <div>
                  <div>
                    <label htmlFor="content">Content: </label>
                    <span>{details.content}</span>
                    <hr />
                  </div>
                  <div>
                    <label htmlFor="stickers">Stickers: </label>
                    <div className="flex flex-wrap">
                      {details.comment_stickers.map(
                        (sticker: { [key: string]: string }) => (
                          <Image
                            preview
                            width="100"
                            src={getUrlImage(sticker.comment_file_path)}
                          />
                        )
                      )}
                    </div>
                    <hr />
                  </div>
                  <div>
                    <label htmlFor="files">Files: </label>
                    <div className="flex flex-wrap">
                      {details.comment_files.map(
                        (file: { [key: string]: string }) => (
                          <Image
                            preview
                            width="100"
                            src={getUrlImage(file.comment_file_path)}
                          />
                        )
                      )}
                    </div>
                    <hr />
                  </div>
                  <div>
                    <label htmlFor="tagged_users">Tagged Users: </label>
                    <div className="flex flex-wrap"></div>
                    <hr />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Details);
