/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import SettingService from "services/settings";
import FormAdditional from "components/v-form-additional";
import { nanoid } from "nanoid";
import VUpload from "components/v-upload";

const Details = (props, ref) => {
  const { data, valueSetting, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    id: nanoid(),
    name: '',
    icon: "",
    key: '',
    type: '',
    order: '',
    fields: [],
    object_type: '',
    attachments: {
      enable: false,
      guide: null
    }
  };

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  const onChange = (name, value) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  }

  useEffect(() => {
    if (data) setDetails(data);
  }, [data]);

  const submit = async () => {
    try {
      setLoading(true);
      if (valueSetting) {
        const isEdit = valueSetting.find(el => el.id === details.id)
        if(isEdit){
          const newData = valueSetting.map(el => el.id === details.id ? details : el)
          await SettingService.updateSetting({
            body: {
              name: "invoice",
              value: newData,
            },
          });
        } else {
          await SettingService.updateSetting({
            body: {
              name: "invoice",
              value: [
                ...valueSetting,
                details
              ],
            },
          });
        }
       
      } else
        await SettingService.createSetting({
          body: {
            name: "invoice",
            value: [details],
          },
        });
      setLoading(false);
      showToast(toast, "success", "File saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div>
      <div className="field col-12">
        <label>Icon</label>
        <div className="mt-2">
          <VUpload
            urlFile={details.icon}
            setUrlFile={(file) => onChange("icon", file)}
          />
        </div>
      </div>
      <FormAdditional
        dataForm={details}
        setDataForm={setDetails}
      />
    </div>
  );
};

export default forwardRef(Details);
