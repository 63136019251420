import React, { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";

import Home from "./components/Home/Home";
import Documents from "./components/Documents/Document";
import NFT from "./components/NFT/NFT";
import Projects from "./components/Projects/Projects";
import Tokenomics from "./components/Tokenomics/Tokenomics";

const View = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>Landing Pages</h4>
          </div>

          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            <TabPanel header="Home">
              <Home />
            </TabPanel>
            <TabPanel header="Documents">
              <Documents />
            </TabPanel>
            <TabPanel header="NFT">
              <NFT />
            </TabPanel>
            <TabPanel header="Tokenomics">
              <Tokenomics />
            </TabPanel>
            <TabPanel header="Projects">
              <Projects />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </div>
  );
};

export default View;
