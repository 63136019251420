import React, { useEffect, useRef, useState } from "react";
import { Button, Toast } from "primereact";
import VEditor from "components/v-editor";
import SettingService from "services/settings";
import { showToast } from "utils/common";

const View = () => {
  const toast = useRef(null);
  const [state, setState] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = async () => {
    try {
      getData();
    } catch (error) {}
  };

  const getData = async () => {
    try {
      const res: any = await SettingService.getSettingsByName({
        params: {
          name: "landing_page",
        },
      });

      if (res) {
        const { setting } = res;
        if (setting) {
          const whitepaper = setting.value.whitepaper;
          if (typeof whitepaper === 'string' || whitepaper instanceof String) {
            setState(whitepaper+"");
          } else {
            setState('');
          }
        }
      }
    } catch (error) {}
  };

  const submitHandler = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      const _value = {
        whitepaper: state,
      };
      await SettingService.updateSetting({
        body: {
          name: "landing_page",
          value: _value,
        },
      });
      await getData();
      showToast(toast, "success", "Update dsuccess!");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <Toast ref={toast} />

      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>Whitepaper</h4>
          </div>
          <div className="grid mb-3">
            <div className="field col-12">
              {state && (
                // <Editor
                //   value={state}
                //   onTextChange={(e) => setState(e.htmlValue)}
                //   style={{ height: "350px" }}
                // />
                <VEditor
                  className="h-20rem"
                  value={state}
                  onChange={(e) => {
                    setState(e?.html);
                  }}
                  haveData={state ? true : false}
                />
              )}
              {!state && (
                // <Editor
                //   value={state}
                //   onTextChange={(e) => setState(e.htmlValue)}
                //   style={{ height: "350px" }}
                // />
                <VEditor
                  className="h-20rem"
                  value={state}
                  onChange={(e) => {
                    setState(e?.html);
                  }}
                  haveData={state ? true : false}
                />
              )}
            </div>
          </div>
          <div className="">
            <Button
              label="Cancel"
              className="p-button-danger mr-2"
              loading={loading}
              onClick={handleCancel}
            />
            <Button label="Submit" loading={loading} onClick={submitHandler} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
