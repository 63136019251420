import qs from 'qs';
import { parseErrorResponse, request } from './request';

const createPageBackground = (req: { body: any; }) =>
	new Promise((resolve, reject) => {
		request()
			.post('/admin/page-backgrounds', req.body)
			.then((res) => {
				const { data } = res.data;
				if (data) resolve(data);
				else reject(new Error('Something went error!'));
			})
			.catch(parseErrorResponse)
			.then(reject);
	});

const updatePageBackground = (req: { params: { id: any }; body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .put(`admin/page-backgrounds/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const deletePageBackground = (req: { params: { id: any } }) =>
  new Promise((resolve, reject) => {
    request()
      .delete(`admin/page-backgrounds/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getPageBackgrounds = () =>
	new Promise((resolve, reject) => {
		request()
			.get('/admin/page-backgrounds')
			.then((res) => {
				const { data } = res.data;
				if (data) resolve(data);
				else reject(new Error('Something went error!'));
			})
			.catch(parseErrorResponse)
			.then(reject);
	});

const search = (req: any) =>
new Promise((resolve, reject) => {
		const query = qs.stringify(req.query);
		request()
			.get(`/admin/page-backgrounds/search?${query}`)
			.then((res) => {
				const { data } = res.data;
				if (data) resolve(data);
				else reject(new Error('Something went error!'));
			})
			.catch(parseErrorResponse)
			.then(reject);
	});

const PageBackgroundsService = {
	createPageBackground,
	updatePageBackground,
	deletePageBackground,
	getPageBackgrounds,
	search
};

export default PageBackgroundsService;