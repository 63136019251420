/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import VUpload from "components/v-upload";
import { InputNumber, InputSwitch, Panel } from "primereact";
import StarService from "services/stars";
import { Dropdown } from "primereact/dropdown";
import { CATEGORY_POST, STAR_FEELING_TYPE } from "utils/enum";
import CategoryPostService from "services/category-posts";
import CategoryFilterDetails from "components/v-filter";
import VEditor from "components/v-editor";

const Details = (props, ref) => {
  const {
    data,
    reload,
    toast,
    onCancel,
    setLoading,
    categoryFilters,
    getCategoryFilters,
  } = props;

  const emptyData = {
    name: "",
    image: "",
    link: "",
    status: "enable",
    key: "",
    price: 0,
    category_id: null,
    type: STAR_FEELING_TYPE.FEELING,
    category_filter_id: null,
    order: 0,
    description: "",
  };

  const [details, setDetails] = useState(emptyData);
  const [categories, setCategories] = useState([]);

  const refDetailCategory = useRef(null);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setDetails({
      ...emptyData,
      ...data
    });
    getCategories();
  }, [data]);

  const onChange = (name, value) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };

  const onChaneEditor = (name, value) => {
    setDetails(prev => ({
      ...prev,
      [name]: value
    }))
  }
  
  const getCategories = () => {
    CategoryPostService.getCategories({
      query: {
        category: CATEGORY_POST.FEELING,
      },
    }).then((res: any) => {
      if (res && res.categories) {
        const data = [...res.categories].map((i) => ({
          label: i.name.toString(),
          value: i._id,
        }));
        setCategories(data);
      }
    });
  };

  const submit = async () => {
    try {
      setLoading(true);
      let category = null;
      if (!details.category_filter_id) {
        category = await refDetailCategory.current.submit();
        getCategoryFilters();
      }

      if (data) {
        await StarService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      } else
        await StarService.create({
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      setLoading(false);
      showToast(toast, "success", "File saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12">
        <label htmlFor="status">Filter</label>
        <Dropdown
          filter
          showClear
          value={details.category_filter_id}
          options={categoryFilters.map((item) => ({
            label: item.name,
            value: item._id,
          }))}
          optionLabel="label"
          optionValue="value"
          placeholder="Select filter"
          onChange={(e) => onChange("category_filter_id", e.value)}
        />
      </div>
      <div className="field col-12  md:col-6">
        <label htmlFor="name">Category</label>
        <Dropdown
          value={details.category_id}
          options={categories}
          onChange={(e) => onChange("category_id", e.value)}
          optionLabel="label"
          placeholder="Select category"
        />
      </div>
      <div className="field col-6">
        <label htmlFor="key">Type</label>
        <Dropdown
          value={details.type}
          options={Object.keys(STAR_FEELING_TYPE).map((k) => ({
            label: k,
            value: STAR_FEELING_TYPE[k],
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("type", e.value)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange("name", e.target.value)}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="order">Order</label>
        <InputNumber
          value={details.order}
          onChange={(e) => onChange("order", e.value)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="price">Price (VND)</label>
        <InputNumber
          id="price"
          value={details.price}
          onChange={(e) => onChange("price", e.value)}
          required
        />
      </div>

      <div className="field col-6">
        <label htmlFor="name">Website</label>
        <InputText
          id="link"
          value={details.link}
          onChange={(e) => onChange("link", e.target.value)}
          required
        />
      </div>
      <div className="field col-12">
        <label htmlFor="is_deposit">Status</label>
        <br />
        <InputSwitch
          checked={details.status === "enable"}
          onChange={(e) => onChange("status", e.value ? "enable" : "disable")}
        />
      </div>

      <div className="field col-12">
        <VUpload
          urlFile={details.image}
          setUrlFile={(file) => onChange("image", file)}
        />
      </div>

      <div className="field col-12">
        <label htmlFor="description">Description</label>
        <br />
        <VEditor
          value={details.description}
          onChange={(e) => onChaneEditor("description", e?.html)}
          haveData={details.description ? true : false}
        />
      </div>

      {!details.category_filter_id && (
        <div className="field col-12">
          <Panel header="Filter" toggleable className="my-2" collapsed={false}>
            <div className="grid">
              <div className="field col-12">
                <CategoryFilterDetails ref={refDetailCategory} />
              </div>
            </div>
          </Panel>
        </div>
      )}
    </div>
  );
};

export default forwardRef(Details);
