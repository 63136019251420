import { request, parseErrorResponse } from "./request";
import qs from "query-string";

const search = (req: { query: any }) =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/apply-captchas/search?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });
const create = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post(`admin/apply-captchas`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const update = (req: { body: any; params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .put(`admin/apply-captchas/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const deleted = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .delete(`admin/apply-captchas/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const ApplyCaptchaService = {
  search,
  update,
  deleted,
  create,
};

export default ApplyCaptchaService;
