import VUpload from "components/v-upload";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import React from "react";
import { IObject } from "types";
import { v4 as uuidv4 } from "uuid";

type IProps = {
  data: any;
  onChange: any;
};

const ActionsField = (props: IProps) => {
  const { data, onChange } = props;

  const handleAddBanner = () => {
    const _actions = [
      ...data,
      {
        id: uuidv4(),
        type: "text",
        name: "",
        link: "",
        background_color: "",
        button_image: "",
        color: "",
      },
    ];
    onChange(_actions, "action_button");
  };

  const handleSubBanner = (id: string) => {
    const _actions = data.filter((el) => el.id !== id);
    onChange(_actions, "action_button");
  };
  const handleChange = (value: any, name: string, index: number) => {
    const _actions = data;
    _actions[index] = { ..._actions[index], [name]: value };
    onChange(_actions, "action_button");
  };

  return (
    <>
      {data?.length > 0 &&
        data?.map((action: IObject, index) => (
          <div className="relative field col-12 p-3" key={action.id}>
            <div className="absolute right-0 top-0">
              <Button
                icon="pi pi-times"
                className="w-2rem h-2rem p-button-rounded p-button-danger"
                aria-label="Sub"
                onClick={(_) => handleSubBanner(action.id)}
              />
            </div>
            <Panel
              header={`Action ${index + 1}`}
              toggleable
              collapsed={true}
              className="my-2"
            >
              <div className="flex align-items-center">
                <label className="font-bold mr-2" htmlFor="type">
                  Type:
                </label>
                <Dropdown
                  options={[
                    {
                      label: "Text",
                      value: "text",
                    },
                    {
                      label: "Image",
                      value: "image",
                    },
                  ]}
                  value={action.type}
                  onChange={(e: any) => handleChange(e.value, "type", index)}
                  className="flex-1"
                />
              </div>
              {action.type === "text" && (
                <div className="grid col-12">
                  <div className="field col-12 md:col-6">
                    <label htmlFor="name">Name</label>
                    <InputText
                      placeholder="Name"
                      value={action.name}
                      onChange={(e: any) =>
                        handleChange(e.target.value, "name", index)
                      }
                    />
                  </div>
                  <div className="field col-12 md:col-6">
                    <label htmlFor="link">Link</label>
                    <InputText
                      placeholder="Link"
                      value={action.link}
                      onChange={(e: any) =>
                        handleChange(e.target.value, "link", index)
                      }
                    />
                  </div>
                  <div className="field col-12 md:col-6 ">
                    <label htmlFor="color">Text color</label>
                    <div className="flex align-items-center">
                      <InputText
                        placeholder="Text color"
                        value={action.color}
                        onChange={(e: any) =>
                          handleChange(e.target.value, "color", index)
                        }
                      />
                      <input
                        type="color"
                        value={action.color}
                        onChange={(e: any) =>
                          handleChange(e.target.value, "color", index)
                        }
                      />
                    </div>
                  </div>
                  <div className="field col-12 md:col-6 ">
                    <label htmlFor="color">Background color</label>
                    <div className="flex align-items-center">
                      <InputText
                        placeholder="Background color"
                        value={action.background_color}
                        onChange={(e: any) =>
                          handleChange(
                            e.target.value,
                            "background_color",
                            index
                          )
                        }
                      />
                      <input
                        type="color"
                        value={action.background_color}
                        onChange={(e: any) =>
                          handleChange(
                            e.target.value,
                            "background_color",
                            index
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
              {action.type === "image" && (
                <div className="grid col-12">
                  <div className="field col-12">
                    <VUpload
                      urlFile={action.button_image}
                      setUrlFile={(file) =>
                        handleChange(file, "button_image", index)
                      }
                    />
                  </div>
                  <div className="field col-12">
                    <label htmlFor="link">Link</label>
                    <InputText
                      placeholder="Link"
                      value={action.link}
                      onChange={(e: any) =>
                        handleChange(e.target.value, "link", index)
                      }
                    />
                  </div>
                </div>
              )}
            </Panel>
          </div>
        ))}
      <div className="w-full flex justify-content-center">
        <Button
          icon="pi pi-plus"
          className="p-button-rounded p-button-success"
          aria-label="Add"
          onClick={(_) => handleAddBanner()}
        />
      </div>
    </>
  );
};

export default ActionsField;
