import { parseErrorResponse, request } from "./request";
import qs from "query-string";

const search = (req: any) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/campaigns/search?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getCampaigns = () =>
  new Promise((resolve, reject) => {
    request()
      .get("/admin/campaigns")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getCampaignById = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .get("/admin/campaigns/" + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const createCampaign = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post("/admin/campaigns", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const editCampaign = (req: { params: { id: any }; body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .put(`/admin/campaigns/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const deleteCampaign = (req: { params: { id: any } }) =>
  new Promise((resolve, reject) => {
    request()
      .delete(`/admin/campaigns/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const approvedFile = (req: any) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/admin/campaigns/approveFile`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getRevenues = (req: any) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/admin/revenues`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const CampaignService = {
  getCampaigns,
  createCampaign,
  getCampaignById,
  deleteCampaign,
  editCampaign,
  approvedFile,
  getRevenues,
  search,
};

export default CampaignService;
