import React, { useEffect, useRef, useState } from "react";
import { Button, Toast } from "primereact";
import SettingService from "services/settings";
import { showToast } from "utils/common";
import { nanoid } from "nanoid";
import Groups from "./Group";
import { InputText } from "primereact/inputtext";
import Permissions from "./Permissions";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";

const StockPrices = () => {
  const toast = useRef(null);
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res: any = await SettingService.getSettingsByName({
        params: {
          name: "roles",
        },
      });

      if (res) {
        const { setting } = res;
        if (setting) {
          const value = setting["value"] ? setting["value"] : {};
          const array = Object.keys(value).map((key, index) => {
            return {
              index,
              role_name: key,
              ...value[key],
            };
          });
          setState(array);
        }
      }
    } catch (error) {}
  };

  const handleCancel = async () => {
    try {
      getData();
    } catch (error) {}
  };

  const changeHandler = (value, name, index) => {
    const arr = state.map((i) =>
      i.index === index
        ? {
            ...i,
            [name]: value,
          }
        : i
    );
    setState(arr);
  };

  const onChangeDefault = (id) => {
    setState((prev) =>
      prev.map((el) =>
        el.index === id ? { ...el, default: true } : { ...el, default: false }
      )
    );
  };

  const handleSubIndex = (index) => {
    const newState = [...state].filter((i) => i.index !== index);
    setState(newState);
  };

  const handleAdd = () => {
    setState((state) => [
      ...state,
      {
        index: nanoid(),
        role_name: `ROLE_${nanoid(5)}`.toUpperCase(),
        groups: [],
        permissions: [],
      },
    ]);
  };

  const submitHandler = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      const data = state.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.role_name]: cur,
        }),
        {}
      );
      await SettingService.updateSetting({
        body: {
          name: "roles",
          value: data,
        },
      });
      await getData();
      showToast(toast, "success", "Update dsuccess!");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <Toast ref={toast} />

      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <Button
              label="Cancel"
              className="p-button-danger"
              loading={loading}
              onClick={handleCancel}
            />
            <Button
              label="Submit"
              className={"mx-2"}
              loading={loading}
              onClick={submitHandler}
            />
            <Button
              onClick={handleAdd}
              icon="bx bxs-add-to-queue"
              // className="p-button-rounded p-button-success mb-3"
              className="p-button-success"
              aria-label="Search"
            />
          </div>
          <div>Default</div>
          {state.map((item, key) => {
            return (
              <div className="flex align-items-center">
                <div className="mr-4">
                  <RadioButton
                    onChange={(e) => onChangeDefault(item?.index)}
                    checked={item.default || false}
                  />
                </div>
                <Panel
                  header={item.role_name}
                  toggleable
                  className="my-2 w-full"
                  collapsed={true}
                >
                  <div className="grid" key={key}>
                    <div className="field col-12">
                      <InputText
                        required
                        value={item.role_name}
                        className="w-full"
                        onChange={(e) =>
                          changeHandler(e.target.value, "role_name", item.index)
                        }
                      />
                    </div>
                    <div className="field col-12">
                      <Groups
                        value={item.groups}
                        setValue={(value) =>
                          changeHandler(value, "groups", item.index)
                        }
                      />
                      <Permissions
                        value={item.permissions}
                        setValue={(value) =>
                          changeHandler(value, "permissions", item.index)
                        }
                      />
                    </div>
                    <div className="field col-4 md:col-3">
                      <Button
                        icon="pi pi-times"
                        className="p-button-rounded p-button-danger"
                        aria-label="Cancel"
                        onClick={(_) => handleSubIndex(item.index)}
                      />
                    </div>
                  </div>
                </Panel>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default StockPrices;
