/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import Service from "services/religions";
import VUpload from "components/v-upload";
import { InputSwitch } from "primereact";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    name: "",
    logo: "",
    key: "",
    status: "enable",
    background_card_visit: {
      front: "",
      back: ""
    }
  };

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setDetails({
      ...emptyData,
      ...data
    });
  }, [data]);

  const onChange = (name, value, key?) => {
    let _details = { ...details };

    if (key) {
      _details[`${key}`] = {
        ..._details[`${key}`],
        [name]: value
      };
    } else {
      _details[`${name}`] = value;
    }
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await Service.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else
        await Service.create({
          body: {
            ...details,
          },
        });
      setLoading(false);
      showToast(toast, "success", "File saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-6">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange("name", e.target.value)}
          required
          autoFocus
        />
      </div>

      <div className="field col-6">
        <label htmlFor="key">Key</label>
        <InputText
          id="key"
          value={details.key}
          onChange={(e) => onChange("key", e.target.value)}
          required
          autoFocus
        />
      </div>
      <div className="field col-6">
        <label htmlFor="is_deposit">Status</label>
        <br />
        <InputSwitch
          checked={details.status === "enable"}
          onChange={(e) => onChange("status", e.value ? "enable" : "disable")}
        />
      </div>

      <div className="field col-12">
        <label>Logo</label>
        <div className="mt-2">
          <VUpload
            urlFile={details.logo}
            setUrlFile={(file) => onChange("logo", file)}
          />
        </div>
      </div>
      <div className="field col-12">
        <div className="grid">
          <div className="col-12 md:col-6 mb-2">
            <label>Front visit card</label>
            <div className="mt-2">
              <VUpload
                urlFile={details.background_card_visit.front}
                setUrlFile={(file) => onChange("front", file, "background_card_visit")}
              />
            </div>
          </div>
          <div className="col-12 md:col-6 mb-2">
            <label>Back visit card</label>
            <div className="mt-2">
              <VUpload
                urlFile={details.background_card_visit.back}
                setUrlFile={(file) => onChange("back", file, "background_card_visit")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Details);
