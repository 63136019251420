import React, { useEffect, useState } from "react";
import { InputNumber, InputSwitch } from "primereact";
import BannersField from "./BannersField";
import { COMMON_STATUS } from "utils/enum";
import MarqueeField from "./MarqueeField";
import ActionsField from "./ActionsField";

const BANNER_DEFAULT = {
  status: COMMON_STATUS.ENABLE,
  time_start_show: 0,
  time_end_show: 0,
  enable_close: false,
  time_enable_close: 0,
  banners: [],
  marquee: {
    text_color: "",
    background_color: "",
    content: [],
  },
  action_button: [],
};

type IProps = {
  position?: string;
  data?: any;
  onChange?: any;
  setData: (data: any) => void;
};

const EditBanners = ({ position, data, setData, onChange }: IProps) => {
  const [bannersData, setBannersData] = useState(BANNER_DEFAULT);

  useEffect(() => {
    if (data) {
      setBannersData((prev: any) => ({
        ...prev,
        ...data,
      }));
    } else {
      setBannersData(BANNER_DEFAULT);
    }
  }, [data]);

  const handleChange = (value: any, name: string) => {
    let _data = bannersData[name];
    _data = value;
    setBannersData((prev: any) => ({
      ...prev,
      [name]: _data,
    }));
    onChange(_data, name);
  };

  return (
    <div className="grid">
      {/* <div className="field col-12 md:col-6">
        <label htmlFor="status">Status</label>
        <Dropdown
          value={data.status}
          options={Object.keys(COMMON_STATUS).map((k) => ({
            label: k,
            value: COMMON_STATUS[k],
          }))}
          onChange={(e) => onChange(e.value, "status")}
          optionLabel="label"
          placeholder="Select status"
        />
      </div> */}
      {/* <div className="field col-6">
        <label htmlFor="isLoop">Is loop</label>
        <br />
        <InputSwitch
          checked={data.is_loop}
          onChange={(e) => onChange(e.value, "is_loop")}
        />
        <br />
        {data.is_loop && (
          <>
            <label htmlFor="timeLoop">{`Time loop (s)`}</label>
            <InputNumber
              id="timeLoop"
              value={data.time_loop}
              onChange={(e) => onChange(e.value, "time_loop")}
              required
              autoFocus
            />
          </>
        )}
      </div> */}
      <div className="field col-12 md:col-6">
        <label htmlFor="enableClose">Status: {bannersData.status}</label>
        <br />
        <InputSwitch
          checked={bannersData?.status === "enable"}
          onChange={(e) =>
            onChange(e.value === true ? "enable" : "disable", "status")
          }
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="enableClose">Enable close</label>
        <br />
        <InputSwitch
          checked={bannersData?.enable_close}
          onChange={(e) => onChange(e.value, "enable_close")}
        />
      </div>
      {bannersData?.enable_close && (
        <div className="field col-12 md:col-6">
          <label htmlFor="timeEnableClose">{`Time enable close (s)`}</label>
          <InputNumber
            id="timeEnableClose"
            value={bannersData?.time_enable_close}
            onChange={(e) => onChange(e.value, "time_enable_close")}
            required
            autoFocus
          />
        </div>
      )}

      <div className="field col-12 md:col-6">
        <label htmlFor="timeStart">Time start show</label>
        <InputNumber
          id="timeStartShow"
          value={bannersData?.time_start_show}
          onChange={(e) => onChange(e.value, "time_start_show")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="timeEnd">Time end show</label>
        <InputNumber
          id="timeEndShow"
          value={bannersData?.time_end_show}
          onChange={(e) => onChange(e.value, "time_end_show")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 p-5 border-1 border-round">
        <span className="flex justify-content-center text-lg font-bold text-center">
          Media
        </span>
        <BannersField data={bannersData?.banners} onChange={handleChange} />
      </div>
      <div className="field col-12 p-5 border-1 border-round">
        <span className="flex justify-content-center text-lg font-bold text-center">
          Actions button
        </span>
        <ActionsField
          data={bannersData?.action_button}
          onChange={handleChange}
        />
      </div>
      <div className="field col-12 p-5 border-1 border-round">
        <span className="flex justify-content-center text-lg font-bold text-center">
          Marquee
        </span>
        <MarqueeField data={bannersData?.marquee} onChange={handleChange} />
      </div>
      {/* <div className="field col-12 md:col-6">
        <label htmlFor="timeEnd">Start date</label>
        <Calendar
          className="m-1"
          id="time24"
          placeholder="Start Date"
          value={data?.start_date ? new Date(data?.start_date) : undefined}
          onChange={(e) => onChange(e.value, "start_date")}
          showTime
          showSeconds
          showButtonBar
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="timeEnd">End date</label>
        <Calendar
          className="m-1"
          id="time24"
          placeholder="End Date"
          value={data?.end_date ? new Date(data?.end_date) : undefined}
          onChange={(e) => onChange(e.value, "end_date")}
          showTime
          showSeconds
          showButtonBar
        />
      </div> */}
    </div>
  );
};

export default EditBanners;
