import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import classNames from "classnames";
import { useContext } from "react";
import { MasterContext } from "contexts/MasterContext";
import SettingService from "services/settings";
import { getUrlImage } from "utils/common";

export const AppTopbar = (props: any) => {
  const history = useHistory();
  const { setAuth } = useContext(MasterContext);

  const [logo, setLogo] = useState(null);

  useEffect(() => {
    getLogos();
  }, []);

  const getLogos = async () => {
    try {
      const res: any = await SettingService.getSettingsByName({
        params: {
          name: "general",
        },
      });
      if (res && res.setting) {
        const _logo = res?.setting?.value?.site?.find(
          (el) => el.key === "logo_admin"
        );
        setLogo(_logo);
      }
    } catch (error) {}
  };

  const handleLogout = () => {
    setAuth(null);
    localStorage.removeItem("token_auth");
    history.push("/login");
  };

  return (
    <div className="layout-topbar">
      <Link to="/" className="layout-topbar-logo">
        <img
          src={
            logo
              ? getUrlImage(logo.site_media)
              : props.layoutColorMode === "light"
              ? "/assets/layout/images/logo-dark.png"
              : "/assets/layout/images/logo-white.png"
          }
          alt="logo"
        />
        {/* <span className="v-text-logo">{`${
          appName ? appName?.content : "VDIARYBOOK"
        }`}</span> */}
      </Link>

      <button
        type="button"
        className="p-link  layout-menu-button layout-topbar-button"
        onClick={props.onToggleMenuClick}
      >
        <i className="pi pi-bars" />
      </button>

      <button
        type="button"
        className="p-link layout-topbar-menu-button layout-topbar-button"
        onClick={props.onMobileTopbarMenuClick}
      >
        <i className="pi pi-ellipsis-v" />
      </button>

      <ul
        className={classNames("layout-topbar-menu lg:flex origin-top", {
          "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive,
        })}
      >
        <li>
          <button
            className="p-link layout-topbar-button"
            onClick={props.onMobileSubTopbarMenuClick}
          >
            <i className="pi pi-calendar" />
            <span>Events</span>
          </button>
        </li>
        <li>
          <button
            className="p-link layout-topbar-button"
            onClick={props.onMobileSubTopbarMenuClick}
          >
            <i className="pi pi-cog" />
            <span>Settings</span>
          </button>
        </li>
        <li>
          <button
            className="p-link layout-topbar-button"
            onClick={props.onMobileSubTopbarMenuClick}
          >
            <i className="pi pi-user" />
            <span>Profile</span>
          </button>
        </li>
        <li>
          <button
            className="p-link layout-topbar-button"
            onClick={handleLogout}
          >
            <i className="pi pi-sign-out"></i>
            <span>Logout</span>
          </button>
        </li>
      </ul>
    </div>
  );
};
