/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
} from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import SettingService from "services/settings";
import VUpload from "components/v-upload";
import { nanoid } from "nanoid";

const Details = (props, ref) => {
  const { setting, data, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    id: nanoid(),
    name: "",
    position: 0,
    icon: "",
    link: "",
  };

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) {
      setDetails(data);
    }
  }, [data]);


  const onChange = (value, name, field = null) => {
    let _details = { ...details };
    if (field) {
      _details[field][`${name}`] = value;
    } else {
      _details[`${name}`] = value;
    }
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      let newSetting = setting.map(el => el.id === details.id ? details : el);
      if (data) {
        await SettingService.updateSetting({
          body: {
            name: 'companions',
            value: newSetting
          }
        });
      } else {
        if (setting.length > 0) {
          await SettingService.updateSetting({
            body: {
              name: 'companions',
              value: [
                ...setting,
                details
              ]
            }
          });
        } else {
          await SettingService.createSetting({
            body: {
              name: 'companions',
              value: [details]
            }
          });
        }
      }
      setLoading(false);
      showToast(toast, "success", "Successful!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12 md:col-6">
        <label htmlFor="title">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange(e.target.value, "name")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="link">Position</label>
        <InputText
          id="position"
          type={"number"}
          value={details.position}
          onChange={(e) => onChange(e.target.value, "position")}
          required
        />
      </div>
      <div className="field col-12">
        <VUpload
          label="Logo"
          urlFile={details.icon}
          setUrlFile={(file) => onChange(file, "icon")}
        />
      </div>
     
      <div className="field col-12">
        <label htmlFor="link">Link</label>
        <InputText
          id="link"
          value={details.link}
          onChange={(e) => onChange(e.target.value, "link")}
          required
          
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
