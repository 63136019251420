import { parseErrorResponse, request } from "./request";

const createDonors = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post("/admin/donors", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updateDonors = (req: { params: { id: any }; body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .put(`admin/donors/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const deleteDonors = (req: { params: { id: any } }) =>
  new Promise((resolve, reject) => {
    request()
      .delete(`admin/donors/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getDonors = () =>
  new Promise((resolve, reject) => {
    request()
      .get("/admin/donors")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const DonorsService = {
  createDonors,
  updateDonors,
  deleteDonors,
  getDonors,
};

export default DonorsService;
