/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
// import VEditor from "components/v-editor";
import MailTemplateService from "services/mail-templates";
// import { InputSwitch } from "primereact/inputswitch";
import { InputTextarea } from "primereact/inputtextarea";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    name: "",
    content: "",
    title: "",
    key: "",
  };

  const [details, setDetails] = useState(emptyData);
  // const [isShowEditor, setIsShowEditor] = useState(false);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
  }, [data]);

  const onChange = (name, value) => {
    setDetails((prev) => ({
      ...prev,
      [`${name}`]: value,
    }));
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await MailTemplateService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else
        await MailTemplateService.create({
          body: {
            ...details,
          },
        });
      setLoading(false);
      showToast(toast, "success", "Success!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12">
        <label htmlFor="key">Key</label>
        <InputText
          id="key"
          value={details.key}
          onChange={(e) => onChange("key", e.target.value)}
          required
        />
      </div>
      <div className="field col-12 ">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange("name", e.target.value)}
          required
        />
      </div>
      <div className="field col-12 ">
        <label htmlFor="title">Title Mail</label>
        <InputText
          id="title"
          value={details.title}
          onChange={(e) => onChange("title", e.target.value)}
          required
        />
      </div>
      {/* <div className="field col-12">
        <InputSwitch
          checked={isShowEditor}
          onChange={(e) => setIsShowEditor(e.value)}
        />
      </div> */}
      <div className="field col-12">
        <label htmlFor="">
          Key :{" "}
          {`{full_name} {email} {phone} {otp} {reason} {platform} {device} {time}`}
        </label>
        {/* <Editor
            style={{ height: "500px" }}
            value={details.content}
            onTextChange={(e) => onChange("content", e.htmlValue)}
          /> */}
        <InputTextarea
          id="options"
          value={details.content}
          onChange={(e) => onChange("content", e.target.value)}
          required
          rows={7}
        />
        {/* <VEditor
            id="note"
            className="h-30rem"
            value={details.content}
            onChange={(e) => {
              onChange("content", e?.html);
            }}
            haveData={details.content ? true : false}
          /> */}
      </div>
    </div>
  );
};

export default forwardRef(Details);
