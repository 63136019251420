/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import Service from "services/apply-captcha";
import { Dropdown } from "primereact/dropdown";
import { APPLY_CAPTCHA_STATUS } from "utils/enum";

const Details = (props, ref) => {
  const {
    data,
    reload,
    toast,
    onCancel,
    setLoading,
    // questions
  } = props;
  const emptyData = {
    path: "",
    captcha_id: null,
    status: APPLY_CAPTCHA_STATUS.ENABLE,
  };

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
  }, [data]);

  const onChange = (name, value) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await Service.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else
        await Service.create({
          body: {
            ...details,
          },
        });
      setLoading(false);
      showToast(toast, "success", "File saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      {/* <div className="field col-5">
        <label htmlFor="attribute">Question</label>
        <Dropdown
          value={details.captcha_id}
          options={questions?.map((k) => ({
            label: k.title,
            value: k._id,
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("captcha_id", e.value)}
        />
      </div> */}
      <div className="field col-5">
        <label htmlFor="name">Path</label>
        <InputText
          id="key"
          value={details.path}
          onChange={(e) => onChange("path", e.target.value)}
          required
          autoFocus
        />
      </div>
      <div className="field col-2">
        <label htmlFor="description">Status</label>
        <Dropdown
          value={details.status}
          options={Object.keys(APPLY_CAPTCHA_STATUS).map((k) => ({
            label: k,
            value: APPLY_CAPTCHA_STATUS[k],
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => {
            onChange("status", e.value);
          }}
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
