import { request, parseErrorResponse } from './request';
import qs from 'query-string';

const search = (req: any) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/kycs/search?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });


const getAlls = (req: { query: Record<string, any>; }) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/kycs/?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const deleteKYC = (req: { params: { id: string; }; }) =>
  new Promise((resolve, reject) => {
    request()
      .delete('admin/kycs/' + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getById = (req: { params: { id: string; }; }) =>
  new Promise((resolve, reject) => {
    request()
      .get('admin/kycs/' + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const update = (req: { params: { id: string; }; body: any; }) =>
  new Promise((resolve, reject) => {
    request()
      .put('admin/kycs/' + req.params.id, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const KycService = {
  getAlls,
  deleteKYC,
  getById,
  update,
  search
};

export default KycService;
