import { request, parseErrorResponse } from './request';

const getAllEstock = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/admin/your-estock`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const approveEstock = (id: string, req: any) =>
  new Promise((resolve, reject) => {
    request()
      .put(`/admin/your-estock/${id}`, req)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });


const createTokenIntime = (id: string, req: any) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/admin/your-estock/create-token/${id}`, req)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });



const StocksService = {
  getAllEstock,
  approveEstock,
  createTokenIntime
};

export default StocksService;
