/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useImperativeHandle,
  useContext,
  useEffect,
} from "react";
import { forwardRef } from "react";
import { MasterContext } from "contexts/MasterContext";
import { Dropdown } from "primereact/dropdown";
import VLocation from "components/v-location";
import { OBJECT_TYPE, USER_GENDER } from "utils/enum";
import CategoryFilterService from "services/category-filters";
import { TreeSelect } from "primereact/treeselect";
import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";
import { FILTER_LOOP } from "utils/constant";
import { InputNumber } from "primereact/inputnumber";
import CategoryService from "services/categories";
import { MultiSelect } from "primereact/multiselect";

const CategoryFilterDetails = (props, ref) => {
  const emptyData = {
    object_type: null,
    account_type: null,
    career_id: null,
    nation_id: null,
    religion_id: null,
    category_field_id: null,
    location: null,
    gender: null,
    family_id: null,
    start_date: null,
    end_date: null,
    enable_loop: false,
    loop_type: null,
    loop_value: null,
    category_id: null,
    sites: null,
  };
  const {
    locations,
    careers,
    nations,
    religions,
    categoryFields,
    families,
    categories,
    sites,
  } = useContext(MasterContext);
  const { name_key, onChangeOptions } = props;
  const [accountTypes, setAccountTypes] = useState([]);
  const [details, setDetails] = useState(emptyData);

  useEffect(() => {
    getAccountTypes();
  }, []);

  useImperativeHandle(ref, () => ({
    submit: () => {
      return submit();
    },
  }));

  const onChange = (name, value) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
    if (name_key && onChangeOptions) {
      onChangeOptions(null, name_key);
    }
    if (onChangeOptions && !name_key) {
      onChangeOptions(name, value);
    }
  };

  const submit = async () => {
    try {
      console.log("1111");

      const name = getName();
      const data = await CategoryFilterService.create({
        body: {
          ...details,
          name,
          isCheck: true,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const getAccountTypes = async () => {
    const res: any = await CategoryService.getByGroup({
      query: {
        group: "account_type",
      },
    });
    setAccountTypes(res);
  };

  const getCategories = (data) => {
    let treeData;
    const mapData = (list: any) => {
      const dataChil = list.map((item: any) => chil(item));
      return dataChil.length > 0 ? dataChil : null;
    };
    const chil = (ct: any) => {
      const chilData = data.filter(
        (category) => String(category.parent_id) === String(ct._id)
      );
      return {
        ...ct,
        key: ct._id,
        label: ct.name,
        children: mapData(chilData),
      };
    };

    treeData = data
      .filter((ct) => !Boolean(ct.parent_id))
      .map((el) => chil(el));
    return treeData;
  };

  const getName = () => {
    let name = "";
    Object.keys(details).forEach((key) => {
      switch (key) {
        case "sites":
          if (details[key]) {
            name = name + `${String(details[key])}, `;
          }
          break;
        case "object_type":
          if (details[key]) {
            name = name + `${String(details[key])}, `;
          }
          break;
        case "account_type":
          if (details[key]) {
            name = name + `${String(details[key])}, `;
          }
          break;
        case "gender":
          if (details[key]) {
            name = name + `${String(details[key])}, `;
          }
          break;
        case "family_id":
          if (details[key]) {
            const objects = families.filter((c) =>
              details[key].includes(c._id)
            );
            name = name + `${objects.map((c) => c.name)}, `;
          }
          break;
        case "career_id":
          if (details[key]) {
            const objects = careers.filter((c) => details[key].includes(c._id));
            name = name + `${objects.map((c) => c.name)}, `;
          }
          break;
        case "nation_id":
          if (details[key]) {
            const objects = nations.filter((c) => details[key].includes(c._id));
            name = name + `${objects.map((c) => c.name)}, `;
          }
          break;
        case "religion_id":
          if (details[key]) {
            const objects = religions.filter((c) =>
              details[key].includes(c._id)
            );
            name = name + `${objects.map((c) => c.name)}, `;
          }
          break;
        case "category_field_id":
          if (details[key]) {
            const objects = categoryFields.filter((c) =>
              details[key].includes(c._id)
            );
            name = name + `${objects.map((c) => c.name)}, `;
          }
          break;
        case "category_id":
          if (details[key]) {
            const objects = categories.filter((c) =>
              details[key].includes(c._id)
            );
            name = name + `${objects.map((c) => c.name)}, `;
          }
          break;

        case "location":
          if (details[key]) {
            if (details[key]["ward"]) {
              const field = locations.find(
                (c) => c._id === details[key]["ward"]
              );
              name = name + `${field.name}, `;
            }
            if (details[key]["district"]) {
              const field = locations.find(
                (c) => c._id === details[key]["district"]
              );
              name = name + `${field.name}, `;
            }
            if (details[key]["province"]) {
              const field = locations.find(
                (c) => c._id === details[key]["province"]
              );
              name = name + `${field.name}, `;
            }
            if (details[key]["country"]) {
              const field = locations.find(
                (c) => c._id === details[key]["country"]
              );
              name = name + `${field.name}, `;
            }
          }

          break;
        default:
          break;
      }
    });
    return name;
  };

  const handleChangeLoopValue = (e) => {
    const value = e.value;

    if (
      (details.loop_type === "minute" && (value < 0 || value > 59)) ||
      (details.loop_type === "hour" && (value < 1 || value > 24)) ||
      (details.loop_type === "hour" && (value < 1 || value > 24)) ||
      (details.loop_type === "day" && (value < 1 || value > 31)) ||
      (details.loop_type === "week" && (value < 1 || value > 4)) ||
      (details.loop_type === "month" && (value < 1 || value > 12))
    ) {
      onChange("loop_value", null);
    } else {
      onChange("loop_value", value);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12 ">
        <label htmlFor="name">Sites</label>
        <MultiSelect
          filter
          value={details.sites}
          options={sites}
          optionLabel="name"
          optionValue="domain"
          onChange={(e) => onChange("sites", e.value)}
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Object</label>
        <MultiSelect
          showClear
          value={details.object_type}
          options={Object.keys(OBJECT_TYPE).map((k) => ({
            label: k,
            value: OBJECT_TYPE[k],
          }))}
          optionLabel="label"
          optionValue="value"
          placeholder="Select object"
          onChange={(e) => onChange("object_type", e.value)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Account type</label>
        <MultiSelect
          showClear
          value={details.account_type}
          options={accountTypes.map((c) => ({
            label: c.name,
            value: c.key,
          }))}
          optionLabel="label"
          optionValue="value"
          placeholder="Select account type"
          onChange={(e) => onChange("account_type", e.value)}
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="name">Gender</label>
        <MultiSelect
          value={details.gender}
          options={Object.keys(USER_GENDER).map((k) => ({
            label: k,
            value: USER_GENDER[k],
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("gender", e.value)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Family</label>
        <MultiSelect
          filter
          value={details.family_id}
          options={families.map((c) => ({
            label: c.name,
            value: c._id,
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("family_id", e.value)}
          showClear
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="name">Career</label>
        <MultiSelect
          showClear
          filter
          value={details.career_id}
          options={careers.map((c) => ({
            label: c.name,
            value: c._id,
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("career_id", e.value)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Nation</label>
        <MultiSelect
          showClear
          filter
          value={details.nation_id}
          options={nations.map((c) => ({
            label: c.name,
            value: c._id,
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("nation_id", e.value)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Religion</label>
        <MultiSelect
          showClear
          filter
          value={details.religion_id}
          options={religions.map((c) => ({
            label: c.name,
            value: c._id,
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("religion_id", e.value)}
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="name">Category Field</label>
        <TreeSelect
          value={details.category_field_id}
          filter
          options={getCategories(categoryFields)}
          onChange={(e: any) => onChange("category_field_id", e?.value)}
          className="w-full"
          placeholder="Select Parent"
        ></TreeSelect>
      </div>
      <div className="field col-12 ">
        <label htmlFor="name">Category Product</label>
        <TreeSelect
          value={details.category_id}
          filter
          options={getCategories(categories)}
          onChange={(e: any) => onChange("category_id", e?.value)}
          className="w-full"
          placeholder="Select Parent"
        ></TreeSelect>
      </div>
      <div className="field col-12">
        <label htmlFor="name">Address current</label>
        <VLocation
          address={details.location}
          setAddress={(a) => onChange("location", a)}
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="start-date">Start Date</label>
        <Calendar
          id="start-date"
          showIcon
          showTime
          placeholder="Start date"
          value={details.start_date}
          onChange={(e) => onChange("start_date", e.value)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="end-date">End Date</label>
        <Calendar
          id="end-date"
          showIcon
          showTime
          placeholder="End date"
          value={details.end_date}
          onChange={(e) => onChange("end_date", e.value)}
        />
      </div>
      <div className="col-12">
        <div className="field flex align-items-center">
          <label htmlFor="enable_loop" className="mr-2">
            Enable Loop
          </label>
          <InputSwitch
            id="enable_loop"
            checked={details.enable_loop}
            onChange={(e) => onChange("enable_loop", e.value)}
          />
        </div>
      </div>
      {details.enable_loop && (
        <>
          <div className="field col-12 md:col-6">
            <Dropdown
              value={details.loop_type}
              options={FILTER_LOOP}
              onChange={(e) => onChange("loop_type", e.value)}
              optionLabel="name"
              filterBy="name"
              optionValue="value"
              filter
              showClear
              placeholder="Select type of loop"
            />
          </div>
          <div className="field col-12 md:col-6">
            <InputNumber
              onChange={handleChangeLoopValue}
              value={details.loop_value}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default forwardRef(CategoryFilterDetails);
