/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { Image } from "primereact/image";
import { formatLocalTime, getUrlImage, showToast } from "utils/common";
import VDialog from "components/v-dialog";
import VConfirm from "components/v-confirm";
import CategoryFieldService from "services/category-field";
import Detail from "./components/Detail";
import debounce from "lodash.debounce";
import { Calendar, MultiSelect } from "primereact";
import { TabPanel, TabView } from "primereact/tabview";
import ImportExcel from "./components/ImportExcel";
import StatusEditorCell from "components/v-edit-cell/StatusEditorCell";
import { Panel } from "primereact/panel";
import { TreeTable } from "primereact/treetable";

const STATUS = [
  { name: "ENABLE", value: "enable" },
  { name: "DISABLE", value: "disable" },
];

const Crud = () => {
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState(null);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    status: ["enable", "disable"],
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 5,
    page: 0,
    sortField: "order",
    sortOrder: 1,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [categoriesField, setCategoriesField] = useState([]);
  const [treeData, setTreeData] = useState([]);

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);
  const refDetail = useRef(null);

  useEffect(() => {
    getCategoriesField();
  }, []);

  useEffect(() => {
    if (data) {
      const mapAll = categoriesField.map((e: any) => ({
        ...e,
        id: e._id != null ? String(e._id) : null,
        keyVal: e.key,
        parent_id: e.parent_id ? e.parent_id : null,
      }));

      const mapList = data.map((e: any) => ({
        ...e,
        id: e._id != null ? String(e._id) : null,
        keyVal: e.key,
        parent_id: e.parent_id ? e.parent_id : null,
      }));
      const tree = mapList.filter((e: any) => e.parent_id == null);
      tree.forEach((el: any) => {
        const items = getItems(mapAll, el._id);
        if (items && items != null && items.length > 0) {
          el.children = items;
          el.leaf = false;
        }
      });

      if (tree.length === 0 && mapList) {
        const treeChild = mapList.map((el: any) => {
          const items = getItems(mapAll, el._id);
          if (items && items != null && items.length > 0) {
            el.children = items;
            el.leaf = false;
          }
          return el;
        });
        setTreeData([...treeChild]);
      } else {
        setTreeData([...tree]);
      }
    } else setTreeData([]);
  }, [data, categoriesField]);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  const getItems = (mapList, parentId) => {
    let items = mapList.filter((e: any) => e.parent_id === parentId);
    items.forEach((el: any) => {
      const items = getItems(mapList, el._id);
      if (items && items != null && items.length > 0) {
        el.items = items;
        el.children = items;
      }
    });
    return items;
  };

  const getCategoriesField = async () => {
    const res: any = await CategoryFieldService.getAll();
    setCategoriesField(res);
  };

  const loadData = async () => {
    try {
      const res: any = await CategoryFieldService.search({
        query: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
          limit: lazyParams.rows,
        },
      });
      if (res && res.docs) {
        setData(res.docs);
        setTotalRecords(res.totalDocs);
      } else setData([]);
    } catch (error) {
      setData([]);
    }
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  //CREATE OR NEW
  const openNew = () => {
    setDetail(null);
    refDialogDetail.current.show();
  };

  const editActionPost = (data) => {
    setDetail({ ...data });
    refDialogDetail.current.show();
  };

  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };

  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };

  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  // DELETE
  const confirmDelete = (product) => {
    setDetail(product);
    refDialogDelete.current.show();
  };
  const handleDelete = async () => {
    try {
      await CategoryFieldService.deleted({
        params: {
          id: detail._id,
        },
      });
      const _data = data.filter((val) => val._id !== detail._id);
      setData(_data);
      refDialogDelete.current.close();
      setDetail(null);
      showToast(toast, "success", "Deleted");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const onChangeStatus = async (options, rowData, value) => {
    let updateRow;
    let newData = data.map((e, index) => {
      if (e._id === rowData._id) {
        e[options.field] = value;
        updateRow = e;
      }
      return e;
    });
    setData(newData);

    if (updateRow) {
      await CategoryFieldService.update({
        params: { id: updateRow._id },
        body: {
          ...updateRow,
        },
      });
    }
  };

  // const statusEditor = (options) => {
  //   return <StatusEditor options={options} onChange={onChangeStatus} />;
  // };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />

          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled={true}
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editActionPost(rowData)}
        />

        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning"
          onClick={() => confirmDelete(rowData)}
        />
      </div>
    );
  };

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="grid px-2 align-items-center">
        <div className="col-12 md:col-4">
          <span className="block p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e: any) => onSearch("search", e.target.value)}
              placeholder="Search..."
              className="w-full"
            />
          </span>{" "}
        </div>
        <div className="col-12 md:col-4">
          <Calendar
            className="w-full"
            id="time24"
            placeholder="Start Date"
            value={globalFilter.start}
            onChange={(e: any) => onSearch("start", e.value)}
            showTime
            showSeconds
            showButtonBar
          />{" "}
        </div>
        <div className="col-12 md:col-4">
          <Calendar
            className="w-full"
            id="time24"
            placeholder="End Date"
            value={globalFilter.end}
            onChange={(e: any) => onSearch("end", e.value)}
            showTime
            showSeconds
            showButtonBar
          />
        </div>
        <div className="col-12 md:col-6">
          <MultiSelect
            value={globalFilter.status}
            options={STATUS}
            onChange={(e: any) => onSearch("status", e.value)}
            optionLabel="name"
            placeholder="Status"
            maxSelectedLabels={3}
            className={"w-full"}
            showClear
          />
        </div>
      </div>
    </Panel>
  );
  console.log("treeData", treeData);

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            <TabPanel header="List category field">
              <Toolbar
                className="mb-4"
                left={leftToolbarTemplate}
                right={rightToolbarTemplate}
              ></Toolbar>
              <TreeTable
                ref={dt}
                value={treeData}
                header={header}
                scrollable
                // showGridlines
                lazy
                paginator
                resizableColumns
                columnResizeMode="expand"
                first={lazyParams.first}
                rows={lazyParams.rows}
                totalRecords={totalRecords}
                rowsPerPageOptions={[5, 10, 20]}
                // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                // currentPageReportTemplate="{first} - {last} of {totalRecords}"
                onPage={onPage}
                //sort
                sortField={lazyParams.sortField}
                sortOrder={lazyParams.sortOrder}
                onSort={onSort}
              >
                <Column
                  // frozen
                  // alignFrozen="left"
                  body={actionBodyTemplate}
                  style={{ flexGrow: 1, flexBasis: "120px" }}
                ></Column>
                <Column
                  style={{ flexGrow: 1, flexBasis: "80px" }}
                  header="Image"
                  expander
                  // frozen
                  body={(rowData) => {
                    return (
                      <Image
                        src={getUrlImage(rowData.logo)}
                        alt={rowData.name}
                        width="50"
                        height="50"
                        preview
                      />
                    );
                  }}
                ></Column>

                <Column
                  field="name"
                  header="Name"
                  body={(rowData) => String(rowData.name).slice(0, 30)}
                  style={{ flexGrow: 1, flexBasis: "150px" }}
                ></Column>
                <Column
                  field="key"
                  header="Key"
                  body={(rowData) => String(rowData.key).slice(0, 30)}
                  style={{ flexGrow: 1, flexBasis: "150px" }}
                ></Column>
                <Column
                  field="link"
                  header="Link"
                  body={(rowData) => String(rowData.link).slice(0, 30)}
                  style={{
                    flexGrow: 1,
                    flexBasis: "200px",
                    position: "relative",
                  }}
                ></Column>
                <Column
                  // editor={statusEditor}
                  field="status"
                  header="Status"
                  style={{
                    flexGrow: 1,
                    flexBasis: "180px",
                    position: "relative",
                  }}
                  // body={(rowData) => (
                  //   <span
                  //     className={`v-badge status-${rowData.status.toLowerCase()}`}
                  //   >
                  //     {rowData.status}
                  //   </span>
                  // )}
                  body={(rowData, options) => (
                    <StatusEditorCell
                      value={rowData.status}
                      options={options}
                      onChange={onChangeStatus}
                    />
                  )}
                ></Column>
                <Column
                  style={{ flexGrow: 1, flexBasis: "200px" }}
                  field="createdAt"
                  header="Created At"
                  sortable
                  body={(rowData) => formatLocalTime(rowData.createdAt)}
                ></Column>
              </TreeTable>
            </TabPanel>
            <TabPanel header="Import Excel">
              <ImportExcel />
            </TabPanel>
          </TabView>

          <VDialog
            ref={refDialogDetail}
            header="Category Field Details"
            onSubmit={onSumitDialogDetail}
          >
            <Detail
              ref={refDetail}
              data={detail}
              categoryField={categoriesField}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
            />
          </VDialog>

          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Crud, comparisonFn);
