import { request, parseErrorResponse } from "./request";
import qs from "query-string";

interface IQuota {
  content: any;
  name: string;
  link: string;
  image: string;
  status?: string;
  category_filter_id: string;
}

const search = (req: any) => {
  return new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/quotas/search?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          resolve(data);
        } else {
          reject(new Error("Something went error!"));
        }
      })
      .catch(parseErrorResponse)
      .then(reject);
  });
};

const deleted = (req: { params: { id: string } }) => {
  return new Promise((resolve, reject) => {
    request()
      .delete("admin/quotas/" + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          resolve(data);
        } else {
          reject(new Error("Something went error!"));
        }
      })
      .catch(parseErrorResponse)
      .then(reject);
  });
};

const create = (req: { body: IQuota }) => {
  return new Promise((resolve, reject) => {
    request()
      .post("admin/quotas", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          resolve(data);
        } else {
          reject(new Error("Something went error!"));
        }
      })
      .catch(parseErrorResponse)
      .then(reject);
  });
};

const update = (req: { params: { id: string }; body: IQuota }) => {
  return new Promise((resolve, reject) => {
    request()
      .put("admin/quotas/" + req.params.id, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          resolve(data);
        } else {
          reject(new Error("Something went error!"));
        }
      })
      .catch(parseErrorResponse)
      .then(reject);
  });
};

const importExcel = (req: { body: any }) => {
  return new Promise((resolve, reject) => {
    request()
      .post("admin/quotas/import-excel", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          resolve(data);
        } else {
          reject(new Error("Something went error!"));
        }
      })
      .catch(parseErrorResponse)
      .then(reject);
  });
};

const QuotaService = {
  search,
  deleted,
  create,
  update,
  importExcel,
};

export default QuotaService;
