import { request, parseErrorResponse } from "./request";

const login = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post("/admin/login", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const register = (data: any) =>
  new Promise((resolve, reject) => {
    request()
      .post("/register", data)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

  const getUserToken = (req: any) =>
  new Promise((resolve, reject) => {
    request()
      .post("/admin/get-user-token", req)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const AuthService = {
  login,
  register,
  getUserToken,
};

export default AuthService;
