import { socket } from "./index";

export const socketEvents = ({ setDashboard }) => {
  socket.on("dashboard", (data) => {
    setDashboard(data);
  });
  socket.on("updateDashboard", (data) => {
    const { key, value } = data;
    setDashboard((state) => {
      return { ...state, [key]: state[key] + value };
    });
  });
};
