/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useImperativeHandle, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import AdvPriceService from "services/adv-price";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { InputNumber, Panel } from "primereact";
import CategoryFilterDetails from "components/v-filter";
import Service from "services/categories";
import { CATEGORY_GROUP_KEY } from "utils/constant";
import debounce from "lodash.debounce";

const emptyData = {
  name: "",
  positions: [],
  price: 0,
  category_filter_id: null,
};

const Details = (props, ref) => {
  const {
    data,
    reload,
    toast,
    onCancel,
    setLoading,
    bannerPositions,
    categoryFilters,
    setBannerPositions,
    getCategoryFilters,
  } = props;
  const [details, setDetails] = useState(emptyData);
  const refDetailCategory = useRef(null);
  // const [advUsers, setAdvUsers] = useState([]);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data)
      setDetails({
        ...data,
        start_date: data.start_date ? new Date(data.start_date) : new Date(),
        end_date: data.end_date ? new Date(data.end_date) : new Date(),
        countdown: new Date(data.countdown),
      });
  }, [data]);

  const onChange = (name, value) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };

  const onInputCheckChange = (val, name) => {
    setDetails({
      ...details,
      [name]: val,
    });
  };

  const submit = async () => {
    try {
      setLoading(true);
      let category = null;
      if (!details.category_filter_id) {
        category = await refDetailCategory.current.submit();
        getCategoryFilters();
      }
      if (data) {
        await AdvPriceService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      } else
        await AdvPriceService.create({
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      setLoading(false);
      showToast(toast, "success", "File saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const onFilterPosition = async (e) => {
    if (e?.filter) {
      const res: any = await Service.search({
        query: {
          group: CATEGORY_GROUP_KEY.BANNER_PAGE,
          search: e?.filter,
          page: 1,
          limit: 10,
        },
      });
      if (res && res?.docs) {
        const newData = res?.docs?.filter(
          (el) => !bannerPositions.find((item) => item?._id === el?._id)
        );
        setBannerPositions((bannerPositions || []).concat(newData));
      }
    }
  };

  const onSearchFilterPosition = debounce(onFilterPosition, 700);
  console.log("price", details);

  return (
    <div className="grid">
      <Toast ref={toast} />

      <div className="col-12">
        <div className="card">
          <div className="grid">
            <div className="field col-12 md:col-6">
              <label htmlFor="name">Name</label>
              <InputText
                id="name"
                className="w-full"
                value={details.name}
                onChange={(e) => onChange("name", e.target.value)}
              />
            </div>

            <div className="field col-12 md:col-6">
              <label htmlFor="price">Price</label>
              <InputNumber
                id="price"
                className="w-full"
                value={details.price}
                onChange={(e) => onChange("price", e.value)}
              />
            </div>

            <div className="field col-12">
              <label htmlFor="status">Banner Position By Key</label>
              <Dropdown
                value={details?.positions}
                options={bannerPositions.map((item) => ({
                  label: item.name,
                  value: item.key,
                }))}
                filter
                showClear
                optionLabel="label"
                optionValue="value"
                placeholder="Select banner position"
                onFilter={(e) => onSearchFilterPosition(e)}
                onChange={(e) => {
                  onInputCheckChange(e.value, "positions");
                }}
              />
            </div>
          </div>
          <div className="grid">
            <div className="field col-12">
              <label htmlFor="status">Filter</label>
              <Dropdown
                filter
                showClear
                value={details.category_filter_id}
                options={categoryFilters.map((item) => ({
                  label: item.name,
                  value: item._id,
                }))}
                optionLabel="label"
                optionValue="value"
                placeholder="Select filter"
                onChange={(e) =>
                  onInputCheckChange(e.value, "category_filter_id")
                }
              />
            </div>
          </div>
          {!details.category_filter_id && (
            <div className="field col-12">
              <Panel
                header="Filter"
                toggleable
                className="my-2"
                collapsed={false}
              >
                <div className="grid">
                  <div className="field col-12">
                    <CategoryFilterDetails
                      ref={refDetailCategory}
                      onChangeOptions={onChange}
                    />
                  </div>
                </div>
              </Panel>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Details);
