/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useContext,
} from "react";
import { getUrlImage, showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import LoansService from "services/loans";
import { Image } from "primereact/image";
import { MasterContext } from "contexts/MasterContext";
import { InputText } from "primereact/inputtext";
import VEditor from "components/v-editor";
import VLocation from "components/v-location";
import { InputNumber } from "primereact/inputnumber";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;
  const { locations } = useContext(MasterContext);

  const emptyData = {
    status: "pending",
    note: "",
  };

  const optionStatus = [
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Approved",
      value: "approved",
    },
    {
      label: "Declined",
      value: "declined",
    },
  ];

  const [details, setDetails] = useState(emptyData);
  const [readOnly, setReadOnly] = useState(false);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
    if (data.status && data.status !== "pending") {
      setReadOnly(true);
    }
  }, [data]);

  const onChangeEditor = (e, name) => {
    setDetails((prev) => ({
      ...prev,
      [name]: e.htmlValue,
    }));
  };

  const onDropdownChange = (e, name) => {
    const val = e.value || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      if (details.status === "declined" && !details.note) {
        return showToast(toast, "warn", "Please enter note!");
      }

      setLoading(true);
      if (data) {
        await LoansService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Loan saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const isImage = (url) => {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg|mov)$/.test(url);
  };

  const srcIsVideo = (src: any) => {
    if (typeof src !== "string") return false;
    const type = src.split(".")[src.split(".")?.length - 1];
    switch (type.toLowerCase()) {
      case "m4v":
      case "avi":
      case "mpg":
      case "mp4":
      case "mov":
      case "quicktime":
        // etc
        return true;
    }
    return false;
  };

  const checkIsLocation = (id: string) => {
    const country = locations.find((el) => String(el._id) === id);
    if (country) {
      return country;
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="grid">
        <div className="field col-12 md:col-6">
          <label htmlFor="description">Amount</label>
          <InputNumber
            id="percent_provice"
            disabled
            value={data?.value_loans?.amount}
          />
        </div>
        <div className="field col-12 md:col-6">
          <label htmlFor="description">Type</label>
          <InputText id="percent_provice" disabled value={data?.type} />
        </div>
        <div className="field col-12">
          <label htmlFor="name">Address</label>
          <VLocation
            address={data?.value_loans?.address}
            isDisabled
            setAddress={(a) => {}}
          />
        </div>
        {Object.keys(data?.value_loans?.additionalData || {})?.map(
          (value, i) => {
            if (srcIsVideo(data?.value_loans?.additionalData?.[value])) {
              return (
                <div key={i} className="field col-12 md:col-12">
                  <span className="field col-12 md:col-6">- {value}:</span>
                  <div style={{ maxWidth: "500px" }}>
                    <video
                      src={data?.value_loans?.additionalData?.[value]}
                      width="100%"
                      height="auto"
                      autoPlay
                      loop
                    >
                      Your browser does not support HTML video.
                    </video>
                  </div>
                </div>
              );
            } else if (!isImage(data.value_loans?.additionalData?.[value])) {
              return (
                <>
                  <div key={i} className="field col-12 md:col-6">
                    <label htmlFor={value}>{value}</label>
                    <InputText
                      id={value}
                      value={
                        checkIsLocation(
                          data.value_loans?.additionalData?.[value]
                        )?.name || data.value_loans?.additionalData?.[value]
                      }
                      disabled
                    />
                  </div>
                </>
              );
            } else {
              let imgs = data?.value_loans?.additionalData?.[value];
              if (!Array.isArray(imgs)) {
                imgs = [imgs];
              }

              return (
                <div key={i} className="grid mt-3">
                  {imgs.map((at, index) => (
                    <div key={index} className="">
                      <span className="font-semibold block mb-3">
                        - {value}:
                      </span>
                      <Image
                        src={getUrlImage(at)}
                        width="200"
                        height="auto"
                        preview
                      />
                    </div>
                  ))}
                </div>
              );
            }
          }
        )}
        {/* </div> */}
      </div>
      <div className="grid">
        <div className="field col-12 md:col-6">
          <label htmlFor="description">Status</label>
          <Dropdown
            value={details.status}
            options={optionStatus}
            onChange={(e) => onDropdownChange(e, "status")}
            optionLabel="label"
            placeholder="Select status"
            disabled={readOnly}
          />
        </div>

        <div className="field col-12">
          <label htmlFor="description">Note</label>
          {/* <Editor
            id="note"
            className="h-12rem"
            value={details.note}
            onTextChange={(e) => onChangeEditor(e, "note")}
          /> */}
          <VEditor
            id="note"
            className="h-12rem"
            value={details.note}
            onChange={(e) => {
              onChangeEditor(e?.html, "note");
            }}
            haveData={details.note ? true : false}
          />
        </div>
      </div>
    </>
  );
};

export default forwardRef(Details);
