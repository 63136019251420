/* eslint-disable react-hooks/exhaustive-deps */
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";
import VDialog from "components/v-dialog";
import { useRef, useState } from "react";
import ImportOptions from "./components/import-options";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import VEditor from "components/v-editor";
import VUpload from "components/v-upload";
import { CATEGORY_STATUS } from "utils/enum";
import { KET_DESCRIPTION_FORM_KYC } from "utils/constant";
import { Panel } from "primereact/panel";
import { nanoid } from "nanoid";
// import { CKEditor } from "ckeditor4-react";

const optionsTypeGroup = [
  {
    label: "Input",
    value: "input",
  },
  {
    label: "Textarea",
    value: "textarea",
  },
  {
    label: "Dropdown",
    value: "dropdown",
  },
  {
    label: "Radio",
    value: "radio",
  },
  {
    label: "Multi select",
    value: "multiselect",
  },
  {
    label: "Media",
    value: "media",
  },
  {
    label: "Editor",
    value: "editor",
  },
  {
    label: "Date",
    value: "date",
  },
  {
    label: "Date Time",
    value: "date-time",
  },
  {
    label: "Nationality",
    value: "nationality",
  },
  {
    label: "Location",
    value: "location",
  },
];
const FromAdditional = (props) => {
  const { dataForm, setDataForm } = props;
  const refDialogDetail = useRef(null);
  const refDetail = useRef(null);

  const [indexImport, setIndexImport] = useState(null);

  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };
  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };

  const onInputChange = (e, name, index?) => {
    if (
      name === "name" ||
      name === "key" ||
      name === "type" ||
      (name === "order" && !index && index !== 0) ||
      name === "cover"
    ) {
      const val = e?.value || (e?.target && e?.target?.value) || e || "";
      let _dataForm = { ...dataForm };
      _dataForm[`${name}`] = val;
      setDataForm(_dataForm);
    } else {
      const val = e?.checked || e?.value || e.target.value || e;
      setDataForm({
        ...dataForm,
        fields: dataForm.fields.map((el: any, idx: number) =>
          idx === index ? { ...el, [name]: val } : el
        ),
      });
    }
  };

  const onInputChangeDescription = (e, name, index?) => {
    setDataForm({
      ...dataForm,
      fields: dataForm.fields.map((el: any, idx: number) =>
        idx === index
          ? {
              ...el,
              description: {
                ...el?.description,
                [name]: e.checked || e?.value || e.target.value,
              },
            }
          : el
      ),
    });
  };

  const onDropdownChange = (e, name, index) => {
    const val = e.value || "";
    setDataForm((prev) => ({
      ...prev,
      fields: prev.fields.map((el: any, idx: number) =>
        idx === index ? { ...el, [name]: val } : el
      ),
    }));
  };

  const handleAddOptions = (index: number) => {
    setDataForm({
      ...dataForm,
      fields: dataForm.fields.map((el: any, idx: number) =>
        idx === index
          ? {
              ...el,
              options: el.options.concat({
                name: "",
              }),
            }
          : el
      ),
    });
  };

  const handleSubOptions = (index: number) => {
    setDataForm({
      ...dataForm,
      fields: dataForm.fields.map((el: any, idx: number) =>
        idx === index
          ? {
              ...el,
              options: el.options.slice(0, el.options.length - 1),
            }
          : el
      ),
    });
  };

  const handleImportOptions = (index: number) => {
    setIndexImport(index);
    refDialogDetail.current.show();
  };

  const changeHandlerOptions = (e: any, index: number, idxOp: number, name) => {
    setDataForm({
      ...dataForm,
      fields: dataForm.fields.map((el: any, idx: number) =>
        idx === idxOp
          ? {
              ...el,
              options: el.options.map((op: any, i: number) =>
                i === index ? { ...op, [name]: e.target.value } : op
              ),
            }
          : el
      ),
    });
  };

  const changeHandlerContent = (content) => {
    setDataForm((data) => ({
      ...data,
      attachments: {
        ...data.attachments,
        guide: content || " ",
      },
    }));
  };

  const onInputCheckChange = (e) => {
    const val = e.value || false;
    let _dataForm = { ...dataForm };
    _dataForm[`attachments`][`enable`] = val;
    setDataForm(_dataForm);
  };

  const handleAdd = () => {
    setDataForm((prev) => ({
      ...prev,
      fields: prev.fields.concat({
        label: "",
        placeholder: "",
        typeField: "",
        options: [],
        status: CATEGORY_STATUS.ENABLE,
      }),
    }));
  };

  const handleSub = () => {
    setDataForm((prev) => ({
      ...prev,
      fields: prev.fields.slice(0, prev.fields.length - 1),
    }));
  };
  const [a, setA] = useState(true);

  const handleSubItem = (index: number) => {
    setA(false);
    setDataForm((prev) => ({
      ...prev,
      fields: prev.fields.filter((el, idn) => idn !== index),
    }));
    setTimeout(() => {
      setA(true);
    }, 200);
  };

  return (
    <div className="grid">
      <div className="field col-12 md:col-6">
        <label htmlFor="title">Title</label>
        <InputText
          id="name"
          value={dataForm.name}
          onChange={(e) => onInputChange(e, "name")}
          required
          autoFocus
        />
      </div>
      <div className="field col-6">
        <label htmlFor="name">Key</label>
        <InputText
          id="key"
          value={dataForm.key}
          onChange={(e) => onInputChange(e, "key")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-6">
        <VUpload
          label="Cover"
          urlFile={dataForm.cover}
          setUrlFile={(file) => onInputChange(file, "cover")}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="">Order</label>
        <InputNumber
          id="order"
          value={dataForm.order}
          onChange={(e) => onInputChange(e, "order")}
          autoFocus
          placeholder="Enter order"
        />
      </div>
      <div className="flex col-12 align-items-center">
        Field:
        <div className="flex ml-2">
          <Button
            label="+"
            className="p-button-primary py-1"
            onClick={handleAdd}
          />
          <Button
            label="-"
            className="p-button-primary py-1 ml-1"
            onClick={handleSub}
          />
        </div>
      </div>
      {dataForm.fields?.length > 0 &&
        a &&
        dataForm.fields.map((item, index) => (
          <Panel
            className="w-full mb-2"
            key={index}
            header={
              item?.label ? item?.label?.replace(/(<([^>]+)>)/gi, "") : "Label"
            }
            toggleable
            collapsed={true}
          >
            <div className="grid border">
              <div className="field col-12">
                <Button
                  icon="pi pi-times"
                  className="p-button-sm p-button-danger"
                  aria-label="Delete"
                  style={{
                    height: "30px",
                    marginLeft: "3px",
                  }}
                  onClick={() => handleSubItem(index)}
                />
              </div>

              <div className="field col-12 md:col-6">
                <label htmlFor="">Label</label>
                <VEditor
                  value={item.label}
                  onChange={(e) => {
                    onInputChange(
                      {
                        value: e?.html,
                      },
                      "label",
                      index
                    );
                  }}
                />
                {/* <InputText
                id="label"
                value={item.label}
                onChange={(e) => onInputChange(e, "label", index)}
                required
                autoFocus
                placeholder="Enter label"
              /> */}
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="">Placeholder</label>
                <InputText
                  id="placeholder"
                  value={item.placeholder}
                  onChange={(e) => onInputChange(e, "placeholder", index)}
                  required
                  autoFocus
                  placeholder="Enter placeholder"
                />
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="typeField">Type field</label>
                <Dropdown
                  defaultValue={item.typeField}
                  value={item.typeField}
                  options={optionsTypeGroup}
                  onChange={(e) => onDropdownChange(e, "typeField", index)}
                  optionLabel="label"
                  placeholder="Select type"
                />
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="">Order</label>
                <InputNumber
                  id="order"
                  value={item.order}
                  onChange={(e) => onInputChange(e, "order", index)}
                  autoFocus
                  placeholder="Enter order"
                />
              </div>
              <div className="field col-6">
                <label htmlFor="description">Item / Row</label>
                <Dropdown
                  value={item.col || 1}
                  options={[1, 2, 3, 4, 6].map((k) => ({
                    label: k,
                    value: k,
                  }))}
                  optionLabel="label"
                  optionValue="value"
                  onChange={(e) => onInputChange(e, "col", index)}
                />
              </div>
              <div className="field col-6">
                <label htmlFor="description">Status</label>
                <Dropdown
                  value={item.status || CATEGORY_STATUS.ENABLE}
                  options={Object.keys(CATEGORY_STATUS).map((k) => ({
                    label: k,
                    value: CATEGORY_STATUS[k],
                  }))}
                  optionLabel="label"
                  optionValue="value"
                  onChange={(e) => onInputChange(e, "status", index)}
                />
              </div>
              {["dropdown", "radio", "multiselect"].includes(
                item.typeField
              ) && (
                <div className="field col-12">
                  <div className="field col-12 md:col-12">
                    <div className="flex col-12 align-items-center">
                      Option:
                      <div className="flex ml-2">
                        <Button
                          label="+"
                          className="p-button-primary py-1"
                          onClick={() => handleAddOptions(index)}
                        />
                        <Button
                          label="-"
                          className="p-button-primary py-1 ml-1"
                          onClick={() => handleSubOptions(index)}
                        />
                      </div>
                      <Button
                        label="Import"
                        style={{
                          width: "100px",
                        }}
                        className="p-button-primary py-1 ml-1 flex justify-content-center"
                        onClick={() => handleImportOptions(index)}
                      />
                    </div>
                    {item.options &&
                      item.options.map((el, i) => (
                        <div key={i} className="grid col-12 md:col-12">
                          <div className="field col-12 md:col-4">
                            <VUpload
                              label="Cover"
                              urlFile={el.icon}
                              setUrlFile={(file) =>
                                changeHandlerOptions(
                                  {
                                    target: { value: file },
                                  },
                                  i,
                                  index,
                                  "icon"
                                )
                              }
                            />
                          </div>
                          <div className="field col-12 md:col-8">
                            <label htmlFor="">Name</label>
                            <InputText
                              id="name"
                              value={el.name}
                              onChange={(e) =>
                                changeHandlerOptions(e, i, index, "name")
                              }
                              required
                              autoFocus
                              placeholder="Enter name"
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
              {item.typeField === "media" && (
                <div className="field col-12">
                  <div className="field col-12 md:col-6">
                    <label htmlFor="">Limit file</label>
                    <InputNumber
                      id="limit_file"
                      value={item.limit_file}
                      onChange={(e) => onInputChange(e, "limit_file", index)}
                      required
                      autoFocus
                    />
                  </div>
                </div>
              )}
              <div className="field col-12 md:col-2">
                <label htmlFor="key">Disabled</label>
                <div className="ml-2">
                  <Checkbox
                    onChange={(e) => onInputChange(e, "disabled", index)}
                    checked={item.disabled}
                  ></Checkbox>
                </div>
              </div>
              <div className="field col-12 md:col-2">
                <label htmlFor="key">Required</label>
                <div className="ml-2">
                  <Checkbox
                    onChange={(e) => onInputChange(e, "required", index)}
                    checked={item.required}
                  ></Checkbox>
                </div>
              </div>
              <div className="field col-12 md:col-2">
                <label htmlFor="key">Link</label>
                <div className="ml-2">
                  <Checkbox
                    onChange={(e) => onInputChange(e, "link", index)}
                    checked={item.link}
                  ></Checkbox>
                </div>
              </div>
              <div className="field col-12 md:col-2">
                <label htmlFor="key">Important key</label>
                <div className="ml-2">
                  <Checkbox
                    onChange={(e) =>
                      onInputChangeDescription(e, "enable", index)
                    }
                    checked={item.description?.enable || false}
                  ></Checkbox>
                </div>
              </div>

              {item?.description?.enable && (
                <div className="field col-12 md:col-6">
                  <label htmlFor="description">Type key</label>
                  <Dropdown
                    value={item?.description?.key}
                    options={Object.keys(KET_DESCRIPTION_FORM_KYC).map((k) => ({
                      label: k,
                      value: KET_DESCRIPTION_FORM_KYC[k],
                    }))}
                    optionLabel="label"
                    optionValue="value"
                    onChange={(e) => onInputChangeDescription(e, "key", index)}
                  />
                  {/* <div className="field col-12 md:col-6">
                  <InputText
                    id="key"
                    value={item.description?.key}
                    onChange={(e) => onInputChangeDescription(e, "key", index)}
                    required
                    autoFocus
                    placeholder="Enter key"
                  />
                </div> */}
                </div>
              )}
            </div>
          </Panel>
        ))}

      <div className="field col-12 md:col-12">
        <label htmlFor="attachments">Attachments</label>
        <br />
        <InputSwitch
          checked={dataForm.attachments.enable}
          onChange={(e) => onInputCheckChange(e)}
        />
      </div>
      {dataForm.attachments.enable && (
        <div className="field col-12 md:col-12">
          <label htmlFor="attachments">Guild</label>
          <br />
          <VEditor
            value={dataForm.attachments.guide}
            onChange={(e) => {
              changeHandlerContent(e?.html);
            }}
            haveData={dataForm.attachments.guide ? true : false}
          />
        </div>
      )}
      <VDialog
        ref={refDialogDetail}
        header="Options"
        onSubmit={onSumitDialogDetail}
      >
        <ImportOptions
          ref={refDetail}
          index={indexImport}
          dataForm={dataForm}
          setDataForm={setDataForm}
          onCancel={onCancelDialogDetail}
        />
      </VDialog>
    </div>
  );
};

export default FromAdditional;
