/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Panel } from "primereact";
import "../styles.scss";
import CampaignService from "services/campaigns";
import { nanoid } from "nanoid";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputTextarea } from "primereact/inputtextarea";
import VUpload from "components/v-upload";
import VReward from "components/v-reward";
import VAffiliate from "components/v-affiliate";

const Levels = (props, ref) => {
  const { data, reload, toast, setLoading } = props;

  const [details, setDetails] = useState({});
  const [levels, setLevels] = useState([]);

  const defaultLevel = {
    f1_amount: 0,
    f1_enable: false,

    trust_amount: 0,
    trust_enable: false,

    post_amount: 0,
    post_enable: false,

    like_amount: 0,
    like_enable: false,

    feel_amount: 0,
    feel_enable: false,

    comment_amount: 0,
    comment_enable: false,

    like_receive_amount: 0,
    like_receive_enable: false,

    feel_receive_amount: 0,
    feel_receive_enable: false,

    share_amount: 0,
    share_enable: false,

    image_amount: 0,
    image_enable: false,

    reward: {
      stocks: [],
      vouchers: [],
      tickets: [],
      codes: [],
    },

    affiliates: {},
  };

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) {
      setDetails(data);
      setLevels(data.levels);
    }
  }, [data]);

  const handleAddLevel = () => {
    setLevels((prev) => [
      ...prev,
      {
        id: `LEVEL_${nanoid(6).toUpperCase()}`,
        ...defaultLevel,
      },
    ]);
  };

  const handleSubLevel = () => {
    setLevels((prev) => prev.slice(0, prev.length - 1));
  };

  const handleChangeLevel = (id) => (name, value) => {
    let _levels = levels.map((p) =>
      p.id === id
        ? {
            ...p,
            [name]: value,
          }
        : p
    );
    setLevels(_levels);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await CampaignService.editCampaign({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            levels: levels,
          },
        });
      } else {
        await CampaignService.createCampaign({
          body: {
            ...details,
            levels: levels,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Saved!");
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <>
      <div className="col-12">
        <Button
          icon="bx bxs-add-to-queue"
          className="p-button-rounded p-button-success"
          aria-label="Search"
          onClick={handleAddLevel}
        />
        <Button
          icon="bx bxs-minus-circle"
          className="p-button-rounded p-button-danger"
          aria-label="Cancel"
          onClick={handleSubLevel}
        />
      </div>
      {levels.map((level, index) => (
        <Panel
          header={`Level ${index + 1}`}
          toggleable
          className="my-2"
          collapsed={true}
        >
          <div className="grid">
            <div className="grid col-12">
              <div className="field col-6">
                <label htmlFor="time_lock">ID</label>
                <InputText id="id" value={level.id} required disabled />
              </div>
              <div className="field col-6">
                <label htmlFor="name">Name</label>
                <InputText
                  id="name"
                  value={level.name}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("name", e.target.value)
                  }
                />
              </div>

              <div className="field col-12">
                <label htmlFor="desc">Description</label>
                <InputTextarea
                  value={level.description}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("description", e.target.value)
                  }
                />
              </div>

              <div className="field col-12">
                <VUpload
                  label="Logo level"
                  urlFile={level.image}
                  setUrlFile={(file) =>
                    handleChangeLevel(level.id)("image", file)
                  }
                />
              </div>
            </div>

            <div className="grid col-12 md:col-6">
              <div className="field col-8">
                <label htmlFor="f1_amount">F1</label>
                <InputNumber
                  id="f1_amount"
                  value={level.f1_amount}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("f1_amount", e.value)
                  }
                />
              </div>
              <div className="field col-4">
                <br />
                <br />
                <InputSwitch
                  checked={level.f1_enable}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("f1_enable", e.value)
                  }
                />
              </div>
            </div>

            <div className="grid col-12 md:col-6">
              <div className="field col-8">
                <label htmlFor="trust_amount">Trust</label>
                <InputNumber
                  id="trust_amount"
                  value={level.trust_amount}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("trust_amount", e.value)
                  }
                />
              </div>
              <div className="field col-4">
                <br />
                <br />
                <InputSwitch
                  checked={level.trust_enable}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("trust_enable", e.value)
                  }
                />
              </div>
            </div>

            <div className="grid col-12 md:col-6">
              <div className="field col-8">
                <label htmlFor="post_amount">Post</label>
                <InputNumber
                  id="post_amount"
                  value={level.post_amount}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("post_amount", e.value)
                  }
                />
              </div>
              <div className="field col-4">
                <br />
                <br />
                <InputSwitch
                  checked={level.post_enable}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("post_enable", e.value)
                  }
                />
              </div>
            </div>

            <div className="grid col-12 md:col-6">
              <div className="field col-8">
                <label htmlFor="like_amount">Like</label>
                <InputNumber
                  id="like_amount"
                  value={level.like_amount}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("like_amount", e.value)
                  }
                />
              </div>
              <div className="field col-4">
                <br />
                <br />
                <InputSwitch
                  checked={level.like_enable}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("like_enable", e.value)
                  }
                />
              </div>
            </div>

            <div className="grid col-12 md:col-6">
              <div className="field col-8">
                <label htmlFor="feel_amount">Feel</label>
                <InputNumber
                  id="feel_amount"
                  value={level.feel_amount}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("feel_amount", e.value)
                  }
                />
              </div>
              <div className="field col-4">
                <br />
                <br />
                <InputSwitch
                  checked={level.feel_enable}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("feel_enable", e.value)
                  }
                />
              </div>
            </div>

            <div className="grid col-12 md:col-6">
              <div className="field col-8">
                <label htmlFor="comment_amount">Comment</label>
                <InputNumber
                  id="comment_amount"
                  value={level.comment_amount}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("comment_amount", e.value)
                  }
                />
              </div>
              <div className="field col-4">
                <br />
                <br />
                <InputSwitch
                  checked={level.comment_enable}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("comment_enable", e.value)
                  }
                />
              </div>
            </div>

            <div className="grid col-12 md:col-6">
              <div className="field col-8">
                <label htmlFor="share_amount">Share</label>
                <InputNumber
                  id="share_amount"
                  value={level.share_amount}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("share_amount", e.value)
                  }
                />
              </div>
              <div className="field col-4">
                <br />
                <br />
                <InputSwitch
                  checked={level.share_enable}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("share_enable", e.value)
                  }
                />
              </div>
            </div>

            <div className="grid col-12 md:col-6">
              <div className="field col-8">
                <label htmlFor="like_receive_amount">Like receive</label>
                <InputNumber
                  id="like_receive_amount"
                  value={level.like_receive_amount}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("like_receive_amount", e.value)
                  }
                />
              </div>
              <div className="field col-4">
                <br />
                <br />
                <InputSwitch
                  checked={level.like_receive_enable}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("like_receive_enable", e.value)
                  }
                />
              </div>
            </div>

            <div className="grid col-12 md:col-6">
              <div className="field col-8">
                <label htmlFor="feel_receive_amount">Feel receive</label>
                <InputNumber
                  id="feel_receive_amount"
                  value={level.feel_receive_amount}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("feel_receive_amount", e.value)
                  }
                />
              </div>
              <div className="field col-4">
                <br />
                <br />
                <InputSwitch
                  checked={level.feel_receive_enable}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("feel_receive_enable", e.value)
                  }
                />
              </div>
            </div>

            <div className="grid col-12 md:col-6">
              <div className="field col-8">
                <label htmlFor="image_amount">Image upload</label>
                <InputNumber
                  id="image_amount"
                  value={level.image_amount}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("image_amount", e.value)
                  }
                />
              </div>
              <div className="field col-4">
                <br />
                <br />
                <InputSwitch
                  checked={level.image_enable}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("image_enable", e.value)
                  }
                />
              </div>
            </div>

            <div className="field col-12">
              <Panel
                header="Reward"
                toggleable
                className="my-2"
                collapsed={true}
              >
                <VReward
                  reward={level.reward}
                  setReward={(reward) =>
                    handleChangeLevel(level.id)("reward", reward)
                  }
                />
              </Panel>
            </div>
            <div className="field col-12">
              <Panel header="Affilate" toggleable collapsed={true}>
                <VAffiliate
                  affiliates={level.affiliates}
                  setAffiliates={(a) =>
                    handleChangeLevel(level.id)("affiliates", a)
                  }
                />
              </Panel>
            </div>
          </div>
        </Panel>
      ))}
    </>
  );
};

export default forwardRef(Levels);
