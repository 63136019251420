/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { Image } from "primereact/image";
import {
  convertObjectToListValue,
  downloadFile,
  formatLocalTime,
  getUrlImage,
  showToast,
} from "utils/common";
import VDialog from "components/v-dialog";
import VConfirm from "components/v-confirm";
import Detail from "./Detail";
import Import from "./Import";
import debounce from "lodash.debounce";
import QuotaService from "services/quotas";
import { MENU_STATUS } from "utils/enum";
import { MultiSelect } from "primereact";
import CategoryFilterService from "services/category-filters";
import { Dropdown } from "primereact/dropdown";
import StatusEditorCell from "components/v-edit-cell/StatusEditorCell";
import { Panel } from "primereact/panel";

const Crud = (props: any) => {
  const { isSpecialDate } = props;
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState(null);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    status: convertObjectToListValue(MENU_STATUS),
    category_filter_id: null,
    is_prioritize: null,
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });
  const defaultFields = [
    { field: "content", header: "Content" },
    { field: "name", header: "Name" },
    { field: "link", header: "Link" },
    { field: "image", header: "Image" },
    { field: "status", header: "Status" },
    { field: "start_date", header: "start_date" },
    { field: "end_date", header: "end_date" },
    { field: "category_filter_id", header: "category_filter_id" },
    { field: "order", header: "Order" },
    { field: "is_prioritize", header: "is_prioritize" },
    { field: "special_date", header: "special_date" },
    { field: "icon", header: "icon" },
    { field: "color", header: "color" },
    { field: "background_color", header: "background_color" },
    { field: "background_image", header: "background_image" },
    { field: "title", header: "title" },
  ];
  const [totalRecords, setTotalRecords] = useState(0);
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [importedData, setImportedData] = useState([]);
  const [importedCols, setImportedCols] = useState([]);
  const [defaultCateId, setDefaultCateId] = useState(null);

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);
  const refDetail = useRef(null);
  const fileRef = useRef(null);
  const refDialogImport = useRef(null);
  const refImport = useRef(null);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  useEffect(() => {
    getCategoryFilters();
  }, []);

  const loadData = async () => {
    try {
      const res: any = await QuotaService.search({
        query: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
          limit: lazyParams.rows,
          isSpecialDate: isSpecialDate,
        },
      });
      if (res && res.docs) {
        setData(res.docs);
        setTotalRecords(res.totalDocs);
      } else setData([]);
    } catch (error) {
      setData([]);
    }
  };

  const getCategoryFilters = async () => {
    try {
      const res: any = await CategoryFilterService.getAll();
      if (res) {
        setCategoryFilters(res);
        setDefaultCateId(res?.find((e) => e?.name === "default")?._id);
      }
    } catch (error) {}
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  //CREATE OR NEW
  const openNew = () => {
    setDetail(null);
    refDialogDetail.current.show();
  };

  const editActionPost = (data) => {
    setDetail({ ...data });
    refDialogDetail.current.show();
  };

  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };

  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };

  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  const onSumitDialogImport = () => {
    refImport.current.submit();
  };

  // DELETE
  const confirmDelete = (quotas) => {
    setDetail(quotas);
    refDialogDelete.current.show();
  };
  const handleDelete = async () => {
    try {
      await QuotaService.deleted({
        params: {
          id: detail._id,
        },
      });
      const _data = data.filter((val) => val._id !== detail._id);
      setData(_data);
      refDialogDelete.current.close();
      setDetail(null);
      showToast(toast, "success", "User Deleted");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const toCapitalize = (s) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const importExcelFunc = (e) => {
    const file = e.files[0];
    import("xlsx")
      .then((xlsx) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const wb = xlsx.read(e.target.result, { type: "array" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data: any = xlsx.utils.sheet_to_json(ws, { header: 1 });
          // Prepare DataTable
          const cols: any = data[0];
          data.shift();
          let _importedCols = cols.map((col) => ({
            field: col.toLowerCase(),
            header: toCapitalize(col),
          }));
          const dataNoEmpty = data
            .map((i, index) => {
              if (i.length > 0) {
                return [...i];
              }
            })
            .filter(Boolean);

          const withoutImport = defaultFields?.filter(
            (e) => !cols?.includes(e.field)
          );
          const _importedData = dataNoEmpty.map((row: any) => {
            let newRow = {};
            _importedCols?.map((e: any, index) => {
              newRow = { ...newRow, [e.field]: row[index] };
            });
            withoutImport?.map((e: any, index) => {
              newRow = { ...newRow, [e.field]: null };
            });
            return newRow;
          });

          setImportedCols(
            _importedCols?.filter((e) =>
              defaultFields?.map((f) => f.field)?.includes(e.field)
            )
          );
          setImportedData(_importedData);
        };

        reader.readAsArrayBuffer(file);
      })
      .finally(e.options.clear());

    refDialogImport.current.show();

    showToast(toast, "success", "File imported!");
  };

  const cancelOptions = {
    label: "Cancel",
    icon: "pi pi-times",
    className: "p-button-danger",
  };

  const saveDataImport = async (dataImportMerged) => {
    try {
      await QuotaService.importExcel({
        body: {
          quotas: dataImportMerged,
        },
      });
      loadData();
      clear();
      showToast(toast, "success", "Success");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const clear = () => {
    setImportedData([]);
    fileRef.current.clear();
    refDialogImport.current.close();
  };

  const onChangeStatus = async (options, rowData, value) => {
    let updateRow;
    let newData = data.map((e, index) => {
      if (e._id === rowData._id) {
        e[options.field] = value;
        updateRow = e;
      }
      return e;
    });
    setData(newData);

    if (updateRow) {
      await QuotaService.update({
        params: { id: updateRow._id },
        body: {
          ...updateRow,
        },
      });
    }
  };

  // const statusEditor = (options) => {
  //   return <StatusEditor options={options} onChange={onChangeStatus} />;
  // };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />

          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled={true}
          />
        </div>
      </React.Fragment>
    );
  };

  const templateUrl = isSpecialDate
    ? "admin/template/2a281c81-6365-4f6e-9e02-831c1eda2e8d.xlsx"
    : "admin/template/b4eee816-bc99-412f-89eb-af7ae7191878.xlsx";

  const templateFileName = isSpecialDate
    ? "Import_Special_Dates_Template.xlsx"
    : "Import_Quotas_Template.xlsx";

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          ref={fileRef}
          chooseOptions={{
            label: `Import ${isSpecialDate ? "Special Dates" : "Quotas"} `,
            icon: "pi pi-file-excel",
            className: "p-button-success",
          }}
          mode="basic"
          // progressBarTemplate={loading && <ProgressBar mode="indeterminate" />}
          name="demo[]"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          className="mr-2"
          uploadHandler={importExcelFunc}
          customUpload={true}
          cancelOptions={cancelOptions}
        />
        <Button
          label="Download Template"
          icon="pi pi-download"
          className="p-button-help"
          onClick={() => downloadFile(templateUrl, templateFileName)}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editActionPost(rowData)}
        />

        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning"
          onClick={() => confirmDelete(rowData)}
        />
      </div>
    );
  };

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="grid px-2 align-items-center">
        <div className="col-12 md:col-6">
          <span className="block p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e: any) => onSearch("search", e.target.value)}
              placeholder="Search..."
              className="w-full"
            />
          </span>{" "}
        </div>
        <div className="col-12 md:col-6">
          <MultiSelect
            value={globalFilter.status}
            options={Object.keys(MENU_STATUS).map((k) => ({
              label: k,
              value: MENU_STATUS[k],
            }))}
            onChange={(e: any) => onSearch("status", e.value)}
            optionLabel="label"
            placeholder="Status"
            maxSelectedLabels={3}
            className={"w-full"}
            showClear
          />
        </div>
        <div className="col-12 md:col-6">
          <Dropdown
            filter
            showClear
            value={globalFilter.category_filter_id}
            options={categoryFilters.map((item) => ({
              label: item.name,
              value: item._id,
            }))}
            optionLabel="label"
            optionValue="value"
            placeholder="Select filter"
            onChange={(e) => onSearch("category_filter_id", e.value)}
            className="w-full"
          />
        </div>
        <div className="col-12 md:col-6">
          <Dropdown
            value={globalFilter.is_prioritize}
            options={[
              {
                label: "TRUE",
                value: true,
              },
              {
                label: "FALSE",
                value: false,
              },
            ]}
            optionLabel="label"
            optionValue="value"
            placeholder="Select priority"
            onChange={(e) => onSearch("is_prioritize", e.value)}
            className="w-full"
          />
        </div>
      </div>
    </Panel>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={data}
            header={header}
            emptyMessage="No data found."
            dataKey="id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            paginator
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[5, 10, 20]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            //sort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onSort}
          >
            <Column
              frozen
              alignFrozen="left"
              body={actionBodyTemplate}
              style={{ flexGrow: 1, flexBasis: "120px" }}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "80px" }}
              header="Image"
              body={(rowData) => {
                return (
                  <Image
                    src={getUrlImage(rowData.image)}
                    alt={rowData.name}
                    width="50"
                    height="50"
                    preview
                  />
                );
              }}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="name"
              header="Name"
              sortable
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "120px" }}
              field="is_prioritize"
              header="Prioritize"
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "80px" }}
              field="content"
              header="Quantity"
              body={(rowData) => rowData?.content?.length}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "300px" }}
              field="link"
              header="Link"
              body={(rowData) => {
                let targetLink = "";
                if (rowData.link.length > 50) {
                  targetLink = rowData.link.slice(0, 50) + "...";
                } else {
                  targetLink = rowData.link;
                }
                return <span>{targetLink}</span>;
              }}
            ></Column>
            <Column
              // editor={statusEditor}
              style={{ flexGrow: 1, flexBasis: "180px" }}
              field="status"
              header="Status"
              // body={(rowData) => {
              //   return (
              //     <span
              //       className={`v-badge status-${rowData.status.toLowerCase()}`}
              //     >
              //       {rowData.status}
              //     </span>
              //   );
              // }}
              body={(rowData, options) => (
                <StatusEditorCell
                  value={rowData.status}
                  options={options}
                  rowData={rowData}
                  onChange={onChangeStatus}
                />
              )}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="startDate"
              header="Start Date"
              sortable
              body={(rowData) => formatLocalTime(rowData.start_date)}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="endDate"
              header="End Date"
              sortable
              body={(rowData) => formatLocalTime(rowData.end_date)}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="createdAt"
              header="Created At"
              sortable
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>
          </DataTable>

          <VDialog
            ref={refDialogDetail}
            header="Detail"
            onSubmit={onSumitDialogDetail}
          >
            <Detail
              ref={refDetail}
              data={detail}
              toast={toast}
              categoryFilters={categoryFilters}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
              getCategoryFilters={getCategoryFilters}
            />
          </VDialog>

          <VDialog
            ref={refDialogImport}
            header="Select column to import"
            onSubmit={onSumitDialogImport}
          >
            <Import
              ref={refImport}
              data={importedData}
              toast={toast}
              saveData={saveDataImport}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
              columns={importedCols}
              isSpecialDate={isSpecialDate}
              defaultCategoryId={defaultCateId}
            />
          </VDialog>

          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Crud, comparisonFn);
