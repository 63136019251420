/* eslint-disable react-hooks/exhaustive-deps */
import { MasterContext } from "contexts/MasterContext";
import { Dropdown } from "primereact/dropdown";
import { useCallback, useContext, useEffect, useState } from "react";
import UserService from "services/users";
import debounce from "lodash.debounce";

const View = ({
  value,
  setValue,
  className = "",
  optionValue = "_id",
  optionLabel = "email",
}) => {
  const { users } = useContext(MasterContext);
  const defaultUser = [{}];
  const [userSearch, setUsersSearch] = useState([]);

  useEffect(() => {
    if (value) getUsers("");
  }, [value]);

  useEffect(() => {
    setUsersSearch(users);
  }, []);

  const getUsers = (search) => {
    UserService.getUsers({
      query: {
        search,
        value,
      },
    }).then((res: any) => {
      if (res && res.users && res.users.length > 0) setUsersSearch(res.users);
      else setUsersSearch([defaultUser]);
    });
  };

  const onSearch = useCallback(debounce(getUsers, 500), [value]);

  return (
    <Dropdown
      className={className || ""}
      defaultValue={value}
      value={value}
      options={userSearch}
      onChange={(e) => setValue(e.value)}
      optionLabel={optionLabel}
      filterBy="full_name,email,refer_code"
      optionValue={optionValue}
      showClear
      filter
      onFilter={(f) => {
        onSearch(f.filter);
      }}
      placeholder="Select user"
    />
  );
};

export default View;
