/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import ShipService from "services/ships";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    name: "",
    status: "enable",
    price: 0,
    width: {
      from: 0,
      to: 0,
    },
    height: {
      from: 0,
      to: 0,
    },
    length: {
      from: 0,
      to: 0,
    },
    weight: {
      from: 0,
      to: 0,
    },
  };

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data)
      setDetails({
        ...data,
      });
  }, [data]);

  const onInputChange = (value, name, field?: string) => {
    let _details = { ...details };
    if (!field)
      setDetails({
        ..._details,
        [name]: value,
      });
    else
      setDetails({
        ..._details,
        [field]: {
          ...details[field],
          [name]: value,
        },
      });
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await ShipService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else {
        await ShipService.create({
          body: {
            ...details,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Success!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12 md:col-12">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onInputChange(e.target.value, "name")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Price</label>
        <InputNumber
          id="number"
          value={details.price}
          onChange={(e) => onInputChange(e.value, "price")}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Status</label>
        <Dropdown
          value={details.status}
          options={[
            { label: "Enable", value: "enable" },
            { label: "Disable", value: "disable" },
          ]}
          onChange={(e) => onInputChange(e.value, "status")}
          optionLabel="label"
          placeholder="Select status"
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Width From (cm)</label>
        <InputNumber
          id="number"
          value={details.width.from}
          onChange={(e) => onInputChange(e.value, "from", "width")}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Width To</label>
        <InputNumber
          id="number"
          value={details.width.to}
          onChange={(e) => onInputChange(e.value, "to", "width")}
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Height From (cm)</label>
        <InputNumber
          id="number"
          value={details.height.from}
          onChange={(e) => onInputChange(e.value, "from", "height")}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Height To (cm)</label>
        <InputNumber
          id="number"
          value={details.height.to}
          onChange={(e) => onInputChange(e.value, "to", "height")}
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Length From (cm)</label>
        <InputNumber
          id="number"
          value={details.length.from}
          onChange={(e) => onInputChange(e.value, "from", "length")}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Length To (cm)</label>
        <InputNumber
          id="number"
          value={details.length.to}
          onChange={(e) => onInputChange(e.value, "to", "length")}
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Weight From (kg)</label>
        <InputNumber
          id="number"
          value={details.weight.from}
          onChange={(e) => onInputChange(e.value, "from", "weight")}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Weight To (kg)</label>
        <InputNumber
          id="number"
          value={details.weight.to}
          onChange={(e) => onInputChange(e.value, "to", "weight")}
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
