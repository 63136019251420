import { request, parseErrorResponse } from "./request";
import qs from "query-string";

const search = (req: any) => {
  return new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/register-receive-infos/search?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          resolve(data);
        } else {
          reject(new Error("Something went error!"));
        }
      })
      .catch(parseErrorResponse)
      .then(reject);
  });
};

const deleted = (req: { params: { id: string } }) => {
  return new Promise((resolve, reject) => {
    request()
      .delete("admin/register-receive-infos/" + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          resolve(data);
        } else {
          reject(new Error("Something went error!"));
        }
      })
      .catch(parseErrorResponse)
      .then(reject);
  });
};

const create = (req: { body: any }) => {
  return new Promise((resolve, reject) => {
    request()
      .post("admin/register-receive-infos", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          resolve(data);
        } else {
          reject(new Error("Something went error!"));
        }
      })
      .catch(parseErrorResponse)
      .then(reject);
  });
};

const update = (req: { params: { id: string }; body: any }) => {
  return new Promise((resolve, reject) => {
    request()
      .put("admin/register-receive-infos/" + req.params.id, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          resolve(data);
        } else {
          reject(new Error("Something went error!"));
        }
      })
      .catch(parseErrorResponse)
      .then(reject);
  });
};

const RegisterReceiveInfoService = {
  search,
  deleted,
  create,
  update,
};

export default RegisterReceiveInfoService;
