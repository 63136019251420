/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useImperativeHandle } from "react";
import { getUrlImage, showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";

import PostService from "services/posts";
import { Image } from "primereact/image";
import { Panel } from "primereact/panel";
import VReward from "components/v-reward";
import { POST_TYPE } from "utils/enum";
import { REWARD_DEFAULT } from "utils/constant";

const emptyData = null;

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;
  const [details, setDetails] = useState(emptyData);
  const [reward, setReward] = useState(REWARD_DEFAULT);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) {
      setDetails({
        ...data,
      });
      if (data.post_type === POST_TYPE.EVENT) {
        setReward(data.post_event?.reward || REWARD_DEFAULT);
      }
      if (data.post_type === POST_TYPE.PRODUCT) {
        setReward(data.post_product?.reward || REWARD_DEFAULT);
      }
    }
  }, [data]);

  const onChange = (name, value) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      await PostService.update({
        params: {
          id: data._id,
        },
        body: {
          admin_status: details.admin_status,
          post_privacy: details.post_privacy,
          post_type: details.post_type,
          reward,
        },
      });
      setLoading(false);
      showToast(toast, "success", "Success!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <Toast ref={toast} />
      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>Post Detail</h4>
          </div>

          <div className="grid">
            <div className="field col-12">
              <Dropdown
                defaultValue={details?.admin_status}
                value={details?.admin_status}
                options={[
                  {
                    label: "ENABLE",
                    value: "enable",
                  },
                  {
                    label: "DISABLE",
                    value: "disable",
                  },
                ]}
                onChange={(e) => onChange("admin_status", e.value)}
                optionLabel="label"
                placeholder="Select status"
              />
            </div>
            {details && (
              <div className="field col-12">
                <div>
                  {" "}
                  <Dropdown
                    defaultValue={details?.post_privacy}
                    value={details?.post_privacy}
                    options={[
                      {
                        label: "PUBLIC",
                        value: "public",
                      },
                      {
                        label: "FRIEND",
                        value: "friend",
                      },
                      {
                        label: "FRIEND EXCEPT",
                        value: "friend_except",
                      },
                      {
                        label: "ONLY ME",
                        value: "only_me",
                      },
                      {
                        label: "FRIEND DETAIL",
                        value: "friend_detail",
                      },
                      {
                        label: "ACQUAINTANCE",
                        value: "acquaintance",
                      },
                    ]}
                    onChange={(e) => onChange("post_privacy", e.value)}
                    optionLabel="label"
                    placeholder="Select status"
                  />
                  <hr />
                </div>
                <div>
                  {" "}
                  <label htmlFor="order">Status : </label>
                  <span>{details.post_status}</span>
                  <hr />
                </div>

                <div>
                  {" "}
                  <label htmlFor="order">Title : </label>
                  <span>{details.post_title}</span>
                  <hr />
                </div>
                <div>
                  {" "}
                  <label htmlFor="order">Content : </label>
                  <span>{details.post_content}</span>
                  <hr />
                </div>
                <div>
                  <label htmlFor="order">File : </label>
                  <div>
                    {String(details.file_main?.post_file_type || "").includes(
                      "video"
                    ) ? (
                      <video
                        src={getUrlImage(details.file_main?.post_file_path)}
                        width={400}
                        autoPlay
                      ></video>
                    ) : (
                      <Image
                        preview
                        width="400"
                        src={getUrlImage(details.file_main?.post_file_path)}
                      />
                    )}
                  </div>

                  <hr />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {(data?.post_type === POST_TYPE.PRODUCT ||
        data?.post_type === POST_TYPE.EVENT) && (
        <div className="col-12">
          {" "}
          <Panel header="Reward" toggleable className="my-2" collapsed={true}>
            <VReward
              reward={reward}
              setReward={(reward) => setReward(reward)}
            />
          </Panel>
        </div>
      )}
    </div>
  );
};

export default forwardRef(Details);
