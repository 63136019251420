/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Toast } from "primereact/toast";
import FontService from "services/fonts";
import VUpload from "components/v-upload";

interface Font {
  _id?: string;
  name: string;
  fonts: { style?: string; urls: string[] }[];
}

const defaultFont = {
  name: "",
  fonts: [
    { urls: [], style: "Normal" },
    { urls: [], style: "Bold" },
    { urls: [], style: "Italic" },
  ],
};

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const [formData, setFormData] = useState<Font>(data || defaultFont);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  const submit = async () => {
    try {
      setLoading(true);
      let filterData = {
        ...formData,
        name: formData.name.trim(),
      };

      const fontNormal = filterData?.fonts?.find((e) => e?.style === "Normal")
        ?.urls?.[0];

      if (fontNormal) {
        const defaultFontIndex = filterData?.fonts?.findIndex((e) => !e?.style);
        if (defaultFontIndex !== -1) {
          filterData.fonts[defaultFontIndex] = { urls: [fontNormal] };
        } else {
          filterData.fonts.push({ urls: [fontNormal] });
        }
      }

      if (formData?._id) {
        await FontService.update({
          params: {
            id: formData?._id,
          },
          body: filterData,
        });
      } else {
        await FontService.create({
          body: filterData,
        });
      }

      setLoading(false);
      showToast(toast, "success", "File saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const onChangeUrl = (e, item, idx) => {
    const newFont: any = JSON.parse(JSON.stringify(formData));
    newFont.fonts[idx].urls[0] = e.target.value;
    setFormData({ ...newFont });
  };

  const handleUploadFontFile = (filePath: string, index: number) => {
    setFormData((prev) => {
      return {
        ...prev,
        fonts: prev?.fonts?.map((f, i) => {
          return i === index ? { ...f, urls: [filePath] } : { ...f };
        }),
      };
    });
  };

  return (
    <div className="grid">
      <Toast ref={toast} />

      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>Fonts</h4>
          </div>

          <div className="grid">
            <div className="field col-12 md:col-6">
              <label htmlFor="name">Name</label>
              <InputText
                id="name"
                className="w-full"
                value={formData.name}
                onChange={(e) =>
                  setFormData((prev: any) => ({
                    ...prev,
                    name: e.target.value,
                  }))
                }
              />
            </div>
          </div>

          <div className="pb-3">
            <h4>Urls</h4>
          </div>
          {formData?.fonts?.map((item, idx) => {
            return (
              <div className="grid pb-3 align-items-center" key={idx}>
                {/* link */}
                <div className="col-12 lg:col-4">
                  <span className="p-float-label">
                    <InputText value={item?.style || "Normal"} />
                    <label htmlFor="link">Type</label>
                  </span>
                </div>

                {/* url */}
                <div className="col-12 lg:col-6">
                  <span className="p-float-label">
                    <InputText
                      id="name"
                      className="w-full"
                      value={item?.urls[0]}
                      placeholder="Example: https://file2.vdiarybook.com:4443/api/fonts/..."
                      onChange={(e) => onChangeUrl(e, item, idx)}
                    />
                    <label htmlFor="link">Url</label>
                  </span>
                </div>

                <div className="col-12 lg:col-2">
                  <VUpload
                    fileType="*"
                    isShowFile={false}
                    folderPath="image-editor/fonts"
                    setUrlFile={(file) => handleUploadFontFile(file, idx)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Details);
