/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useImperativeHandle,
} from "react";
import { forwardRef } from "react";
import { InputTextarea } from "primereact/inputtextarea";

const ImportOptions = (props, ref) => {
  const { index, dataForm, setDataForm, onCancel } = props;

  const [details, setDetails] = useState('');

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  const onChange = (e) => {
    setDetails(e.target.value);
  };

  const submit =  () => {
    const sep = /\r\n|\n/;
    const data = details?.split(sep)
    setDataForm({
      ...dataForm,
      fields: dataForm.fields.map((el: any, idx: number) =>
        idx === index
          ? {
              ...el,
              options: data?.map((op) =>( { name: op }))
            }
          : el
      ),
    });
    onCancel()
  };

  return (
    <div className="grid">
      <div className="field col-12 md:col-12">
        <label htmlFor="">List</label>
        <InputTextarea
          id="options"
          value={details}
          onChange={(e) => onChange(e)}
          required
          autoFocus
          placeholder="Options 1 \n Options 2 \n ..."
        />
      </div>
    </div>
  );
};

export default forwardRef(ImportOptions);
