const ProductDetail = (props) => {
  const { data } = props;
  return (
    <div>
      <iframe
        loading={"lazy"}
        title="question-and-answer-table"
        src={`${
          process.env.REACT_APP_CLIENT_DOMAIN
        }/products/form?token=${localStorage.getItem("token_auth")}&productId=${
          data?._id
        }&redirect=false&webview=true`}
        frameBorder="0"
        className="w-full"
        height={window.innerHeight - 150}
      ></iframe>
    </div>
  );
};

export default ProductDetail;
