/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useImperativeHandle } from "react";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";

const Import = (props, ref) => {
  const { data, isSpecialDate, columns, saveData, defaultCategoryId } = props;

  const emptyData = {
    content: { field: "content", header: "Content" },
    name: { field: "name", header: "Name" },
    link: { field: "link", header: "Link" },
    image: { field: "image", header: "Image" },
    status: { field: "status", header: "Status" },
    start_date: { field: "start_date", header: "start_date" },
    end_date: { field: "end_date", header: "end_date" },
    category_filter_id: {
      field: "category_filter_id",
      header: "category_filter_id",
    },
    order: { field: "order", header: "Order" },
    is_prioritize: { field: "is_prioritize", header: "is_prioritize" },
    special_date: { field: "special_date", header: "special_date" },
    icon: { field: "icon", header: "Icon" },
    color: { field: "color", header: "Color" },
    background_color: { field: "background_color", header: "background_color" },
    background_image: { field: "background_image", header: "background_image" },
    title: { field: "title", header: "Title" },
  };

  const [mapping, setMapping] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  const onChange = (name, value, index?: number) => {
    let _details = { ...mapping };
    if (name === "content") {
      _details[`${name}`] = _details[`${name}`].map((el, i) =>
        index === i
          ? {
              name: value,
            }
          : el
      );
    } else {
      _details[`${name}`] = value;
    }
    setMapping(_details);
  };

  const submit = () => {
    const mergeData = data?.map((row: any) => {
      let newRow: any = {};
      columns?.map((e: any) => {
        newRow = {
          ...newRow,
          [e.field]: row[mapping[e.field]?.field] || null,
        };
      });
      if (!newRow?.category_filter_id) {
        newRow.category_filter_id = defaultCategoryId;
      }
      return newRow;
    });
    saveData(mergeData);
  };

  const ColumnSelect = (field) => {
    return (
      <Dropdown
        showClear
        defaultValue={mapping?.[`${field}`]}
        value={mapping?.[`${field}`]}
        options={columns}
        onChange={(e) => onChange(field, e.value)}
        optionLabel="header"
        placeholder="Select column"
      />
    );
  };

  return (
    <div className="grid">
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Name</label>
        {ColumnSelect("name")}
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="status">Status</label>
        {ColumnSelect("status")}
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="order">Order</label>
        {ColumnSelect("order")}
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="link">Link</label>
        {ColumnSelect("link")}
      </div>

      <div className="field col-12 md:col-6">
        <label className="startDate">Start Date</label>
        {ColumnSelect("start_date")}
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="endDate">End Date</label>
        {ColumnSelect("end_date")}
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="Prioritize">Prioritize</label>
        {ColumnSelect("is_prioritize")}
      </div>

      {isSpecialDate && (
        <>
          <div className="field col-12 md:col-6">
            <label className="specialDate">Special Date</label>
            {ColumnSelect("special_date")}
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="title">Title</label>
            {ColumnSelect("title")}
          </div>
          <div className="field col-12 md:col-6">
            <label>Icon</label>
            {ColumnSelect("icon")}
          </div>
        </>
      )}

      {isSpecialDate && (
        <>
          <div className="field col-12 md:col-6">
            <label htmlFor="text_color">Text color &nbsp; &nbsp;</label>
            {ColumnSelect("text_color")}
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="name">Background color &nbsp; &nbsp;</label>
            {ColumnSelect("background_color")}
          </div>
        </>
      )}

      <div className="field col-12 md:col-6">
        <label>Image</label>
        {ColumnSelect("image")}
      </div>
      <div className="field col-12 md:col-6">
        <label>Background Image</label>
        {ColumnSelect("background_image")}
      </div>
      <div className="field col-12 md:col-6">
        <label>Category Filter Id</label>
        {ColumnSelect("category_filter_id")}
      </div>
    </div>
  );
};

export default forwardRef(Import);
