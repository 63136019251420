import React, { useEffect, useRef, useState } from "react";
import { InputSwitch } from "primereact/inputswitch";
import { Button, InputText, Toast } from "primereact";
import SettingService from "services/settings";
import { nanoid } from "nanoid";
import { showToast } from "utils/common";
import VDialog from "components/v-dialog";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import VUpload from "components/v-upload";

const View = () => {
  const toast = useRef(null);
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const refDialogDetail = useRef(null);
  const [dataDouble, setDataDouble] = useState(null);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdd = () => {
    setState((state) => [...state, { index: nanoid() }]);
  };

  const handleCancel = async () => {
    try {
      getData();
    } catch (error) {}
  };

  const getData = async () => {
    try {
      const res: any = await SettingService.getSettingsByName({
        params: {
          name: "sites",
        },
      });
      if (res) {
        const { setting } = res;
        if (setting) {
          const value = setting["value"] ? setting["value"] : [];
          setState(value);
        }
      }
    } catch (error) {}
  };

  const handleSubIndex = (index) => {
    const newState = [...state].filter((i) => i.index !== index);
    setState(newState);
  };

  const submitHandler = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      await SettingService.updateSetting({
        body: {
          name: "sites",
          value: state,
        },
      });
      await getData();
      showToast(toast, "success", "Update dsuccess!");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const onInputCheckChange = (e, name, index) => {
    const _state = state.map((i) =>
      i.index === index
        ? {
            ...i,
            [name]: true,
          }
        : {
            ...i,
            [name]: false,
          }
    );
    setState(_state);
  };

  const changeHandler = (value, name, index) => {
    const arr = state.map((i) =>
      i.index === index
        ? {
            ...i,
            [name]: value,
          }
        : i
    );
    setState(arr);
  };

  //CREATE OR NEW
  const openModalDoubleData = (fromSite) => {
    setDataDouble({
      to_site: fromSite,
    });
    refDialogDetail.current.show();
  };

  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };

  const onSumitDialogDetail = async () => {
    try {
      setLoading(true);
      await SettingService.doubleData({
        body: dataDouble,
      });
      onCancelDialogDetail();
      // update double
      const _state = state.map((i) =>
        i.domain === dataDouble.to_site
          ? {
              ...i,
              is_double: true,
            }
          : i
      );
      setState(_state);
      await SettingService.updateSetting({
        body: {
          name: "sites",
          value: _state,
        },
      });
      showToast(toast, "success", "Success!");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const onChangeDoubleData = (name, value) => {
    setDataDouble((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const ModalDoubleData = () => {
    return (
      <div className="grid">
        <div className="field col-12">
          <label htmlFor="id">From Site</label>
          <Dropdown
            value={dataDouble?.from_site}
            className="w-full"
            options={state.filter((a) => a.domain !== dataDouble.to_site)}
            onChange={(e) => onChangeDoubleData("from_site", e.value)}
            optionLabel="domain"
            optionValue="domain"
            placeholder="From site"
          />
        </div>
        <div className="field col-12">
          <label htmlFor="id">To Site</label>
          <InputText id="id" className="w-full" value={dataDouble?.to_site} />
        </div>

        <div className="field col-12 ">
          <label htmlFor="name">Tables</label>
          <MultiSelect
            filter
            value={dataDouble.tables}
            options={[
              {
                name: "Menu",
                value: "menu",
              },
              {
                name: "Category",
                value: "category",
              },
              {
                name: "Banner",
                value: "banner",
              },
              {
                name: "Setting",
                value: "setting",
              },
              {
                name: "Page background",
                value: "page-background",
              },
              {
                name: "Locale",
                value: "locale",
              },
              {
                name: "Quota",
                value: "quota",
              },
            ]}
            optionLabel="name"
            optionValue="value"
            onChange={(e) => onChangeDoubleData("tables", e.value)}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="grid">
      <Toast ref={toast} />

      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>Sites</h4>
          </div>
          {state.map((item, index) => {
            return (
              <div className="grid" key={index}>
                <div className="field col-12 md:col-2">
                  <VUpload
                    label="Logo"
                    urlFile={item.logo}
                    setUrlFile={(file) =>
                      changeHandler(file, "logo", item.index)
                    }
                  />
                </div>
                <div className="field col-12 md:col-2">
                  <span className="p-float-label">
                    <InputText
                      id="name"
                      className="w-full"
                      value={item.name}
                      onChange={(e) =>
                        changeHandler(e.target.value, "name", item.index)
                      }
                    />
                    <label htmlFor="id">Name</label>
                  </span>
                </div>
                <div className="field col-12 md:col-2">
                  <span className="p-float-label">
                    <InputText
                      id="name"
                      className="w-full"
                      value={item.domain}
                      onChange={(e) =>
                        changeHandler(e.target.value, "domain", item.index)
                      }
                    />
                    <label htmlFor="id">Domain</label>
                  </span>
                </div>
                <div className="field col-6 md:col-2">
                  <span className="p-float-label">
                    <Button
                      className="p-button-rounded p-button-warning"
                      aria-label="Cancel"
                      onClick={(_) => openModalDoubleData(item.domain)}
                    >
                      Double Data
                    </Button>
                  </span>
                </div>
                <div
                  className="field col-6 md:col-1"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <InputSwitch checked={item.is_double} disabled />
                </div>
                <div
                  className="field col-6  md:col-1"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <br />
                  <InputSwitch
                    checked={item.is_default}
                    onChange={(e) =>
                      onInputCheckChange(e, "is_default", item.index)
                    }
                  />
                </div>
                <div className="field col-12 md:col-2">
                  <Button
                    icon="pi pi-times"
                    className="p-button-rounded p-button-danger"
                    aria-label="Cancel"
                    onClick={(_) => handleSubIndex(item.index)}
                  />
                </div>
              </div>
            );
          })}
          <Button
            onClick={handleAdd}
            icon="bx bxs-add-to-queue"
            className="p-button-rounded p-button-success mb-3"
            aria-label="Search"
          />
          <div className="">
            <Button
              label="Cancel"
              className="p-button-danger mr-2"
              loading={loading}
              onClick={handleCancel}
            />
            <Button label="Submit" loading={loading} onClick={submitHandler} />
          </div>
        </div>

        <VDialog
          loading={loading}
          ref={refDialogDetail}
          header="Double Data"
          onSubmit={onSumitDialogDetail}
        >
          <ModalDoubleData />
        </VDialog>
      </div>
    </div>
  );
};

export default View;
