/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { selectEmpty, showToast } from "utils/common";
import { forwardRef } from "react";
import MenuService from "services/menus";
import { MENU_STATUS, MENU_TYPE, MENU_TYPE_VIEW, MENU_ROLE } from "utils/enum";
import { Dropdown, InputNumber } from "primereact";
import IconBar from "components/v-icon-bar";
import VDialog from "components/v-dialog";
import { Button } from "primereact/button";
import VUpload from "components/v-upload";
import { TreeSelect } from "primereact/treeselect";
import { ICONS } from "utils/constant";

const Details = (props, ref) => {
  const {
    data,
    reload,
    toast,
    onCancel,
    setLoading,
    treeMenus,
    loadTreeMenus,
    categoryFilters,
    parentMenu,
  } = props;

  const emptyData = {
    name: "",
    key: "",
    type: MENU_TYPE.MENU,
    url: "",
    type_view: MENU_TYPE_VIEW.INTERNAL,
    icon: "",
    img_icon: "",
    parent_id: null,
    category: "",
    order: 0,
    status: MENU_STATUS.ENABLE,
    module: "",
    category_filter_id: null,
  };

  const [details, setDetails] = useState(emptyData);
  const refDialogDetail = useRef(null);
  const icons = ICONS.KEYS.map((i) => ICONS.PREFIX + i + ICONS.SIZE);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
  }, [data]);

  const onChange = (name, value) => {
    setDetails((prev) => ({
      ...prev,
      [`${name}`]: value === "-1" ? null : value,
    }));
  };

  //CREATE OR UPDATE
  const openNew = () => {
    refDialogDetail.current.show();
  };

  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };

  const onSelectIcon = (e) => {
    setDetails({ ...details, icon: e });
    onCancelDialogDetail();
  };

  const submit = async () => {
    try {
      setLoading(true);
      let category = null;
      if (!details.category_filter_id) {
        category = categoryFilters.find((c) => c.name === "default");
      }
      if (data) {
        await MenuService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
            role: MENU_ROLE.CENSOR,
          },
        });
      } else
        await MenuService.create({
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
            role: MENU_ROLE.CENSOR,
          },
        });
      setLoading(false);
      loadTreeMenus();
      showToast(toast, "success", "Success!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      {/* <div className="field col-12 md:col-6">
        <label htmlFor="name">Position</label>
        <InputText
          id="position"
          value={details.position}
          onChange={(e) => onChange("position", e.target.value)}
          required
        />
      </div> */}

      <div className="field col-12 md:col-6">
        <label htmlFor="key">Key (Language)</label>
        <InputText
          id="key"
          value={details.key}
          onChange={(e) => onChange("key", e.target.value)}
          required
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange("name", e.target.value)}
          required
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Type</label>
        <Dropdown
          value={details.type}
          options={Object.keys(MENU_TYPE).map((k) => ({
            label: k,
            value: MENU_TYPE[k],
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("type", e.value)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Type View</label>
        <Dropdown
          value={details.type_view}
          options={Object.keys(MENU_TYPE_VIEW).map((k) => ({
            label: k,
            value: MENU_TYPE_VIEW[k],
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("type_view", e.value)}
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="name">Icon</label>
        <div>
          {details.icon ? (
            <Button icon={details.icon || ""} disabled visible />
          ) : (
            <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
          )}{" "}
          &nbsp;&nbsp;
          <>
            {!details.icon ? (
              <Button icon="pi pi-plus" onClick={openNew} />
            ) : (
              <Button
                icon="pi pi-times"
                className="p-button-sm p-button-danger"
                aria-label="Delete"
                style={{
                  height: "38px",
                  marginLeft: "3px",
                }}
                onClick={(_) => {
                  if (details.icon) onChange("icon", null);
                }}
              />
            )}
          </>
        </div>

        {/* <InputText
          id="icon"
          value={details.icon}
          onChange={(e) => onChange("icon", e.target.value)}
          required
        /> */}
      </div>

      <div className="field col-12 md:col-6">
        <div className="mt-2">
          <VUpload
            label="Upload Icon"
            urlFile={details.img_icon}
            setUrlFile={(file) => onChange("img_icon", file)}
          />
        </div>
      </div>

      <VDialog ref={refDialogDetail} header="Select icon">
        <IconBar selected={onSelectIcon} icons={icons}></IconBar>
      </VDialog>

      {/* <div className="field col-12 md:col-12">
        <label htmlFor="description">Parent</label>
        <Dropdown
          value={details.parent_id}
          options={menus.map((m) => ({
            label: m.name,
            value: m._id,
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("parent_id", e.value)}
          showClear
          filter
        />
      </div> */}
      <div className="field col-12 md:col-12">
        <label htmlFor="parent">Parent</label>
        {details?.parent_id?.length > 0 ? (
          <Dropdown
            value={details.parent_id}
            options={[parentMenu]}
            optionLabel="name"
            optionValue="id"
            disable={true}
          />
        ) : (
          <TreeSelect
            placeholder="Select Parent Menu"
            inputId="treeselect"
            value={details.parent_id}
            onChange={(e) => onChange("parent_id", e.value)}
            options={[selectEmpty, ...treeMenus]}
          />
        )}
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="name">URL</label>
        <InputText
          id="url"
          value={details.url}
          onChange={(e) => onChange("url", e.target.value)}
          required
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="name">Module</label>
        <InputText
          id="module"
          value={details.module}
          onChange={(e) => onChange("module", e.target.value)}
          required
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="name">Order</label>
        <InputNumber
          id="name"
          value={details.order}
          onChange={(e) => onChange("order", e.value)}
          required
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Status</label>
        <Dropdown
          value={details.status}
          options={Object.keys(MENU_STATUS).map((k) => ({
            label: k,
            value: MENU_STATUS[k],
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("status", e.value)}
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
