/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { formatLocalTime, showToast } from "utils/common";
import Pairs from "./components/Edit-view";
import VDialog from "components/v-dialog";
import { Calendar } from "primereact/calendar";
import debounce from "lodash.debounce";
import { MultiSelect } from "primereact";
import EventsService from "services/events";
import { Panel } from "primereact/panel";

const STATUS = [
  { name: "Enable", value: "enable" },
  { name: "Disable", value: "disable" },
];

const View = () => {
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState(null);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    status: STATUS.map((s) => s.value),
  });
  const [, setLoading] = useState(false);
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });
  const [totalRecords, setTotalRecords] = useState(0);

  const toast = useRef(null);
  const dt = useRef(null);
  // const refDialogDetail = useRef(null);
  const refDialogRole = useRef(null);
  const refRole = useRef(null);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await EventsService.getEvents();
      if (res) {
        setData(res);
        setTotalRecords(res.length);
      } else setData([]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setData([]);
    }
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  //CREATE OR NEW
  // const openNew = () => {
  //   setDetail(null);
  //   refDialogDetail.current.show();
  // };

  //  ROLES
  const onSubmitDialogRole = () => {
    refRole.current.submit();
  };
  const editRole = (data) => {
    setDetail({ ...data });
    refDialogRole.current.show();
  };
  const setLoadingSaveRole = (flg) => {
    refDialogRole.current.setLoading(flg);
  };
  const onCancelDialogRole = () => {
    refDialogRole.current.close();
  };

  // ACTION
  const previewOrEditEvent = async (item: any, type: string) => {
    try {
      const token = localStorage.getItem("token_auth");
      if (token) {
        window.open(
          `${process.env.REACT_APP_CLIENT_DOMAIN}/events/${
            item?._id
          }?token=${token}${type === "edit" ? "&isEdit=true" : ""}`
        );
      } else {
        showToast(toast, "error", "Something went error!");
      }
    } catch (error) {
      showToast(toast, "error", "Something went error!");
    }
  };

  const hanldleAction = async (id: string, status: string) => {
    try {
      await EventsService.updateStatusEvent(id, { status: status }).then(
        (data) => {
          showToast(toast, "success", "Successfully");
        }
      );
      loadData();
    } catch (error) {
      showToast(toast, "error", "Something went error!");
    }
  };

  //RENDER
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={() => {
              window.open(
                `${
                  process.env.REACT_APP_CLIENT_DOMAIN
                }/events/create?token=${localStorage.getItem("token_auth")}`
              );
            }}
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled={true}
          />
        </div>
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-sm p-button-info mr-2"
          onClick={() => previewOrEditEvent(rowData, "view")}
        />
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-info mr-2"
          onClick={() => previewOrEditEvent(rowData, "edit")}
        />
        <Button
          icon="pi pi-check"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => hanldleAction(rowData._id, "approved")}
        />
        <Button
          icon="bx bx-window-close"
          className="p-button-rounded p-button-sm p-button-warning"
          onClick={() => hanldleAction(rowData._id, "declined")}
        />
      </div>
    );
  };

  const actionBodyEditTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editRole(rowData)}
        />
      </div>
    );
  };

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="grid px-2 align-items-center">
        <div className="col-12 md:col-6">
          <span className="block p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e: any) => onSearch("search", e.target.value)}
              placeholder="Search..."
              className="w-full"
            />
          </span>{" "}
        </div>
        <div className="col-12 md:col-6">
          <MultiSelect
            value={globalFilter.status}
            options={STATUS}
            onChange={(e: any) => onSearch("status", e.value)}
            optionLabel="name"
            placeholder="Status"
            maxSelectedLabels={3}
            className={"w-full"}
          />
        </div>
        <div className="col-12 md:col-6">
          <Calendar
            className="w-full"
            id="time24"
            placeholder="Start Date"
            value={globalFilter.start}
            onChange={(e: any) => onSearch("start", e.value)}
            showTime
            showSeconds
            showButtonBar
          />{" "}
        </div>
        <div className="col-12 md:col-6">
          <Calendar
            className="w-full"
            id="time24"
            placeholder="End Date"
            value={globalFilter.end}
            onChange={(e: any) => onSearch("end", e.value)}
            showTime
            showSeconds
            showButtonBar
          />
        </div>
      </div>
    </Panel>
  );

  return (
    <div className="grid View-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
          <DataTable
            ref={dt}
            value={data}
            header={header}
            emptyMessage="No data found."
            dataKey="id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            //panigate
            paginator
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 20, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            //sort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onSort}
          >
            <Column
              frozen
              alignFrozen="left"
              body={actionBodyTemplate}
              style={{ flexGrow: 1, flexBasis: "150px" }}
            ></Column>
            <Column
              field="email"
              header="Email"
              sortable
              style={{ flexGrow: 1, flexBasis: "300px" }}
              body={(rowData) => String(rowData.user_id?.email || "")}
            ></Column>
            <Column
              field="name"
              header="Name Event"
              sortable
              style={{ flexGrow: 1, flexBasis: "300px" }}
              body={(rowData) => String(rowData.event?.name || "")}
            ></Column>
            <Column
              field="banner"
              header="Banner"
              style={{ flexGrow: 1, flexBasis: "100px" }}
              body={(rowData) =>
                rowData.event?.banner?.link
                  ? "Link"
                  : rowData.event?.banner?.slide?.length > 0
                  ? "Slide"
                  : ""
              }
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="start_day"
              header="Start Day"
              sortable
              body={(rowData) =>
                formatLocalTime(rowData.event?.start_day || new Date())
              }
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="end_day"
              header="Start Day"
              sortable
              body={(rowData) =>
                formatLocalTime(rowData.event?.end_day || new Date())
              }
            ></Column>
            <Column
              field="vip"
              header="Vip"
              style={{ flexGrow: 1, flexBasis: "100px" }}
              body={(rowData) =>
                rowData.event?.is_vip_event ? "Vip" : "Normal"
              }
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="status"
              header="Status"
              sortable
              body={(rowData) => (
                <span
                  className={`v-badge status-${rowData.status.toLowerCase()}`}
                >
                  {rowData.status}
                </span>
              )}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="createdAt"
              header="Created At"
              sortable
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>
            <Column
              field="edit"
              header="Edit View"
              body={actionBodyEditTemplate}
              style={{ flexGrow: 1, flexBasis: "70px" }}
            ></Column>
          </DataTable>

          <VDialog ref={refDialogRole} onSubmit={onSubmitDialogRole}>
            <Pairs
              ref={refRole}
              toast={toast}
              event={detail}
              setLoading={setLoadingSaveRole}
              onCancel={onCancelDialogRole}
            />
          </VDialog>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(View, comparisonFn);
