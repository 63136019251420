/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useContext,
} from "react";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import UserService from "services/users";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { ACCOUNT_TYPE, OBJECT_TYPE, ROLE, USER_STATUS } from "utils/enum";
import VUpload from "components/v-upload";
import { Panel } from "primereact";
import { MasterContext } from "contexts/MasterContext";
import VLocation from "components/v-location";
import UserVirtualService from "services/users-virtual";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    avatar: "",
    first_name: "",
    last_name: "",
    full_name: "",
    role: "USER",
    email: "",
    phone: "",
    status: "confirmed",
    password: "12345679@",
    is_leader: false,
    referred_by: "",
    percent_sales: 0,
    is_swap: true,
    is_withdraw: true,
    is_deposit: true,
    is_claim: true,
    id_region: false,
    id_province: false,
    percent_region: 0,
    percent_provice: 0,
    id_inherit: "",
    object_type: OBJECT_TYPE.USER,
    account_type: ACCOUNT_TYPE.NORMAL,
    amount: 1,
  };

  const emptyProfile = {
    career_id: null,
    location: {},
    home_town: {},
    nation_id: null,
    religion_id: null,
  };

  const [details, setDetails] = useState(emptyData);
  const [profile, setProfile] = useState(emptyProfile);
  const { careers, nations, religions } = useContext(MasterContext);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) {
      setDetails(data);
      setProfile(data.profile || emptyProfile);
    }
  }, [data]);

  const onChangeProfile = (name, value) => {
    setProfile((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onChangeUser = (name, value) => {
    setDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await UserService.updateUser({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            profile,
          },
        });
      } else {
        await UserVirtualService.createVirtualUsers({
          body: {
            ...details,
            profile,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "User saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <>
      {data && (
        <Panel header="Genaral" toggleable className="my-2">
          <div className="grid">
            <div className="col-12 ">
              <VUpload
                urlFile={details.avatar}
                setUrlFile={(file) => onChangeUser("avatar", file)}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="name">Email</label>
              <InputText
                id="email"
                value={details.email}
                onChange={(e) => onChangeUser("email", e.target.value)}
                required
                autoFocus
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="description">Phone</label>
              <InputText
                id="phone"
                value={details.phone}
                onChange={(e) => onChangeUser("phone", e.target.value)}
                required
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="first_name">First Name</label>
              <InputText
                id="first_name"
                value={details.first_name}
                onChange={(e) => onChangeUser("first_name", e.target.value)}
                required
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="last_name">Last Name</label>
              <InputText
                id="last_name"
                value={details.last_name}
                onChange={(e) => onChangeUser("last_name", e.target.value)}
                required
              />
            </div>
            <div className="field col-12 md:col-12">
              <label htmlFor="last_name">Full Name</label>
              <InputText
                id="full_name"
                value={details.full_name}
                onChange={(e) => onChangeUser("full_name", e.target.value)}
                required
              />
            </div>

            <div className="field col-12 md:col-6">
              <label htmlFor="description">Object</label>
              <Dropdown
                value={details.object_type}
                options={Object.keys(OBJECT_TYPE).map((k) => ({
                  label: k,
                  value: OBJECT_TYPE[k],
                }))}
                optionLabel="label"
                optionValue="value"
                placeholder="Select object"
                onChange={(e) => onChangeUser("object_type", e.value)}
              />
            </div>

            <div className="field col-12 md:col-6">
              <label htmlFor="description">Account Type</label>
              <Dropdown
                value={details.account_type}
                options={Object.keys(ACCOUNT_TYPE).map((k) => ({
                  label: k,
                  value: ACCOUNT_TYPE[k],
                }))}
                optionLabel="label"
                optionValue="value"
                placeholder="Select account type"
                onChange={(e) => onChangeUser("account_type", e.value)}
              />
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="description">Role</label>
              <Dropdown
                value={details.role}
                options={Object.keys(ROLE).map((k) => ({
                  label: k,
                  value: ROLE[k],
                }))}
                optionLabel="label"
                optionValue="value"
                placeholder="Select role"
                onChange={(e) => onChangeUser("role", e.value)}
              />
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="description">Status</label>
              <Dropdown
                value={details.status}
                options={Object.keys(USER_STATUS).map((k) => ({
                  label: k,
                  value: USER_STATUS[k],
                }))}
                optionLabel="label"
                optionValue="value"
                onChange={(e) => onChangeUser("status", e.value)}
              />
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="description">ID Sponsor</label>
              <InputText
                id="referred_by"
                value={details.referred_by}
                onChange={(e) => onChangeUser("referred_by", e.target.value)}
                required
              />
            </div>
          </div>
        </Panel>
      )}
      <Panel header="Profile" className="my-2" collapsed={true}>
        <div className="grid">
          <div className="field col-12">
            <label htmlFor="name">Career</label>
            <Dropdown
              value={profile.career_id}
              options={careers.map((c) => ({
                label: c.name,
                value: c._id,
              }))}
              optionLabel="label"
              optionValue="value"
              onChange={(e) => onChangeProfile("career_id", e.value)}
            />
          </div>
          {!data && (
            <div className="field col-12">
              <label htmlFor="amount">Amount</label>
              <InputNumber
                id="amount"
                value={details.amount}
                onChange={(e) => onChangeUser("amount", e.value)}
              />
            </div>
          )}
          <div className="field col-12">
            <label htmlFor="name">Nation</label>
            <Dropdown
              value={profile.nation_id}
              options={nations.map((c) => ({
                label: c.name,
                value: c._id,
              }))}
              optionLabel="label"
              optionValue="value"
              onChange={(e) => onChangeProfile("nation_id", e.value)}
            />
          </div>
          <div className="field col-12">
            <label htmlFor="name">Religion</label>
            <Dropdown
              value={profile.religion_id}
              options={religions.map((c) => ({
                label: c.name,
                value: c._id,
              }))}
              optionLabel="label"
              optionValue="value"
              onChange={(e) => onChangeProfile("religion_id", e.value)}
            />
          </div>
          <div className="field col-12">
            <label htmlFor="name">Address current</label>
            <VLocation
              address={profile.location}
              setAddress={(a) => onChangeProfile("location", a)}
            />
          </div>
        </div>
      </Panel>
    </>
  );
};

export default forwardRef(Details);
