import { request, parseErrorResponse } from './request';
import qs from 'query-string';

const search = (req: any) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/invoices/search?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const deleteInvoice = (req: { params: { id: string; }; }) =>
  new Promise((resolve, reject) => {
    request()
      .delete('admin/invoices/' + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

  const update = (req: { params: { id: string; }; body: any; }) =>
  new Promise((resolve, reject) => {
    request()
      .put('admin/invoices/' + req.params.id, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const KycService = {
  search, 
  deleteInvoice,
  update
};

export default KycService;
