/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { formatLocalTime, getUrlImage } from "utils/common";
import VDialog from "components/v-dialog";
// import VConfirm from "components/v-confirm";
import Detail from "./components/Detail";
import debounce from "lodash.debounce";
import { Calendar } from "primereact/calendar";
import ShopService from "services/shops";
import { SHOP_STATUS } from "utils/enum";
import StatusEditorCell from "components/v-edit-cell/StatusEditorCell";
import VLocation from "components/v-location";
import { Dropdown } from "primereact/dropdown";
import { MasterContext } from "contexts/MasterContext";

const Crud = () => {
  const { categories } = useContext(MasterContext);

  const [data, setData] = useState([]);
  const [detail, setDetail] = useState(null);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    status: "pending",
    category_id: "",
  });
  const [locationFilter, setLocationFilter] = useState({
    country: null,
    province: null,
    district: null,
    ward: null,
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "updatedAt",
    sortOrder: -1,
  });
  const [totalRecords, setTotalRecords] = useState(0);

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  // const refDialogDelete = useRef(null);
  const refDetail = useRef(null);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter, locationFilter]);

  const loadData = async () => {
    try {
      const res: any = await ShopService.search({
        query: {
          ...locationFilter,
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
          limit: lazyParams.rows,
        },
      });
      if (res && res.docs) {
        setData(res.docs);
        setTotalRecords(res.totalDocs);
      } else setData([]);
    } catch (error) {
      setData([]);
    }
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    if (name === "location") {
      setLocationFilter(value);
    } else {
      setGlobalFilter((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  //CREATE OR NEW
  // const openNew = () => {
  //   setDetail(null);
  //   refDialogDetail.current.show();
  // };

  const editActionPost = (data) => {
    setDetail({ ...data });
    refDialogDetail.current.show();
  };

  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };

  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };

  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  // DELETE
  // const confirmDelete = (quotas) => {
  //   setDetail(quotas);
  //   refDialogDelete.current.show();
  // };
  // const handleDelete = async () => {
  //   try {
  //     await PostService.deleted({
  //       params: {
  //         id: detail._id,
  //       },
  //     });
  //     const _data = data.filter((val) => val._id !== detail._id);
  //     setData(_data);
  //     refDialogDelete.current.close();
  //     setDetail(null);
  //     showToast(toast, "success", "User Deleted");
  //   } catch (error) {
  //     showToast(toast, "error", error.errors);
  //   }
  // };

  const onChangeStatus = async (options, rowData, value) => {
    let updateRow;
    let newData = data.map((e, index) => {
      if (e._id === rowData._id) {
        e[options.field] = value;
        updateRow = e;
      }
      return e;
    });
    setData(newData);

    if (updateRow)
      await ShopService.update({
        params: {
          id: updateRow._id,
        },
        body: {
          ...updateRow,
        },
      });
  };

  // const statusEditor = (options) => {
  //   return (
  //     <StatusEditor
  //       options={options}
  //       status={SHOP_STATUS}
  //       onChange={onChangeStatus}
  //     />
  //   );
  // };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            disabled
            icon="pi pi-plus"
            className="p-button-success mr-2"
          />

          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled={true}
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editActionPost(rowData)}
        />

        {/* <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning"
          onClick={() => confirmDelete(rowData)}
        /> */}
      </div>
    );
  };

  const header = (
    <div className="grid px-2 align-items-center">
      <div className="col-12">
        <div className="block p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e: any) => onSearch("search", e.target.value)}
            placeholder="Search..."
            className="w-full"
          />
        </div>
      </div>
      <div className="col-12 md:col-6">
        <Dropdown
          className="w-full"
          placeholder={"Category"}
          value={globalFilter?.category_id}
          options={categories?.map((el: any) => {
            return {
              label: el?.name,
              value: el?._id,
            };
          })}
          onChange={(e: any) => onFilter("category_id", e.value)}
          filter
          showClear
        />
      </div>
      <div className="col-12 md:col-6">
        <Dropdown
          value={globalFilter.status}
          options={Object.keys(SHOP_STATUS).map((k) => ({
            label: k,
            value: SHOP_STATUS[k],
          }))}
          onChange={(e: any) => {
            onSearch("status", e.value);
          }}
          optionLabel="label"
          placeholder="Status"
          className={"w-full"}
          showClear
        />
      </div>
      <div className="col-12 md:col-6">
        <Calendar
          placeholder="Start Date"
          value={globalFilter.start}
          onChange={(e) => onSearch("start", e.value)}
          showTime
          className="w-full"
        />
      </div>
      <div className="col-12 md:col-6">
        <Calendar
          placeholder="End Date"
          value={globalFilter.end}
          onChange={(e) => onSearch("end", e.value)}
          showTime
          className="w-full"
        />
      </div>
      <div className="col-12">
        <VLocation
          address={locationFilter}
          setAddress={(a) => onSearch("location", a)}
          viewInLine={false}
        />
      </div>
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={data}
            header={header}
            emptyMessage="No data found."
            dataKey="id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            paginator
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[5, 10, 20]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            //sort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onSort}
          >
            <Column
              frozen
              alignFrozen="left"
              body={actionBodyTemplate}
              style={{ flexGrow: 1, flexBasis: "80px" }}
            ></Column>
            <Column
              field="image"
              header="Image"
              body={(rowData) => (
                <img
                  src={getUrlImage(rowData?.user.avatar)}
                  alt=""
                  style={{ height: "50px" }}
                />
              )}
              style={{ flexGrow: 1, flexBasis: "120px" }}
            />
            <Column
              style={{ flexGrow: 1, flexBasis: "150px" }}
              field="user.refer_code"
              header="ID"
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "250px" }}
              field="name"
              header="Name"
              body={(rowData) => String(rowData?.user.full_name)}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "180px" }}
              field="status"
              header="Status"
              body={(rowData, options) => (
                <StatusEditorCell
                  status={SHOP_STATUS}
                  value={rowData.status}
                  options={options}
                  rowData={rowData}
                  onChange={onChangeStatus}
                />
              )}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="updatedAt"
              header="Created At"
              sortable
              body={(rowData) => formatLocalTime(rowData.updatedAt)}
            ></Column>
          </DataTable>

          <VDialog
            ref={refDialogDetail}
            header="Detail"
            onSubmit={onSumitDialogDetail}
          >
            <Detail
              ref={refDetail}
              data={detail}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
            />
          </VDialog>

          {/* <VConfirm ref={refDialogDelete} onConfirm={handleDelete} /> */}
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Crud, comparisonFn);
