import { forwardRef} from "react";

const View = (props) => {
  const { icons, selected } = props;

  const select =(e : any) => {
    selected(e.target.className);
  };

  return (
    <>
      {
        <div className="grid">
          <div className="field col-12">
          {icons.map((item) => (
            <i className={item} onClick={item=>select(item)}>&nbsp;</i>
          ))}
          </div>
        </div>
      }
    </>
  );
};

export default forwardRef(View);
