/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import VUpload from "components/v-upload";
import { nanoid } from "nanoid";
import VEditor from "components/v-editor";
import TooltipService from "services/tooltips";
import { Dropdown } from "primereact/dropdown";
import { COMMON_STATUS } from "utils/enum";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    id: nanoid(),
    name: "",
    positions: "",
    content: "",
    illustration_location: "",
    status: COMMON_STATUS.ENABLE,
  };

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) {
      setDetails(data);
    }
  }, [data]);

  const onChange = (value, name) => {
    setDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submit = async () => {
    try {
      setLoading(true);

      if (data) {
        await TooltipService.update({
          params: {
            id: data._id,
          },
          body: details,
        });
      } else {
        await TooltipService.create({
          body: details,
        });
      }
      setLoading(false);
      showToast(toast, "success", "Successful!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12 md:col-6">
        <label htmlFor="title">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange(e.target.value, "name")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="title">Position Key</label>
        <InputText
          id="name"
          value={details.positions}
          onChange={(e) => onChange(e.target.value, "positions")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-6">
        <Dropdown
          defaultValue={details.status}
          value={details.status}
          options={[
            {
              label: "ENABLE",
              value: "enable",
            },
            {
              label: "DISABLE",
              value: "disable",
            },
          ]}
          onChange={(e) => onChange(e.value, "status")}
          optionLabel="label"
          placeholder="Select status"
        />
      </div>
      <div className="field col-12">
        <VUpload
          label="Image"
          urlFile={details.illustration_location}
          setUrlFile={(file) => onChange(file, "illustration_location")}
        />
      </div>

      <div className="field col-12">
        <label htmlFor="content">Content</label>
        <VEditor
          className="h-20rem"
          value={details.content}
          onChange={(e) => onChange(e?.html, "content")}
          haveData={details.content ? true : false}
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
