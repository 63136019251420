/* eslint-disable jsx-a11y/anchor-is-valid */
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import React, { useState } from "react";
import AuthService from "services/auth";
import { useRef } from "react";
import { Toast } from "primereact/toast";
import { showToast } from "utils/common";
import { useHistory } from "react-router-dom";
import { classNames } from "primereact/utils";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";

const View = () => {
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const history = useHistory();

  const handleChange = (event: any) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const res: any = await AuthService.login({
        body: data,
      });
      if (res && res.token) {
        localStorage.setItem("token_auth", res.token);
        history.push("/");
      }
      setLoading(false);
      showToast(toast, "success", "Login Success");
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const containerClassName = classNames(
    "surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden"
  );

  return (
    <div className={containerClassName}>
      <Toast ref={toast} />
      <div className="flex flex-column align-items-center justify-content-center">
        <div
          style={{
            borderRadius: "56px",
            padding: "0.3rem",
            background:
              "linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)",
          }}
        >
          <div
            className="w-full surface-card py-8 px-5 sm:px-8"
            style={{ borderRadius: "53px" }}
          >
            <div className="text-center mb-5">
              <div className="text-900 text-3xl font-medium mb-3">Welcome!</div>
              <span className="text-600 font-medium">Sign in to continue</span>
            </div>

            <div>
              <label
                htmlFor="email1"
                className="block text-900 text-xl font-medium mb-2"
              >
                Email
              </label>
              <InputText
                id="email1"
                type="text"
                name="email"
                onChange={handleChange}
                placeholder="Email address"
                className="w-full md:w-30rem mb-5"
                style={{ padding: "1rem" }}
              />

              <label
                htmlFor="password1"
                className="block text-900 font-medium text-xl mb-2"
              >
                Password
              </label>
              <Password
                id="passwored1"
                type="password"
                name="password"
                onChange={handleChange}
                placeholder="Password"
                toggleMask
                className="w-full mb-5"
                inputClassName="w-full p-3 md:w-30rem"
              ></Password>

              <div className="flex align-items-center justify-content-between mb-5 gap-5">
                <div className="flex align-items-center">
                  <Checkbox inputId="rememberme1" className="mr-2"></Checkbox>
                  <label htmlFor="rememberme1">Remember me</label>
                </div>
                <a
                  className="font-medium no-underline ml-2 text-right cursor-pointer"
                  style={{ color: "var(--primary-color)" }}
                >
                  Forgot password?
                </a>
              </div>
              <Button
                className="w-full p-3 text-xl justify-content-center"
                loading={loading}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps: any, nextProps: any) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(View, comparisonFn);
