import VUpload from "components/v-upload";
import React from "react";
import { IObject } from "types";

type IProps = {
  data?: IObject;
  onChange?: any;
};

const EditAvatar = (props: IProps) => {
  const { data, onChange } = props;

  return (
    <>
      <div className="field col-2">
        <VUpload
          urlFile={data?.avatar}
          setUrlFile={(file) => onChange(file, "avatar")}
        />
      </div>
    </>
  );
};

export default EditAvatar;
