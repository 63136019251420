import qs from "qs";
import { parseErrorResponse, request } from "./request";

const search = (req: any) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/censor-verify/search?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const update = (req: { params: { id: string }; body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .put("admin/censor-verify/" + req.params.id, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const CensorVerifyService = {
  search,
  update,
};

export default CensorVerifyService;
