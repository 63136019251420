const View = () => {
  return (
    <div>
      <iframe
        title="question-and-answer-table"
        src={`${
          process.env.REACT_APP_CLIENT_DOMAIN
        }/question-and-answer/table?vdb_token=${localStorage.getItem(
          "token_auth"
        )}`}
        frameBorder="0"
        className="w-full"
        height={window.innerHeight - 150}
      ></iframe>
    </div>
  );
};

export default View;
