import React, { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import debounce from "lodash.debounce";
import { FileUpload, Toolbar, InputText, Calendar } from "primereact";
import VDialog from "components/v-dialog";
import { showToast } from "utils/common";
import { PRODUCT_STATUS } from "utils/enum";
import ProductDetail from "./components/Details";
import StatusEditorCell from "components/v-edit-cell/StatusEditorCell";
import { Panel } from "primereact/panel";
import { Dropdown } from "primereact/dropdown";
import { formatLocalTime } from "utils/common";
import AttributeSetService from "services/attribute-set";
import NumberEditorCell from "components/v-edit-cell/NumberEditorCell";
import VConfirm from "components/v-confirm";

const View = () => {
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [detail, setDetail] = useState(null);
  const toast = useRef(null);

  const refDetail = useRef(null);
  const refDialogDetail = useRef(null);

  const refDialogDelete = useRef(null);

  const dt = useRef(null);
  const [globalFilter, setGlobalFilter] = useState({
    status: null,
    search: null,
    start: null,
    end: null,
  });

  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "order",
    sortOrder: -1,
  });

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };

  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSearch = useCallback(debounce(onFilter, 500), []);

  const loadData = async () => {
    try {
      const res: any = await AttributeSetService.search({
        query: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
        },
      });
      if (res && res.docs) {
        setData(res.docs);
        setTotalRecords(res.totalDocs);
      } else setData([]);
    } catch (error) {
      setData([]);
    }
  };

  const openNew = () => {
    setDetail(null);
    refDialogDetail.current.show();
  };

  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };

  const onSumitDialogDetail = () => {
    loadData();
    refDetail.current.submit();
    refDialogDetail.current.close();
  };

  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  const confirmDelete = (product) => {
    setDetail(product);
    refDialogDelete.current.show();
  };

  const handleDelete = async () => {
    try {
      await AttributeSetService.deleted({
        params: {
          id: detail._id,
        },
      });
      const _data = data.filter((val) => val._id !== detail._id);
      setData(_data);
      refDialogDelete.current.close();
      setDetail(null);
      showToast(toast, "success", "Deleted");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const editProduct = async (data) => {
    try {
      setDetail({ ...data });
      refDialogDetail.current.show();
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const onChangeStatus = async (options, rowData, value) => {
    let updateRow;
    let newData = data.map((e, index) => {
      if (e._id === rowData._id) {
        e[options.field] = value;
        updateRow = e;
      }
      return e;
    });
    setData(newData);
    showToast(toast, "success", "Success");
    if (updateRow) {
      await AttributeSetService.update({
        params: { id: updateRow._id },
        body: {
          ...updateRow,
        },
      });
    }
  };

  const onChangeOrder = debounce(onChangeStatus, 700);

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="grid px-2 align-items-center">
        <div className="col-12 md:col-3">
          <span className="block p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e: any) => onSearch("search", e.target.value)}
              placeholder="Search..."
              className="w-full"
            />
          </span>{" "}
        </div>
        <div className="col-12 md:col-3">
          <Dropdown
            value={globalFilter.status}
            options={Object.keys(PRODUCT_STATUS).map((item) => ({
              name: item,
              value: PRODUCT_STATUS[item],
            }))}
            onChange={(e: any) => onSearch("status", e.value)}
            optionLabel="name"
            placeholder="Status"
            className={"w-full"}
            showClear
          />
        </div>
        <div className="col-12 md:col-3">
          <Calendar
            className="w-full"
            id="time24"
            placeholder="Start Date"
            value={globalFilter.start}
            onChange={(e: any) => onSearch("start", e.value)}
            showTime
            showSeconds
            showButtonBar
          />
        </div>
        <div className="col-12 md:col-3">
          <Calendar
            className="w-full"
            id="time24"
            placeholder="End Date"
            value={globalFilter.end}
            onChange={(e: any) => onSearch("end", e.value)}
            showTime
            showSeconds
            showButtonBar
          />
        </div>
      </div>
    </Panel>
  );

  return (
    <div className="grid View-demo">
      <div className="col-12 ">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={data}
            header={header}
            emptyMessage="No data found."
            dataKey="id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            //panigate
            paginator
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 20, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            //sort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onSort}
          >
            <Column
              alignFrozen="left"
              style={{ flexGrow: 1, flexBasis: "50px" }}
              body={(rowData) => (
                <div className="actions ">
                  <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-sm p-button-success mr-1"
                    onClick={() => editProduct(rowData)}
                  />
                  <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-sm p-button-warning mr-1"
                    onClick={() => confirmDelete(rowData)}
                  />
                </div>
              )}
            ></Column>
            <Column
              field="title"
              header="Title"
              style={{ flexGrow: 1, flexBasis: "300px" }}
              body={(rowData) => <span>{rowData.title || ""}</span>}
            ></Column>
            <Column
              field="order"
              header="Order"
              sortable
              style={{ flexGrow: 1, width: "8%" }}
              body={(rowData, options) => (
                <NumberEditorCell
                  value={rowData.order}
                  options={options}
                  onChange={(options, value) =>
                    onChangeOrder(options, rowData, value)
                  }
                />
              )}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "100px" }}
              field="status"
              header="Status"
              sortable
              body={(rowData, options) => (
                <StatusEditorCell
                  status={PRODUCT_STATUS}
                  value={rowData.status}
                  options={options}
                  rowData={rowData}
                  onChange={onChangeStatus}
                />
              )}
            ></Column>
            <Column
              sortable
              field="updatedAt"
              header="Updated At"
              style={{ flexGrow: 1, flexBasis: "200px" }}
              body={(rowData) => (
                <span>{formatLocalTime(rowData.updatedAt)}</span>
              )}
            ></Column>
            <Column
              field="Amount row"
              header="Amount row"
              style={{ flexGrow: 1, flexBasis: "10px" }}
              body={(rowData) => rowData?.attributes?.length || 0}
            ></Column>
          </DataTable>

          <VDialog
            ref={refDialogDetail}
            header="Detail"
            onSubmit={onSumitDialogDetail}
          >
            <ProductDetail
              ref={refDetail}
              data={detail}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
            />
          </VDialog>
          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
        </div>
      </div>
    </div>
  );
};

export default View;
