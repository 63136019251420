/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useImperativeHandle } from "react";
import { getUrlImage, showToast } from "utils/common";
import { forwardRef } from "react";

import VEditor from "components/v-editor";
import { Image } from "primereact";
import { Checkbox } from "primereact/checkbox";

import Supports from "services/supports";

const Details = (props, ref) => {
  const { data, reload, toast, onCancle, setLoading } = props;

  const [state, setState] = useState({
    relly: false,
    value: data?.reply || "",
  });

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  const submit = async () => {
    try {
      if (!state.value) return onCancle();
      setLoading(true);
      const res = await Supports.replySupportById({
        params: {
          id: data._id,
        },
        body: {
          value: state.value,
        },
      });
      if (res) {
        setLoading(false);
        showToast(toast, "success", "Supports saved!");
        onCancle();
        reload();
      }
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12">
        {Object.keys(data?.value)?.map((value, i) => {
          if (value !== "attachments") {
            if (value !== "qa_id") {
              return (
                <div key={i} className="mt-3">
                  <span className="font-semibold">- {value}:</span>
                  <div className="ml-4">{data.value[value]}</div>
                </div>
              );
            }
          } else {
            let imgs = data?.value[value];
            if (!Array.isArray(imgs)) {
              imgs = [imgs];
            }

            return (
              <div key={i} className="grid mt-3">
                {imgs.map((at, index) => (
                  <div key={index} className="field col-6 md:col-3">
                    <Image
                      src={getUrlImage(at)}
                      width="100"
                      height="100"
                      preview
                    />
                  </div>
                ))}
              </div>
            );
          }
        })}
      </div>
      <div className="flex mb-2">
        <label htmlFor="key">Relly</label>
        <div className="ml-2">
          <Checkbox
            onChange={(e) =>
              setState({
                ...state,
                relly: e.checked,
              })
            }
            checked={state.relly}
          ></Checkbox>
        </div>
      </div>
      {state.relly && (
        <div className="field col-12 md:col-12">
          <label htmlFor="content">Content</label>
          <br />

          {/* <Editor
            id="content"
            className="h-16rem"
            value={state.value}
            onTextChange={(e) => setState({
              ...state,
              value: e.htmlValue
            })}
          /> */}
          <VEditor
            id="content"
            className="h-16rem"
            value={state.value}
            onChange={(e) =>
              setState({
                ...state,
                value: e?.html,
              })
            }
            haveData={state.value ? true : false}
          />
        </div>
      )}
    </div>
  );
};

export default forwardRef(Details);
