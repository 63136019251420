/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact";
import VStockDropdown from "components/v-stock";
import AllotmentService from "services/allotments";
import { showToast } from "utils/common";
import CategoryFilterDetails from "components/v-filter";
import { InputNumber } from "primereact/inputnumber";
import { MultiSelect } from "primereact";

const AllotmentFilter = () => {
  const [importedData, setImportedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCheck, setLoadingCheck] = useState(false);
  const toast = useRef(null);
  const refDetailCategory = useRef(null);
  const [statusKyc, setStatusKyc] = useState([]);

  const defaultState = {
    data: null,
    token: null,
    type: "filter",
    amount: 0,
    category_filter_id: null,
  };

  const [allotment, setAllotment] = useState(defaultState);

  const onChange = (val, name) => {
    let _details = { ...allotment };
    _details[`${name}`] = val;
    setAllotment(_details);
  };

  const clear = () => {
    setImportedData([]);
  };

  const checkUsers = async () => {
    try {
      setLoadingCheck(true);
      let category = await refDetailCategory.current.submit();
      if (category && category._id) {
        const users: any = await AllotmentService.getUserAllotmentsByFilter({
          params: {
            filterId: category._id,
          },
          query: {
            status_kyc: statusKyc,
          },
        });
        setAllotment((prev) => ({
          ...prev,
          category_filter_id: category._id,
        }));
        setImportedData(users);
      }
      setLoadingCheck(false);
    } catch (error) {
      setLoadingCheck(false);
    }
  };

  const submit = async () => {
    try {
      setLoading(true);
      await AllotmentService.createAllotments({
        body: {
          ...allotment,
          data: importedData,
        },
      });
      clear();
      showToast(toast, "success", "Success");
      setLoading(false);
    } catch (error) {
      showToast(toast, "error", error.errors);
      setLoading(false);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12 p-fluid card">
        <CategoryFilterDetails ref={refDetailCategory} />
        <div className="field col-12">
          <MultiSelect
            value={statusKyc}
            options={[
              {
                label: "KYC",
                value: "kyc",
              },
              {
                label: "VERIFIED",
                value: "verified",
              },
            ]}
            onChange={(e: any) => setStatusKyc(e.value)}
            optionLabel="label"
            placeholder="Status KYC"
            maxSelectedLabels={3}
            showClear
          />
        </div>
      </div>
      <Toast ref={toast}></Toast>

      <div className="col-4"></div>
      <div className="field col-12 md:col-4">
        <VStockDropdown
          className="w-full"
          value={allotment.token}
          setValue={(v) => onChange(v, "token")}
        />
      </div>
      <div className="col-4"></div>
      <div className="col-4"></div>
      <div className="field col-12 md:col-4">
        <span className="p-float-label">
          <InputNumber
            id="name"
            className="w-full"
            value={allotment.amount}
            onChange={(e) => onChange(e.value, "amount")}
          />
          <label htmlFor="id">Amount</label>
        </span>
      </div>

      <div className="col-12 md:col-12 text-center">
        <Button
          icon="pi pi-check"
          type="button"
          label="Check"
          className="p-button-success mr-2"
          loading={loadingCheck}
          onClick={(_) => checkUsers()}
        />
        <Button
          icon="pi pi-check"
          type="button"
          label="Submit"
          disabled={!(importedData && importedData.length > 0)}
          className="p-button-info mr-2"
          loading={loading}
          onClick={(_) => submit()}
        />
        <Button
          type="button"
          disabled={!(importedData && importedData.length > 0)}
          label="Clear"
          icon="pi pi-times"
          onClick={clear}
          className="p-button-danger ml-2"
        />
      </div>

      <div className="col-12">
        <DataTable
          value={importedData}
          emptyMessage="No data"
          paginator
          totalRecords={importedData.length}
          rowsPerPageOptions={[10, 20, 50, 100]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="{first} - {last} of {totalRecords}"
          rows={10}
          alwaysShowPaginator={false}
          responsiveLayout="scroll"
        >
          <Column field="refer_code" header="Id"></Column>
          <Column field="full_name" header="Name"></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default AllotmentFilter;
