/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import VUpload from "components/v-upload";
import { Dropdown, InputNumber, InputSwitch } from "primereact";
import UtilityService from "services/utilities";
import SettingService from "services/settings";
import CategoryFilterDetails from "components/v-filter";
import { Panel } from "primereact/panel";
import { MENU_TYPE_VIEW } from "utils/enum";
import { MultiSelect } from "primereact/multiselect";

const Details = (props, ref) => {
  const {
    data,
    reload,
    toast,
    categories,
    onCancel,
    setLoading,
    categoryFilters,
    getCategoryFilters,
  } = props;

  const emptyData = {
    name: "",
    logo: "",
    link: "",
    status: "enable",
    key: "",
    position: 0,
    category: "",
    type: null,
    category_filter_id: null,
    type_view: MENU_TYPE_VIEW.INTERNAL,
  };

  const refDetailCategory = useRef(null);
  const [details, setDetails] = useState(emptyData);
  const [utilityCategories, setUtilityCategories] = useState([]);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    loadUtilityCategories();
  }, []);

  useEffect(() => {
    if (data) setDetails(data);
  }, [data]);

  const loadUtilityCategories = () => {
    SettingService.getSettingsByName({
      params: {
        name: "utility_categories",
      },
    }).then((res: any) => {
      if (res && res.setting) {
        setUtilityCategories(res.setting.value);
      }
    });
  };

  const onChange = (name, value) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      let category = null;
      if (!details.category_filter_id) {
        category = await refDetailCategory.current.submit();
        getCategoryFilters();
      }
      if (data) {
        await UtilityService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      } else
        await UtilityService.create({
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      setLoading(false);
      showToast(toast, "success", "File saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      {/* <div className="field col-12">
        <label htmlFor="name">Type</label>
        <Dropdown
          filter
          showClear
          value={details.type}
          options={(categories || [])?.map((item) => ({
            label: item.name,
            value: item.key,
          }))}
          optionLabel="label"
          optionValue="value"
          placeholder="Select type"
          onChange={(e) => onChange("type", e.value)}
        />
      </div> */}
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange("name", e.target.value)}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="position">Position </label>
        <InputNumber
          id="position"
          value={details.position}
          onChange={(e) => onChange("position", e.value)}
          required
        />
      </div>
      <div className="field col-6">
        <label htmlFor="key">Key</label>
        <InputText
          id="key"
          value={details.key}
          onChange={(e) => onChange("key", e.target.value)}
          required
        />
      </div>
      <div className="field col-6">
        <label htmlFor="name">Website</label>
        <InputText
          id="link"
          value={details.link}
          onChange={(e) => onChange("link", e.target.value)}
          required
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Category</label>
        <Dropdown
          value={details.category}
          options={utilityCategories.map((m) => ({
            label: m.name,
            value: m.key,
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("category", e.value)}
          showClear
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="">Utility type</label>
        <MultiSelect
          value={details.type}
          options={(categories || []).map((item) => ({
            label: item.name,
            value: item.key,
          }))}
          filter
          showClear
          optionLabel="label"
          optionValue="value"
          placeholder="Select utility type"
          onChange={(e) => {
            onChange("type", e.value);
          }}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Type View</label>
        <Dropdown
          value={details.type_view}
          options={Object.keys(MENU_TYPE_VIEW).map((k) => ({
            label: k,
            value: MENU_TYPE_VIEW[k],
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("type_view", e.value)}
        />
      </div>
      <div className="field col-6">
        <label htmlFor="is_deposit">Status</label>
        <br />
        <InputSwitch
          checked={details.status === "enable"}
          onChange={(e) => onChange("status", e.value ? "enable" : "disable")}
        />
      </div>
      <div className="field col-12">
        <VUpload
          urlFile={details.logo}
          setUrlFile={(file) => onChange("logo", file)}
        />
      </div>
      <div className="field col-12">
        <label htmlFor="status">Filter</label>
        <Dropdown
          filter
          showClear
          value={details.category_filter_id}
          options={categoryFilters.map((item) => ({
            label: item.name,
            value: item._id,
          }))}
          optionLabel="label"
          optionValue="value"
          placeholder="Select filter"
          onChange={(e) => onChange("category_filter_id", e.value)}
        />
      </div>
      {!details.category_filter_id && (
        <div className="field col-12">
          <Panel header="Filter" toggleable className="my-2" collapsed={false}>
            <div className="grid">
              <div className="field col-12">
                <CategoryFilterDetails ref={refDetailCategory} />
              </div>
            </div>
          </Panel>
        </div>
      )}
    </div>
  );
};

export default forwardRef(Details);
