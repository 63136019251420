import { request, parseErrorResponse } from './request';
import qs from 'query-string';

const search = (req: any) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/loans/search?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const deleteLoan = (req: { params: { id: string; }; }) =>
  new Promise((resolve, reject) => {
    request()
      .delete('admin/loans/' + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

  const update = (req: { params: { id: string; }; body: any; }) =>
  new Promise((resolve, reject) => {
    request()
      .put('admin/loans/' + req.params.id, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const LoansService = {
  search, 
  deleteLoan,
  update
};

export default LoansService;
