/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useContext,
} from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { MasterContext } from "contexts/MasterContext";
import { Card, Column, DataTable } from "primereact";
import LocaleService from "services/locales";
import { MultiSelect } from "primereact/multiselect";

const Details = (props, ref) => {
  const { sites } = useContext(MasterContext);
  const { data, reload, toast, onCancel, setLoading } = props;

  const { languages } = useContext(MasterContext);

  const emptyData = {
    name: "",
    value: {},
    key: "",
    sites: "",
  };

  const [details, setDetails] = useState(emptyData);
  const [value, setValue] = useState({});
  const [keySearch, setKeySearch] = useState("");
  const [filterLanguages, setFilterLanguages] = useState(languages);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) {
      setDetails(data);
      setValue(data.value || {});
    }
  }, [data]);

  useEffect(() => {
    if (keySearch) {
      let filterLan = languages?.filter(
        (e) => e?.key === keySearch || e?.name?.includes(keySearch)
      );
      setFilterLanguages(filterLan);
    } else {
      setFilterLanguages(languages);
    }
  }, [keySearch]);

  const onChange = (name, value) => {
    setDetails((prev) => ({
      ...prev,
      [`${name}`]: value,
    }));
  };

  const onChangeValue = (key, value) => {
    setValue((prev) => ({
      ...prev,
      [`${key}`]: value,
    }));
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await LocaleService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            value,
          },
        });
      } else
        await LocaleService.create({
          body: {
            ...details,
            value,
          },
        });
      setLoading(false);
      showToast(toast, "success", "Success!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12">
        <label htmlFor="key">Sites</label>
        <MultiSelect
          value={details.sites}
          options={sites}
          onChange={(e) => onChange("sites", e.target.value)}
          optionLabel="name"
          optionValue="domain"
          placeholder="Sites"
          showClear
          filter
        />
      </div>
      <div className="field col-12">
        <label htmlFor="key">Key</label>
        <InputText
          id="key"
          value={details.key}
          onChange={(e) => onChange("key", e.target.value)}
          required
        />
      </div>
      <div className="field col-12 ">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange("name", e.target.value)}
          required
        />
      </div>
      <div className="field col-12 ">
        <Card>
          <div className="field col-12">
            <label htmlFor="key_search">Filter</label>
            <InputText
              id="key_search"
              value={keySearch}
              onChange={(e) => setKeySearch(e.target.value)}
              placeholder="Filter language"
            />
          </div>
          <DataTable
            value={filterLanguages}
            responsiveLayout="scroll"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            showGridlines
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          >
            <Column field="key" header="Key"></Column>
            <Column field="name" header="Name"></Column>
            <Column
              field="value"
              header="Value"
              body={(rowData) => (
                <InputText
                  value={value[rowData.key]}
                  onChange={(e) => onChangeValue(rowData.key, e.target.value)}
                ></InputText>
              )}
            ></Column>
          </DataTable>
        </Card>
      </div>
    </div>
  );
};

export default forwardRef(Details);
