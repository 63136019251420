export enum RequestParams {
  USERID = "userId",
}

export enum COMMON_STATUS {
  ENABLE = "enable",
  DISABLE = "disable",
}

export enum MAIL_TEMPLATE {
  REGISTER = "REGISTER",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  SEND_CODE = "SEND_CODE",
  VERIFY_LOGIN = "VERIFY_LOGIN",
}

export enum ROLE {
  ADMIN = "ADMIN",
  USER = "USER",
  SUB_ADMIN = "SUB_ADMIN",
}

export enum LOCATION_TYPES {
  COUNTRY = "country",
  PROVINCE = "province",
  DISTRICT = "district",
  WARD = "ward",
}

export enum USER_STATUS {
  PENDING = "pending",
  CONFIRMED = "confirmed",
  BLOCKED = "blocked",
}

export enum USER_KYC_STATUS {
  KYC = "kyc",
  VERIFIED = "verified",
  CANCEL = "cancel",
}

export enum USER_VERIFIED_STATUS {
  PENDING = "pending_verified",
  VERIFIED = "verified",
  DECLINED = "declined_verified",
}

export enum USER_GENDER {
  MALE = "MALE",
  FEMALE = "FEMALE",
}

export enum OBJECT_TYPE {
  USER = "USER",
  UNDER_18 = "UNDER_18",
  CEO = "CEO",
  ORGANIZATION = "ORGANIZATION",
  PAGE = "PAGE",
  GROUP = "GROUP",
  BUSINESS = "BUSINESS",
  COMMUNITY = "COMMUNITY",
  CHANNEL = "CHANNEL",
  FAMILY = "FAMILY",
  GENEALOGY = "GENEALOGY",
}

export enum ACCOUNT_TYPE {
  NORMAL = "normal",
  USER = "user",
  COUNTRY = "country",
  PROVINCE = "province",
  DISTRICT = "district",
  WARD = "ward",
  CAREER = "career",
  NATION = "nation",
  RELIGION = "religion",
  FAMILY = "family",
  MESSAGE = "message",
  SHOW_INHOME = "show_inhome",
  SHOW_INHOME_FILTER = "show_inhome_filter",
  CHAT = "chat",
  SPECIAL = "special",
  ADV = "adv",
  CATEGORY = "category",
  CEMETERY = "cemetery",
  MARTYR = "martyr",
  SUPPORT = "support",
  MENU = "menu",
  SHOP = "shop",
  PRODUCT = "product",
  BRANCH = "branch"
}

export enum FRAME_TYPE {
  FRAME_AVATAR = "frame_avatar",
  FRAME_BACKGROUND = "frame_background",
  FRAME_POST = "frame_post",
}

export enum BACKGROUND_TYPE {
  POST = "post",
  PROFILE = "profile",
}

export enum REGISTER_TYPE {
  NORMAL = "normal",
  GOOGLE = "google",
  FAST = "fast",
  APPLE = "apple",
}

export enum CAREER_STATUS {
  ENABLE = "enable",
  DISABLE = "disable",
}

export enum MENU_STATUS {
  ENABLE = "enable",
  DISABLE = "disable",
}

export enum CATEGORY_STATUS {
  ENABLE = "enable",
  DISABLE = "disable",
}

export enum APPLY_CAPTCHA_STATUS {
  ENABLE = "enable",
  DISABLE = "disable",
}

export enum MENU_TYPE {
  MENU = "menu",
  SUBMENU = "submenu",
  TAB = "tab",
}

export enum MENU_TYPE_VIEW {
  LINK = "link",
  INTERNAL = "internal",
  WEBVIEW = "webview",
  SELECT = "select",
}

export enum MENU_ROLE {
  ADMIN = "admin",
  USER = "user",
  CENSOR = "censor",
}

export enum STAR_FEELING_TYPE {
  FEELING = "feeling",
  STAR = "star",
  ACTIVITY = "activity",
  REACTION = "reaction",
  PRODUCT = "product",
  ATTITUDE = "attitude",
}

export enum CATEGORY_POST {
  POST_BACKGROUND = "post_background",
  FRAME_AVATAR = "frame_avatar",
  FRAME_BACKGROUND = "frame_background",
  FRAME_POST = "frame_post",
  STICKER = "sticker",
  FEELING = "feeling",
  ACTION = "action",
  ACTION_POST = "action_post",
  MASCOT = "mascot",
  NFT = "nft",
}

export enum PRODUCT_STATUS {
  PENDING = "pending",
  ENABLE = "enable",
  DISABLE = "disable",
}

export enum BANNER_STATUS {
  PENDING = "pending",
  ENABLE = "enable",
  DISABLE = "disable",
}

export enum PRODUCT_TYPE {
  // san pham thuong
  NORMAL = "normal",
  MASCOT = "mascot",
  NFT = "nft",
  // san pham code
  CODE_INTERNAL = "code_internal",
  CODE_EXTERNAL = "code_external",
  // san pham so
  STICKER = "sticker",
  FRAME_AVATAR = "frame_avatar",
  FRAME_BACKGROUND = "frame_background",
  FRAME_POST = "frame_post",
  ACTION_POST = "action_post",
  POST_BACKGROUND = "post_background",
  VIP_GROUP = "vip_group",
  STAR = "star",
  DIGITAL = "digital",
}

export enum PARTICIPANT_STATUS {
  COMPLETED = "completed",
  PENDING = "pending",
  ERROR = "ERROR",
}

export enum LEVEL_STATUS {
  APPROVED = "approved",
  PENDING = "pending",
  CANCEL = "cancel",
}

export enum PARTICIPANT_TYPE {
  TASK = "task",
  CAMPAIGN = "campaign",
}

export enum SHOP_STATUS {
  APPROVED = "approved",
  PENDING = "pending",
  CANCEL = "cancel",
}

export enum TYPE_KYC {
  ACCOUNT = "account",
  CAREER = "career",
}

export enum POST_TYPE {
  NORMAL = "normal",
  VIDEO = "video",
  IMAGE = "image",
  SURVEY = "survey",
  BACKGROUND = "background",
  AUDIO = "audio",
  ACTION = "action",
  VIDEO_YTB = "video_ytb",
  VIDEO_FB = "video_fb",
  FILE = "file",
  EVENT = "event",
  CHAT = "chat",
  TEMPLATE = "template",
  STICKET = "sticker",
  MESSAGE = "messages",
  PRODUCT = "product",
}

export enum PRODUCT_DIGITAL_TYPE {
  STICKER = "sticker",
  MASCOT = "mascot",
  NFT = "nft",
  ACTION_POST = "action_post",
  POST_BACKGROUND = "post_background",
  FRAME_AVATAR = "frame_avatar",
  FRAME_BACKGROUND = "frame_background",
  FRAME_POST = "frame_post",
}

export enum DIGITAL_STASTUS {
  PENDING = "pending",
  APPROVED = "approved",
  CANCEL = "cancel",
}

export enum CATEGORY_EXTRA_FORM_GROUP {
  PRODUCT_CATEGORY_NORMAL = "product_category_normal",
  AGE = "age",
  PROFILE_DIARYBOOK_NOT_DELETE = "profile_diarybook_not_delete",
  PRODUCT_CATEGORY_MARTYR = "product_category_martyr",
  ACCOUNT_TYPE = "account_type",
}

export enum STICKER_TYPE {
  STICKER = "sticker",
  MASCOT = "mascot",
  NFT = "nft",
}

export enum TASK_STATUS {
  PENDING = "Pending",
  OPENING = "opening",
  CLOSED = "closed",
}

export enum CENSOR_STATUS {
  PENDING = "Pending",
  VERIFIED = "verified",
  DENIED = "denied",
}

export enum BANK_USERS_STATUS {
  COMPLETE = "complete",
  PENDING = "pending",
  DENIED = "denied",
}

export enum QUOTAS_TYPE {
  QUOTAS = "quotas",
  POST_SUGGESTIONS = "post_suggestions",
}

export enum PRODUCT_METHOD_SALE {
  RETAIL = "retail",
  WHOLESALE = "wholesale",
  DISTRIBUTION = "distribution",
}

export enum TYPE_CODE {
  CODE = "code",
  VOUCHER = "voucher",
}

export enum TYPE_VOUCHER {
  AMOUNT = "amount",
  PERCENT = "percent",
}

export enum USER_ACTION {
  View,
  Reaction,
  Comment,
  Share,
  Follow,
  UnFollow,
  Report,
  Edit,
  Delete,
  Star,
  Create,
  // common action
  Chat,
  Conversation,
  ViewDetailPage,

  // friend action
  FriendAcceptRequest, // Đồng ý kết bạn
  FriendDeclineRequest, // Từ chối kết bạn
  FriendSendRequest, // Kết bạn
  FriendCancelRequest, // Hủy kết bạn
  FriendUnfriend,
  FriendResponse,
  FriendRefuse,

  // business action
  BusinessRequestJoin,
  BusinessCancelRequest,
  BusinessLeaveGroup,
  BusinessRemoveMember,
  BusinessAcceptMember,
  BusinessCancelAcceptMember,
  BusinessViewDetail,
  BusinessInviteMember,
  BusinessRevokeInvite,
  BusinessAcceptInvite,
  BusinessCancelInvite,
  BusinessResponse,

  // group action
  GroupAcceptRequest,
  GroupDeclineRequest,
  GroupSendRequest,
  GroupCancelRequest,
  GroupRequestJoin, // Yêu cầu tham gia nhóm
  GroupLeave, // Rời khỏi nhóm
  GroupRemoveMember, // Xóa thành viên khỏi nhóm
  GroupAcceptMember, // Chấp nhận thành viên vào nhóm
  GroupCancelAcceptMember, // Hủy việc chấp nhận thành viên
  GroupViewDetail, // Xem chi tiết nhóm
  GroupInviteMember, // Mời thành viên tham gia nhóm
  GroupRevokeInvite, // Hủy lời mời thành viên
  GroupAcceptInvite, // Chấp nhận lời mời tham gia nhóm
  GroupCancelInvite, // Hủy lời mời tham gia nhóm
  GroupResponse, // Phản hồi yêu cầu hoặc lời mời
  CopyContent,
  Hide,
  Pin,
  UnPin,
  CopyLink,
  CopyEmbed,
  TurnOffFeeling,
  DownloadVideo,
  Download,

  // product
  Buy,
}

export enum ACTIVITY_OBJECT {
  post,
  user,
  product,
}
