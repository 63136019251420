import VBanner from "components/v-banner";
import React from "react";

type IProps = {
  data?: any;
  setData: (e: any) => void;
};

const EditBackground = (props: IProps, ref: any) => {
  const { data, setData } = props;

  const onChange = (value, name) => {
    setData((prev) => ({
      ...prev,
      background: {
        ...prev.background,
        [name]: value,
      },
    }));
  };

  return (
    <>
      <VBanner
        data={{
          ...data,
          images: data.slide,
        }}
        onChange={(value: any) => onChange(value, "slide")}
      />
    </>
  );
};

export default EditBackground;
