/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressBar, Toast } from "primereact";
import { showToast } from "utils/common";
import ProductServices from "services/products";

const ImportCodeProduct = (props) => {
  const productId = props.match.params.id;

  const defaultFields = [
    { field: "code", header: "Code" },
    { field: "link", header: "Link" },
  ];

  const [importedData, setImportedData] = useState([]);
  const [importedCols, setImportedCols] = useState(defaultFields);
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const fileRef = useRef(null);

  useEffect(() => {
    if (productId) {
      ProductServices.getProductsById({
        params: {
          id: productId,
        },
      }).then((res: any) => {
        if (res) {
          setImportedData(res.product.codes);
        }
      });
    }
  }, [productId]);

  const toCapitalize = (s) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const importExcelFunc = (e) => {
    const file = e.files[0];
    import("xlsx").then((xlsx) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const wb = xlsx.read(e.target.result, { type: "array" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data: any = xlsx.utils.sheet_to_json(ws, { header: 1 });
        // Prepare DataTable
        const cols: any = data[0];
        data.shift();
        let _importedCols = cols.map((col) => ({
          field: col.toLowerCase(),
          header: toCapitalize(col),
        }));
        const dataNoEmpty = data
          .map((i, index) => {
            if (i.length > 0) {
              return [...i];
            }
          })
          .filter(Boolean);
        const _importedData = dataNoEmpty.map((row: any) => {
          return {
            [cols[0].toLowerCase()]: row[0],
            [cols[1].toLowerCase()]: row[1],
            [cols[2].toLowerCase()]: row[2],
            [cols[3].toLowerCase()]: row[3],
            [cols[4].toLowerCase()]: row[4],
            [cols[5].toLowerCase()]: row[5],
          };
        });

        setImportedCols(_importedCols);
        setImportedData(_importedData);
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const clear = () => {
    setImportedData([]);
    setImportedCols(defaultFields);
    fileRef.current.clear();
  };

  const submit = async () => {
    try {
      setLoading(true);
      await ProductServices.importCode({
        params: {
          id: productId,
        },
        body: {
          codes: importedData,
        },
      });
      clear();
      showToast(toast, "success", "Success");
      setLoading(false);
    } catch (error) {
      showToast(toast, "error", error.errors);
      setLoading(false);
    }
  };

  const cancelOptions = {
    label: "Cancel",
    icon: "pi pi-times",
    className: "p-button-danger",
  };

  return (
    <div className="grid View-demo">
      <div className="col-12">
        <div className="card grid">
          <Toast ref={toast}></Toast>
          <div className="col-12">
            <div className="flex align-items-center justify-content-center py-2">
              <FileUpload
                ref={fileRef}
                chooseOptions={{
                  label: "Import file code",
                  icon: "pi pi-file-excel",
                  className: "p-button-success",
                }}
                mode="basic"
                progressBarTemplate={
                  loading && <ProgressBar mode="indeterminate" />
                }
                name="demo[]"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                className="mr-2"
                uploadHandler={importExcelFunc}
                customUpload={true}
                cancelOptions={cancelOptions}
              />
            </div>
          </div>
          <div className="col-4"></div>
          <div className="col-3">
            <Button
              icon="pi pi-check"
              type="button"
              label="Submit"
              className="p-button-info ml-auto"
              loading={loading}
              onClick={(_) => submit()}
            />
          </div>
          <div className="col-2">
            <Button
              type="button"
              label="Clear"
              icon="pi pi-times"
              onClick={clear}
              className="p-button-danger ml-auto"
            />
          </div>
          <div className="col-12">
            <DataTable
              value={importedData}
              emptyMessage="No data"
              paginator
              totalRecords={importedData.length}
              rowsPerPageOptions={[10, 20, 50, 100]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{first} - {last} of {totalRecords}"
              rows={10}
              alwaysShowPaginator={false}
              responsiveLayout="scroll"
            >
              {importedCols.map((col, index) => (
                <Column key={index} field={col.field} header={col.header} />
              ))}
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportCodeProduct;
