import { request, parseErrorResponse } from "./request";

const getTransactionSystem = () =>
  new Promise((resolve, reject) => {
    request()
      .get("admin/systems")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getBalanceSystem = (req: any) =>
  new Promise((resolve, reject) => {
    request()
      .get(`finances/balances/system`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getCountPending = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/systems/notify`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const SystemService = {
  getTransactionSystem,
  getBalanceSystem,
  getCountPending,
};

export default SystemService;
