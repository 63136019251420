import VStockDropdown from "components/v-stock";
import { MasterContext } from "contexts/MasterContext";
import { Button, Dropdown, InputNumber, InputSwitch } from "primereact";
import { useContext } from "react";

const View = (props) => {
  const { minigames } = useContext(MasterContext);
  const { reward, setReward } = props;

  const defaultValue = {
    stocks: { index: reward[`stocks`].length, symbol: "", amount: 0 },
    vouchers: { index: reward[`vouchers`].length, id: "", amount: 0 },
    codes: { index: reward[`codes`].length, price: 0, amount: 0, link: "" },
    tickets: { index: reward[`tickets`].length, game_id: "", amount: 0 },
  };

  const handleAdd = (type = "stocks") => {
    setReward({
      ...reward,
      [`${type}`]: [...reward[`${type}`], defaultValue[`${type}`]],
    });
  };

  const handleSub = (type = "stocks") => {
    setReward({
      ...reward,
      [`${type}`]: reward[`${type}`].slice(0, reward.stocks.length - 1),
    });
  };

  const changeHandler =
    (index) =>
    (type = "stocks", name, value) => {
      let game_name = null;
      if (name === "game_id") {
        game_name = minigames.find((g) => g.id === value).name;
      }
      setReward({
        ...reward,
        [`${type}`]: reward[`${type}`].map((item) =>
          item.index === index
            ? {
                ...item,
                ...(game_name ? { game_name } : {}),
                [name]: value,
              }
            : item
        ),
      });
    };

  return (
    <div className="grid">
      <div className="grid">
        <div className="col-12">
          <h5>Stocks</h5>
          <Button
            icon="bx bxs-add-to-queue"
            className="p-button-rounded p-button-success"
            aria-label="Search"
            onClick={(_) => handleAdd()}
          />
          <Button
            icon="bx bxs-minus-circle"
            className="p-button-rounded p-button-danger"
            aria-label="Cancel"
            onClick={(_) => handleSub()}
          />
        </div>
        {reward.stocks.map((stock, index) => (
          <>
            <div className="field col-12 md:col-5">
              <label htmlFor="website">Stock</label>
              <VStockDropdown
                value={stock.symbol}
                setValue={(e) =>
                  changeHandler(stock.index)("stocks", "symbol", e)
                }
              />
            </div>
            <div className="field col-12 md:col-5">
              <label htmlFor="time_lock">Amount/Percent</label>
              <InputNumber
                id="amount"
                value={stock.amount}
                onChange={(e) =>
                  changeHandler(stock.index)("stocks", "amount", e.value)
                }
                required
              />
            </div>
            <div className="field col-6 md:col-2">
              <label htmlFor="is_enable_claim">Is Percent</label>
              <br />
              <InputSwitch
                checked={stock.is_percent}
                onChange={(e) =>
                  changeHandler(stock.index)("stocks", "is_percent", e.value)
                }
              />
            </div>
          </>
        ))}
      </div>

      <hr className="w-full" />

      <div className="grid">
        <div className="col-12">
          <h5>Tickets</h5>
          <Button
            icon="bx bxs-add-to-queue"
            className="p-button-rounded p-button-success"
            aria-label="Search"
            onClick={(_) => handleAdd("tickets")}
          />
          <Button
            icon="bx bxs-minus-circle"
            className="p-button-rounded p-button-danger"
            aria-label="Cancel"
            onClick={(_) => handleSub("tickets")}
          />
        </div>
        {reward.tickets.map((item, index) => (
          <>
            <div className="field col-12 md:col-6">
              <label htmlFor="website">Tickets</label>
              <Dropdown
                value={item.game_id}
                options={minigames}
                onChange={(e) =>
                  changeHandler(item.index)("tickets", "game_id", e.value)
                }
                optionLabel="name"
                filterBy="name"
                optionValue="id"
                filter
                showClear
                placeholder="Select game"
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="time_lock">Amount</label>
              <InputNumber
                id="amount"
                value={item.amount}
                onChange={(e) =>
                  changeHandler(item.index)("tickets", "amount", e.value)
                }
                required
              />
            </div>
          </>
        ))}
      </div>

      <hr className="w-full" />

      <div className="grid">
        <div className="col-12">
          <h5>Vouchers</h5>
          <Button
            icon="bx bxs-add-to-queue"
            className="p-button-rounded p-button-success"
            aria-label="Search"
            onClick={(_) => handleAdd("vouchers")}
          />
          <Button
            icon="bx bxs-minus-circle"
            className="p-button-rounded p-button-danger"
            aria-label="Cancel"
            onClick={(_) => handleSub("vouchers")}
          />
        </div>
        {reward.vouchers.map((stock, index) => (
          <>
            <div className="field col-12 md:col-6">
              <label htmlFor="website">Vouchers</label>
              <Dropdown
                value={stock.symbol}
                options={[]}
                onChange={(e) =>
                  changeHandler(stock.index)("vou", "symbol", e.value)
                }
                optionLabel="fullName"
                filterBy="fullName"
                optionValue="symbol"
                filter
                showClear
                placeholder="Select vouchers"
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="time_lock">Amount</label>
              <InputNumber
                id="amount"
                value={stock.amount}
                onChange={(e) =>
                  changeHandler(stock.index)("vou", "amount", e.value)
                }
                required
              />
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default View;
