import { request, parseErrorResponse } from "./request";
// import qs from "query-string";

const getMinigames = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/vouchers/minigames`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const VoucherService = {
  getMinigames,
};

export default VoucherService;
