/* eslint-disable react-hooks/exhaustive-deps */
import VDialog from "components/v-dialog";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React, { useCallback, useEffect, useRef, useState } from "react";
import FontService from "services/fonts";
import Detail from "./Detail";
import VConfirm from "components/v-confirm";
import { downloadFile, showToast } from "utils/common";
import debounce from "lodash.debounce";

const Crud = () => {
  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);
  const refDetail = useRef(null);

  const [data, setData] = useState([]);
  const [detail, setDetail] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [globalFilter, setGlobalFilter] = useState({});
  // const lazyParams: any = {
  //   limit: 1000,
  //   offset: 0,
  // };
  const [lazyParams, setLazyParams]: any = useState({
    name: "",
    first: 0,
    rows: 10,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });

  const templateUrl =
    "admin/template/8543190f-a141-469d-9af8-188a4628c461.xlsx";

  const templateFileName = "Import_Fonts_Template.xlsx";

  //CREATE OR NEW
  const openNew = () => {
    setDetail(null);
    refDialogDetail.current.show();
  };

  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };

  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };

  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  const getCategoryFilters = async () => {
    // try {
    //   const res: any = await CategoryFilterService.getAll();
    //   if (res) {
    //     setCategoryFilters(res);
    //   }
    // } catch (error) {}
  };

  const confirmDelete = (quotas) => {
    setDetail(quotas);
    refDialogDelete.current.show();
  };

  const handleDelete = async () => {
    try {
      await FontService.deleted({
        params: {
          id: detail._id,
        },
      });
      const _data = data.filter((val) => val._id !== detail._id);
      setData(_data);
      refDialogDelete.current.close();
      setDetail(null);
      showToast(toast, "success", "User Deleted");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const handleImportFontDefault = async () => {
    try {
      await FontService.importData();
      showToast(toast, "success", "Import font data success");
      await loadData();
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };

  const loadData = async () => {
    try {
      const res: any = await FontService.search({
        query: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
          limit: lazyParams.rows,
        },
      });
      if (res && res.docs) {
        setData(res.docs);
        setTotalRecords(res.totalDocs);
      } else setData([]);
    } catch (error) {
      setData([]);
    }
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Import default data"
          className="mr-2 inline-block"
          icon="pi pi-plus"
          onClick={handleImportFontDefault}
        />
        <Button
          label="Download Template"
          icon="pi pi-download"
          className="p-button-help"
          onClick={() => downloadFile(templateUrl, templateFileName)}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => {
            setDetail(rowData);
            refDialogDetail.current.show();
          }}
        />

        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning"
          onClick={() => confirmDelete(rowData)}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Manage Fonts</h5>
      <div className="grid my-2 align-items-center">
        <div className="block m-1 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e: any) => onSearch("name", e.target.value)}
            placeholder="Search..."
          />
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    loadData();
  }, [globalFilter, lazyParams]);

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          />
          <DataTable
            ref={dt}
            header={header}
            value={data}
            emptyMessage="No data found."
            dataKey="id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            paginator
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[5, 10, 20]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            //sort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onSort}
          >
            <Column
              frozen
              alignFrozen="left"
              body={actionBodyTemplate}
              style={{ flexGrow: 1, flexBasis: "150px" }}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="name"
              header="Name"
              sortable
              body={(rowData) => rowData.name}
            ></Column>
          </DataTable>

          <VDialog
            ref={refDialogDetail}
            header="Detail"
            onSubmit={onSumitDialogDetail}
          >
            <Detail
              ref={refDetail}
              data={detail}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
              getCategoryFilters={getCategoryFilters}
            />
          </VDialog>

          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(Crud, comparisonFn);
