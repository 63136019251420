import { RadioButton } from "primereact/radiobutton";
import React from "react";

interface RadioData {
  label: string;
  value: string;
}

type IProps = {
  checked?: any;
  options?: RadioData[];
  onChange: (e: any) => void;
};

const VGroupRadio = (props: IProps) => {
  const { options, checked, onChange } = props;
  return (
    <div className="grid">
      {options?.map((el, index) => (
        <div
          key={index}
          className="field col-2 flex flex-column align-items-center gap-2"
        >
          <label htmlFor={el.value}>{el.label}</label>
          <RadioButton
            inputId={el.value}
            name={el.value}
            value={el.value}
            onChange={(e) => onChange(e.value)}
            checked={checked === el.value}
          />
        </div>
      ))}
    </div>
  );
};

export default VGroupRadio;
