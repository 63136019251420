import { request, parseErrorResponse } from "./request";
import qs from "query-string";

const getAll = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/tooltips`)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          resolve(data);
        } else {
          reject(new Error("Something went error!"));
        }
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const search = (req: any) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/tooltips/search?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          resolve(data);
        } else {
          reject(new Error("Something went error!"));
        }
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const create = (req: any) =>
  new Promise((resolve, reject) => {
    request()
      .post("admin/tooltips", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          resolve(data);
        } else {
          reject(new Error("Something went error!"));
        }
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const update = (req: any) =>
  new Promise((resolve, reject) => {
    request()
      .put("admin/tooltips/" + req.params.id, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          resolve(data);
        } else {
          reject(new Error("Something went error!"));
        }
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const deleted = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .delete("admin/tooltips/" + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          resolve(data);
        } else {
          reject(new Error("Something went error!"));
        }
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const importExcel = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post("admin/tooltips/import-excel", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          resolve(data);
        } else {
          reject(new Error("Something went error!"));
        }
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const TooltipService = {
  getAll,
  search,
  deleted,
  create,
  update,
  importExcel,
};

export default TooltipService;
