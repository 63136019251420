/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import { Panel } from "primereact";
import "../styles.scss";
import { LEVEL_STATUS, PARTICIPANT_STATUS } from "utils/enum";

const Details = (props, ref) => {
  const { data } = props;

  const emptyData: any = {
    user: {},
    user_id: "",
    status: PARTICIPANT_STATUS.COMPLETED,
    data: {},
    campaign_attachment: {
      status: LEVEL_STATUS.PENDING,
      uploads: [],
      approved: [],
    },
  };

  const [details, setDetails] = useState(emptyData);

  useEffect(() => {
    if (data)
      setDetails({
        ...data,
        start_date: new Date(data.start_date),
        end_date: new Date(data.end_date),
      });
  }, [data]);

  const renderAmount = (
    label: string,
    isEnable: boolean,
    currentAmount: number,
    requireAmount: number
  ) => {
    return (
      <>
        {isEnable && (
          <div className="field col-12 md:col-6">
            <label className="mr-2 font-bold">{`${label}:`}</label>
            <span>{`${currentAmount || 0}/${requireAmount}`}</span>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <Panel header="Genaral" toggleable className="my-2">
        <div className="grid">
          <div className="field col-12 md:col-6">
            <label className="mr-2 font-bold">Email:</label>
            <span>{details.user.email}</span>
          </div>
          <div className="field col-12 md:col-6">
            <label className="mr-2 font-bold">Full name:</label>
            <span>{details.user.full_name}</span>
          </div>

          <div className="field col-12 md:col-6">
            <label className="mr-2 font-bold">Status level:</label>
            <span>{details.status}</span>
          </div>
        </div>
      </Panel>
      {details.levels?.length &&
        details.levels?.map((level: any) => (
          <Panel header={level.name} toggleable className="my-2">
            {renderAmount(
              "Total image",
              level.image_enable,
              details?.data?.image_amount,
              level.image_amount
            )}
            {renderAmount(
              "Total feel",
              level.feel_enable,
              details?.data?.feel_amount,
              level.feel_amount
            )}
            {renderAmount(
              "Total feel receive",
              level.feel_receive_enable,
              details?.data?.feel_receive_amount,
              level.feel_receive_amount
            )}
            {renderAmount(
              "Total f1",
              level.f1_enable,
              details?.data?.f1_amount,
              level.f1_amount
            )}
            {renderAmount(
              "Total like",
              level.like_enable,
              details?.data?.like_amount,
              level.like_amount
            )}
            {renderAmount(
              "Total like receive",
              level.like_receive_enable,
              details?.data?.like_receive_amount,
              level.like_receive_amount
            )}
            {renderAmount(
              "Total post",
              level.post_enable,
              details?.data?.post_amount,
              level.post_amount
            )}
            {renderAmount(
              "Total share",
              level.share_enable,
              details?.data?.share_amount,
              level.share_amount
            )}
            {renderAmount(
              "Total trust",
              level.trust_enable,
              details?.data?.trust_amount,
              level.trust_amount
            )}
          </Panel>
        ))}
    </>
  );
};

export default forwardRef(Details);
