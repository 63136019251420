import { parseErrorResponse, request } from './request';

export const getProjects = () =>
  new Promise((resolve, reject) => {
    request()
      .get('/admin/projects')
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getProjectById = (req: { params: { id: string; }; }) =>
  new Promise((resolve, reject) => {
    request()
      .get('/admin/projects/' + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const createProject = (req: { body: any; }) =>
  new Promise((resolve, reject) => {
    request()
      .post('/admin/projects', req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const editProject = (req: { params: { id: any; }; body: any; }) =>
  new Promise((resolve, reject) => {
    request()
      .put(`/admin/projects/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const deleteProject = (req: { params: { id: any; }; }) =>
  new Promise((resolve, reject) => {
    request()
      .delete(`/admin/projects/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });
  
const ProjectService = {
  getProjects,
  createProject,
  getProjectById,
  deleteProject,
  editProject
};

export default ProjectService;
