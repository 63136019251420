import React from "react";
import { Button, InputText } from "primereact";
import { nanoid } from "nanoid";
import { InputTextarea } from "primereact/inputtextarea";

const Permissions = ({ value, setValue }) => {
  const changeHandler = (_value, name, index) => {
    const arr = value.map((i) =>
      i.index === index
        ? {
            ...i,
            [name]: _value,
          }
        : i
    );
    setValue(arr);
  };

  const handleAdd = () => {
    const _value = [...value, { index: nanoid() }];
    setValue(_value);
  };

  const handleSubIndex = (index) => {
    const newState = [...value].filter((i) => i.index !== index);
    setValue(newState);
  };

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>Permissions</h4>
            <Button
              onClick={handleAdd}
              icon="bx bxs-add-to-queue"
              className="p-button-rounded p-button-success mb-3"
              aria-label="Search"
            />
          </div>
          {value.map((item, index) => {
            return (
              <div className="grid" key={index}>
                <div className="field col-12 md:col-6">
                  <span className="p-float-label">
                    <InputText
                      required
                      value={item.key}
                      className="w-full"
                      onChange={(e) =>
                        changeHandler(e.target.value, "key", item.index)
                      }
                    />
                    <label htmlFor="bank">Key</label>
                  </span>
                </div>
                <div className="field col-12 md:col-6">
                  <span className="p-float-label">
                    <InputText
                      required
                      value={item.name}
                      className="w-full"
                      onChange={(e) =>
                        changeHandler(e.target.value, "name", item.index)
                      }
                    />
                    <label htmlFor="memo">Name</label>
                  </span>
                </div>
                <div className="field col-12 md:col-10">
                  <span className="p-float-label">
                    <InputTextarea
                      required
                      value={item.description}
                      className="w-full"
                      onChange={(e) =>
                        changeHandler(e.target.value, "description", item.index)
                      }
                    />
                    <label htmlFor="memo">Description</label>
                  </span>
                </div>

                <div className="field col-12 md:col-2">
                  <Button
                    icon="pi pi-times"
                    className="p-button-rounded p-button-danger"
                    aria-label="Cancel"
                    onClick={(_) => handleSubIndex(item.index)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Permissions;
