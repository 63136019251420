/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact";
import { ROLE_CLIENT } from "utils/constant";
import VipService from "services/vips";
import { InputText } from "primereact/inputtext";
import VEditor from "components/v-editor";
import { ROLE } from "utils/enum";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    key: "",
    name: "",
    price: 0,
    role: "USER",
    description: "",
  };

  const [details, setDetails] = useState(emptyData);
  const [disableKey, setDisableKey] = useState(false);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) {
      setDetails(data);
      setDisableKey(true);
    }
  }, [data]);

  const onChange = (name, value) => {
    setDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await VipService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else
        await VipService.create({
          body: {
            ...details,
          },
        });
      setLoading(false);
      showToast(toast, "success", "File saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      {/* <div className="field col-12 md:col-6">
        <label htmlFor="description">Key</label>
        <Dropdown
          defaultValue={details.key}
          value={details.key}
          options={ROLE_CLIENT.map((r) => ({
            label: r,
            value: r,
          }))}
          onChange={(e) => onChange("key", e.value)}
          optionLabel="label"
          placeholder="Select"
        />
      </div> */}

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Key</label>
        <InputText
          disabled={disableKey}
          onChange={(e) => onChange("key", e.target.value)}
          value={details.key}
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Name</label>
        <InputText
          onChange={(e) => onChange("name", e.target.value)}
          value={details.name}
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Role</label>
        <Dropdown
          value={details.role}
          options={Object.keys(ROLE).map((k) => ({
            label: k,
            value: ROLE[k],
          }))}
          optionLabel="label"
          optionValue="value"
          placeholder="Select role"
          onChange={(e) => onChange("role", e.value)}
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Price</label>
        <InputNumber
          onChange={(e) => onChange("price", e.value)}
          value={details.price}
        />
      </div>
      <div className="field col-12 md:col-12">
        <label htmlFor="description">Description</label>
        <br />
        <VEditor
          value={details.description}
          onChange={(e) => onChange("description", e?.html)}
          haveData={details.description ? true : false}
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
