/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import CategoryFieldService from "services/category-field";
import VUpload from "components/v-upload";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";

const Details = (props, ref) => {
  const { data, reload, toast, categoryField, onCancel, setLoading } = props;

  const emptyData = {
    name: "",
    logo: "",
    key: "",
    link: "",
    status: "enable",
    order: 0,
    parent_id: null,
  };

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setDetails({
      ...data,
      type: data.type || "normal"
    });
  }, [data]);

  const onChange = (name, value) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await CategoryFieldService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else
        await CategoryFieldService.create({
          body: {
            ...details,
          },
        });
      setLoading(false);
      showToast(toast, "success", "File saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12">
        <label htmlFor="name">Parent</label>
        <Dropdown
          value={details.parent_id}
          options={categoryField}
          optionLabel="name"
          optionValue="_id"
          filter
          showClear
          onChange={(e) => onChange("parent_id", e.value)}
        />
      </div>
      <div className="field col-12">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange("name", e.target.value)}
          required
          autoFocus
        />
      </div>

      <div className="field col-6">
        <label htmlFor="key">Key</label>
        <InputText
          id="key"
          value={details.key}
          onChange={(e) => onChange("key", e.target.value)}
          required
          autoFocus
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Status</label>
        <Dropdown
          defaultValue={details.status}
          value={details.status}
          options={[
            {
              label: "ENABLE",
              value: "enable",
            },
            {
              label: "DISABLE",
              value: "disable",
            },
          ]}
          onChange={(e) => onChange("status", e.value)}
          optionLabel="label"
          placeholder="Select status"
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="link">Link</label>
        <InputText
          id="link"
          value={details.link}
          onChange={(e) => onChange("link", e.target.value)}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="link">Order</label>
        <InputNumber
          id="link"
          value={details.order}
          onChange={(e) => onChange("order", e.value)}
          required
          autoFocus
        />
      </div>

      <div className="field col-12">
        <VUpload
          urlFile={details.logo}
          setUrlFile={(file) => onChange("logo", file)}
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
