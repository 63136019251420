import VBanner from "components/v-banner";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import React from "react";
import { IObject } from "types";
import { v4 as uuidv4 } from "uuid";

type IProps = {
  data: any;
  onChange: any;
};

const BannersField = (props: IProps) => {
  const { data, onChange } = props;

  const handleAddBanner = () => {
    const _banners = [
      ...data,
      {
        id: uuidv4(),
        type: "slide",
        video: "",
        link_href_video: "",
        images: [],
      },
    ];
    onChange(_banners, "banners");
  };

  const handleSubBanner = (id: string) => {
    const _banners = data.filter((el) => el.id !== id);
    onChange(_banners, "banners");
  };
  const handleBannersChange = (value: any, name: string, index: number) => {
    const _banners = data;
    _banners[index] = { ..._banners[index], [name]: value };
    onChange(_banners, "banners");
  };

  return (
    <>
      {data?.length > 0 &&
        data?.map((banner: IObject, index) => (
          <div className="relative field col-12 p-3" key={banner.id}>
            <div className="absolute right-0 top-0">
              <Button
                icon="pi pi-times"
                className="w-2rem h-2rem p-button-rounded p-button-danger"
                aria-label="Sub"
                onClick={(_) => handleSubBanner(banner.id)}
              />
            </div>
            <Panel
              header={`Banner ${index + 1}`}
              toggleable
              collapsed={true}
              className="my-2"
            >
              <VBanner
                data={banner}
                onChange={(value: any, name: string) =>
                  handleBannersChange(value, name, index)
                }
              />
            </Panel>
          </div>
        ))}
      <div className="w-full flex justify-content-center">
        <Button
          icon="pi pi-plus"
          className="p-button-rounded p-button-success"
          aria-label="Add"
          onClick={(_) => handleAddBanner()}
        />
      </div>
    </>
  );
};

export default BannersField;
