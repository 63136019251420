/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import Service from "services/categories-groups";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;
  const emptyAdditionalData = {
    enable: false,
    name: '',
    key: ''
  };
  const emptyData = {
    name: "",
    key: "",
    description: ""
  };

  const [details, setDetails] = useState(emptyData);
  const [additionalData, setAdditionalData] = useState(emptyAdditionalData)

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
    if (data && data.additionalDataForm)
      setAdditionalData({
        ...emptyAdditionalData,
        ...data.additionalDataForm
      });
  }, [data]);

  const onChange = (name, value) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await Service.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            additionalDataForm: additionalData
          },
        });
      } else
        await Service.create({
          body: {
            ...details,
            additionalDataForm: additionalData
          },
        });
      setLoading(false);
      showToast(toast, "success", "File saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">     
      <div className="field col-6">
        <label htmlFor="name">Key</label>
        <InputText
          id="key"
          value={details.key}
          onChange={(e) => onChange("key", e.target.value)}
          required
          autoFocus
        />
      </div>
      <div className="field col-6">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange("name", e.target.value)}
          required
          autoFocus
        />
      </div>

      <div className="field col-12">
        <label htmlFor="key">Description</label>
        <InputText
          id="key"
          value={details.description}
          onChange={(e) => onChange("description", e.target.value)}
          required
          autoFocus
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
