import { SocketContext } from "contexts/SocketContext";
import React, { useContext } from "react";
import { formatNumber } from "utils/common";
import "./styles.scss";

const Dashboard = (props: any) => {
  const { dashboard } = useContext(SocketContext);

  const infoUsers = [
    {
      name: "USER",
      value: formatNumber(dashboard.user, 0),
      icon: <i className="bx bx-user text-blue-500 text-xl" />,
      label: (
        <>
          {" "}
          <span className="text-green-500 font-medium">+%10 </span>
          <span className="text-500">since last week</span>
        </>
      ),
    },
    {
      name: "BUSSINESS",
      value: formatNumber(dashboard.business, 0),
      icon: <i className="bx bxs-business text-yellow-500 text-xl" />,
      label: (
        <>
          {" "}
          <span className="text-green-500 font-medium">+%10 </span>
          <span className="text-500">since last week</span>
        </>
      ),
    },
    {
      name: "PAGE",
      value: formatNumber(dashboard.page, 0),
      icon: <i className="bx bx-group text-blue-600 text-xl" />,
      label: (
        <>
          {" "}
          <span className="text-green-500 font-medium">+%10 </span>
          <span className="text-500">since last week</span>
        </>
      ),
    },
    {
      name: "GROUP",
      value: formatNumber(dashboard.group, 0),
      icon: <i className="bx bxs-group text-blue-500 text-xl" />,
      label: (
        <>
          {" "}
          <span className="text-green-500 font-medium">+%10 </span>
          <span className="text-500">since last week</span>
        </>
      ),
    },
    {
      name: "CEO",
      value: formatNumber(dashboard.ceo, 0),
      icon: <i className="bx bx-user text-green-500 text-xl" />,
      label: (
        <>
          {" "}
          <span className="text-green-500 font-medium">+%10 </span>
          <span className="text-500">since last week</span>
        </>
      ),
    },
    {
      name: "UNDER 18",
      value: formatNumber(dashboard.under_18, 0),
      icon: <i className="bx bx-child text-pink-500 text-xl" />,
      label: (
        <>
          {" "}
          <span className="text-green-500 font-medium">+%10 </span>
          <span className="text-500">since last week</span>
        </>
      ),
    },
    {
      name: "KYC",
      value: formatNumber(dashboard.kyc, 0),
      icon: <i className="bx bx-user-check text-green-500 text-xl" />,
      label: (
        <>
          {" "}
          <span className="text-green-500 font-medium">+%10 </span>
          <span className="text-500">since last week</span>
        </>
      ),
    },
    {
      name: "VERIFIED",
      value: formatNumber(dashboard.verified, 0),
      icon: <i className="bx bx-user-check text-green-500 text-xl" />,
      label: (
        <>
          {" "}
          <span className="text-green-500 font-medium">+%10 </span>
          <span className="text-500">since last week</span>
        </>
      ),
    },
  ];

  const infoPosts = [
    {
      name: "POST",
      value: formatNumber(dashboard.post, 0),
      icon: <i className="bx bxl-blogger text-blue-500 text-xl" />,
      label: (
        <>
          {" "}
          <span className="text-green-500 font-medium">+%10 </span>
          <span className="text-500">since last week</span>
        </>
      ),
    },
    {
      name: "COMMENT",
      value: formatNumber(dashboard.comment, 0),
      icon: <i className="bx bxs-comment-detail text-blue-500 text-xl" />,
      label: (
        <>
          {" "}
          <span className="text-green-500 font-medium">+%10 </span>
          <span className="text-500">since last week</span>
        </>
      ),
    },
    {
      name: "SHARE",
      value: formatNumber(dashboard.share, 0),
      icon: <i className="bx bxs-share text-blue-500 text-xl" />,
      label: (
        <>
          {" "}
          <span className="text-green-500 font-medium">+%10 </span>
          <span className="text-500">since last week</span>
        </>
      ),
    },
    {
      name: "FEEL",
      value: formatNumber(dashboard.feel, 0),
      icon: <i className="bx bxs-like text-blue-500 text-xl" />,
      label: (
        <>
          {" "}
          <span className="text-green-500 font-medium">+%10 </span>
          <span className="text-500">since last week</span>
        </>
      ),
    },
  ];

  return (
    <div className="grid">
      <div className="col-12">
        <div className="col-12 lg:col-6 xl:col-4  m-auto">
          <div className="card relative flex justify-content-center align-items-center">
            <div className="amount-online">
              {formatNumber(dashboard.online, 0)}
            </div>
            <img src="images/online.gif" alt="" width={350} />
          </div>
        </div>
      </div>

      {infoUsers.map((item) => (
        <div className="col-12 lg:col-6 xl:col-3">
          <div className="card mb-0">
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block text-600 font-medium mb-3">
                  {item.name}
                </span>
                <div className="text-900 font-medium text-xl">{item.value}</div>
              </div>
              <div
                className="flex align-items-center justify-content-center bg-blue-100 border-round"
                style={{ width: "2.5rem", height: "2.5rem" }}
              >
                {item.icon}
              </div>
            </div>
            {/* {item.label} */}
          </div>
        </div>
      ))}

      {infoPosts.map((item) => (
        <div className="col-12 lg:col-6 xl:col-3">
          <div className="card mb-0">
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block text-600 font-medium mb-3">
                  {item.name}
                </span>
                <div className="text-900 font-medium text-xl">{item.value}</div>
              </div>
              <div
                className="flex align-items-center justify-content-center bg-blue-100 border-round"
                style={{ width: "2.5rem", height: "2.5rem" }}
              >
                {item.icon}
              </div>
            </div>
            {/* {item.label} */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Dashboard;
