/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import Service from "services/frames";
import VUpload from "components/v-upload";
import { Dropdown } from "primereact/dropdown";
import CategoryPostService from "services/category-posts";
import { InputNumber, Panel } from "primereact";
import CategoryFilterDetails from "components/v-filter";
import { COMMON_STATUS, FRAME_TYPE } from "utils/enum";
import VEditor from "components/v-editor";
import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";

const Details = (props, ref) => {
  const {
    data,
    reload,
    toast,
    onCancel,
    setLoading,
    categoryFilters,
    getCategoryFilters,
  } = props;
  const [options, setOptions] = useState([]);

  const emptyData = {
    image: "",
    name: "",
    thumbnail: "",
    type: "free",
    price: 0,
    category_id: "",
    category_filter_id: null,
    order: 0,
    status: COMMON_STATUS.ENABLE,
    description: "",
    frame_type: FRAME_TYPE.FRAME_POST,
    start_date: new Date(),
    end_date: new Date(),
    use_default: false,
  };
  const [details, setDetails] = useState(emptyData);
  const refDetailCategory = useRef(null);

  const getCategories = () => {
    CategoryPostService.getCategories({
      query: {
        category: "frame",
      },
    }).then((res: any) => {
      if (res && res.categories) {
        const data = [...res.categories].map((i) => ({
          label: i.name.toString(),
          value: i._id,
        }));
        setOptions(data);
      }
    });
  };

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (data) setDetails({
      ...data,
      start_date: data.start_date ? new Date(data.start_date) : new Date(),
      end_date: data.end_date ? new Date(data.end_date) : new Date(),
    });
  }, [data]);

  const onChange = (name, value) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      let category = null;
      if (!details.category_filter_id) {
        category = await refDetailCategory.current.submit();
        getCategoryFilters();
      }
      if (data) {
        await Service.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      } else
        await Service.create({
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      setLoading(false);
      showToast(toast, "success", "File saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12 ">
        <label htmlFor="status">Filter</label>
        <Dropdown
          filter
          showClear
          value={details.category_filter_id}
          options={categoryFilters.map((item) => ({
            label: item.name,
            value: item._id,
          }))}
          optionLabel="label"
          optionValue="value"
          placeholder="Select filter"
          onChange={(e) => onChange("category_filter_id", e.value)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Status</label>
        <Dropdown
          defaultValue={details.status}
          value={details.status}
          options={[
            {
              label: "ENABLE",
              value: "enable",
            },
            {
              label: "DISABLE",
              value: "disable",
            },
          ]}
          onChange={(e) => onChange("status", e.value)}
          optionLabel="label"
          placeholder="Select status"
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Category</label>
        <Dropdown
          value={details.category_id}
          options={options}
          onChange={(e) => onChange("category_id", e.value)}
          optionLabel="label"
          placeholder="Select category"
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange("name", e.target.value)}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="order">Order</label>
        <InputNumber
          value={details.order}
          onChange={(e) => onChange("order", e.value)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Type</label>
        <Dropdown
          defaultValue={details.type}
          value={details.type}
          options={[
            {
              label: "FREE",
              value: "free",
            },
            {
              label: "FEE",
              value: "fee",
            },
          ]}
          onChange={(e) => onChange("type", e.value)}
          optionLabel="label"
          placeholder="Select type"
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Price</label>
        <InputNumber
          id="name"
          value={details.price}
          onChange={(e) => onChange("price", e.value)}
          required
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Frame Type</label>
        <Dropdown
          defaultValue={details.frame_type}
          value={details.frame_type}
          options={Object.keys(FRAME_TYPE).map((k) => ({
            label: k,
            value: FRAME_TYPE[k],
          }))}
          onChange={(e) => onChange("frame_type", e.value)}
          optionLabel="label"
          placeholder="Select Frame Type"
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="use_default">Use default</label>
        <br />
        <InputSwitch
          checked={details.use_default}
          onChange={(e) => onChange("use_default", e.value)}
        />
      </div>
      {details.use_default && (
        <>
          <div className="field col-12 md:col-6">
            <label htmlFor="start-date">Start Date</label>
            <Calendar
              id="start-date"
              showIcon
              showTime
              placeholder="Start date"
              value={details.start_date}
              onChange={(e) => onChange("start_date", e.value)}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="end-date">End Date</label>
            <Calendar
              id="end-date"
              showIcon
              showTime
              placeholder="End date"
              value={details.end_date}
              onChange={(e) => onChange("end_date", e.value)}
            />
          </div>
        </>
      )}

      <div className="field col-12">
        <div className="flex justify-content-center ">
          <label htmlFor="name">Thumbnail</label>
        </div>
        <VUpload
          urlFile={details.thumbnail}
          setUrlFile={(file) => onChange("thumbnail", file)}
        />
      </div>

      <div className="field col-12">
        <div className="flex justify-content-center ">
          <label htmlFor="name">Image</label>
        </div>
        <VUpload
          urlFile={details.image}
          setUrlFile={(file) => onChange("image", file)}
        />
      </div>

      <div className="field col-12">
        <label htmlFor="description">Description</label>
        <br />
        <VEditor
          value={details.description}
          onChange={(e) => {
            onChange("description", e?.html);
          }}
          haveData={details.description ? true : false}
        />
      </div>

      {!details.category_filter_id && (
        <div className="field col-12">
          <Panel header="Filter" toggleable className="my-2" collapsed={false}>
            <div className="grid">
              <div className="field col-12">
                <CategoryFilterDetails ref={refDetailCategory} />
              </div>
            </div>
          </Panel>
        </div>
      )}
    </div>
  );
};

export default forwardRef(Details);
