import { useState } from "react";
import FontsList from "./components/Tables";
import { TabPanel, TabView } from "primereact/tabview";
import ImportExcel from "./components/ImportExcel";

const Fonts = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>Fonts</h4>

            <TabView
              activeIndex={activeIndex}
              onTabChange={(e) => setActiveIndex(e.index)}
            >
              <TabPanel header="List Fonts">
                <FontsList />
              </TabPanel>
              <TabPanel header="Import Excel">
                <ImportExcel />
              </TabPanel>
            </TabView>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fonts;
