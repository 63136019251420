import { request, parseErrorResponse } from "./request";
import qs from "query-string";

const search = (req: any) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/payments/search?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getAllPayments = (req: { query: Record<string, any> }) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/payments/?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const createPayment = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post(`admin/payments`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const deletePayment = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .delete("admin/payments/" + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getPaymentById = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .get("admin/payments/" + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updatePayment = (req: { params: { id: string }; body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .put("admin/payments/" + req.params.id, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const changeStatusPayment = (req: { params: { id: string }; body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post("admin/payments/change-status/" + req.params.id, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const changeStatusPaymentWithdraw = (req: {
  params: { id: string };
  body: any;
}) =>
  new Promise((resolve, reject) => {
    request()
      .post("admin/payments/change-status-withdraw/" + req.params.id, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const PaymentService = {
  getAllPayments,
  createPayment,
  deletePayment,
  getPaymentById,
  updatePayment,
  changeStatusPayment,
  changeStatusPaymentWithdraw,
  search
};

export default PaymentService;
