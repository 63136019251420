import { request, parseErrorResponse } from "./request";

const getSettings = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/settings`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getSettingsByName = (req: { params: { name: any } }) =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/settings/get-by-name/${req.params.name}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updateSetting = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .put(`admin/settings/update-by-name`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const createSetting = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post(`admin/settings/create`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const doubleData = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post(`admin/settings/doubleData`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const SettingService = {
  createSetting,
  getSettings,
  updateSetting,
  getSettingsByName,
  doubleData,
};

export default SettingService;
