/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ShipService from "services/ships";
import { TabPanel, TabView } from "primereact/tabview";
import Dark from "./components/Dark";
import Light from "./components/Light";

const View = () => {
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    status: null,
  });
  const [, setLoading] = useState(false);
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await ShipService.search({
        query: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
        },
      });
      if (res && res.docs) {
        setData(res.docs);
        setTotalRecords(res.totalDocs);
      } else setData([]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setData([]);
    }
  };

  //TABLE EVENT

  //CREATE OR NEW

  // DELETE

  //RENDER

  return (
    <div className="card">
      <div className="pb-3">
        <h4>Theme</h4>
        <TabView>
          <TabPanel header="Light theme">
            <Light />
          </TabPanel>
          <TabPanel header="Dark theme">
            <Dark />
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(View, comparisonFn);
