/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import {
  convertObjectToListValue,
  formatLocalTime,
  getUrlImage,
  showToast,
} from "utils/common";
import VDialog from "components/v-dialog";
import VConfirm from "components/v-confirm";
import Details from "./components/Details";
import debounce from "lodash.debounce";
import MenuService from "services/menus";
import { MultiSelect } from "primereact";
import { MENU_ROLE, MENU_STATUS, MENU_TYPE, MENU_TYPE_VIEW } from "utils/enum";
import { TreeTable } from "primereact/treetable";
import CategoryFilterService from "services/category-filters";
import StatusEditorCell from "components/v-edit-cell/StatusEditorCell";
import NumberEditorCell from "components/v-edit-cell/NumberEditorCell";
import { Panel } from "primereact/panel";
import { Dropdown } from "primereact/dropdown";

const Crud = () => {
  const [data, setData] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [detail, setDetail] = useState(null);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    status: MENU_STATUS.ENABLE,
    type_view: null,
    type: null,
    position: null,
    category_filter_id: null,
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });
  // const [menus, setMenus] = useState([]);
  const [treeMenus, setTreeMenus] = useState([]);
  const [parentMenu, setParentMenu] = useState({});

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);
  const refDetail = useRef(null);
  const [categoryFilters, setCategoryFilters] = useState([]);

  useEffect(() => {
    loadData();
    getCategoryFilters();
  }, [lazyParams, globalFilter]);

  useEffect(() => {
    loadTreeMenus();
  }, []);

  useEffect(() => {
    if (data) {
      const mapAll = allData.map((e: any) => ({
        ...e,
        key: e._id != null ? String(e._id) : null,
        keyVal: e.key,
        parent_id: e.parent_id ? e.parent_id : null,
      }));

      const mapList = data.map((e: any) => ({
        ...e,
        key: e._id != null ? String(e._id) : null,
        keyVal: e.key,
        parent_id: e.parent_id ? e.parent_id : null,
      }));
      const tree = mapList.filter((e: any) => e.parent_id == null);
      tree.forEach((el: any) => {
        const items = getItems(mapAll, el._id);
        if (items && items != null && items.length > 0) {
          el.children = items;
          el.leaf = false;
        }
      });

      if (tree.length === 0 && mapList) {
        const treeChild = mapList.map((el: any) => {
          const items = getItems(mapAll, el._id);
          if (items && items != null && items.length > 0) {
            el.children = items;
            el.leaf = false;
          }
          return el;
        });
        setTreeData([...treeChild]);
      } else {
        setTreeData([...tree]);
      }
    } else setTreeData([]);
  }, [data, allData]);

  const getCategoryFilters = async () => {
    try {
      const res: any = await CategoryFilterService.getAll();
      if (res) {
        setCategoryFilters(res);
      }
    } catch (error) {}
  };

  const loadTreeMenus = () => {
    MenuService.getTreeMenu({ params: { role: MENU_ROLE.ADMIN } }).then(
      (res: any) => {
        if (res) {
          setTreeMenus(res);
        }
      }
    );
  };

  const loadData = async () => {
    try {
      const res: any = await MenuService.search({
        query: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
          limit: 99999,
          role: MENU_ROLE.ADMIN,
        },
      });
      if (res && res.docs) {
        setData(res.docs);
        setAllData(res.docs);
      } else {
        setData([]);
        setAllData([]);
      }
    } catch (error) {
      setData([]);
      setAllData([]);
    }
  };

  const getItems = (mapList: any, parent_id: string) => {
    let items = mapList?.filter((e: any) => e.parent_id === parent_id);
    items.forEach((el: any) => {
      const items = getItems(mapList, el._id);
      if (items && items != null && items.length > 0) {
        el.children = items;
        el.leaf = false;
      }
    });
    return items;
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  const onTreeSearch = (keySearch) => {
    let search = keySearch.trim();
    let filterData = allData.filter((e) =>
      e.name.toUpperCase().includes(search.toUpperCase())
    );
    setData(filterData);
  };

  //CREATE OR NEW
  const openNew = () => {
    setDetail(null);
    refDialogDetail.current.show();
  };

  const openNewChild = (data) => {
    setDetail({ parent_id: data?._id });
    setParentMenu({ id: data?._id, name: data?.name });
    refDialogDetail.current.show();
  };

  const editProduct = (data) => {
    setDetail({ ...data, key: data.keyVal });
    refDialogDetail.current.show();
  };
  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };
  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };
  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  // DELETE
  const confirmDelete = (product) => {
    setDetail(product);
    refDialogDelete.current.show();
  };
  const handleDelete = async () => {
    try {
      await MenuService.deleted({
        params: {
          id: detail._id,
        },
      });
      const _data = data.filter((val) => val._id !== detail._id);
      setData(_data);
      refDialogDelete.current.close();
      setDetail(null);
      showToast(toast, "success", "User Deleted");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const onChangeStatus = async (options, rowData, value) => {
    let updateRow;
    let newData = data.map((e, index) => {
      if (e._id === rowData?._id) {
        e[options.field] = value;
        updateRow = e;
      }
      return e;
    });
    setData(newData);

    if (updateRow) {
      await MenuService.update({
        params: { id: updateRow._id },
        body: {
          ...updateRow,
        },
      });
    }
  };

  const onChangeOrder = debounce(onChangeStatus, 700);

  // const orderEditor = (options) => {
  //   return <NumberEditor options={options} onChange={onChangeOrder} />;
  // };

  // const statusEditor = (options) => {
  //   return <StatusEditor options={options} onChange={onChangeStatus} />;
  // };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled={true}
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-plus"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => openNewChild(rowData)}
        />
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editProduct(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning"
          onClick={() => confirmDelete(rowData)}
        />
      </div>
    );
  };

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="grid px-2 align-items-center">
        <div className="col-12 md:col-4">
          <span className="block p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e: any) => onTreeSearch(e.target.value)}
              placeholder="Search..."
              className="w-full"
            />
          </span>{" "}
        </div>

        <div className="col-12 md:col-4">
          <Dropdown
            filter
            showClear
            value={globalFilter.category_filter_id}
            options={categoryFilters.map((item) => ({
              label: item.name,
              value: item._id,
            }))}
            optionLabel="label"
            optionValue="value"
            placeholder="Select filter"
            onChange={(e) => onSearch("category_filter_id", e.value)}
            className="w-full"
          />
        </div>
        {/* <Calendar
          className="m-1"
          id="time24"
          placeholder="Start Date"
          value={globalFilter.start}
          onChange={(e: any) => onSearch("start", e.value)}
          showTime
          showSeconds
          showButtonBar
        />{" "}
        <Calendar
          className="m-1"
          id="time24"
          placeholder="End Date"
          value={globalFilter.end}
          onChange={(e: any) => onSearch("end", e.value)}
          showTime
          showSeconds
          showButtonBar
        /> */}
        <div className="col-12 md:col-4">
          <Dropdown
            value={globalFilter.status}
            options={Object.keys(MENU_STATUS).map((k) => ({
              label: k,
              value: MENU_STATUS[k],
            }))}
            onChange={(e: any) => onSearch("status", e.value)}
            optionLabel="label"
            placeholder="Status"
            className={"w-full"}
            showClear
          />
        </div>
        <div className="col-12 md:col-4">
          <Dropdown
            value={globalFilter.type}
            options={Object.keys(MENU_TYPE).map((k) => ({
              label: k,
              value: MENU_TYPE[k],
            }))}
            onChange={(e: any) => onSearch("type", e.value)}
            optionLabel="label"
            placeholder="Type"
            className={"w-full"}
            showClear
          />
        </div>
        <div className="col-12 md:col-4">
          <Dropdown
            value={globalFilter.type_view}
            options={Object.keys(MENU_TYPE_VIEW).map((k) => ({
              label: k,
              value: MENU_TYPE_VIEW[k],
            }))}
            onChange={(e: any) => onSearch("type_view", e.value)}
            optionLabel="label"
            placeholder="Type view"
            className={"w-full"}
            showClear
          />
        </div>
      </div>
    </Panel>
  );

  const iconBody = (rowData: any) => {
    return (
      <>
        {rowData.img_icon ? (
          <div>
            <img
              src={`${getUrlImage(rowData.img_icon)}`}
              alt={rowData.img_icon}
              style={{ width: "20px" }}
            />
          </div>
        ) : (
          <React.Fragment>
            <i style={{ textAlign: "center" }} className={rowData.icon}>
              &nbsp;
            </i>
          </React.Fragment>
        )}
      </>
    );
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <TreeTable
            ref={dt}
            value={treeData}
            header={header}
            // paginator
            // rowsPerPageOptions={[5, 10, 25]}
            // emptyMessage="No data found."
            lazy
            first={lazyParams.first}
            rows={lazyParams.rows}
            // totalRecords={totalRecords}
            resizableColumns
            columnResizeMode="expand"
            scrollable
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            // currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            onSort={onSort}
          >
            <Column
              // frozen
              // alignFrozen="left"
              body={actionBodyTemplate}
              style={{ flexGrow: 1, width: "16%" }}
            ></Column>
            {/* <Column
              field="position"
              header="Position"
              sortable
              style={{ flexGrow: 1, flexBasis: "200px" }}
            ></Column> */}
            {/* <Column
              field="key"
              header="Key"
              style={{ flexGrow: 1, flexBasis: "150px", position: "relative" }}
            ></Column> */}
            <Column
              expander
              field="name"
              header="Name"
              sortable
              body={(rowData) => <span>{rowData.name}</span>}
              style={{ flexGrow: 1, width: "25%" }}
            ></Column>
            <Column
              // field="<i className=icon>&nbsp;</i>"
              body={iconBody}
              header="Icon"
              style={{ flexGrow: 1, width: "8%" }}
            ></Column>
            <Column
              field="type"
              header="Type"
              body={(rowData) => <span>{rowData.type}</span>}
              style={{ flexGrow: 1, width: "8%" }}
            ></Column>
            {/* <Column
              field="type_view"
              header="Type View"
              body={(rowData) => <span>{rowData.type_view}</span>}
              style={{ flexGrow: 1, width: "8%" }}
            ></Column> */}
            <Column
              // editor={orderEditor}
              field="order"
              sortable
              header="Order"
              body={(rowData, options) => (
                <NumberEditorCell
                  value={rowData.order}
                  options={options}
                  onChange={onChangeOrder}
                />
              )}
              style={{ flexGrow: 1, width: "8%" }}
            ></Column>
            <Column
              // editor={statusEditor}
              field="status"
              header="Status"
              style={{ flexGrow: 1, width: "12%" }}
              // body={(rowData) => (
              //   <span
              //     className={`v-badge status-${rowData.status.toLowerCase()}`}
              //   >
              //     {rowData.status}
              //   </span>
              // )}
              body={(rowData, options) => (
                <StatusEditorCell
                  value={rowData.status}
                  options={options}
                  rowData={rowData}
                  onChange={onChangeStatus}
                />
              )}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "6%" }}
              field="createdAt"
              header="Created At"
              sortable
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>

            <Column
              style={{ flexGrow: 1, flexBasis: "6%" }}
              field="updatedAt"
              header="Updated At"
              sortable
              body={(rowData) => formatLocalTime(rowData.updatedAt)}
            ></Column>
          </TreeTable>

          <VDialog
            ref={refDialogDetail}
            header="Menu Details"
            onSubmit={onSumitDialogDetail}
          >
            <Details
              ref={refDetail}
              data={detail}
              toast={toast}
              treeMenus={treeMenus}
              parentMenu={parentMenu}
              categoryFilters={categoryFilters}
              reload={loadData}
              loadTreeMenus={loadTreeMenus}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
            />
          </VDialog>

          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Crud, comparisonFn);
