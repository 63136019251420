/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import PageBackgroundsService from "services/page-backgrounds";
import Item from "./Item";
import { nanoid } from "nanoid";

import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputNumber, Panel } from "primereact";
import CategoryFilterDetails from "components/v-filter";

const Details = (props, ref) => {
  const {
    data,
    reload,
    toast,
    onCancel,
    setLoading,
    categories,
    categoryFilters,
    getCategoryFilters,
  } = props;

  const emptyData = {
    key: "",
    dark: {
      type: "slide",
      slide: [],
      video: "",
      enable: true,
    },
    light: {
      type: "slide",
      slide: [],
      video: "",
      enable: true,
    },
    ratio: "",
    status: "enable",
    start_date: "",
    end_date: "",
    category_filter_id: null,
    order: 0,
    text_color: "",
    description: null
  };

  const [details, setDetails] = useState(emptyData);
  const refDetailCategory = useRef(null);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) {
      setDetails({
        ...data,
        start_date: data.start_date ? new Date(data.start_date) : new Date(),
        end_date: data.end_date ? new Date(data.end_date) : new Date(),
      });
    }
  }, [data]);

  const handleAddOrRemoveItem = (name, type, id?) => {
    if (type === "add") {
      setDetails((state) => ({
        ...state,
        [name]: {
          ...state[name],
          slide: [...state[name]?.slide, { id: nanoid(), src: "", link: "" }],
        },
      }));
    } else {
      const newState = details[name]?.slide?.filter((i) => i.id !== id);
      setDetails((state) => ({
        ...state,
        [name]: {
          ...state[name],
          slide: newState,
        },
      }));
    }
  };

  const handleChangeItemSlide = (
    value: any,
    id: string,
    name: string,
    key: string
  ) => {
    setDetails((state) => ({
      ...state,
      [name]: {
        ...state[name],
        slide: state[name]?.slide?.map((el) =>
          el.id === id
            ? {
                ...el,
                [key]: value,
              }
            : el
        ),
      },
    }));
  };

  const onChange = (value, name, field = null) => {
    let _details = { ...details };
    if (field) {
      _details[field][`${name}`] = value;
    } else {
      _details[`${name}`] = value;
    }
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      let category = null;
      if (!details.category_filter_id) {
        category = await refDetailCategory.current.submit();
        getCategoryFilters();
      }

      if (data) {
        await PageBackgroundsService.updatePageBackground({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      } else {
        await PageBackgroundsService.createPageBackground({
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Page backgrounds saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12 md:col-6">
        <label htmlFor="status">Filter</label>
        <Dropdown
          filter
          showClear
          value={details.category_filter_id}
          options={categoryFilters.map((item) => ({
            label: item.name,
            value: item._id,
          }))}
          optionLabel="label"
          optionValue="value"
          placeholder="Select filter"
          onChange={(e) => onChange(e.value, "category_filter_id")}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="title">Category</label>
        <Dropdown
          filter
          value={details.key}
          options={categories}
          optionLabel="name"
          optionValue="key"
          onChange={(e) => onChange(e.target.value, "key")}
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="start-date">Start Date</label>
        <Calendar
          id="start-date"
          showIcon
          showTime
          placeholder="Start date"
          value={details.start_date}
          onChange={(e) => onChange(e.value, "start_date")}
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="end-date">End Date</label>
        <Calendar
          id="end-date"
          showIcon
          showTime
          placeholder="End date"
          value={details.end_date}
          onChange={(e) => onChange(e.value, "end_date")}
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="status">Status</label>
        <Dropdown
          defaultValue={details.status}
          value={details.status}
          options={[
            {
              label: "ENABLE",
              value: "enable",
            },
            {
              label: "DISABLE",
              value: "disable",
            },
          ]}
          onChange={(e) => onChange(e.value, "status")}
          optionLabel="label"
          placeholder="Select status"
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="title">Ratio</label>
        <InputText
          id="ratio"
          value={details.ratio}
          onChange={(e) => onChange(e.target.value, "ratio")}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="order">Order</label>
        <InputNumber
          value={details.order}
          onChange={(e) => onChange(e.value, "order")}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="title">Description</label>
        <InputText
          id="description"
          value={details.description}
          onChange={(e) => onChange(e.target.value, "description")}
        />
      </div>
      <div className="field col-6">
        <label htmlFor="name">Text Content Color &nbsp; &nbsp;</label>
        <input
          type="color"
          id="text_color"
          name="text_color"
          value={details.text_color}
          onChange={(e) => onChange(e.target.value, "text_color")}
        />
        <InputText
          id="text_color"
          value={details.text_color}
          onChange={(e) => onChange(e.target.value, "text_color")}
          required
        />
      </div>
      <Item
        label={"Dark"}
        name={"dark"}
        details={details}
        handleAddOrRemoveItem={handleAddOrRemoveItem}
        handleChangeItemSlide={handleChangeItemSlide}
        onChange={onChange}
      />
      <Item
        label={"Light"}
        name={"light"}
        handleAddOrRemoveItem={handleAddOrRemoveItem}
        handleChangeItemSlide={handleChangeItemSlide}
        details={details}
        onChange={onChange}
      />

      {!details.category_filter_id && (
        <div className="field col-12">
          <Panel header="Filter" toggleable className="my-2" collapsed={false}>
            <div className="grid">
              <div className="field col-12">
                <CategoryFilterDetails ref={refDetailCategory} />
              </div>
            </div>
          </Panel>
        </div>
      )}
    </div>
  );
};

export default forwardRef(Details);
