/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import UserService from "services/users";
import { MultiSelect } from "primereact";
import debounce from "lodash.debounce";

const View = ({ value, onChange }) => {
  const defaultUser = [{}];
  const [userSearch, setUsersSearch] = useState([]);

  const getUsers = (search) => {
    UserService.getUsers({
      query: {
        search,
        ids: value?.toString(),
      },
    }).then((res: any) => {
      if (res && res.users && res.users.length > 0) setUsersSearch(res.users);
      else setUsersSearch([defaultUser]);
    });
  };

  useEffect(() => {
    if (value && value?.length) getUsers("");
  }, [value]);

  const onSearch = useCallback(debounce(getUsers, 500), [value]);

  return (
    <MultiSelect
      className="w-full"
      value={value}
      options={userSearch}
      onChange={(e) => onChange(e)}
      optionLabel="email"
      optionValue="_id"
      placeholder="Select a user"
      filter
      filterPlaceholder="Search user"
      showClear
      onFilter={(f) => {
        onSearch(f.filter);
      }}
    />
  );
};

export default View;
