/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useImperativeHandle } from "react";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";

const Import = (props, ref) => {
  const { data, columns, saveData } = props;

  const emptyData = {
    name: { field: "name", header: "Name" },
    key: { field: "key", header: "Key" },
    link: { field: "link", header: "Link" },
    status: { field: "status", header: "Status" },
    order: { field: "order", header: "Order" },
    logo: { field: "logo", header: "Logo" },
  };

  const [mapping, setMapping] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  const onChange = (name, value, index?: number) => {
    let _details = { ...mapping };
    if (name === "content") {
      _details[`${name}`] = _details[`${name}`].map((el, i) =>
        index === i
          ? {
              name: value,
            }
          : el
      );
    } else {
      _details[`${name}`] = value;
    }
    setMapping(_details);
  };

  const submit = () => {
    const mergeData = data?.map((row: any) => {
      let newRow = {};
      columns?.map((e: any) => {
        newRow = {
          ...newRow,
          [e.field]: row[mapping[e.field]?.field] || null,
        };
      });
      return newRow;
    });
    saveData(mergeData);
  };

  const ColumnSelect = (field) => {
    return (
      <Dropdown
        showClear
        defaultValue={mapping?.[`${field}`]}
        value={mapping?.[`${field}`]}
        options={columns}
        onChange={(e) => onChange(field, e.value)}
        optionLabel="header"
        placeholder="Select column"
      />
    );
  };

  return (
    <div className="grid">
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Name</label>
        {ColumnSelect("name")}
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="key">Key</label>
        {ColumnSelect("key")}
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="link">Link</label>
        {ColumnSelect("link")}
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="status">Status</label>
        {ColumnSelect("status")}
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="order">Order</label>
        {ColumnSelect("order")}
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="logo">Logo</label>
        {ColumnSelect("logo")}
      </div>
    </div>
  );
};

export default forwardRef(Import);
