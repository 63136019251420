import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button, Toast } from "primereact";
import SettingService from "services/settings";
import { showToast } from "utils/common";
import ProductService from "services/products";
const StockPrices = () => {
  const toast = useRef(null);
  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    try {
      const res1: any = await ProductService.getProductsDonate({});
      if (res1) {
        const _products = (res1.docs || []).map((i) => ({
          ...i,
          name: `${i.name} - ${i.user.full_name}`,
        }));
        setProducts(_products);
      }
      const res2: any = await SettingService.getSettingsByName({
        params: {
          name: "product_donate",
        },
      });
      if (res2) setState(res2.setting?.value);
    } catch (error) {}
  };

  const handleCancel = async () => {
    try {
      getData();
    } catch (error) {}
  };

  const submitHandler = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      await SettingService.updateSetting({
        body: {
          name: "product_donate",
          value: state || null,
        },
      });
      await getData();
      showToast(toast, "success", "Update dsuccess!");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <Toast ref={toast} />

      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>Product Donate</h4>
          </div>
          <div className="grid">
            <div className="field col-12">
              <Dropdown
                value={state}
                className="w-full"
                options={products}
                onChange={(e) => setState(e.value)}
                optionLabel="name"
                optionValue="_id"
                placeholder="Products"
                showClear
              />
            </div>
          </div>
          <div className="">
            <Button
              label="Cancel"
              className="p-button-danger mr-2"
              loading={loading}
              onClick={handleCancel}
            />
            <Button label="Submit" loading={loading} onClick={submitHandler} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockPrices;
