/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import VDialog from "components/v-dialog";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React from "react";
import { useEffect, useRef, useState } from "react";
import CaptchaService from "services/captcha";
import Detail from "./components/details";

const Captcha = () => {
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState(null);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    type: ["image", "question"],
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 5,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);
  const toast = useRef(null);
  const refDetail = useRef(null);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  const loadData = async () => {
    try {
      const res: any = await CaptchaService.search({
        query: {
          ...lazyParams,
          ...globalFilter,
        },
      });
      if (res) {
        setData(res);
      }
    } catch (error) {
      setData([]);
    }
  };

  const editProduct = (data) => {
    setDetail({ ...data });
    refDialogDetail.current.show();
  };

  const confirmDelete = (product) => {
    setDetail(product);
    refDialogDelete.current.show();
  };

  const openNew = () => {
    setDetail(null);
    refDialogDetail.current.show();
  };

  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };

  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editProduct(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning"
          onClick={() => confirmDelete(rowData)}
        />
      </div>
    );
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            disabled={true}
          />
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
          <DataTable value={data}>
            <Column
              frozen
              alignFrozen="left"
              body={actionBodyTemplate}
              style={{ flexGrow: 1, flexBasis: "110px" }}
            />
            <Column
              field="title"
              header="Title"
              sortable
              style={{ flexGrow: 1, flexBasis: "350px" }}
            />
            <Column
              field="type"
              header="Type"
              sortable
              style={{ flexGrow: 1, flexBasis: "350px" }}
            />
          </DataTable>
          <VDialog
            ref={refDialogDetail}
            header="Details"
            onSubmit={onSumitDialogDetail}
          >
            <Detail
              ref={refDetail}
              data={detail}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
            />
          </VDialog>
        </div>
      </div>
    </div>
  );
};

export default Captcha;
