/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import Service from "services/locations";
import VUpload from "components/v-upload";
import { Checkbox } from "primereact/checkbox";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading, parent } = props;

  const emptyData = {
    name: "",
    keyword: "",
    icon: "",
    logo: "",
    default: false,
    coordinate: {
      lat: "",
      lng: ""
    }
  };

  const [details, setDetails] = useState(emptyData);
  const [parentName, setParentName] = useState(null);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
  }, [data]);

  useEffect(() => {
    if (parent) setParentName(parent.name);
  }, [parent]);

  const onChange = (name, value) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data && data._id) {
        await Service.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else
        await Service.create({
          body: {
            ...details,
          },
        });
      setLoading(false);
      showToast(toast, "success", "File saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-6">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange("name", e.target.value)}
          required
          autoFocus
        />
      </div>
      <div className="field col-6">
        <label htmlFor="parent">Parent Name</label>
        <InputText id="parent" value={parentName} disabled />
      </div>
      <div className="field col-6">
        <label htmlFor="name">Key</label>
        <InputText
          id="name"
          value={details.keyword}
          onChange={(e) => onChange("keyword", e.target.value)}
          required
          autoFocus
        />
      </div>
      <div className="field col-6">
        <label htmlFor="name">Default</label>&ensp;
        <Checkbox
          onChange={(e) => onChange("default", e.checked)}
          checked={details.default}
        ></Checkbox>
      </div>
      <div className="field col-6">
        <label htmlFor="name">Latitude (Lat)</label>
        <InputText
          id="name"
          value={details?.coordinate?.lat}
          onChange={(e) => onChange("coordinate.lat", e.target.value)}
          required
          autoFocus
        />
      </div>
      <div className="field col-6">
        <label htmlFor="name">Longitude (Lng)</label>
        <InputText
          id="name"
          value={details?.coordinate?.lng}
          onChange={(e) => onChange("coordinate.lng", e.target.value)}
          required
          autoFocus
        />
      </div>
      <div className="field col-6">
        <label htmlFor="name">Icon</label>
        <VUpload
          urlFile={details.icon}
          setUrlFile={(file) => onChange("icon", file)}
        />
      </div>
      <div className="field col-6">
        <label htmlFor="name">Logo</label>
        <VUpload
          urlFile={details.logo}
          setUrlFile={(file) => onChange("logo", file)}
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
