/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import { FileUpload } from "primereact/fileupload";
import UploadService from "services/uploads";
import { Button, Image, ProgressBar, Toast } from "primereact";
import { getDateDirectory, getUrlImage, showToast } from "utils/common";

type Props = {
  urlFile?: any;
  setUrlFile?: any;
  setFile?: any;
  label?: string;
  folder?: string;
  fileType?: "image" | "video" | "audio" | ".pdf" | "*";
  subFileType?: string;
  folderPath?: string;
  isShowFile?: boolean;
};

const View = (props: Props) => {
  const {
    urlFile,
    setUrlFile,
    setFile,
    label,
    folder,
    fileType = "image",
    subFileType = "*",
    folderPath = "",
    isShowFile = true,
  } = props;
  const toast = useRef(null);
  // const [isShow, setIsShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const refUpload: any = useRef();

  const myUploader = async (event) => {
    try {
      setLoading(true);
      let res = null;
      const file = event.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("files", file);
        formData.append(
          "folderPath",
          folderPath ||
            "admin/"
              .concat(getDateDirectory())
              .concat(folder ? "/".concat(folder) : "")
        );
        res = await UploadService.uploadFile({ body: formData });
      }
      if (res && res.filePath) {
        if (setFile) setFile(res);
        if (setUrlFile) setUrlFile(res.filePath);
        setLoading(false);
        refUpload.current.clear();
        // setIsShow(false);
      }
    } catch (error) {
      showToast(error);
      setLoading(false);
    }
  };

  const onUpload = (data) => {};

  const chooseOptions = { label: " ", icon: "pi pi-upload" };

  const renderFile = () => {
    switch (fileType) {
      case "image":
        return (
          <Image
            src={getUrlImage(urlFile)}
            width={"100"}
            height="100"
            preview
          />
        );
      case ".pdf":
        return <p>{urlFile}</p>;
      case "video":
        return <video src={getUrlImage(urlFile)} width={200} height={200} />;
      default:
        return null;
    }
  };

  return (
    <div className="">
      <Toast ref={toast} />
      {label && <div className="text-center mb-2">{label}</div>}
      <div className="flex flex-column align-items-center">
        {isShowFile && renderFile()}
        <div className="flex align-items-center">
          <div>
            <FileUpload
              ref={refUpload}
              chooseOptions={chooseOptions}
              customUpload={true}
              progressBarTemplate={
                loading && <ProgressBar mode="indeterminate" />
              }
              mode={"basic"}
              uploadHandler={myUploader}
              onUpload={onUpload}
              multiple={false}
              auto={true}
              // accept="image/*"
              accept={
                fileType === ".pdf" ? fileType : `${fileType}/${subFileType}`
              }
              maxFileSize={500000000}
              emptyTemplate={
                <p className="m-0">Drag and drop files to here to upload.</p>
              }
            />
          </div>
          <Button
            icon="pi pi-times"
            className="p-button-sm p-button-danger"
            aria-label="Delete"
            style={{
              height: "38px",
              marginLeft: "3px",
            }}
            onClick={(_) => {
              if (setUrlFile) setUrlFile(null);
              if (setFile) setFile({});
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default View;
