/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import AllotmentService from "services/allotments";
import debounce from "lodash.debounce";
import { Calendar, FileUpload, Toolbar } from "primereact";
import VDialog from "components/v-dialog";
import AllotmentPersonal from "./AllotmentPersonal";
import { formatLocalTime, formatNumber } from "utils/common";
import { renderAmount } from "utils/render";
import VStockDropdown from "components/v-stock";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";

const Tables = () => {
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [detail, setDetail] = useState(null);
  const [sum, setSum] = useState([]);
  const toast = useRef(null);

  const refDetail = useRef(null);
  const refDialogDetail = useRef(null);
  const dt = useRef(null);

  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    stock: null,
  });

  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };

  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSearch = useCallback(debounce(onFilter, 500), []);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  const loadData = async () => {
    try {
      const res: any = await AllotmentService.search({
        query: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
        },
      });
      if (res) {
        setData(res.content);
        setSum(res.sum);
        setTotalRecords(res.total);
      } else setData([]);
    } catch (error) {
      setData([]);
    }
  };

  const openNew = () => {
    setDetail(null);
    refDialogDetail.current.show();
  };

  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };
  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };
  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="grid px-2 align-items-center">
        <div className="col-12 md:col-6">
          <div className="block  p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e: any) => onSearch("search", e.target.value)}
              placeholder="Search..."
              className="w-full"
            />
          </div>
        </div>
        <div className="col-12 md:col-6">
          <VStockDropdown
            className="w-full"
            value={globalFilter.stock}
            setValue={(v) => onSearch("stock", v)}
          />
        </div>
        <div className="col-12 md:col-6">
          <Calendar
            className="w-full"
            id="time24"
            placeholder="Start Date"
            value={globalFilter.start}
            onChange={(e: any) => onSearch("start", e.value)}
            showTime
            showSeconds
            showButtonBar
          />{" "}
        </div>
        <div className="col-12 md:col-6">
          <Calendar
            className="w-full"
            id="time24"
            placeholder="End Date"
            value={globalFilter.end}
            onChange={(e: any) => onSearch("end", e.value)}
            showTime
            showSeconds
            showButtonBar
          />
        </div>
      </div>
    </Panel>
  );

  const footer = (
    <div>
      {sum.map((s) => {
        return (
          <div>
            <span>
              {" "}
              {s._id} : {formatNumber(Math.abs(s.totalAmount))}
            </span>
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="grid">
      <div className="col-12 ">
        <Toast ref={toast} />
        <div className="">
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={data}
            paginator
            header={header}
            footer={footer}
            dataKey="_id"
            emptyMessage="No data found."
            showGridlines
            lazy
            responsiveLayout="scroll"
            // paginator
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 20, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            //sort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onSort}
          >
            <Column field="to" header="To"></Column>
            <Column
              field="email"
              header="Email"
              body={(rowData) => <span>{rowData.user?.email || ""}</span>}
            ></Column>
            <Column field="token" header="Token"></Column>
            <Column
              field="amount"
              header="Amount"
              sortable
              body={(rowData) => renderAmount(rowData.amount)}
            ></Column>
            <Column
              field="createdAt"
              header="Created At"
              sortable
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>
            <Column
              frozen
              alignFrozen="right"
              header="Status"
              style={{ flexGrow: 1, flexBasis: "150px" }}
              body={(rowData) => (
                <span className={`v-badge status-success`}>success</span>
              )}
            ></Column>
          </DataTable>

          <VDialog
            ref={refDialogDetail}
            header="Allotment Personal"
            onSubmit={onSumitDialogDetail}
          >
            <AllotmentPersonal
              ref={refDetail}
              data={detail}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
            />
          </VDialog>
        </div>
      </div>
    </div>
  );
};

export default Tables;
