import VUpload from "components/v-upload";
import { Button, InputText } from "primereact";
import { v4 as uuidv4 } from "uuid";

const View = (props) => {
  const { slide, setSlide } = props;

  const defaultValue = {
    id: uuidv4(),
    src: "",
    link: "",
  };

  const handleAdd = () => {
    const _slide = slide.concat(defaultValue);
    setSlide(_slide);
  };

  const handleSub = () => {
    const _slide = slide.slice(0, slide.length - 1);
    setSlide(_slide);
  };

  const handleUpload = (file: any, id: string) => {
    const _slide = slide.map((el) => {
      if (el.id === id) return { ...el, src: file };
      else return { ...el };
    });
    setSlide(_slide);
  };

  const handleChangeLink = (value: string, id: string) => {
    const _slide = slide.map((el) => {
      if (el.id === id) return { ...el, link: value };
      else return { ...el };
    });
    setSlide(_slide);
  };

  return (
    <div className="field col-12 grid">
      <div className="col-12">
        <div className="field col-12">
          <h5>Images</h5>
          <Button
            icon="bx bxs-add-to-queue"
            className="p-button-rounded p-button-success"
            aria-label="Search"
            onClick={(_) => handleAdd()}
          />
          <Button
            icon="bx bxs-minus-circle"
            className="p-button-rounded p-button-danger"
            aria-label="Cancel"
            onClick={(_) => handleSub()}
          />
        </div>
        <div className="field col-12 grid">
          {slide.length > 0 &&
            slide.map((el) => (
              <div key={el.id} className="field col-4 flex flex-column">
                <VUpload
                  urlFile={el.url || el.src}
                  setUrlFile={(file) => handleUpload(file, el.id)}
                />
                <InputText
                  value={el.link}
                  placeholder="Link..."
                  onChange={(e) => handleChangeLink(e.target.value, el.id)}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default View;
