/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";

import Settings from "services/settings";
import Supports from "services/supports";
import { formatLocalTime, showToast } from "utils/common";
import VDialog from "components/v-dialog";
import VConfirm from "components/v-confirm";
import Details from "./components/Details";
import { useParams } from "react-router-dom";

const Crud = () => {
  let params: any = useParams();
  const [data, setData] = useState([]);
  const [listSupports, setListSupports] = useState([]);
  const [detail, setDetail] = useState(null);
  //   const [globalFilter, setGlobalFilter] = useState({
  //     search: null,
  //     start: null,
  //     end: null,
  //   });
  const [loading, setLoading] = useState(false);

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);
  const refDetail = useRef(null);

  useEffect(() => {
    loadData();
  }, [params?.type]);

  const handleDelete = async () => {
    try {
      const newSetting = data.filter((el) => el.id !== detail.id);
      await Settings.updateSetting({
        body: {
          name: "supports",
          value: newSetting,
        },
      }).then((data) => {
        showToast(toast, "success", "Support Deleted");
      });
      setData(newSetting);
      refDialogDelete.current.close();
      setDetail(null);
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const loadData = async () => {
    try {
      setLoading(true);
      const list: any = await Supports.getSupports(params);
      setListSupports(list.support);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  //CREATE OR NEW
  const editProduct = (data) => {
    setDetail({ ...data });
    refDialogDetail.current.show();
  };
  const onCancleDialogDetail = () => {
    refDialogDetail.current.close();
  };
  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };
  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  const actionBodyTemplateList = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-sm p-button-success mr-1"
          onClick={() => editProduct(rowData)}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Supports</h5>
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          {/* <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar> */}
          <DataTable
            ref={dt}
            value={listSupports}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            // currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
            emptyMessage="No data found."
            header={header}
            showGridlines
            scrollable
            size="small"
            scrollDirection="both"
            loading={loading}
          >
            <Column
              frozen
              alignFrozen="left"
              body={actionBodyTemplateList}
              style={{ flexGrow: 1, flexBasis: "70px" }}
            />
            <Column
              field="email"
              header="Email"
              sortable
              style={{ flexGrow: 1, flexBasis: "300px" }}
              body={(rowData) => (
                <span>{rowData?.userId?.email || rowData?.userId?.email}</span>
              )}
            />
            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="title"
              header="Title"
              body={(rowData) => <span>{rowData?.qa_id?.name}</span>}
            />
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="status"
              header="Status"
              sortable
              body={(rowData) => (
                <span
                  className={`v-badge status-${rowData.status.toLowerCase()}`}
                >
                  {rowData.status}
                </span>
              )}
            />
            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="createdAt"
              header="Created At"
              sortable
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>
          </DataTable>

          <VDialog
            ref={refDialogDetail}
            header="Support Details"
            onSubmit={onSumitDialogDetail}
          >
            <Details
              ref={refDetail}
              data={detail}
              setting={data}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
              onCancle={onCancleDialogDetail}
            />
          </VDialog>
          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Crud, comparisonFn);
