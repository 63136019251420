import { parseErrorResponse, request } from "./request";
import { ACTIVITY_OBJECT, USER_ACTION } from "utils/enum";
import qs from "query-string";

const create = (body: {
  object_id: string;
  object_type: string;
  action: USER_ACTION;
  count?: number;
  type?: string;
  video_played_time?: number;
  action_id?: string;
  action_ref?: ACTIVITY_OBJECT;
}) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/user-analytics`, body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getMy = (query?: {
  page?: number;
  limit?: number;
  createdAt?: any;
  action?: USER_ACTION;
}) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/user-analytics/my?${qs.stringify(query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const search = (query?: any) =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/user-analytics/search?${qs.stringify(query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const viewPost = (id: string) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/user-analytics/view-post/${id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const UserAnalyticService = {
  create,
  getMy,
  viewPost,
  search,
};

export default UserAnalyticService;
