/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import Service from "services/form-templates";
import FormAdditional from "components/v-form-additional";
import SettingService from "services/settings";
import { FORM_TEMPLATE_KEY } from "utils/constant";
import CareerService from "services/careers";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    name: "",
    key: "",
    type: "",
    fields: [],
    object_type: "",
    option_id: "",
    attachments: {
      enable: true,
      guide: null,
    },
  };

  const [details, setDetails] = useState(emptyData);
  const [objects, setObjects] = useState([]);
  const [options, setOptiops] = useState([]);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data)
      setDetails({
        ...data,
        fields: [...data?.fields?.sort((a, b) => a.order - b.order)],
      });
  }, [data]);

  const onInputChange = (e, name, index?) => {
    if (
      name === "name" ||
      name === "key" ||
      name === "type" ||
      name === "object_type" ||
      name === "option_id"
    ) {
      const val = e?.value || (e.target && e.target.value) || "";
      let _details = { ...details };
      _details[`${name}`] = val;
      setDetails(_details);
    } else {
      setDetails({
        ...details,
        fields: details.fields.map((el: any, idx: number) =>
          idx === index ? { ...el, [name]: e.checked || e.target.value } : el
        ),
      });
    }
  };

  const getDataObject = async () => {
    const res: any = await SettingService.getSettingsByName({
      params: {
        name: "objects",
      },
    });
    if (res && res.setting) {
      setObjects(res.setting.value);
    }
  };

  const getDataOptions = async () => {
    const res: any = await CareerService.getAll();
    if (res) {
      setOptiops(res);
    }
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await Service.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else
        await Service.create({
          body: {
            ...details,
          },
        });
      setLoading(false);
      showToast(toast, "success", "File saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  useEffect(() => {
    getDataObject();
    getDataOptions();
  }, []);

  return (
    <div>
      <div className="grid">
        <div className="field col-12 md:col-6">
          <label htmlFor="key">Select Type</label>
          <Dropdown
            value={details.type}
            onChange={(e) => onInputChange(e, "type")}
            options={FORM_TEMPLATE_KEY.map((el) => ({
              value: el.value,
              name: el.name,
            }))}
            optionLabel="name"
            placeholder="Select Type"
          />
        </div>
        {(details.type === "kyc" ||
          details.type === "verify" ||
          details.type === "profile_setting") && (
          <div className="field col-12 md:col-6">
            <label htmlFor="key">Select Object</label>
            <Dropdown
              value={details.object_type}
              onChange={(e) => onInputChange(e, "object_type")}
              options={objects?.map((el) => ({
                value: el.key,
                name: el.key,
              }))}
              optionLabel="name"
              placeholder="Select Type"
            />
          </div>
        )}
        {details.type === "career" && (
          <div className="field col-12 md:col-6">
            <label htmlFor="key">Select Career</label>
            <Dropdown
              value={details.option_id}
              onChange={(e) => onInputChange(e, "option_id")}
              filter
              options={[
                {
                  value: "default",
                  name: "Default",
                },
                ...options?.map((el) => ({
                  value: el._id,
                  name: el.name,
                })),
              ]}
              optionLabel="name"
              placeholder="Select Type"
            />
          </div>
        )}
      </div>
      <FormAdditional dataForm={details} setDataForm={setDetails} />
    </div>
  );
};

export default forwardRef(Details);
