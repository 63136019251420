import { MasterContext } from "contexts/MasterContext";
import { MultiSelect } from "primereact";
import { useContext } from "react";

const View = ({ value, setValue }) => {
  const { stocks } = useContext(MasterContext);

  return (
    <MultiSelect
      value={value}
      options={stocks}
      onChange={(e: any) => setValue(e.value)}
      optionLabel="fullName"
      optionValue="symbol"
      placeholder="Stocks"
      maxSelectedLabels={3}
      className={"w-full m-1"}
    />
  );
};

export default View;
