/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { showToast } from "utils/common";
import VDialog from "components/v-dialog";
import VConfirm from "components/v-confirm";
import Service from "services/locations";
import Detail from "./components/Detail";
import { TreeTable } from "primereact/treetable";
import { LOCATION_TYPES } from "utils/enum";
import { Dropdown } from "primereact/dropdown";
import { Panel } from "primereact/panel";

const Crud = () => {
  const [data, setData] = useState([]);
  const [countries, setCountries] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [detail, setDetail] = useState(null);
  const [parent, setParent] = useState(null);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    country: null,
    province: null,
    district: null,
    ward: null,
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 100,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });

  const [treeFilter, setTreeFilter] = useState({
    search: null,
    country: null,
    province: null,
    district: null,
    ward: null,
  });

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);
  const refDetail = useRef(null);

  useEffect(() => {
    loadTree();
    getCountries();
  }, []);

  useEffect(() => {
    loadTree();
  }, [treeFilter]);

  useEffect(() => {
    let convertFilter: any = {};
    if (globalFilter.country) {
      convertFilter.id = globalFilter.country;
    }
    if (globalFilter.country && globalFilter.province) {
      convertFilter.id = globalFilter.province;
    }
    if (
      globalFilter.country &&
      globalFilter.province &&
      globalFilter.district
    ) {
      convertFilter.id = globalFilter.district;
    }
    if (
      globalFilter.country &&
      globalFilter.province &&
      globalFilter.district &&
      globalFilter.ward
    ) {
      convertFilter.id = globalFilter.ward;
    }
    convertFilter.search = null;
    setTreeFilter(convertFilter);
  }, [globalFilter]);

  const loadTree = async () => {
    try {
      const res: any = await Service.search({
        query: {
          ...lazyParams,
          ...treeFilter,
          page: lazyParams.page + 1,
          limit: lazyParams.rows,
        },
      });

      if (res) {
        if (res.length) {
          let mapRes = res.map((e) => {
            if (e.type !== LOCATION_TYPES.WARD) {
              e.leaf = false;
            } else {
              e.leaf = true;
            }
            e.key = e._id;
            return e;
          });
          setData(mapRes);
        } else {
          if (res.type !== LOCATION_TYPES.WARD) {
            res.leaf = false;
          } else {
            res.leaf = true;
          }
          res.key = res._id;
          setData([{ ...res }]);
        }
      } else setData([]);
    } catch (error) {
      setData([]);
    }
  };

  const getByParent = async (parent, parentName) => {
    try {
      const res: any = await Service.getByTypeAndParent({
        query: {
          parent: parent,
          page: lazyParams.page + 1,
          limit: lazyParams.rows,
        },
      });

      if (res) {
        let mapRes = res.map((e) => {
          if (e.type !== LOCATION_TYPES.WARD) {
            e.leaf = false;
          } else {
            e.leaf = true;
          }
          e.key = e._id;
          e.parentName = parentName;
          return e;
        });
        return mapRes;
      }
    } catch (error) {
      return [];
    }
  };

  const getCountries = async () => {
    try {
      const res: any = await Service.getByTypeAndParent({
        query: {
          type: LOCATION_TYPES.COUNTRY,
        },
      });
      if (res) {
        setCountries(res);
      } else setCountries([]);
    } catch (error) {
      setCountries([]);
    }
  };

  const onChangeFilter = async (e, type) => {
    if (type === LOCATION_TYPES.COUNTRY) {
      const childData = await getByParent(e.target.value, "");
      setGlobalFilter({
        ...globalFilter,
        country: e.target.value,
        province: null,
        district: null,
        ward: null,
      });
      setProvinces(childData);
      setDistricts([]);
      setWards([]);
    }
    if (type === LOCATION_TYPES.PROVINCE) {
      const childData = await getByParent(e.target.value, "");
      setGlobalFilter({
        ...globalFilter,
        province: e.target.value,
        district: null,
        ward: null,
      });
      setDistricts(childData);
      setWards([]);
    }
    if (type === LOCATION_TYPES.DISTRICT) {
      const childData = await getByParent(e.target.value, "");
      setGlobalFilter({
        ...globalFilter,
        district: e.target.value,
        ward: null,
      });
      setWards(childData);
    }
    if (type === LOCATION_TYPES.WARD) {
      setGlobalFilter({ ...globalFilter, ward: e.target.value });
    }
  };

  const onExpand = async (event) => {
    let lazyNode = { ...event.node };

    lazyNode.children = await getByParent(event.node._id, event.node.name);

    let _nodes = data.map((e) => {
      if (e.key === event.node.key) {
        e = lazyNode;
      } else {
        e.children = e.children ? buildNode(e.children, lazyNode) : null;
      }
      return e;
    });

    setData(_nodes);
  };

  const buildNode = (children, lazyNode) => {
    let _nodes = children.map((chil) => {
      if (chil.key === lazyNode.key) {
        chil = lazyNode;
        return chil;
      }
      if (chil.children && chil.children.length > 0) {
        chil.children = buildNode(chil.children, lazyNode);
      }
      return chil;
    });

    return _nodes;
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };

  //CREATE OR NEW
  const openNew = () => {
    setDetail(null);
    refDialogDetail.current.show();
  };

  const addChild = (data) => {
    let childData = { parent: data._id, type: getChildType(data.type, true) };
    setDetail({ ...childData });
    setParent({ id: data._id, name: data.name });
    refDialogDetail.current.show();
  };

  const getChildType = (type, isChild) => {
    if (LOCATION_TYPES.COUNTRY === type) {
      return isChild ? LOCATION_TYPES.PROVINCE : null;
    }
    if (LOCATION_TYPES.PROVINCE === type) {
      return isChild ? LOCATION_TYPES.DISTRICT : LOCATION_TYPES.COUNTRY;
    }
    if (LOCATION_TYPES.DISTRICT === type) {
      return isChild ? LOCATION_TYPES.WARD : LOCATION_TYPES.PROVINCE;
    }
    if (LOCATION_TYPES.WARD === type) {
      return isChild ? null : LOCATION_TYPES.DISTRICT;
    }
    return null;
  };

  const getParentName = (id, childType) => {
    let type = getChildType(childType, false);
    let checkList =
      type === LOCATION_TYPES.COUNTRY
        ? countries
        : type === LOCATION_TYPES.PROVINCE
        ? provinces
        : type === LOCATION_TYPES.DISTRICT
        ? districts
        : wards;
    return checkList.find((e) => e._id === id)?.name;
  };

  const editLocation = (data) => {
    setDetail({ ...data });
    setParent({ name: getParentName(data.parent, data.type) });
    refDialogDetail.current.show();
  };

  const onCancelDialogDetail = () => {
    setParent(null);
    refDialogDetail.current.close();
  };

  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };

  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  // DELETE
  const confirmDelete = (product) => {
    setDetail(product);
    refDialogDelete.current.show();
  };
  const handleDelete = async () => {
    try {
      await Service.deleted({
        params: {
          id: detail._id,
        },
      });
      refDialogDelete.current.close();
      setDetail(null);
      loadTree();
      showToast(toast, "success", "Location Deleted");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
            disabled
          />

          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled={true}
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        {!rowData.leaf ? (
          <Button
            icon="pi pi-plus"
            className="p-button-rounded p-button-sm p-button-success mr-2"
            onClick={() => addChild(rowData)}
          />
        ) : (
          <></>
        )}

        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editLocation(rowData)}
        />

        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning"
          onClick={() => confirmDelete(rowData)}
        />
      </div>
    );
  };

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="grid px-2 align-items-center">
        <div className="col-12 md:col-6">
          <Dropdown
            value={globalFilter.country}
            options={countries}
            // onChange={(e) => onSearch("name", e.target.value)}
            onChange={(e) => onChangeFilter(e, LOCATION_TYPES.COUNTRY)}
            optionLabel="name"
            filterBy="name"
            optionValue="_id"
            filter
            placeholder="Select country"
            className="w-full"
          />
        </div>
        <div className="col-12 md:col-6">
          <Dropdown
            value={globalFilter.province}
            options={provinces}
            onChange={(e) => onChangeFilter(e, LOCATION_TYPES.PROVINCE)}
            optionLabel="name"
            filterBy="name"
            optionValue="_id"
            filter
            placeholder="Select province"
            className="w-full"
          />
        </div>
        <div className="col-12 md:col-6">
          <Dropdown
            value={globalFilter.district}
            options={districts}
            onChange={(e) => onChangeFilter(e, LOCATION_TYPES.DISTRICT)}
            optionLabel="name"
            filterBy="name"
            optionValue="_id"
            filter
            placeholder="Select district"
            className="w-full"
          />
        </div>
        <div className="col-12 md:col-6">
          <Dropdown
            value={globalFilter.ward}
            options={wards}
            onChange={(e) => onChangeFilter(e, LOCATION_TYPES.WARD)}
            optionLabel="name"
            filterBy="name"
            optionValue="_id"
            filter
            placeholder="Select ward"
            className="w-full"
          />
        </div>
      </div>
    </Panel>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <TreeTable
            ref={dt}
            value={data}
            header={header}
            onExpand={onExpand}
            // paginator
            rowsPerPageOptions={[5, 10, 25]}
            emptyMessage="No data found."
            lazy
            first={lazyParams.first}
            rows={lazyParams.rows}
            // totalRecords={totalRecords}
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            onSort={onSort}
          >
            <Column
              field="name"
              header="Name"
              style={{ flexGrow: 1, flexBasis: "250px" }}
              expander
              body={(rowData) => <span>{rowData.name}</span>}
            ></Column>
            <Column
              frozen
              body={actionBodyTemplate}
              style={{ flexGrow: 1, flexBasis: "150px" }}
            ></Column>
          </TreeTable>

          <VDialog
            ref={refDialogDetail}
            header="Detail"
            onSubmit={onSumitDialogDetail}
          >
            <Detail
              ref={refDetail}
              data={detail}
              parent={parent}
              list={data}
              toast={toast}
              reload={loadTree}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
            />
          </VDialog>

          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Crud, comparisonFn);
