/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { formatLocalTime, getUrlImage } from "utils/common";
import debounce from "lodash.debounce";
import { USER_ACTION, ACTIVITY_OBJECT, OBJECT_TYPE } from "utils/enum";
import { Dropdown } from "primereact/dropdown";
import { Panel } from "primereact/panel";
import { MultiSelect } from "primereact/multiselect";
import UserAnalyticService from "services/user-analytics";
import VLocation from "components/v-location";
import CareerService from "services/careers";
import NationService from "services/nations";
import ReligionService from "services/religions";

const Activities = () => {
  // const _date = getStartAndEndDate(7);

  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    object_type: null,
    action: "",
    career_id: "",
    nation_id: "",
    religion_id: "",
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });
  const [locationFilter, setLocationFilter] = useState({
    country: null,
    province: null,
    district: null,
    ward: null,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [careers, setCareers] = useState([]);
  const [nations, setNations] = useState([]);
  const [religions, setReligions] = useState([]);

  const toast = useRef(null);
  const dt = useRef(null);

  const getCareers = () => {
    CareerService.getAll().then((res: any) => {
      setCareers(res || []);
    });
  };

  const getNations = () => {
    NationService.getAll().then((res: any) => {
      setNations(res || []);
    });
  };

  const getReligions = () => {
    ReligionService.getAll().then((res: any) => {
      setReligions(res || []);
    });
  };

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter, locationFilter]);

  const loadData = async () => {
    try {
      const res: any = await UserAnalyticService.search({
        ...globalFilter,
        ...locationFilter,
        // ...lazyParams,
        limit: lazyParams.rows,
        page: lazyParams.page + 1,
      });
      if (res && res.docs) {
        setData(res.docs);
        setTotalRecords(res.totalDocs);
      } else setData([]);
    } catch (error) {
      setData([]);
    }
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };

  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };

  const onFilter = (name, value) => {
    if (name === "location") {
      setLocationFilter(value);
    } else {
      setGlobalFilter((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const onSearch = useCallback(debounce(onFilter, 500), []);

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="grid px-2">
        <div className="col-12 md:col-6">
          <div className="block px-0 p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e: any) => onSearch("search", e.target.value)}
              placeholder="User name, email..."
              className="w-full"
            />
          </div>
        </div>

        <div className="col-12 md:col-3">
          <MultiSelect
            value={globalFilter.action}
            options={Object.keys(USER_ACTION)
              .filter((key) => isNaN(Number(key)))
              .map((k) => ({
                label: k,
                value: USER_ACTION[k],
              }))}
            onChange={(e: any) => onSearch("action", e.value)}
            optionLabel="label"
            placeholder="Action"
            className="w-full"
            showClear
          />
        </div>
        <div className="col-12 md:col-3">
          <Dropdown
            value={globalFilter.object_type}
            options={Object.keys(ACTIVITY_OBJECT)
              .filter((key) => isNaN(Number(key)))
              .map((k) => ({
                label: k,
                value: k,
              }))}
            onChange={(e: any) => {
              onSearch("object_type", e.value);
            }}
            optionLabel="label"
            placeholder="Object type"
            className="w-full"
            showClear
          />
        </div>

        <div className="col-12 md:col-3">
          <Dropdown
            value={globalFilter.career_id}
            options={careers?.map((career) => ({
              label: career?.name,
              value: career?._id,
              icon: getUrlImage(career?.logo),
            }))}
            onChange={(e: any) => {
              onSearch("career_id", e.value);
            }}
            optionLabel="label"
            placeholder="Career"
            className="w-full"
            showClear
          />
        </div>
        <div className="col-12 md:col-3">
          <Dropdown
            value={globalFilter.nation_id}
            options={nations?.map((nation) => ({
              label: nation?.name,
              value: nation?._id,
              icon: getUrlImage(nation?.logo),
            }))}
            onChange={(e: any) => {
              onSearch("nation_id", e.value);
            }}
            optionLabel="label"
            placeholder="Nation"
            className="w-full"
            showClear
          />
        </div>
        <div className="col-12 md:col-3">
          <Dropdown
            value={globalFilter.religion_id}
            options={religions?.map((religion) => ({
              label: religion?.name,
              value: religion?._id,
              icon: getUrlImage(religion?.logo),
            }))}
            onChange={(e: any) => {
              onSearch("religion_id", e.value);
            }}
            optionLabel="label"
            placeholder="Religion"
            className="w-full"
            showClear
          />
        </div>
        <div className=" col-12 md:col-3">
          <Dropdown
            value={globalFilter.object_type}
            options={Object.keys(OBJECT_TYPE).map((k) => ({
              label: k,
              value: OBJECT_TYPE[k],
            }))}
            onChange={(e: any) => onSearch("object_type", e.value)}
            optionLabel="label"
            placeholder="Object"
            className="w-full"
            showClear
          />
        </div>

        <div className="col-12">
          <VLocation
            address={locationFilter}
            setAddress={(a) => onSearch("location", a)}
            viewInLine={false}
          />
        </div>
        {/* <div className="col-12 md:col-6">
          <Calendar
            className="w-full"
            id="time24"
            placeholder="Start Date"
            value={globalFilter.end}
            onChange={(e: any) => onSearch("start", e.value)}
            showTime
            showSeconds
            showButtonBar
          />
        </div>
        <div className="col-12 md:col-6">
          <Calendar
            className="w-full"
            id="time24"
            placeholder="End Date"
            value={globalFilter.start}
            onChange={(e: any) => onSearch("start", e.value)}
            showTime
            showSeconds
            showButtonBar
          />
        </div> */}
      </div>
    </Panel>
  );

  const getActivityObjectName = (index: number, obj: any): string => {
    const keys = Object.keys(obj).filter((key) => isNaN(Number(key)));
    if (index < 0 || index >= keys.length) return "";
    return keys[index];
  };

  useEffect(() => {
    getCareers();
    getNations();
    getReligions();
  }, []);

  return (
    <div className="grid View-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          {/* <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar> */}
          <DataTable
            ref={dt}
            value={data}
            header={header}
            emptyMessage="No data found."
            dataKey="id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            paginator
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 20, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onSort}
          >
            <Column
              field="user"
              header="User"
              style={{ flexGrow: 1, flexBasis: "300px", height: 55 }}
              body={(rowData) => rowData?.user?.email}
            ></Column>
            <Column
              field="action"
              header="Action"
              style={{ flexGrow: 1, flexBasis: "150px" }}
              body={(rowData) =>
                getActivityObjectName(rowData?.action, USER_ACTION)
              }
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "300px" }}
              field="object_type"
              header="Object type"
            ></Column>
            <Column
              field="Object user"
              header="Object user"
              style={{ flexGrow: 1, flexBasis: "300px" }}
              body={(rowData) => rowData?.object_user?.email}
            ></Column>
            <Column
              field="Object post"
              header="Object post"
              style={{ flexGrow: 1, flexBasis: "300px" }}
              body={(rowData) => rowData?.object_post?.post_title}
            ></Column>
            <Column
              field="count"
              header="Count"
              style={{ flexGrow: 1, flexBasis: "100px" }}
              body={(rowData) => rowData?.count || 1}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="createdAt"
              header="Created At"
              sortable
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Activities, comparisonFn);
