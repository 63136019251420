import React, { useEffect, useRef, useState } from "react";
import { Button, Toast, Card } from "primereact";
import SettingService from "services/settings";
import { showToast } from "utils/common";
import UploadService from "services/uploads";

const View = () => {
  const toast = useRef(null);
  const [data, setData] = useState<any>({});
  const [filePdf, setFilePdf] = useState(null);
  const [previewUrl, setPreviewUrl] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const handleCancel = async () => {
    try {
      getData();
    } catch (error) {}
  };

  const handleLogoChange = (e: any) => {
    e.preventDefault();
    const files: any = Array.from(e.target.files);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewUrl(reader.result);
      setFilePdf(files[0]);
    };
    reader.readAsDataURL(files[0]);
  };

  const getData = async () => {
    try {
      const res: any = await SettingService.getSettingsByName({
        params: {
          name: "landing_page",
        },
      });
      if (res) {
        const { setting } = res;
        if (setting.value.whitepaper) {
          setData(res.setting.value);
        }
      }
    } catch (error) {}
  };

  const submitHandler = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      let urlPdf;
      if (filePdf) {
        const formData = new FormData();
        formData.append("files", filePdf);
        const res: any = await UploadService.uploadFile({ body: formData });
        setPreviewUrl(res.filePath);
        urlPdf = res.filePath;
      }
      await SettingService.updateSetting({
        body: {
          name: "landing_page",
          value: {
            ...data,
            whitepaper: {
              pdf: urlPdf || data?.whitepaper?.pdf,
            },
          },
        },
      });
      await getData();
      showToast(toast, "success", "Update dsuccess!");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="grid">
      <Toast ref={toast} />

      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>Whitepaper</h4>
          </div>
          <div className="grid">
            <div className="col-12 card-image-wrapper">
              <Card className="card-pdf">
                <iframe src={previewUrl ? previewUrl : ""} title="pdf"></iframe>
                <div className="card-cover-action">
                  <input
                    accept="application/pdf, application/vnd.ms-excel"
                    id="change-logo"
                    name="change-logo"
                    multiple
                    type="file"
                    onChange={handleLogoChange}
                  />
                  <label htmlFor="change-logo">
                    <div>PDF</div>
                    {/* <Button icon="bx bx-cloud-upload">Pdf</Button> */}
                  </label>
                </div>
              </Card>
            </div>
          </div>
          <Button
            label="Cancel"
            className="p-button-danger mr-2"
            loading={loading}
            onClick={handleCancel}
          />
          <Button label="Submit" loading={loading} onClick={submitHandler} />
        </div>
      </div>
    </div>
  );
};

export default View;
