/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useImperativeHandle, useContext } from "react";
import { getUrlImage, showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { SHOP_STATUS } from "utils/enum";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ShopService from "services/shops";
import { Image } from "primereact/image";
import { MasterContext } from "contexts/MasterContext";
import { Chip } from "primereact/chip";
import { Splitter, SplitterPanel } from "primereact/splitter";
import VEditor from "components/v-editor";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";

const emptyData = null;

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;
  const [details, setDetails] = useState(emptyData);
  const [pendingApproved, setPendingApproved] = useState(emptyData);
  const { productCategories } = useContext(MasterContext);
  const [histories, setHistories] = useState([]);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data)
      setDetails({
        ...data,
      });
    getHistories();
    getBranches();
  }, [data]);

  const getBranches = async () => {
    const res: any = await ShopService.getBranches({
      params: {
        userId: data?.user_id,
      },
    });
    if (res) {
      setDetails({
        ...data,
        branches: res,
      });
    }
  };

  useEffect(() => {
    if (histories) setPendingApproved(histories[0]);
  }, [histories]);

  const getHistories = async () => {
    try {
      const res: any = await ShopService.getHistories({
        params: { id: data?.user_id },
      });
      if (res) {
        setHistories(res?.splice(1));
      }
    } catch (error) {}
  };

  const arraysEqual = (a1, a2) => {
    return JSON.stringify(a1) === JSON.stringify(a2);
  };

  const onChange = (name, value) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };
  const submit = async () => {
    try {
      setLoading(true);
      const { _id, ...rest } = details;
      await ShopService.update({
        params: {
          id: data._id,
        },
        // body: {
        //   status: details.status,
        // },
        body: rest,
      });
      setLoading(false);
      showToast(toast, "success", "Success!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const BranchTable = (prop: any) => {
    return (
      <DataTable value={prop?.data} scrollable>
        <Column
          header="Thumbnail"
          body={(rowData) => (
            <Image
              height="30"
              src={getUrlImage(rowData.avatar)}
              alt="thumbnail"
            />
          )}
          style={{ flexGrow: 1, flexBasis: "60px" }}
        />
        {/* <Column
          header="Code"
          field="refer_code"
          style={{ flexGrow: 1, flexBasis: "60px" }}
        /> */}
        <Column
          header="Name"
          field="full_name"
          style={{ flexGrow: 1, flexBasis: "100px" }}
        />
        <Column
          header="Address"
          style={{ flexGrow: 1, flexBasis: "200px" }}
          body={(rowData, options) => (
            <span>{rowData?.profile?.location?.detail}</span>
          )}
        />
      </DataTable>
    );
  };

  const ChipCate = (prop: any) => {
    const mapCategories = productCategories?.filter((e) =>
      prop?.data?.includes(e?._id)
    );
    return (
      <div className={`${prop?.isOne ? "flex" : "grid"}`}>
        {mapCategories?.map((el: any) => (
          <div className={`${prop?.isOne ? "flex mx-1" : "col-6"} flex-wrap`}>
            <Chip label={el?.name} image={getUrlImage(el?.icon)} className="" />
          </div>
        ))}
      </div>
    );
  };

  const EditorDes = (prop: any) => {
    return (
      <VEditor
        id="guide"
        className="h-20rem"
        value={prop?.data}
        haveData={prop?.data ? true : false}
        disabled={true}
      />
    );
  };

  return (
    <div className="grid">
      <Toast ref={toast} />
      <div className="col-12">
        <div className="card">
          <div className="grid">
            {details && (
              <div className="field col-12">
                <div className="flex justify-content-center">
                  <img
                    src={getUrlImage(details.image)}
                    alt=""
                    style={{ width: "200px" }}
                  />
                </div>
                <div className="mt-3">
                  <label className="font-bold" htmlFor="name">
                    Status:{" "}
                  </label>
                  <Dropdown
                    defaultValue={details?.status}
                    value={details?.status}
                    options={Object.keys(SHOP_STATUS).map((k) => ({
                      label: k,
                      value: SHOP_STATUS[k],
                    }))}
                    onChange={(e) => onChange("status", e.value)}
                    optionLabel="label"
                    placeholder="Select status"
                  />
                </div>

                <div className="mt-3">
                  <label htmlFor="name" className="font-bold">
                    ID:{" "}
                  </label>
                  <span>{details.user.refer_code}</span>
                  <hr />
                </div>
                <div className="mt-3">
                  <label htmlFor="name" className="font-bold">
                    Name:{" "}
                  </label>
                  <span>{details.user.full_name}</span>
                  <hr />
                </div>

                {histories?.length > 0 && (
                  <div className="mt-3">
                    <label className="font-bold" htmlFor="name">
                      Histories:{" "}
                    </label>
                    <Dropdown
                      value={pendingApproved}
                      options={histories}
                      onChange={(e) => setPendingApproved(e.value)}
                      optionLabel="createdAt"
                      placeholder="Select histories"
                    />
                  </div>
                )}

                <div className="mt-1">
                  <label htmlFor="category" className="font-bold">
                    Categories:{" "}
                  </label>
                  {pendingApproved &&
                  pendingApproved?.category_id?.length > 0 &&
                  !arraysEqual(
                    details?.category_id,
                    pendingApproved?.category_id
                  ) ? (
                    <Splitter>
                      {pendingApproved?.category_id?.length > 0 && (
                        <SplitterPanel className="flex align-items-center justify-content-center">
                          <ChipCate data={pendingApproved?.category_id} />
                        </SplitterPanel>
                      )}
                      <SplitterPanel className="flex align-items-center justify-content-center">
                        <ChipCate data={details?.category_id} />
                      </SplitterPanel>
                    </Splitter>
                  ) : (
                    <ChipCate data={details?.category_id} isOne={true} />
                  )}
                </div>
                <Panel header="Reactions" className="mt-2" toggleable>
                  <div>
                    <label htmlFor="" className="font-bold">
                      Service quality
                    </label>
                    <div className="flex">
                      {data.reactions?.qualities?.map((i) => (
                        <div key={i._id} className="flex flex-column ml-2">
                          <img
                            src={getUrlImage(i.image)}
                            alt={i.name}
                            className="h-8rem w-8rem m-auto mb-2"
                          />
                          <InputText value={i.name} disabled />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="mt-3">
                    <label htmlFor="" className="font-bold">
                      Service attitude
                    </label>
                    <div className="flex">
                      {data.reactions?.attitudes?.map((i) => (
                        <div key={i._id} className="flex flex-column ml-2">
                          <img
                            src={getUrlImage(i.image)}
                            alt={i.name}
                            className="h-8rem w-8rem m-auto mb-2"
                          />
                          <InputText value={i.name} disabled />
                        </div>
                      ))}
                    </div>
                  </div>
                </Panel>
                <div className="mt-2">
                  <label htmlFor="branchs" className=" font-bold">
                    Branches:
                  </label>
                  {pendingApproved &&
                  pendingApproved?.branches?.length > 0 &&
                  !arraysEqual(pendingApproved?.branches, details?.branches) ? (
                    <Splitter>
                      {pendingApproved &&
                        pendingApproved?.branches?.length > 0 && (
                          <SplitterPanel className="flex align-items-center justify-content-center">
                            <BranchTable data={pendingApproved?.branches} />
                          </SplitterPanel>
                        )}
                      <SplitterPanel className="flex align-items-center justify-content-center">
                        <BranchTable data={details?.branches} />
                      </SplitterPanel>
                    </Splitter>
                  ) : (
                    <BranchTable data={details?.branches} />
                  )}
                </div>

                <div className="mt-2">
                  <label htmlFor="description" className=" font-bold">
                    Description:
                  </label>
                  {pendingApproved &&
                  pendingApproved?.description?.length > 0 &&
                  pendingApproved?.description !== details?.description ? (
                    <Splitter>
                      {pendingApproved &&
                        pendingApproved?.description?.length > 0 && (
                          <SplitterPanel className="flex align-items-center justify-content-center">
                            <EditorDes data={pendingApproved?.description} />
                          </SplitterPanel>
                        )}
                      <SplitterPanel className="flex align-items-center justify-content-center">
                        <EditorDes data={details?.description} />
                      </SplitterPanel>
                    </Splitter>
                  ) : (
                    <EditorDes data={details?.description} />
                  )}
                </div>

                <div className="mt-5">
                  <label htmlFor="branchs" className="font-bold">
                    Affiliates:{" "}
                  </label>
                  <div className="mt-2">
                    <DataTable value={details.share_profits} scrollable>
                      <Column
                        header="ID User"
                        field="id"
                        style={{ flexGrow: 1, flexBasis: "150px" }}
                      />
                      <Column
                        header="Percent"
                        field="percent"
                        style={{ flexGrow: 1, flexBasis: "100px" }}
                      />
                    </DataTable>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Details);
