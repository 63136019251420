import React, { useEffect, useRef, useState } from "react";
import { Button, InputText, Toast } from "primereact";
import SettingService from "services/settings";
import { showToast } from "utils/common";
import { nanoid } from "nanoid";
import { Card } from "primereact/card";
import VUserDropdown from "components/v-user";
import { InputNumber } from "primereact/inputnumber";
const types = ["default_shop", "shop", "ads"];

const View = () => {
  const toast = useRef(null);
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const handleCancel = async () => {
    try {
      getData();
    } catch (error) {}
  };

  const changeHandler = (value, name, index, type) => {
    const newState = state[type].map((i) =>
      i.index === index
        ? {
            ...i,
            [name]: value,
          }
        : i
    );
    setState((prev) => ({
      ...prev,
      [type]: newState,
    }));
  };

  const handleSub = (index, type) => {
    const newState = state[type].filter((i) => i.index !== index);
    setState((prev) => ({
      ...prev,
      [type]: newState,
    }));
  };

  const handleAdd = (type) => {
    setState((state) => ({
      ...state,
      [type]: [
        ...(state[type] || []),
        { index: nanoid(), name: "", id: null, percent: 0 },
      ],
    }));
  };

  const submitHandler = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      await SettingService.updateSetting({
        body: {
          name: "affiliate",
          value: state,
        },
      });
      await getData();
      showToast(toast, "success", "Success!");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const getData = async () => {
    try {
      const res: any = await SettingService.getSettingsByName({
        params: {
          name: "affiliate",
        },
      });

      if (res) {
        const { setting } = res;
        if (setting) {
          const value = setting["value"] ? setting["value"] : {};
          setState(value);
        }
      }
    } catch (error) {}
  };

  return (
    <div className="grid">
      <Toast ref={toast} />

      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>Affiliate Setting</h4>
          </div>
          {types.map((type) => (
            <div className="my-2">
              <div className="field col-12 ">
                <span className="p-float-label">
                  <InputText required value={type} className="w-full" />
                </span>
              </div>
              <div className="field col-12">
                <Card>
                  <Button
                    onClick={(_) => handleAdd(type)}
                    icon="bx bxs-add-to-queue"
                    className="p-button-rounded p-button-success mb-3"
                  />
                  {(state[type] || []).map((item) => (
                    <div className="grid">
                      <div className="field col-12 md:col-12">
                        <InputText
                          required
                          value={item.name}
                          className="w-full"
                          onChange={(e) =>
                            changeHandler(
                              e.target.value,
                              "name",
                              item.index,
                              type
                            )
                          }
                        />
                      </div>
                      <div className="field col-12 md:col-5">
                        <VUserDropdown
                          className="w-full"
                          value={item.id}
                          optionValue="refer_code"
                          setValue={(v) =>
                            changeHandler(v, "id", item.index, type)
                          }
                        />
                      </div>
                      <div className="field col-12 md:col-5">
                        <InputNumber
                          prefix="%"
                          required
                          value={item.percent}
                          className="w-full"
                          onChange={(e) =>
                            changeHandler(e.value, "percent", item.index, type)
                          }
                        />
                      </div>
                      <div className="field col-2 md:col-2">
                        <Button
                          icon="pi pi-times"
                          className="p-button-rounded p-button-danger"
                          aria-label="Cancel"
                          onClick={(_) => handleSub(item.index, type)}
                        />
                      </div>
                    </div>
                  ))}
                </Card>
              </div>
              <hr />
            </div>
          ))}

          <div className="">
            <Button
              label="Cancel"
              className="p-button-danger mr-2"
              loading={loading}
              onClick={handleCancel}
            />
            <Button label="Submit" loading={loading} onClick={submitHandler} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
