/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useCallback,
} from "react";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { getUrlImage, showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import CodeService from "services/codes";
import { Calendar } from "primereact";
import { TYPE_CODE, TYPE_VOUCHER } from "utils/enum";
import debounce from "lodash.debounce";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ShopService from "services/shops";
import { Checkbox } from "primereact/checkbox";
import VUpload from "components/v-upload";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;
  const [shops, setShops] = useState([]);
  const [selectedShops, setSelectedShops] = useState([]);
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "updatedAt",
    sortOrder: -1,
  });
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    status: "approved",
  });
  const [totalRecords, setTotalRecords] = useState(0);

  const emptyData = {
    name: "",
    link: "",
    status: "enable",
    is_used: false,
    price: 0,
    key: "",
    used: 0,
    number: 0,
    start_date: null,
    end_date: null,
    stock: "vnex",
    type: "code",
    type_voucher: "amount",
    shops: [],
    icon: "",
    img: "",
  };

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data)
      setDetails({
        ...data,
        start_date: new Date(data.start_date),
        end_date: new Date(data.end_date),
      });
  }, [data]);

  useEffect(() => {
    let shops = [];
    selectedShops?.map((e) => {
      shops = [
        ...shops,
        { id: e?._id, branches: e?.branches?.filter((el) => el?.checked) },
      ];
    });
    setDetails((state) => ({
      ...state,
      shops: shops,
    }));
  }, [selectedShops]);

  useEffect(() => {
    setSelectedShops((state) =>
      state?.map((e) => {
        let branches = shops?.find((el) => el?.id === e?.id)?.branches;
        e.branches = branches;
        return e;
      })
    );
  }, [shops]);

  useEffect(() => {
    if (shops?.length > 0) {
      let shopsSelect = [];
      if (selectedShops?.length === 0 && data?.shops?.length > 0) {
        shopsSelect = data?.shops?.map((em: { branches: any; id: any }) => {
          return {
            ...shops?.find((e) => e?.id === em?.id),
            branches: em?.branches,
          };
        });
        setSelectedShops(shopsSelect);
        setShops((state) =>
          state?.map((e) => {
            let selectedShop = data?.shops?.find((el) => el?.id === e?.id);
            if (selectedShop) {
              e.branches = e?.branches?.map((em) => ({
                ...em,
                checked:
                  selectedShop?.branches?.find((en) => en?.id === em?.id)
                    ?.checked || false,
              }));
              e.isCheckAll =
                e.branches?.length ===
                selectedShop?.branches?.filter((en) => en?.checked)?.length;
            }
            return e;
          })
        );
      }
    }
  }, [shops, data]);

  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, page: event?.page, first: event?.first };
    setLazyParams(_lazyParams);
  };

  const onInputChange = (value, name) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await CodeService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else {
        await CodeService.create({
          body: {
            ...details,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Success!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  const loadData = async () => {
    try {
      const res: any = await ShopService.search({
        query: {
          ...lazyParams,
          globalFilter,
          page: lazyParams.page + 1,
          limit: lazyParams.rows,
        },
      });
      if (res && res.docs) {
        setShops(res.docs);
        setTotalRecords(res.totalDocs);
      } else setShops([]);
    } catch (error) {
      setShops([]);
    }
  };

  const onFilter = (name, value) => {
    // if (name === "location") {
    //   setLocationFilter(value);
    // } else {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
    // }
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  const handleChangeStore = (
    name: string,
    value: any,
    branchId: any,
    rowId: any
  ) => {
    if (value && !selectedShops?.find((e) => e?.id === rowId)) {
      setSelectedShops((state) => [
        ...state,
        shops?.find((e) => e?._id === rowId),
      ]);
    }
    if (name === "checkAll") {
      setShops((state) =>
        state.map((item) => {
          if (item.id === rowId) {
            item.isCheckAll = value;
            item?.branches?.map((b) => {
              b.checked = value;
            });
          }
          return item;
        })
      );
    } else if (name === "checkOne") {
      setShops((state) =>
        state.map((item) => {
          if (item.id === rowId) {
            item?.branches?.map((b) => {
              if (b?.id === branchId) {
                b.checked = value;
              }
            });
            if (
              item?.branches?.filter((e) => e?.checked)?.length ===
              item?.branches?.length
            )
              item.isCheckAll = true;
            else item.isCheckAll = false;
          }
          return item;
        })
      );
    }
    // handleChange(name, value);
  };

  const renderSelectBranches = (rowData) => {
    return (
      <div className="grid">
        {rowData?.branches?.length > 1 && (
          <div className="col-6">
            <Checkbox
              checked={rowData?.isCheckAll}
              onChange={(e) =>
                handleChangeStore("checkAll", e?.checked, null, rowData?._id)
              }
            />
            &ensp;
            <label htmlFor="name">Chọn tất cả</label>&ensp;
          </div>
        )}
        {rowData?.branches?.map((b: any) => (
          <div
            className={`${
              rowData?.branches?.length === 1 ? "col-12" : "col-6"
            }`}
          >
            <Checkbox
              checked={rowData?.isCheckAll || b?.checked}
              onChange={(e) =>
                handleChangeStore("checkOne", e?.checked, b?.id, rowData?._id)
              }
            />
            &ensp;
            <label htmlFor="name">{b?.name}</label>&ensp;
          </div>
        ))}
      </div>
    );
  };

  const header = (
    <div className="grid px-2 align-items-center">
      <div className="col-12 md:col-6">
        <div className="block p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e: any) => onSearch("search", e.target.value)}
            placeholder="Search..."
            className="w-full"
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="grid">
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onInputChange(e.target.value, "name")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="key">Key</label>
        <InputText
          id="key"
          value={details.key}
          onChange={(e) => onInputChange(e.target.value, "key")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-12">
        <label htmlFor="logo">Link</label>
        <InputText
          id="link"
          value={details.link}
          onChange={(e) => onInputChange(e.target.value, "link")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Number</label>
        <InputNumber
          id="number"
          value={details.number}
          onChange={(e) => onInputChange(e.value, "number")}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Value</label>
        <InputNumber
          id="price"
          value={details.price}
          onChange={(e) => onInputChange(e.value, "price")}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label>Icon</label>
        <div className="mt-2">
          <VUpload
            urlFile={details.icon}
            setUrlFile={(file) => onInputChange(file, "icon")}
          />
        </div>
      </div>
      <div className="field col-12 md:col-6">
        <label>Image</label>
        <div className="mt-2">
          <VUpload
            urlFile={details.img}
            setUrlFile={(file) => onInputChange(file, "img")}
          />
        </div>
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Used</label>
        <InputNumber
          id="used"
          value={details.used}
          onChange={(e) => onInputChange(e.value, "used")}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Status</label>
        <Dropdown
          value={details.status}
          options={[
            { label: "Enable", value: "enable" },
            { label: "Disable", value: "disable" },
          ]}
          onChange={(e) => onInputChange(e.value, "status")}
          optionLabel="label"
          placeholder="Select status"
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Type</label>
        <Dropdown
          value={details.type}
          options={Object.keys(TYPE_CODE).map((k) => ({
            label: k,
            value: TYPE_CODE[k],
          }))}
          onChange={(e) => onInputChange(e.value, "type")}
          optionLabel="label"
          placeholder="Type"
          className={"w-full"}
          showClear
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Type Voucher</label>
        <Dropdown
          value={details.type_voucher}
          options={Object.keys(TYPE_VOUCHER).map((k) => ({
            label: k,
            value: TYPE_VOUCHER[k],
          }))}
          onChange={(e) => onInputChange(e.value, "type_voucher")}
          optionLabel="label"
          placeholder="Type Voucher"
          disabled={details.type === TYPE_CODE.CODE}
          className={"w-full"}
          showClear
        />
      </div>

      <div className="field col-6 md:col-6">
        <label htmlFor="swap">Start Date</label>
        <Calendar
          className="m-1"
          id="time24"
          placeholder="Start Date"
          value={details.start_date}
          onChange={(e: any) => onInputChange(e.value, "start_date")}
          showTime
          showSeconds
          showButtonBar
        />{" "}
      </div>
      <div className="field col-6 md:col-6">
        <label htmlFor="swap">End Date</label>
        <Calendar
          className="m-1"
          id="time24"
          placeholder="End Date"
          value={details.end_date}
          onChange={(e: any) => onInputChange(e.value, "end_date")}
          showTime
          showSeconds
          showButtonBar
        />{" "}
      </div>
      {/* On off option */}
      <div className="field col-6 md:col-3">
        <label htmlFor="swap">Is Used</label>
        <br />
        <InputSwitch
          checked={details.is_used}
          onChange={(e) => onInputChange(e.value, "is_used")}
        />
      </div>
      <div className="w-full">
        <DataTable
          // ref={dt}
          value={shops}
          header={header}
          emptyMessage="No data found."
          dataKey="id"
          size="small"
          scrollDirection="both"
          className="datatable-responsive"
          scrollable
          showGridlines
          lazy
          paginator
          onPage={onPage}
          first={lazyParams.first}
          rows={lazyParams.rows}
          totalRecords={totalRecords}
          rowsPerPageOptions={[5, 10, 20]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="{first} - {last} of {totalRecords}"
          selectionMode="checkbox"
          selection={selectedShops}
          onSelectionChange={(e) => setSelectedShops(e.value)}
        >
          <Column selectionMode="multiple"></Column>
          <Column
            field="image"
            header="Image"
            body={(rowData) => (
              <img
                src={getUrlImage(rowData?.user.avatar)}
                alt=""
                style={{ height: "50px" }}
              />
            )}
            style={{ flexGrow: 1, flexBasis: "60px" }}
          />
          <Column
            style={{ flexGrow: 1, flexBasis: "100px" }}
            field="user.refer_code"
            header="ID"
          ></Column>
          <Column
            style={{ flexGrow: 1, flexBasis: "200px" }}
            field="name"
            header="Name"
            body={(rowData) => String(rowData?.user.full_name)}
          ></Column>
          <Column
            style={{ flexGrow: 1, flexBasis: "280px" }}
            header="Branches"
            body={(rowData, options) => renderSelectBranches(rowData)}
          ></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default forwardRef(Details);
