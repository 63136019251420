/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useContext,
} from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { MasterContext } from "contexts/MasterContext";
import { Dropdown } from "primereact/dropdown";
import VLocation from "components/v-location";
import { OBJECT_TYPE, USER_GENDER } from "utils/enum";
import CategoryFilterService from "services/category-filters";
import CategoryService from "services/categories";

const CategoryFilterDetails = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    name: "",
    description: "",
    career_id: null,
    location: {},
    nation_id: null,
    religion_id: null,
    category_field_id: null,
    object_type: null,
    account_type: null,
    gender: null,
    family_id: null,
  };
  const { careers, nations, religions, categoryFields, families } =
    useContext(MasterContext);

  const [accountTypes, setAccountTypes] = useState([]);
  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    getAccountTypes();
  }, []);

  useEffect(() => {
    if (data) setDetails(data);
  }, [data]);

  const onChange = (name, value) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };

  const getAccountTypes = async () => {
    const res: any = await CategoryService.getByGroup({
      query: {
        group: "account_type",
      },
    });
    setAccountTypes(res);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await CategoryFilterService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else
        await CategoryFilterService.create({
          body: {
            ...details,
          },
        });
      setLoading(false);
      showToast(toast, "success", "File saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange("name", e.target.value)}
          required
          autoFocus
        />
      </div>
      <div className="field col-12">
        <label htmlFor="key">Description</label>
        <InputText
          id="key"
          value={details.description}
          onChange={(e) => onChange("description", e.target.value)}
          required
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Object</label>
        <Dropdown
          showClear
          value={details.object_type}
          options={Object.keys(OBJECT_TYPE).map((k) => ({
            label: k,
            value: OBJECT_TYPE[k],
          }))}
          optionLabel="label"
          optionValue="value"
          placeholder="Select object"
          onChange={(e) => onChange("object_type", e.value)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Account type</label>
        <Dropdown
          showClear
          value={details.account_type}
          options={accountTypes.map((c) => ({
            label: c.name,
            value: c.key,
          }))}
          optionLabel="label"
          optionValue="value"
          placeholder="Select account type"
          onChange={(e) => onChange("account_type", e.value)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Gender</label>
        <Dropdown
          value={details.gender}
          options={Object.keys(USER_GENDER).map((k) => ({
            label: k,
            value: USER_GENDER[k],
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("gender", e.value)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Family</label>
        <Dropdown
          filter
          value={details.family_id}
          options={families.map((c) => ({
            label: c.name,
            value: c._id,
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("family_id", e.value)}
          showClear
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Career</label>
        <Dropdown
          showClear
          filter
          value={details.career_id}
          options={careers.map((c) => ({
            label: c.name,
            value: c._id,
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("career_id", e.value)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Nation</label>
        <Dropdown
          showClear
          filter
          value={details.nation_id}
          options={nations.map((c) => ({
            label: c.name,
            value: c._id,
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("nation_id", e.value)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Religion</label>
        <Dropdown
          showClear
          filter
          value={details.religion_id}
          options={religions.map((c) => ({
            label: c.name,
            value: c._id,
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("religion_id", e.value)}
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="name">Category Field</label>
        <Dropdown
          filter
          showClear
          value={details.category_field_id}
          options={categoryFields.map((c) => ({
            label: c.name,
            value: c._id,
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("category_field_id", e.value)}
        />
      </div>

      <div className="field col-12">
        <label htmlFor="name">Address current</label>
        <VLocation
          address={details.location}
          setAddress={(a) => onChange("location", a)}
        />
      </div>
    </div>
  );
};

export default forwardRef(CategoryFilterDetails);
