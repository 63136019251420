import { request, parseErrorResponse } from "./request";
import qs from "query-string";

const findAll = (req?: { query: any }) => {
  return new Promise((resolve, reject) => {
    request()
      .get(`admin/question-and-answers?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          resolve(data);
        } else {
          reject(new Error("Something went error!"));
        }
      })
      .catch(parseErrorResponse)
      .then(reject);
  });
};

const QuestionAndAnswerService = {
  findAll,
};

export default QuestionAndAnswerService;
