import io from "socket.io-client";
import { socketEvents } from "./events";

export const socket = io(`${process.env.REACT_APP_SOCKET_URL}/admin`, {
  query: {
    token: localStorage.getItem("token_auth"),
  },
  transports: ["websocket"],
});

export const initSockets = ({ setDashboard }) => {
  socketEvents({ setDashboard });
};
