/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import Service from "services/category-faq";
import VUpload from "components/v-upload";
import { TreeSelect } from 'primereact/treeselect';

const Details = (props, ref) => {
  const { list, data, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    icon: "",
    name: "",
    key: "",
    description: "",
    parent_id: "",
  };

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  const getCategories = (data) => {
    let treeData;
    const mapData = (list: any) => {
      const dataChil = list.map((item: any) => chil(item))
      return dataChil.length > 0 ? dataChil : null
    }
    const chil = (ct: any) => {
      const chilData = data.filter((category) => String(category.parent_id) === String(ct._id))
      return {
        ...ct,
        key: ct._id,
        label: ct.name,
        children: mapData(chilData)
      }
    }

    treeData = data.filter((ct) => !Boolean(ct.parent_id)).map((el) => chil(el))
    return treeData
  }

  useEffect(() => {
    if (data) setDetails(data);
  }, [data]);

  const onChange = (name, value) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await Service.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else
        await Service.create({
          body: {
            ...details,
          },
        });
      setLoading(false);
      showToast(toast, "success", "File saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };


  return (
    <div className="grid">
      <div className="field col-12">
        <label>Icon</label>
        <div className="mt-2">
          <VUpload
            urlFile={details.icon}
            setUrlFile={(file) => onChange("icon", file)}
          />
        </div>
      </div>
      <div className="field col-6">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange("name", e.target.value)}
          required
          autoFocus
        />
      </div>

      <div className="field col-6">
        <label htmlFor="name">Key</label>
        <InputText
          id="key"
          value={details.key}
          onChange={(e) => onChange("key", e.target.value)}
          required
          autoFocus
        />
      </div>

      <div className="field col-6">
        <label htmlFor="key">Description</label>
        <InputText
          id="key"
          value={details.description}
          onChange={(e) => onChange("description", e.target.value)}
          required
          autoFocus
        />
      </div>
      <div className="field col-6 position-relative">
        <label htmlFor="key">Select Parent</label>
        <TreeSelect value={details.parent_id} filter options={getCategories(list)} onChange={(e: any) => onChange("parent_id", e?.value)}
          className="w-full" placeholder="Select Parent"></TreeSelect>
      </div>
    </div>
  );
};

export default forwardRef(Details);
