/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import AllotmentService from "services/allotments";
import VStockDropdown from "components/v-stock";

const AllotmentPersonal = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const defaultState = {
    amount: null,
    to: null,
    token: null,
    type: "email",
  };

  const [allotment, setAllotment] = useState(defaultState);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setAllotment(data);
  }, [data]);

  const onInputChange = (val, name) => {
    let _details = { ...allotment };
    _details[`${name}`] = val;
    setAllotment(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      await AllotmentService.createAllotmentPerson({
        body: {
          ...allotment,
        },
      });
      setLoading(false);
      showToast(toast, "success", "Success!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", "Errors");
    }
  };

  return (
    <div className="grid">
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Type</label>
        <Dropdown
          defaultValue={allotment.type}
          value={allotment.type}
          options={[
            {
              label: "id",
              value: "id",
            },
            {
              label: "email",
              value: "email",
            },
            {
              label: "wallet",
              value: "wallet",
            },
          ]}
          onChange={(e) => onInputChange(e.value, "type")}
          optionLabel="label"
          placeholder="Select role"
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="token">Token</label>
        <VStockDropdown
          value={allotment.token}
          setValue={(e) => onInputChange(e, "token")}
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">To </label>
        <InputText
          id="to"
          value={allotment.to}
          onChange={(e) => onInputChange(e.target.value, "to")}
          required
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="amount">Amount</label>
        <InputNumber
          id="amount"
          value={allotment.amount}
          onValueChange={(e) => onInputChange(e.value, "amount")}
        />
      </div>
    </div>
  );
};

export default forwardRef(AllotmentPersonal);
