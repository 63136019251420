/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { Image } from "primereact/image";
import {
  formatLocalTime,
  getUrlImage,
  showToast,
  downloadFile,
} from "utils/common";
import VDialog from "components/v-dialog";
import VConfirm from "components/v-confirm";
import Detail from "./components/Detail";
import debounce from "lodash.debounce";
import { Calendar, MultiSelect } from "primereact";
import { TabPanel, TabView } from "primereact/tabview";
import FamilyService from "services/families";
import Import from "./components/Import";
import { Panel } from "primereact/panel";

const STATUS = [
  { name: "ENABLE", value: "enable" },
  { name: "DISABLE", value: "disable" },
];

const Crud = () => {
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState(null);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    status: ["enable", "disable"],
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "order",
    sortOrder: 1,
  });
  const defaultFields = [
    { field: "name", header: "Name" },
    { field: "key", header: "Key" },
    { field: "link", header: "Link" },
    { field: "status", header: "Status" },
    { field: "order", header: "Order" },
    { field: "logo", header: "Logo" },
  ];
  const [totalRecords, setTotalRecords] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [importedData, setImportedData] = useState([]);
  const [importedCols, setImportedCols] = useState([]);

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);
  const refDetail = useRef(null);
  const fileRef = useRef(null);
  const refDialogImport = useRef(null);
  const refImport = useRef(null);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  const loadData = async () => {
    try {
      const res: any = await FamilyService.search({
        query: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
          limit: lazyParams.rows,
        },
      });
      if (res && res.docs) {
        setData(res.docs);
        setTotalRecords(res.totalDocs);
      } else setData([]);
    } catch (error) {
      setData([]);
    }
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  //CREATE OR NEW
  const openNew = () => {
    setDetail(null);
    refDialogDetail.current.show();
  };

  const editActionPost = (data) => {
    setDetail({ ...data });
    refDialogDetail.current.show();
  };

  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };

  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };

  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  // DELETE
  const confirmDelete = (product) => {
    setDetail(product);
    refDialogDelete.current.show();
  };
  const handleDelete = async () => {
    try {
      await FamilyService.deleted({
        params: {
          id: detail._id,
        },
      });
      const _data = data.filter((val) => val._id !== detail._id);
      setData(_data);
      refDialogDelete.current.close();
      setDetail(null);
      showToast(toast, "success", "Deleted");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />

          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled={true}
          />
        </div>
      </React.Fragment>
    );
  };

  const toCapitalize = (s) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const importExcelFunc = (e) => {
    const file = e.files[0];
    import("xlsx")
      .then((xlsx) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const wb = xlsx.read(e.target.result, { type: "array" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data: any = xlsx.utils.sheet_to_json(ws, { header: 1 });
          // Prepare DataTable
          const cols: any = data[0];
          data.shift();
          let _importedCols = cols.map((col) => ({
            field: col.toLowerCase(),
            header: toCapitalize(col),
          }));
          const dataNoEmpty = data
            .map((i, index) => {
              if (i.length > 0) {
                return [...i];
              }
            })
            .filter(Boolean);

          const withoutImport = defaultFields?.filter(
            (e) => !cols?.includes(e.field)
          );
          const _importedData = dataNoEmpty.map((row: any) => {
            let newRow = {};
            _importedCols?.map((e: any, index) => {
              newRow = { ...newRow, [e.field]: row[index] };
            });
            withoutImport?.map((e: any, index) => {
              newRow = { ...newRow, [e.field]: null };
            });
            return newRow;
          });

          setImportedCols(
            _importedCols?.filter((e) =>
              defaultFields?.map((f) => f.field)?.includes(e.field)
            )
          );
          setImportedData(_importedData);
        };

        reader.readAsArrayBuffer(file);
      })
      .finally(e.options.clear());

    refDialogImport.current.show();

    showToast(toast, "success", "File imported!");
  };

  const templateUrl =
    "admin/template/be3c4153-1a77-4e8f-adb1-be0611feeeb0.xlsx";
  const templateFileName = "Import_Families_Template.xlsx";

  const cancelOptions = {
    label: "Cancel",
    icon: "pi pi-times",
    className: "p-button-danger",
  };

  const saveDataImport = async (dataImportMerged) => {
    try {
      await FamilyService.importExcel({
        body: {
          list: dataImportMerged,
        },
      });

      loadData();
      clear();
      showToast(toast, "success", "Success");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const onSumitDialogImport = () => {
    refImport.current.submit();
  };

  const clear = () => {
    setImportedData([]);
    fileRef.current.clear();
    refDialogImport.current.close();
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          ref={fileRef}
          chooseOptions={{
            label: `Import Families`,
            icon: "pi pi-file-excel",
            className: "p-button-success",
          }}
          mode="basic"
          // progressBarTemplate={loading && <ProgressBar mode="indeterminate" />}
          name="demo[]"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          className="mr-2"
          uploadHandler={importExcelFunc}
          customUpload={true}
          cancelOptions={cancelOptions}
        />
        <Button
          label="Download Template"
          icon="pi pi-download"
          className="p-button-help"
          onClick={() => downloadFile(templateUrl, templateFileName)}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editActionPost(rowData)}
        />

        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning"
          onClick={() => confirmDelete(rowData)}
        />
      </div>
    );
  };

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="grid px-2 align-items-center">
        <div className="col-12 md:col-6">
          <span className="block p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e: any) => onSearch("search", e.target.value)}
              placeholder="Search..."
              className="w-full"
            />
          </span>{" "}
        </div>
        <div className="col-12 md:col-6">
          <MultiSelect
            value={globalFilter.status}
            options={STATUS}
            onChange={(e: any) => onSearch("status", e.value)}
            optionLabel="name"
            placeholder="Status"
            maxSelectedLabels={3}
            className={"w-full"}
            showClear
          />
        </div>
        <div className="col-12 md:col-6">
          <Calendar
            className="w-full"
            id="time24"
            placeholder="Start Date"
            value={globalFilter.start}
            onChange={(e: any) => onSearch("start", e.value)}
            showTime
            showSeconds
            showButtonBar
          />{" "}
        </div>
        <div className="col-12 md:col-6">
          <Calendar
            className="w-full"
            id="time24"
            placeholder="End Date"
            value={globalFilter.end}
            onChange={(e: any) => onSearch("end", e.value)}
            showTime
            showSeconds
            showButtonBar
          />
        </div>
      </div>
    </Panel>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            <TabPanel header="Families">
              <Toolbar
                className="mb-4"
                left={leftToolbarTemplate}
                right={rightToolbarTemplate}
              ></Toolbar>
              <DataTable
                ref={dt}
                value={data}
                header={header}
                emptyMessage="No data found."
                dataKey="id"
                size="small"
                scrollDirection="both"
                className="datatable-responsive"
                scrollable
                showGridlines
                lazy
                paginator
                first={lazyParams.first}
                rows={lazyParams.rows}
                totalRecords={totalRecords}
                rowsPerPageOptions={[5, 10, 20]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="{first} - {last} of {totalRecords}"
                onPage={onPage}
                //sort
                sortField={lazyParams.sortField}
                sortOrder={lazyParams.sortOrder}
                onSort={onSort}
              >
                <Column
                  style={{ flexGrow: 1, flexBasis: "80px" }}
                  header="Image"
                  body={(rowData) => {
                    return (
                      <Image
                        src={getUrlImage(rowData.logo)}
                        alt={rowData.name}
                        width="50"
                        height="50"
                        preview
                      />
                    );
                  }}
                ></Column>

                <Column
                  field="name"
                  header="Name"
                  style={{ flexGrow: 1, flexBasis: "150px" }}
                ></Column>
                <Column
                  field="key"
                  header="Key"
                  style={{ flexGrow: 1, flexBasis: "150px" }}
                ></Column>
                <Column
                  field="link"
                  header="Link"
                  body={(rowData) => String(rowData.link).slice(0, 30)}
                  style={{
                    flexGrow: 1,
                    flexBasis: "200px",
                    position: "relative",
                  }}
                ></Column>
                <Column
                  field="status"
                  header="Status"
                  style={{
                    flexGrow: 1,
                    flexBasis: "100px",
                    position: "relative",
                  }}
                  body={(rowData) => (
                    <span
                      className={`v-badge status-${rowData.status.toLowerCase()}`}
                    >
                      {rowData.status}
                    </span>
                  )}
                ></Column>
                <Column
                  style={{ flexGrow: 1, flexBasis: "200px" }}
                  field="createdAt"
                  header="Created At"
                  sortable
                  body={(rowData) => formatLocalTime(rowData.createdAt)}
                ></Column>
                <Column
                  alignFrozen="right"
                  body={actionBodyTemplate}
                  style={{ flexGrow: 1, flexBasis: "150px" }}
                ></Column>
              </DataTable>
            </TabPanel>
            {/* <TabPanel header="Import Excel">
              <ImportExcel />
            </TabPanel> */}
          </TabView>

          <VDialog
            ref={refDialogDetail}
            header="Details"
            onSubmit={onSumitDialogDetail}
          >
            <Detail
              ref={refDetail}
              data={detail}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
            />
          </VDialog>

          <VDialog
            ref={refDialogImport}
            header="Select column to import"
            onSubmit={onSumitDialogImport}
          >
            <Import
              ref={refImport}
              data={importedData}
              toast={toast}
              saveData={saveDataImport}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
              columns={importedCols}
            />
          </VDialog>

          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Crud, comparisonFn);
