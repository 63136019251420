/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle, useRef } from "react";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { Calendar, MultiSelect, Panel } from "primereact";
import "../styles.scss";
import VUpload from "components/v-upload";
import VReward from "components/v-reward";
import VAffilate from "components/v-affiliate";
import VUserDropdown from "components/v-user";
import TaskService from "services/tasks";
import CategoryFilterDetails from "components/v-filter";
import VEditor from "components/v-editor";

const categories = [
  "Real estate",
  "Entertainment",
  "Beauty",
  "Education",
  "Telecommunication",
  "Financial services",
  "Online Services",
  "Travel and entertainment",
  "Purchase, e-commerce",
];

const types = ["kyc", "verify", "click_link", "register"];

const labels = ["recommended", "hot", "just_released"];

const Details = (props, ref) => {
  const {
    data,
    reload,
    toast,
    setLoading,
    categoryFilters,
    getCategoryFilters,
  } = props;

  const emptyData = {
    user_id: "",
    name: "",
    description: "",
    banner: "",
    type: "",
    other_rules: "",
    file: "",
    label: "recommended",
    status: "opening",
    categories: "",
    website: "",
    url_task: "",
    reward: {
      stocks: [],
      vouchers: [],
      tickets: [],
      codes: [],
    },
    time: {
      start_date: null,
      end_date: null,
      frame: null,
    },
    affiliates: {},
    introduction: "",
    accecptance_condition: "",
    rejection_condition: "",
    require: {
      number_of_requests: 1,
      max_per_user: 1,
      total: 1,
    },
    objects: [],
    category_filter_id: null,
  };

  const refDetailCategory = useRef(null);
  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data)
      setDetails({
        ...data,
        time: {
          ...data.time,
          start_date: new Date(data.time.start_date),
          end_date: new Date(data.time.end_date),
        },
      });
  }, [data]);

  const onChange = (value, name, field = null) => {
    let _details = { ...details };
    if (field) {
      _details[field][`${name}`] = value;
    } else {
      _details[`${name}`] = value;
    }
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      let category = null;
      if (!details.category_filter_id) {
        category = await refDetailCategory.current.submit();
        getCategoryFilters();
      }
      if (data) {
        await TaskService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      } else {
        await TaskService.create({
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Saved!");
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <>
      <Panel header="Genaral" toggleable className="my-2">
        <div className="grid">
          <div className="field col-12">
            <label htmlFor="status">Filter</label>
            <Dropdown
              filter
              showClear
              value={details.category_filter_id}
              options={categoryFilters.map((item) => ({
                label: item.name,
                value: item._id,
              }))}
              optionLabel="label"
              optionValue="value"
              placeholder="Select filter"
              onChange={(e) => onChange(e.value, "category_filter_id")}
            />
          </div>
          <div className="field col-12 md:col-12">
            <label htmlFor="user">User</label>
            <VUserDropdown
              value={details.user_id}
              setValue={(user) => onChange(user, "user_id")}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="name">Name</label>
            <InputText
              id="name"
              value={details.name}
              onChange={(e) => onChange(e.target.value, "name")}
              required
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="website">Website</label>
            <InputText
              id="website"
              value={details.website}
              onChange={(e) => onChange(e.target.value, "website")}
              required
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="video">Url Task</label>
            <InputText
              id="video"
              value={details.url_task}
              onChange={(e) => onChange(e.target.value, "url_task")}
              required
            />
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="status">Type</label>
            <Dropdown
              value={details.type}
              options={types.map((c) => ({
                value: c,
                label: c,
              }))}
              onChange={(e) => onChange(e.value, "type")}
              optionLabel="label"
              optionValue="value"
              placeholder="Select type"
            />
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="status">Categories</label>
            <Dropdown
              value={details.categories}
              options={categories.map((c) => ({
                value: c,
                label: c,
              }))}
              onChange={(e) => onChange(e.value, "categories")}
              optionLabel="label"
              optionValue="value"
              placeholder="Select categories"
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="status">Label</label>
            <Dropdown
              value={details.label}
              options={labels.map((c) => ({
                value: c,
                label: c,
              }))}
              onChange={(e) => onChange(e.value, "label")}
              optionLabel="label"
              optionValue="value"
              placeholder="Select label"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="status">Status</label>
            <Dropdown
              value={details.status}
              options={[
                {
                  label: "opening",
                  value: "opening",
                },
                {
                  label: "pending",
                  value: "pending",
                },
                {
                  label: "closed",
                  value: "closed",
                },
              ]}
              onChange={(e) => onChange(e.value, "status")}
              optionLabel="label"
              placeholder="Select status"
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="start_date">Start Date</label>
            <Calendar
              className="m-1"
              id="time24"
              placeholder="Date"
              value={details.time.start_date}
              dateFormat="mm-dd-yy"
              onChange={(e: any) => onChange(e.value, "start_date", "time")}
              showTime
              showSeconds
              showButtonBar
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="end_date">End Date</label>
            <Calendar
              className="m-1"
              id="time24"
              placeholder="Date"
              value={details.time.end_date}
              dateFormat="mm-dd-yy"
              onChange={(e: any) => onChange(e.value, "end_date", "time")}
              showTime
              showSeconds
              showButtonBar
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="end_date">Frame Time</label>
            <MultiSelect
              value={details.time.frame}
              options={Array(24)
                .fill(0)
                .map((_, i) => ({ value: i, label: i }))}
              onChange={(e) => onChange(e.value, "frame", "time")}
              optionLabel="label"
              optionValue="value"
              placeholder="Select a frame"
            />
          </div>
        </div>
      </Panel>

      {!details.category_filter_id && (
        <div className="field col-12">
          <Panel header="Filter" toggleable className="my-2" collapsed={false}>
            <div className="grid">
              <div className="field col-12">
                <CategoryFilterDetails ref={refDetailCategory} />
              </div>
            </div>
          </Panel>
        </div>
      )}

      <Panel header="Information" toggleable className="my-2" collapsed={true}>
        <div className="grid">
          <div className="field col-12 md:col-4">
            <label htmlFor="number_of_requests">
              Amount required to claim the reward
            </label>
            <InputNumber
              id="number_of_requests"
              value={details.require.number_of_requests}
              onChange={(e) =>
                onChange(e.value, "number_of_requests", "require")
              }
              required
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="max_per_user">
              Maximum number of rewards per user
            </label>
            <InputNumber
              id="max_per_user"
              value={details.require.max_per_user}
              onChange={(e) => onChange(e.value, "max_per_user", "require")}
              required
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="total">Total number of reward</label>
            <InputNumber
              id="total"
              value={details.require.total}
              onChange={(e) => onChange(e.value, "total", "require")}
              required
            />
          </div>
        </div>
      </Panel>

      <Panel header="Images" toggleable className="my-2" collapsed={true}>
        <div className="grid">
          <div className="field col-12">
            <VUpload
              label="Banner"
              urlFile={details.banner}
              setUrlFile={(file) => onChange(file, "banner")}
            />
          </div>
        </div>
      </Panel>

      <Panel header="Reward" toggleable className="my-2" collapsed={true}>
        <VReward
          reward={details.reward}
          setReward={(reward) => onChange(reward, "reward")}
        />
      </Panel>

      <Panel header="Affilate" toggleable className="my-2" collapsed={true}>
        <VAffilate
          affiliates={details.affiliates}
          setAffiliates={(a) => onChange(a, "affiliates")}
        />
      </Panel>

      <Panel header="Content" toggleable className="my-2" collapsed={true}>
        <label htmlFor="number_of_requests">Introduction</label>
        {/* <Editor
          style={{ height: "300px" }}
          value={details.introduction}
          onTextChange={(e) => onChange(e.htmlValue, "introduction")}
        /> */}
        <VEditor
          id="introduction"
          className="h-20rem"
          value={details.introduction}
          onChange={(e) => {
            onChange(e?.html, "introduction");
          }}
          haveData={details.introduction ? true : false}
        />
        <label>Accecptance condition</label>
        {/* <Editor
          style={{ height: "300px" }}
          value={details.accecptance_condition}
          onTextChange={(e) => onChange(e.htmlValue, "accecptance_condition")}
        /> */}
        <VEditor
          id="accecptance_condition"
          className="h-20rem"
          value={details.accecptance_condition}
          onChange={(e) => {
            onChange(e?.html, "accecptance_condition");
          }}
          haveData={details.accecptance_condition ? true : false}
        />
        <label>Rejection condition</label>
        {/* <Editor
          style={{ height: "300px" }}
          value={details.rejection_condition}
          onTextChange={(e) => onChange(e.htmlValue, "rejection_condition")}
        /> */}
        <VEditor
          id="rejection_condition"
          className="h-20rem"
          value={details.rejection_condition}
          onChange={(e) => {
            onChange(e?.html, "rejection_condition");
          }}
          haveData={details.rejection_condition ? true : false}
        />
        <label>Other rules</label>
        {/* <Editor
          style={{ height: "300px" }}
          value={details.other_rules}
          onTextChange={(e) => onChange(e.htmlValue, "other_rules")}
        /> */}
        <VEditor
          id="other_rules"
          className="h-20rem"
          value={details.other_rules}
          onChange={(e) => {
            onChange(e?.html, "other_rules");
          }}
          haveData={details.other_rules ? true : false}
        />
      </Panel>
    </>
  );
};

export default forwardRef(Details);
