import { InputNumber } from "primereact/inputnumber";

interface IProps {
  onChange?: any;
  data?: any;
  errMessage?: string;
  [key: string]: any;
  options: any;
}

const View = ({
  className,
  ref,
  label,
  disabled = false,
  value = null,
  required = false,
  placeholder = "",
  defaultValue = null,
  haveData,
  onChange = () => {},
  options,
  ...props
}: IProps) => {
  return (
    <InputNumber
      value={value}
      onChange={(e: any) => onChange(options, e.value)}
      placeholder={"Order"}
      className={"w-4rem edit-order"}
    />
  );
};

export default View;
