/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { Image } from "primereact/image";
import { formatLocalTime, getUrlImage, showToast } from "utils/common";
import VDialog from "components/v-dialog";
import VConfirm from "components/v-confirm";
import { Calendar } from "primereact/calendar";
import Uploads from "./components/Details";
import debounce from "lodash.debounce";
import CareerService from "services/careers";
import { MultiSelect } from "primereact";
import { TabPanel, TabView } from "primereact/tabview";
import ImportExcel from "./components/ImportExcel";
import { Panel } from "primereact/panel";

const STATUS = [
  { name: "ENABLE", value: "enable" },
  { name: "DISABLE", value: "disable" },
];

const Crud = () => {
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState(null);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    status: ["enable", "disable"],
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 5,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);
  const refDetail = useRef(null);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  const loadData = async () => {
    try {
      const res: any = await CareerService.search({
        query: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
          limit: lazyParams.rows,
        },
      });
      if (res && res.docs) {
        setData(res.docs);
        setTotalRecords(res.totalDocs);
      } else setData([]);
    } catch (error) {
      setData([]);
    }
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  //CREATE OR NEW
  const openNew = () => {
    setDetail(null);
    refDialogDetail.current.show();
  };
  const editProduct = (data) => {
    setDetail({ ...data });
    refDialogDetail.current.show();
  };
  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };
  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };
  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  // DELETE
  const confirmDelete = (product) => {
    setDetail(product);
    refDialogDelete.current.show();
  };
  const handleDelete = async () => {
    try {
      await CareerService.deleted({
        params: {
          id: detail._id,
        },
      });
      const _data = data.filter((val) => val._id !== detail._id);
      setData(_data);
      refDialogDelete.current.close();
      setDetail(null);
      showToast(toast, "success", "User Deleted");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled={true}
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editProduct(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning"
          onClick={() => confirmDelete(rowData)}
        />
      </div>
    );
  };

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="grid px-2 align-items-center">
        <div className="col-12 md:col-6">
          <span className="block p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e: any) => onSearch("search", e.target.value)}
              placeholder="Search..."
              className="w-full"
            />
          </span>{" "}
        </div>
        <div className="col-12 md:col-6">
          <MultiSelect
            value={globalFilter.status}
            options={STATUS}
            onChange={(e: any) => onSearch("status", e.value)}
            optionLabel="name"
            placeholder="Status"
            maxSelectedLabels={3}
            className={"w-full"}
            showClear
          />
        </div>
        <div className="col-12 md:col-6">
          <Calendar
            className="w-full"
            id="time24"
            placeholder="Start Date"
            value={globalFilter.start}
            onChange={(e: any) => onSearch("start", e.value)}
            showTime
            showSeconds
            showButtonBar
          />{" "}
        </div>
        <div className="col-12 md:col-6">
          <Calendar
            className="w-full"
            id="time24"
            placeholder="End Date"
            value={globalFilter.end}
            onChange={(e: any) => onSearch("end", e.value)}
            showTime
            showSeconds
            showButtonBar
          />
        </div>
      </div>
    </Panel>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            <TabPanel header="List careers">
              <Toolbar
                className="mb-4"
                left={leftToolbarTemplate}
                right={rightToolbarTemplate}
              ></Toolbar>
              <DataTable
                ref={dt}
                value={data}
                header={header}
                emptyMessage="No data found."
                dataKey="id"
                size="small"
                scrollDirection="both"
                className="datatable-responsive"
                scrollable
                showGridlines
                lazy
                //panigate
                paginator
                first={lazyParams.first}
                rows={lazyParams.rows}
                totalRecords={totalRecords}
                rowsPerPageOptions={[5, 10, 20]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="{first} - {last} of {totalRecords}"
                onPage={onPage}
                //sort
                sortField={lazyParams.sortField}
                sortOrder={lazyParams.sortOrder}
                onSort={onSort}
              >
                <Column
                  frozen
                  alignFrozen="left"
                  body={actionBodyTemplate}
                  style={{ flexGrow: 1, flexBasis: "120px" }}
                ></Column>
                <Column
                  style={{ flexGrow: 1, flexBasis: "80px" }}
                  header="Image"
                  // frozen
                  body={(rowData) => {
                    return (
                      <Image
                        src={getUrlImage(rowData.logo)}
                        alt={rowData.name}
                        width="50"
                        height="50"
                        preview
                      />
                    );
                  }}
                ></Column>
                <Column
                  field="key"
                  header="Key"
                  sortable
                  style={{ flexGrow: 1, flexBasis: "350px" }}
                ></Column>
                <Column
                  field="name"
                  header="Name"
                  sortable
                  style={{ flexGrow: 1, flexBasis: "350px" }}
                ></Column>

                <Column
                  field="link"
                  header="Link"
                  sortable
                  style={{
                    flexGrow: 1,
                    flexBasis: "350px",
                    position: "relative",
                  }}
                ></Column>
                <Column
                  style={{ flexGrow: 1, flexBasis: "80px" }}
                  header="Front Visit"
                  // frozen
                  body={(rowData) => {
                    return (
                      <Image
                        src={getUrlImage(rowData?.background_card_visit?.front)}
                        alt={rowData.name}
                        width="50"
                        height="50"
                        preview
                      />
                    );
                  }}
                ></Column>
                <Column
                  style={{ flexGrow: 1, flexBasis: "80px" }}
                  header="Back Visit"
                  // frozen
                  body={(rowData) => {
                    return (
                      <Image
                        src={getUrlImage(rowData?.background_card_visit?.back)}
                        alt={rowData.name}
                        width="50"
                        height="50"
                        preview
                      />
                    );
                  }}
                ></Column>
                <Column
                  style={{ flexGrow: 1, flexBasis: "200px" }}
                  field="createdAt"
                  header="Created At"
                  sortable
                  body={(rowData) => formatLocalTime(rowData.createdAt)}
                ></Column>
              </DataTable>
            </TabPanel>
            <TabPanel header="Import Excel">
              <ImportExcel />
            </TabPanel>
          </TabView>

          <VDialog
            ref={refDialogDetail}
            header="Career Details"
            onSubmit={onSumitDialogDetail}
          >
            <Uploads
              ref={refDetail}
              data={detail}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
            />
          </VDialog>

          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Crud, comparisonFn);
