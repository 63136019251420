/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useContext,
  useCallback,
  useRef,
} from "react";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import UserService from "services/users";
import { getCategories, getUrlImage, showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import debounce from "lodash.debounce";
import {
  OBJECT_TYPE,
  ROLE,
  USER_STATUS,
  USER_KYC_STATUS,
  USER_GENDER,
  ACCOUNT_TYPE,
} from "utils/enum";
import VUpload from "components/v-upload";
import { Panel } from "primereact";
import { MasterContext } from "contexts/MasterContext";
import VLocation from "components/v-location";
import { TreeSelect } from "primereact/treeselect";
import CategoryService from "services/categories";
import { MultiSelect } from "primereact/multiselect";
import { TabPanel, TabView } from "primereact/tabview";
import { defaultSelectedAllFields, treeFields } from "utils/constant";
import CategoryFilterDetails from "components/v-filter";

const Details = (props, ref) => {
  const {
    data,
    reload,
    toast,
    onCancel,
    setLoading,
    isApplyFromAccountType = false,
    categoryFilters,
    getCategoryFilters,
    withFilter = false,
  } = props;

  const emptyData = {
    avatar: "",
    first_name: "",
    last_name: "",
    full_name: "",
    role: "USER",
    email: "",
    phone: "",
    status: "confirmed",
    password: "12345679@",
    is_leader: false,
    referred_by: "",
    percent_sales: 0,
    is_swap: true,
    is_withdraw: true,
    is_deposit: true,
    is_claim: true,
    id_region: false,
    id_province: false,
    percent_region: 0,
    percent_provice: 0,
    id_inherit: "",
    object_type: OBJECT_TYPE.USER,
    account_type: ACCOUNT_TYPE.NORMAL,
    is_contribute: false,
    status_kyc: "",
    career_id: null,
    location: {},
    home_town: {},
    nation_id: null,
    religion_id: null,
    family_id: null,
    category_field_id: null,
    category_id: null,
    gender: "MALE",
    sites: [],
    parent_id: null,
    attribute_id: null,
    vr360: "",
    is_show_footer: false,
  };

  const emptyProfile = {
    // career_id: null,
    // location: {},
    // home_town: {},
    // nation_id: null,
    // religion_id: null,
    // family_id: null,
    // category_field_id: null,
    // category_id: null,
    // gender: "MALE",
    website: null,
  };

  const emptyAdditionalData = {
    icon_maps: null,
    is_show_map: true,
    is_show_create_post: false,
    is_show_affiliate: true,
    icon_donate: null,
    product_type: null,
    attributes: [],
    fields: {},
    width: null,
    height: null,
    count: null,
    is_my_shop: false,
    is_free: false,
    product_view_type: null,
    is_root: false,
    category_filter_id: null,
    category_filter_send_id: null,
    category_filter_receive_id: null,
  };

  const [accountTypes, setAccountTypes] = useState([]);
  const [filterParents, setFilterParents] = useState([]);
  const [parentCurrentOptions, setParentCurrentOptions] = useState([]);
  const [parents, setParents] = useState<any>();
  const [details, setDetails] = useState(emptyData);
  const [profile, setProfile] = useState(emptyProfile);
  const [additionalData, setAdditionalData] = useState(emptyAdditionalData);
  const [attributesChild, setAttributesChild] = useState([]);
  const [attributesSelected, setAttributeSelected] = useState([]);
  const [notChangedAttribute, setNotChangedAttribute] = useState(true);
  const [selectedNodeFields, setSelectedNodeFields] = useState(null);
  const [types, setTypes] = useState([]);
  const {
    sites,
    careers,
    nations,
    religions,
    families,
    categoryFields,
    categories,
    attributeSets,
  } = useContext(MasterContext);
  const [activeIndex, setActiveIndex] = useState(0);

  const [lazyParams, setLazyParams]: any = useState({
    search: "",
    limit: 20,
    page: 1,
    account_type: null,
  });

  const refDetailCategoryReceive = useRef(null);
  const refDetailCategorySend = useRef(null);
  const refDetailCategory = useRef(null);

  useEffect(() => {
    getParents();
  }, [lazyParams]);

  const getParents = async () => {
    const res: any = await UserService.getAll({
      query: { ...lazyParams, account_type: details?.account_type },
    });
    setParents(res);
    setFilterParents((pre) =>
      [
        ...(lazyParams?.isScroll ? [...pre]?.concat(res.users) : res.users),
      ]?.filter((e: { _id: any }) => e?._id !== data?._id)
    );
  };

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    getAccountTypes();
  }, []);

  useEffect(() => {
    if (data) {
      setDetails(data);
      setProfile(data.profile || emptyProfile);
      if (data?.parent_id?.length > 0) {
        getParentInfo(data.parent_id);
      }
      if (data && data.attribute_id)
        setAttributesChild(
          attributeSets?.find((e) => e?._id === data?.attribute_id)?.attributes
        );
      if (data && data.additionalDataForm) {
        setAdditionalData({
          ...emptyAdditionalData,
          ...data.additionalDataForm,
        });
        setAttributeSelected(data.additionalDataForm?.attributes);
        setSelectedNodeFields(
          data.additionalDataForm?.fields || defaultSelectedAllFields
        );
      }

      if (data?.parent_id?.length > 0) {
        getParentInfo(data.parent_id);
      }
    }
  }, [data]);

  useEffect(() => {
    if (details) {
      setAdditionalData((prev) => ({
        ...prev,
        attributes: attributesChild?.map((e) => e?.id),
      }));
      getCategoryProductTypes();
    }
  }, [details]);

  const getParentInfo = async (id) => {
    const res: any = await UserService.getUserById({
      params: { id },
    });
    setParentCurrentOptions([res?.user]);
  };

  const getAccountTypes = async () => {
    const res: any = await CategoryService.getByGroup({
      query: {
        group: "account_type",
      },
    });
    setAccountTypes(res);
  };

  const getCategoryProductTypes = async () => {
    try {
      const categories: any = await CategoryService.getByGroup({
        query: {
          group: "product_category_type",
        },
      });
      setTypes(categories);
    } catch (error) {}
  };

  const onChangeProfile = (name, value) => {
    setProfile((prev) => ({
      ...prev,
      [name]: value || null,
    }));
  };

  const onChangeUser = (name, value) => {
    if (name === "attribute_id") {
      setAttributesChild(
        attributeSets?.find((e) => e?._id === value)?.attributes
      );
      setNotChangedAttribute(false);
    }
    setDetails((prev) => ({
      ...prev,
      [name]: value ?? null,
    }));
  };

  const onChangeAddData = (name, value) => {
    if (name === "attributes") {
      setAttributeSelected(value);
      setNotChangedAttribute(false);
    }
    let _addData = { ...additionalData };
    _addData[`${name}`] = value;
    setAdditionalData(_addData);
  };

  const onInputNumberChange = (e, name) => {
    const val = e.value || 0;
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const onInputCheckChange = (e, name) => {
    const val = e.value || false;
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const handleFilterParent = (event) => {
    setLazyParams((pre) => ({
      ...pre,
      search: event.filter,
      isScroll: false,
      page: 1,
    }));
  };

  const submit = async () => {
    try {
      setLoading(true);
      let category = null;
      let categoryReceive = null;
      let categorySend = null;
      if (!additionalData.category_filter_id) {
        if (refDetailCategory && refDetailCategory?.current) {
          category = await refDetailCategory.current.submit();
        }
      }

      if (!additionalData.category_filter_id) {
        if (refDetailCategory && refDetailCategory?.current) {
          categoryReceive = await refDetailCategoryReceive.current.submit();
        }
      }

      if (!additionalData.category_filter_send_id) {
        if (refDetailCategorySend && refDetailCategorySend?.current) {
          categorySend = await refDetailCategorySend.current.submit();
        }
      }

      // getCategoryFilters();

      if (data) {
        await UserService.updateUser({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            profile,
            additionalDataForm: {
              ...additionalData,
              category_filter_id: category
                ? category._id
                : additionalData.category_filter_id,
              category_filter_receive_id: categoryReceive
                ? categoryReceive._id
                : additionalData.category_filter_receive_id,
              category_filter_send_id: categorySend
                ? categorySend._id
                : additionalData.category_filter_send_id,
            },
          },
        });
      } else {
        await UserService.createUser({
          body: {
            ...details,
            profile,
            additionalDataForm: {
              ...additionalData,
              category_filter_id: category
                ? category._id
                : additionalData.category_filter_id,
              category_filter_receive_id: categoryReceive
                ? categoryReceive._id
                : additionalData.category_filter_receive_id,
              category_filter_send_id: categorySend
                ? categorySend._id
                : additionalData.category_filter_send_id,
            },
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "User saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const onFilterParent = useCallback(debounce(handleFilterParent, 500), []);

  const parentOptionsTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option?.full_name}
          src={getUrlImage(option?.avatar)}
          className={`mr-2 flag flag-${option.name?.toLowerCase()}`}
          style={{ width: "18px" }}
        />
        <div>{option?.full_name}</div>
      </div>
    );
  };

  const onScroll = (event) => {
    if (event.last % 15 === 0 && parents?.hasNextPage) {
      setLazyParams((pre) => ({ ...pre, page: pre.page + 1, isScroll: true }));
    }
  };

  return (
    <>
      <TabView
        className="field col-12"
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        <TabPanel key={"Info"} header="Info">
          <div className="field col-12">
            <Panel header="General" toggleable className="my-2">
              <div className="grid">
                <div className="col-12 ">
                  <VUpload
                    urlFile={details.avatar}
                    setUrlFile={(file) => onChangeUser("avatar", file)}
                  />
                </div>

                <div className="field col-12 md:col-6">
                  <label htmlFor="name">Email</label>
                  <InputText
                    id="email"
                    value={details.email}
                    onChange={(e) => onChangeUser("email", e.target.value)}
                    required
                    autoFocus
                  />
                </div>

                <div className="field col-12 md:col-6">
                  <label htmlFor="description">Phone</label>
                  <InputText
                    id="phone"
                    value={details.phone}
                    onChange={(e) => onChangeUser("phone", e.target.value)}
                    required
                  />
                </div>
                <div className="field col-12 md:col-6">
                  <label htmlFor="first_name">First Name</label>
                  <InputText
                    id="first_name"
                    value={details.first_name}
                    onChange={(e) => onChangeUser("first_name", e.target.value)}
                    required
                  />
                </div>
                <div className="field col-12 md:col-6">
                  <label htmlFor="last_name">Last Name</label>
                  <InputText
                    id="last_name"
                    value={details.last_name}
                    onChange={(e) => onChangeUser("last_name", e.target.value)}
                    required
                  />
                </div>
                <div className="field col-12 md:col-12">
                  <label htmlFor="last_name">Full Name</label>
                  <InputText
                    id="full_name"
                    value={details.full_name}
                    onChange={(e) => onChangeUser("full_name", e.target.value)}
                    required
                  />
                </div>

                <div className="field col-12 md:col-6">
                  <label htmlFor="description">Object</label>
                  <Dropdown
                    value={details.object_type}
                    options={Object.keys(OBJECT_TYPE).map((k) => ({
                      label: k,
                      value: OBJECT_TYPE[k],
                    }))}
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Select object"
                    onChange={(e) => onChangeUser("object_type", e.value)}
                  />
                </div>

                <div className="field col-12 md:col-6">
                  <label htmlFor="description">Account Type</label>
                  {/* <Dropdown
                    value={details?.account_type}
                    options={accountTypes?.map((c) => ({
                      label: c.name,
                      value: c.key,
                    }))}
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Select account type"
                    onChange={(e) => onChangeUser("account_type", e.value)}
                    showClear
                  /> */}
                  <MultiSelect
                    value={
                      Array.isArray(details?.account_type)
                        ? details?.account_type
                        : [details?.account_type]
                    }
                    options={accountTypes?.map((c) => ({
                      label: c.name,
                      value: c.key,
                    }))}
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Select account type"
                    onChange={(e) => onChangeUser("account_type", e.value)}
                    showClear
                  />
                </div>

                <div className="field col-6">
                  <label htmlFor="name">Parent</label>
                  <Dropdown
                    value={details?.parent_id}
                    options={[...parentCurrentOptions, ...filterParents]}
                    optionLabel="full_name"
                    optionValue="_id"
                    filter
                    showFilterClear
                    onFilter={onFilterParent}
                    showClear
                    itemTemplate={parentOptionsTemplate}
                    virtualScrollerOptions={{
                      lazy: true,
                      onLazyLoad: onScroll,
                      itemSize: 40,
                    }}
                    onChange={(e) => onChangeUser("parent_id", e.value)}
                  />
                </div>

                <div className="field col-12 md:col-3">
                  <label htmlFor="description">Role</label>
                  <Dropdown
                    value={details.role}
                    options={Object.keys(ROLE).map((k) => ({
                      label: k,
                      value: ROLE[k],
                    }))}
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Select role"
                    onChange={(e) => onChangeUser("role", e.value)}
                  />
                </div>
                <div className="field col-12 md:col-3">
                  <label htmlFor="description">Status</label>
                  <Dropdown
                    value={details.status}
                    options={Object.keys(USER_STATUS).map((k) => ({
                      label: k,
                      value: USER_STATUS[k],
                    }))}
                    optionLabel="label"
                    optionValue="value"
                    onChange={(e) => onChangeUser("status", e.value)}
                  />
                </div>
                <div className="field col-12 ">
                  <label htmlFor="description">VR360</label>
                  <InputText
                    id="vr360"
                    value={details.vr360}
                    onChange={(e) =>
                      onChangeUser("vr360", e.target.value)
                    }
                    required
                  />
                </div>
                <div className="field col-12 md:col-6">
                  <label htmlFor="description">ID Sponsor</label>
                  <InputText
                    id="referred_by"
                    value={details.referred_by}
                    onChange={(e) =>
                      onChangeUser("referred_by", e.target.value)
                    }
                    required
                  />
                </div>
                <div className="field col-12 md:col-6">
                  <label htmlFor="description">Kycs & Verifies</label>
                  <Dropdown
                    value={details.status_kyc}
                    options={Object.keys(USER_KYC_STATUS).map((k) => ({
                      label: k,
                      value: USER_KYC_STATUS[k],
                    }))}
                    optionLabel="label"
                    optionValue="value"
                    onChange={(e) => onChangeUser("status_kyc", e.value)}
                  />
                </div>
                <div className="field col-12 md:col-6">
                  <label htmlFor="is_contribute">Is Contribute</label>
                  <br />
                  <InputSwitch
                    checked={details.is_contribute}
                    onChange={(e) => onInputCheckChange(e, "is_contribute")}
                  />
                </div>
                <div className="field col-12 md:col-6">
                  <label htmlFor="is_contribute">Is show footer</label>
                  <br />
                  <InputSwitch
                    checked={details.is_show_footer}
                    onChange={(e) => onInputCheckChange(e, "is_show_footer")}
                  />
                </div>
              </div>
            </Panel>

            <Panel
              header="Commisson"
              toggleable
              className="my-2"
              collapsed={true}
            >
              <div className="grid">
                <div className="field col-12 md:col-4">
                  <label htmlFor="description">Percent Sale</label>
                  <InputNumber
                    id="percent_sales"
                    prefix="%"
                    value={details.percent_sales}
                    onValueChange={(e) =>
                      onInputNumberChange(e, "percent_sales")
                    }
                  />
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="description">Percent Region</label>
                  <InputNumber
                    id="percent_region"
                    prefix="%"
                    value={details.percent_region}
                    onValueChange={(e) =>
                      onInputNumberChange(e, "percent_region")
                    }
                  />
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="description">Percent Provice</label>
                  <InputNumber
                    id="percent_provice"
                    prefix="%"
                    value={details.percent_provice}
                    onValueChange={(e) =>
                      onInputNumberChange(e, "percent_provice")
                    }
                  />
                </div>

                <div className="field col-6 md:col-4">
                  <label htmlFor="is_leader">ID Leader</label>
                  <br />
                  <InputSwitch
                    checked={details.is_leader}
                    onChange={(e) => onInputCheckChange(e, "is_leader")}
                  />
                </div>
                <div className="field col-6 md:col-4">
                  <label htmlFor="id_region">ID Region</label>
                  <br />
                  <InputSwitch
                    checked={details.id_region}
                    onChange={(e) => onInputCheckChange(e, "id_region")}
                  />
                </div>
                <div className="field col-6 md:col-4">
                  <label htmlFor="id_province">ID province</label>
                  <br />
                  <InputSwitch
                    checked={details.id_province}
                    onChange={(e) => onInputCheckChange(e, "id_province")}
                  />
                </div>

                <div className="field col-6 md:col-3">
                  <label htmlFor="is_swap">Is Swap</label>
                  <br />
                  <InputSwitch
                    checked={details.is_swap}
                    onChange={(e) => onInputCheckChange(e, "is_swap")}
                  />
                </div>
                <div className="field col-6 md:col-3">
                  <label htmlFor="is_claim">Is Claim</label>
                  <br />
                  <InputSwitch
                    checked={details.is_claim}
                    onChange={(e) => onInputCheckChange(e, "is_claim")}
                  />
                </div>
                <div className="field col-6 md:col-3">
                  <label htmlFor="is_deposit">Is Deposit</label>
                  <br />
                  <InputSwitch
                    checked={details.is_deposit}
                    onChange={(e) => onInputCheckChange(e, "is_deposit")}
                  />
                </div>
                <div className="field col-6 md:col-3">
                  <label htmlFor="is_withdraw">Is Withdraw</label>
                  <br />
                  <InputSwitch
                    checked={details.is_withdraw}
                    onChange={(e) => onInputCheckChange(e, "is_withdraw")}
                  />
                </div>
              </div>
            </Panel>

            <Panel
              header="Profile"
              toggleable
              className="my-2"
              collapsed={true}
            >
              <div className="grid">
                <div className="field col-12 ">
                  <label htmlFor="name">Sites</label>
                  <MultiSelect
                    filter
                    value={details.sites}
                    options={sites}
                    optionLabel="name"
                    optionValue="domain"
                    onChange={(e) => onChangeUser("sites", e.value)}
                  />
                </div>
                <div className="field col-12 md:col-6">
                  <label htmlFor="name">Gender</label>
                  <Dropdown
                    value={details.gender}
                    options={Object.keys(USER_GENDER).map((k) => ({
                      label: k,
                      value: USER_GENDER[k],
                    }))}
                    optionLabel="label"
                    optionValue="value"
                    onChange={(e) => onChangeUser("gender", e.value)}
                  />
                </div>
                <div className="field col-12 md:col-6">
                  <label htmlFor="name">Family</label>
                  <Dropdown
                    filter
                    value={details.family_id}
                    options={families.map((c) => ({
                      label: c.name,
                      value: c._id,
                    }))}
                    optionLabel="label"
                    optionValue="value"
                    onChange={(e) => onChangeUser("family_id", e.value)}
                    showClear
                  />
                </div>
                <div className="field col-12 md:col-6">
                  <label htmlFor="name">Career</label>
                  <Dropdown
                    filter
                    value={details.career_id}
                    options={careers.map((c) => ({
                      label: c.name,
                      value: c._id,
                    }))}
                    optionLabel="label"
                    optionValue="value"
                    onChange={(e) => onChangeUser("career_id", e.value)}
                    showClear
                  />
                </div>
                <div className="field col-12 md:col-6">
                  <label htmlFor="name">Nation</label>
                  <Dropdown
                    value={details.nation_id}
                    filter
                    options={nations.map((c) => ({
                      label: c.name,
                      value: c._id,
                    }))}
                    optionLabel="label"
                    optionValue="value"
                    onChange={(e) => onChangeUser("nation_id", e.value)}
                    showClear
                  />
                </div>
                <div className="field col-12 md:col-6">
                  <label htmlFor="name">Category Fields</label>
                  <Dropdown
                    filter
                    value={details.category_field_id}
                    options={categoryFields.map((c) => ({
                      label: c.name,
                      value: c._id,
                    }))}
                    optionLabel="label"
                    optionValue="value"
                    onChange={(e) => onChangeUser("category_field_id", e.value)}
                    showClear
                  />
                </div>
                <div className="field col-12 md:col-6">
                  <label htmlFor="name">Religion</label>
                  <Dropdown
                    filter
                    value={details.religion_id}
                    options={religions.map((c) => ({
                      label: c.name,
                      value: c._id,
                    }))}
                    optionLabel="label"
                    optionValue="value"
                    onChange={(e) => onChangeUser("religion_id", e.value)}
                    showClear
                  />
                </div>
                <div className="field col-12 ">
                  <label htmlFor="name">Category Product</label>
                  <TreeSelect
                    value={details.category_id}
                    filter
                    options={getCategories(categories)}
                    onChange={(e) => onChangeUser("category_id", e.value)}
                    className="w-full"
                    placeholder="Select Parent"
                  ></TreeSelect>
                </div>

                <div className="field col-12">
                  <label htmlFor="name">Home Town</label>
                  <VLocation
                    address={details.home_town}
                    setAddress={(a) => onChangeUser("home_town", a)}
                  />
                </div>
                <div className="field col-12">
                  <label htmlFor="name">Address current</label>
                  <VLocation
                    address={details.location}
                    setAddress={(a) => onChangeUser("location", a)}
                  />
                </div>
                <div className="field col-12 md:col-6">
                  <label htmlFor="description">Website</label>
                  <InputText
                    id="referred_by"
                    value={profile.website}
                    onChange={(e) => onChangeProfile("website", e.target.value)}
                    required
                  />
                </div>
              </div>
            </Panel>

            {withFilter && (
              <Panel
                header="Filters"
                toggleable
                className="my-2"
                collapsed={true}
              >
                <div className="grid">
                  {/* Hiển thị */}
                  <div className="field col-12">
                    <label htmlFor="status">Filter</label>
                    <Dropdown
                      filter
                      showClear
                      value={additionalData.category_filter_id}
                      options={categoryFilters.map((item) => ({
                        label: item.name,
                        value: item._id,
                      }))}
                      optionLabel="label"
                      optionValue="value"
                      placeholder="Select filter"
                      onChange={(e) =>
                        onChangeAddData("category_filter_id", e.value)
                      }
                    />
                  </div>
                  <div className="field col-12">
                    <Panel
                      header="Filter"
                      toggleable
                      className="my-2"
                      collapsed={true}
                    >
                      <div className="grid">
                        <div className="field col-12">
                          <CategoryFilterDetails
                            name_key="category_filter_id"
                            ref={refDetailCategory}
                            onChangeOptions={(e) =>
                              onChangeAddData(
                                "category_filter_id",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </Panel>
                  </div>

                  {/* Nhận */}
                  <div className="field col-12">
                    <label htmlFor="status">Filter Receive</label>
                    <Dropdown
                      filter
                      showClear
                      value={additionalData.category_filter_receive_id}
                      options={categoryFilters.map((item) => ({
                        label: item.name,
                        value: item._id,
                      }))}
                      optionLabel="label"
                      optionValue="value"
                      placeholder="Select filter"
                      onChange={(e) =>
                        onChangeAddData(
                          "category_filter_receive_id",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div className="field col-12">
                    <Panel
                      header="Filter Receive"
                      toggleable
                      className="my-2"
                      collapsed={true}
                    >
                      <div className="grid">
                        <div className="field col-12">
                          <CategoryFilterDetails
                            name_key="category_filter_receive_id"
                            ref={refDetailCategoryReceive}
                            onChangeOptions={(e) =>
                              onChangeAddData(
                                "category_filter_receive_id",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </Panel>
                  </div>

                  {/* Đăng */}
                  <div className="field col-12">
                    <label htmlFor="status">Filter Send</label>
                    <Dropdown
                      filter
                      showClear
                      value={additionalData.category_filter_send_id}
                      options={categoryFilters.map((item) => ({
                        label: item.name,
                        value: item._id,
                      }))}
                      optionLabel="label"
                      optionValue="value"
                      placeholder="Select filter"
                      onChange={(e) =>
                        onChangeAddData(
                          "category_filter_send_id",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div className="field col-12">
                    <Panel
                      header="Filter Send"
                      toggleable
                      className="my-2"
                      collapsed={true}
                    >
                      <div className="grid">
                        <div className="field col-12">
                          <CategoryFilterDetails
                            name_key="category_filter_send_id"
                            ref={refDetailCategorySend}
                            onChangeOptions={(e) =>
                              onChangeAddData(
                                "category_filter_send_id",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </Panel>
                  </div>
                </div>
              </Panel>
            )}
          </div>
        </TabPanel>
        {isApplyFromAccountType && (
          <TabPanel key={"Product"} header="Product">
            <div className="field col-12">
              <Panel
                header="Product"
                toggleable
                className="my-2"
                collapsed={false}
              >
                <div className="grid">
                  <div className="flex field col-12">
                    <div className="field col-6">
                      <label htmlFor="attribute">Group Attribute</label>
                      <Dropdown
                        value={details.attribute_id}
                        options={attributeSets?.map((k) => ({
                          label: k.title,
                          value: k._id,
                        }))}
                        showClear
                        optionLabel="label"
                        optionValue="value"
                        filter
                        onChange={(e) => onChangeUser("attribute_id", e.value)}
                      />
                    </div>
                    <div className="field col-6">
                      <label htmlFor="felds">Fields</label>
                      <TreeSelect
                        selectionMode="checkbox"
                        value={selectedNodeFields}
                        filter
                        options={[...treeFields]}
                        onChange={(e: any) => setSelectedNodeFields(e?.value)}
                        className="w-full"
                        placeholder="Select Fields"
                      ></TreeSelect>
                      {/* <MultiSelect
            value={additionalData.fields}
            options={fields}
            filter
            showClear
            optionLabel="label"
            optionValue="value"
            placeholder="Select fields"
            onChange={(e) => {
              onChangeAddData("fields", e.target.value);
            }}
          /> */}
                    </div>
                  </div>
                  <div className="flex field col-6">
                    <div className="field col-12">
                      <label htmlFor="status">Attributes</label>
                      <MultiSelect
                        value={
                          notChangedAttribute
                            ? attributesSelected
                            : additionalData.attributes
                        }
                        options={attributesChild?.map((item) => ({
                          label: item.label_name,
                          value: item.id,
                        }))}
                        filter
                        showClear
                        optionLabel="label"
                        optionValue="value"
                        placeholder="Select attributes"
                        onChange={(e) => {
                          onChangeAddData("attributes", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="field col-6">
                    <label htmlFor="description">Product Type</label>
                    <Dropdown
                      value={additionalData.product_type}
                      options={types.map((k) => ({
                        label: k.name,
                        value: k.key,
                      }))}
                      optionLabel="label"
                      optionValue="value"
                      onChange={(e) => onChangeAddData("product_type", e.value)}
                      showClear
                    />
                  </div>
                  <div className="field col-6">
                    <label htmlFor="description">Product View Type</label>
                    <Dropdown
                      value={additionalData.product_view_type}
                      options={[
                        {
                          label: "Normal",
                          value: "normal",
                        },
                        {
                          label: "Thân nhân",
                          value: "relative",
                        },
                      ]}
                      optionLabel="label"
                      optionValue="value"
                      onChange={(e) =>
                        onChangeAddData("product_view_type", e.value)
                      }
                      showClear
                    />
                  </div>
                  <div className="field col-12 md:col-3">
                    <label htmlFor="is_enable_claim">Is my shop</label>
                    <br />
                    <InputSwitch
                      checked={additionalData?.is_my_shop}
                      onChange={(e) => onChangeAddData("is_my_shop", e.value)}
                    />
                  </div>
                  <div className="field col-12 md:col-3">
                    <label htmlFor="is_enable_claim">Show map</label>
                    <br />
                    <InputSwitch
                      checked={additionalData?.is_show_map}
                      onChange={(e) => onChangeAddData("is_show_map", e.value)}
                    />
                  </div>
                  <div className="field col-12 md:col-3">
                    <label htmlFor="is_enable_claim">Is Free</label>
                    <br />
                    <InputSwitch
                      checked={additionalData?.is_free}
                      onChange={(e) => onChangeAddData("is_free", e.value)}
                    />
                  </div>
                  <div className="field col-12 md:col-3">
                    <label htmlFor="is_root">Is Root</label>
                    <br />
                    <InputSwitch
                      checked={additionalData?.is_root}
                      onChange={(e) => onChangeAddData("is_root", e.value)}
                    />
                  </div>

                  <div className="field col-4">
                    <label>Icon Maps</label>
                    <div className="mt-2">
                      <VUpload
                        urlFile={additionalData?.icon_maps}
                        setUrlFile={(file) =>
                          onChangeAddData("icon_maps", file)
                        }
                      />
                    </div>
                  </div>
                  <div className="field col-4">
                    <label>Icon Donate</label>
                    <div className="mt-2">
                      <VUpload
                        urlFile={additionalData?.icon_donate}
                        setUrlFile={(file) =>
                          onChangeAddData("icon_donate", file)
                        }
                      />
                    </div>
                  </div>
                </div>
              </Panel>
            </div>
          </TabPanel>
        )}
      </TabView>
    </>
  );
};

export default forwardRef(Details);
