/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import VEditor from "components/v-editor";
import Service from "services/qas";
import VUpload from "components/v-upload";
import FormAdditional from "components/v-form-additional";
import { Checkbox } from "primereact/checkbox";
import { TreeSelect } from "primereact/treeselect";

const Details = (props, ref) => {
  const { list, data, reload, toast, onCancel, setLoading } = props;
  const emptyAdditionalData = {
    enable: false,
    name: "",
    key: "",
    type: "",
    fields: [],
    attachments: {
      enable: false,
      guide: null,
    },
  };
  const emptyData = {
    name: "",
    icon: "",
    key: "",
    description: "",
    category_faq_id: "",
    content: "",
    form_template_id: "",
  };

  const [details, setDetails] = useState(emptyData);
  const [checked, setChecked] = useState(false);
  const [additionalData, setAdditionalData] = useState(emptyAdditionalData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  const getCategories = (data) => {
    let treeData;
    const mapData = (list: any) => {
      const dataChil = list.map((item: any) => chil(item));
      return dataChil.length > 0 ? dataChil : null;
    };
    const chil = (ct: any) => {
      const chilData = data.filter(
        (category) => String(category.parent_id) === String(ct._id)
      );
      return {
        ...ct,
        key: ct._id,
        label: ct.name,
        children: mapData(chilData),
      };
    };

    treeData = data
      .filter((ct) => !Boolean(ct.parent_id))
      .map((el) => chil(el));
    return treeData;
  };

  useEffect(() => {
    if (data) setDetails(data);
    if (data && data.additionalDataForm)
      setAdditionalData({
        ...emptyAdditionalData,
        ...data.additionalDataForm,
      });
  }, [data]);

  const onChange = (name, value) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await Service.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            additionalDataForm: additionalData,
          },
        });
      } else
        await Service.create({
          body: {
            ...details,
            additionalDataForm: additionalData,
          },
        });
      setLoading(false);
      showToast(toast, "success", "File saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const changeHandlerContent = (content) => {
    setDetails((data) => ({
      ...data,
      content: content,
    }));
  };

  useEffect(() => {
    setChecked(true);
  }, [details.content]);

  return (
    <div className="grid">
      <div className="field col-12">
        <label>Icon</label>
        <div className="mt-2">
          <VUpload
            urlFile={details.icon}
            setUrlFile={(file) => onChange("icon", file)}
          />
        </div>
      </div>
      <div className="field col-6">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange("name", e.target.value)}
          required
          autoFocus
        />
      </div>

      <div className="field col-6">
        <label htmlFor="name">Key</label>
        <InputText
          id="key"
          value={details.key}
          onChange={(e) => onChange("key", e.target.value)}
          required
          autoFocus
        />
      </div>

      <div className="field col-6">
        <label htmlFor="key">Description</label>
        <InputText
          id="key"
          value={details.description}
          onChange={(e) => onChange("description", e.target.value)}
          required
          autoFocus
        />
      </div>
      <div className="field col-6">
        <label htmlFor="key">Select Category</label>
        <TreeSelect
          value={details.category_faq_id}
          filter
          options={getCategories(list)}
          onChange={(e: any) => onChange("category_faq_id", e?.value)}
          className="w-full"
          placeholder="Select Category"
        ></TreeSelect>
      </div>
      <div className="field col-12 md:col-12">
        <label htmlFor="content">Content</label>
        <br />
        {checked && (
          // <Editor
          //   id="content"
          //   className="h-16rem"
          //   value={details.content}
          //   onTextChange={(e) => changeHandlerContent(e.htmlValue)}
          // />
          <VEditor
            id="content"
            className="h-16rem"
            value={details.content}
            onChange={(e) => {
              changeHandlerContent(e?.html);
            }}
            haveData={details.content ? true : false}
          />
        )}
      </div>
      <div className="field col-12 md:col-12  mt-6">
        <div className="flex mb-2">
          <label htmlFor="key">Use form Support</label>
          <div className="ml-2">
            <Checkbox
              onChange={(e) =>
                setAdditionalData({
                  ...additionalData,
                  enable: e.checked,
                })
              }
              checked={additionalData.enable}
            ></Checkbox>
          </div>
        </div>
        {additionalData.enable && (
          <FormAdditional
            dataForm={additionalData}
            setDataForm={setAdditionalData}
          />
        )}
      </div>
    </div>
  );
};

export default forwardRef(Details);
