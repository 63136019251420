import { request, parseErrorResponse } from "./request";
import qs from "query-string";

const searchVirtualUsers = (req: any) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/users-virtual/search-virtual-users?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const createVirtualUsers = (data: { body: any }) =>
  new Promise((rs, rj) => {
    request()
      .post("admin/users-virtual/create-virtual-users", data.body)
      .then((res) => {
        const { data } = res.data;
        if (data) rs(data);
        else {
          rj(new Error("Something went error"));
        }
      })
      .catch(parseErrorResponse)
      .then(rj);
  });

const deleteVirtualUsers = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post("admin/users-virtual/delete-virtual-users", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else {
          reject(new Error("Something went error"));
        }
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const UserVirtualService = {
  searchVirtualUsers,
  createVirtualUsers,
  deleteVirtualUsers,
};

export default UserVirtualService;
