import qs from "query-string";
import { parseErrorResponse, request } from "./request";
import { IRequestData } from "./s3-request";

const create = (req: { body: Record<string, unknown> }) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/admin/attribute-set`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const search = (req: { query: any }) =>
  new Promise(async (resolve, reject) => {
    try {
      const query = qs.stringify(req.query);
      request()
        .get(`admin/attribute-set/search?${query}`)
        .then((res) => {
          const { data } = res.data;
          if (data) {
            resolve(data);
          } else {
            reject(new Error("Something went error!"));
          }
        })
        .catch(parseErrorResponse)
        .then(reject);
    } catch (error) {}
  });

const deleted = (req: IRequestData) =>
  new Promise(async (resolve, reject) => {
    try {
      request()
        .delete(`admin/attribute-set/${req.params.id}`)
        .then((res) => {
          const { data } = res.data;
          if (data) {
            resolve(data);
          } else {
            reject(new Error("Something went error!"));
          }
        })
        .catch(parseErrorResponse)
        .then(reject);
    } catch (error) {}
  });

const update = (req: IRequestData) =>
  new Promise(async (resolve, reject) => {
    try {
      request()
        .put(`admin/attribute-set/${req.params.id}`, req.body)
        .then((res) => {
          const { data } = res.data;
          if (data) {
            resolve(data);
          } else {
            reject(new Error("Something went error!"));
          }
        })
        .catch(parseErrorResponse)
        .then(reject);
    } catch (error) {}
  });

const findAll = () =>
  new Promise(async (resolve, reject) => {
    try {
      request()
        .get(`admin/attribute-set`)
        .then((res) => {
          const { data } = res.data;
          if (data) {
            resolve(data);
          } else {
            reject(new Error("Something went error!"));
          }
        })
        .catch(parseErrorResponse)
        .then(reject);
    } catch (error) {}
  });

const AttributeSetService = {
  search,
  findAll,
  deleted,
  update,
  create,
};

export default AttributeSetService;
