import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { nanoid } from "nanoid";
import VUpload from "components/v-upload";
import { InputSwitch } from "primereact/inputswitch";
import { showToast } from "utils/common";
import CaptchaService from "services/captcha";

const Detail = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;
  const emptyData = {
    title: "",
    options: [],
    type: "image",
  };
  const [details, setDetails] = useState(emptyData);

  useEffect(() => {
    if (data) setDetails(data);
  }, [data]);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  const handleChangeDetail = (name: string, value: string) => {
    setDetails((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleChangeImage = (id: string, name: string, value: any) => {
    setDetails((state) => ({
      ...state,
      options: state.options.map((item) => {
        if (item.id === id) item[name] = value;
        return item;
      }),
    }));
  };

  const handleRenderDetail = () => {
    switch (details.type) {
      case "image":
        return (
          <div className="flex justify-content-between">
            {details.options.map((item) => (
              <div>
                <VUpload
                  urlFile={item.src}
                  setUrlFile={(file) => handleChangeImage(item.id, "src", file)}
                />
                <div className="flex align-items-center mt-2">
                  <label htmlFor="" className="mr-2">
                    Correct
                  </label>
                  <InputSwitch
                    checked={item.correct}
                    onChange={(e) =>
                      handleChangeImage(item.id, "correct", e.value)
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        );
      case "question":
        return <></>;
    }
  };

  const handleAdd = () => {
    const template = {
      id: nanoid(8),
      src: "",
      correct: false,
    };
    setDetails((state) => ({
      ...state,
      options: [...state.options, template],
    }));
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data?._id) {
        await CaptchaService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else {
        await CaptchaService.create({
          body: {
            ...details,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Success");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div>
      <div className="grid col-12">
        <div className="field col-6">
          <label htmlFor="title">Title</label>
          <InputText
            id="title"
            value={details.title}
            onChange={(e) => handleChangeDetail("title", e.target.value)}
          />
        </div>
        <div className="field col-6">
          <label htmlFor="">Type</label>
          <Dropdown
            value={details.type}
            options={[
              {
                label: "QUESTION",
                value: "question",
              },
              {
                label: "IMAGE",
                value: "image",
              },
            ]}
            onChange={(e) => handleChangeDetail("type", e.target.value)}
          />
        </div>
      </div>
      <Button
        onClick={handleAdd}
        icon="bx bxs-add-to-queue"
        className="p-button-rounded p-button-success mb-3"
        aria-label="Search"
      />
      {handleRenderDetail()}
    </div>
  );
};

export default forwardRef(Detail);
