/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import Service from "services/generate-info-virtual-user";
import { Dropdown } from "primereact/dropdown";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    full_name: "",
    email: "",
    domain: "",
    gender: "MALE",
  };

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
  }, [data]);

  const onChange = (name, value) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await Service.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else
        await Service.create({
          body: {
            ...details,
          },
        });
      setLoading(false);
      showToast(toast, "success", "Info saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-6">
        <label htmlFor="name">Email</label>
        <InputText
          id="email"
          value={details.email}
          onChange={(e) => onChange("email", e.target.value)}
          required
          autoFocus
        />
      </div>
      <div className="field col-6">
        <label htmlFor="domain">Domain</label>
        <InputText
          id="domain"
          value={details.domain}
          placeholder="@gmail.com"
          onChange={(e) => onChange("domain", e.target.value)}
          required
          autoFocus
        />
      </div>
      <div className="field col-6">
        <label htmlFor="full_name">Full Name</label>
        <InputText
          id="full_name"
          value={details.full_name}
          onChange={(e) => onChange("full_name", e.target.value)}
          required
          autoFocus
        />
      </div>
      <div className="field col-6">
        <label htmlFor="gender">Gender</label>
        <Dropdown
          defaultValue={details.gender}
          value={details.gender}
          options={[
            {
              label: "MALE",
              value: "MALE",
            },
            {
              label: "FEMALE",
              value: "FEMALE",
            },
            {
              label: "ORTHER",
              value: "ORTHER",
            },
          ]}
          onChange={(e) => onChange("gender", e.value)}
          optionLabel="label"
          placeholder="Select gender"
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
