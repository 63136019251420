import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Toast } from "primereact";
import SettingService from "services/settings";

const folders = ["default", "summer"];

const View = () => {
  const [state, setState] = useState<any>("");
  const toast = useRef(null);

  const onChange = async (value) => {
    setState(value);
    await SettingService.updateSetting({
      body: {
        name: "icon_set",
        value: value,
      },
    });
  };

  const getData = async () => {
    try {
      const res: any = await SettingService.getSettingsByName({
        params: {
          name: "icon_set",
        },
      });
      if (res) {
        const { setting } = res;
        if (setting) {
          const value = setting["value"] ? setting["value"] : "";
          setState(value);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="grid">
      <Toast ref={toast} />
      <div className="col-12">
        <div className="card">
          <h4>Icon set</h4>
          <span className="p-float-label">
            <Dropdown
              value={state}
              className="w-full"
              options={folders.map((f) => ({
                name: f,
                value: f,
              }))}
              optionValue={"value"}
              optionLabel={"name"}
              onChange={(e) => onChange(e.value)}
              placeholder="Currency"
            />
            <label htmlFor="currency">Icon set</label>
          </span>
        </div>
      </div>
    </div>
  );
};

export default View;
