/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import postBackgroundsService from "services/post-backgrounds";
import CategoryPostService from "services/category-posts";
import VUpload from "components/v-upload";
import { Dropdown } from "primereact/dropdown";
import { InputNumber, Panel } from "primereact";
import CategoryFilterDetails from "components/v-filter";
// import VEditor from "components/v-editor";
import { RadioButton } from "primereact/radiobutton";
import { BACKGROUND_TYPE } from "utils/enum";

const Details = (props, ref) => {
  const {
    data,
    reload,
    toast,
    onCancel,
    setLoading,
    categoryFilters,
    getCategoryFilters,
  } = props;
  const [options, setOptions] = useState([]);
  const refDetailCategory = useRef(null);

  const getCategories = () => {
    CategoryPostService.getCategories({
      query: {
        category: "background",
      },
    }).then((res: any) => {
      if (res && res.categories) {
        const data = [...res.categories].map((i) => ({
          label: i.name.toString(),
          value: i._id,
        }));
        setOptions(data);
      }
    });
  };

  const emptyData = {
    type_file: "image",
    video: "",
    image: "",
    name: "",
    text_content_color: "",
    text_bg_color: "",
    category_id: "",
    type: "",
    price: 0,
    category_filter_id: null,
    order: 0,
    description: "",
    thumbnail: "",
  };

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (data)
      setDetails({
        ...emptyData,
        ...data,
      });
  }, [data]);

  const onChange = (name, value) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      let category = null;
      if (!details.category_filter_id) {
        category = await refDetailCategory.current.submit();
        getCategoryFilters();
      }

      if (data) {
        await postBackgroundsService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
            type_of_use: BACKGROUND_TYPE.PROFILE,
          },
        });
      } else
        await postBackgroundsService.create({
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
            type_of_use: BACKGROUND_TYPE.PROFILE,
          },
        });
      setLoading(false);
      showToast(toast, "success", "File saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12 md:col-6">
        <label htmlFor="status">Filter</label>
        <Dropdown
          filter
          showClear
          value={details.category_filter_id}
          options={categoryFilters.map((item) => ({
            label: item.name,
            value: item._id,
          }))}
          optionLabel="label"
          optionValue="value"
          placeholder="Select filter"
          onChange={(e) => onChange("category_filter_id", e.value)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Category</label>
        <Dropdown
          value={details.category_id}
          options={options}
          onChange={(e) => onChange("category_id", e.value)}
          optionLabel="label"
          placeholder="Select category"
        />
      </div>
      <div className="field col-6">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange("name", e.target.value)}
          required
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="order">Order</label>
        <InputNumber
          value={details.order}
          onChange={(e) => onChange("order", e.value)}
        />
      </div>
      {/* <div className="field col-6">
        <label htmlFor="name">Text Content Color &nbsp; &nbsp;</label>
        <input
          type="color"
          id="head"
          name="head"
          value={details.text_content_color}
          onChange={(e) => onChange("text_content_color", e.target.value)}
        />
        <InputText
          id="name"
          value={details.text_content_color}
          onChange={(e) => onChange("text_content_color", e.target.value)}
          required
        />
      </div>

      <div className="field col-6">
        <label htmlFor="name">Text Background Color &nbsp; &nbsp;</label>
        <input
          type="color"
          id="head"
          name="head"
          value={details.text_bg_color}
          onChange={(e) => onChange("text_bg_color", e.target.value)}
        />
        <InputText
          id="name"
          value={details.text_bg_color}
          onChange={(e) => onChange("text_bg_color", e.target.value)}
          required
        />
      </div> */}
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Type</label>
        <Dropdown
          defaultValue={details.type}
          value={details.type}
          options={[
            {
              label: "FREE",
              value: "free",
            },
            {
              label: "FEE",
              value: "fee",
            },
          ]}
          onChange={(e) => onChange("type", e.value)}
          optionLabel="label"
          placeholder="Select type"
        />
      </div>

      <div className="field col-6 md:col-6">
        <label htmlFor="name">Price</label>
        <InputNumber
          id="name"
          value={details.price}
          onChange={(e) => onChange("price", e.value)}
          required
        />
      </div>
      <div className="field col-12 ">
        <div className="field-radiobutton mr-2">
          <RadioButton
            inputId="image"
            name="image"
            value="image"
            onChange={(e) => onChange("type_file", e.value)}
            checked={details.type_file === "image"}
          />
          <label htmlFor="image">Image</label>
        </div>
        <div className="field-radiobutton mr-5">
          <RadioButton
            inputId="video"
            name="video"
            value="video"
            onChange={(e) => onChange("type_file", e.value)}
            checked={details.type_file === "video"}
          />
          <label htmlFor="video">Video</label>
        </div>
      </div>
      <div className="field col-12 ">
        {details.type_file === "image" && (
          <VUpload
            urlFile={details.image}
            setUrlFile={(file) => onChange("image", file)}
          />
        )}
        {details.type_file === "video" && (
          <div className="grid">
            <div className="field col-12 md:col-6">
              <VUpload
                fileType="video"
                urlFile={details.video}
                setUrlFile={(file) => onChange("video", file)}
              />
            </div>
            <div className="field col-12 md:col-6 ">
              <label htmlFor="thumbnail">Thumbnail</label>
              <VUpload
                urlFile={details.thumbnail}
                setUrlFile={(file) => onChange("thumbnail", file)}
              />
            </div>
          </div>
        )}
      </div>

      {/* <div className="field col-12">
        <label htmlFor="description">Description</label>
        <br />
        <VEditor
          value={details.description}
          onChange={(e) => {
            onChange("description", e?.html);
          }}
          haveData={details.description ? true : false}
        />
      </div> */}

      {!details.category_filter_id && (
        <div className="field col-12">
          <Panel header="Filter" toggleable className="my-2" collapsed={false}>
            <div className="grid">
              <div className="field col-12">
                <CategoryFilterDetails ref={refDetailCategory} />
              </div>
            </div>
          </Panel>
        </div>
      )}
    </div>
  );
};

export default forwardRef(Details);
