/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { formatLocalTime, formatNumber, getFromTo } from "utils/common";
import { Calendar } from "primereact/calendar";
import debounce from "lodash.debounce";
import { InputSwitch, MultiSelect } from "primereact";
import TransactionService from "services/transactions";
import { renderAmount, renderHash } from "utils/render";
import { MasterContext } from "contexts/MasterContext";
import VUserDropdown from "components/v-user";
import { Panel } from "primereact/panel";

const STATUS = [
  { name: "Pending", value: "pending" },
  { name: "Success", value: "success" },
  { name: "Error", value: "error" },
];

const View = () => {
  const { stocks } = useContext(MasterContext);
  const [, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    userId: null,
    amount: 1,
    status: ["pending", "success", "error"],
    type: ["", "swap"],
    stocks: stocks.map((s) => s.symbol),
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 20,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [sum, setSum] = useState([]);
  const [checked, setChecked] = useState(true);

  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    setGlobalFilter((prev) => ({
      ...prev,
      amount: checked ? 1 : -1,
    }));
  }, [checked]);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  const loadData = async () => {
    try {
      setLoading(true);
      const res: any = await TransactionService.search({
        query: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
        },
      });
      if (res && res.content) {
        setData(res.content);
        setSum(res.sum);
        setTotalRecords(res.total);
      } else setData([]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            // onClick={openNew}
            disabled
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="grid px-2 align-items-center">
        <div className="col-12 md:col-4">
          <span className="block p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e: any) => onSearch("search", e.target.value)}
              placeholder="Search..."
              className="w-full"
            />
          </span>{" "}
        </div>
        <div className="col-12 md:col-4">
          <Calendar
            className="w-full"
            id="time24"
            placeholder="Start Date"
            value={globalFilter.start}
            onChange={(e: any) => onSearch("start", e.value)}
            showTime
            showSeconds
            showButtonBar
          />{" "}
        </div>

        <div className="col-12 md:col-4">
          <Calendar
            className="w-full"
            id="time24"
            placeholder="End Date"
            value={globalFilter.end}
            onChange={(e: any) => onSearch("end", e.value)}
            showTime
            showSeconds
            showButtonBar
          />
        </div>
        <div className="col-12 md:col-3">
          <MultiSelect
            value={globalFilter.stocks}
            options={stocks}
            onChange={(e: any) => onSearch("stocks", e.value)}
            optionLabel="symbol"
            optionValue="symbol"
            placeholder="Status"
            maxSelectedLabels={3}
            className={"w-full"}
          />
        </div>
        <div className="col-12 md:col-3">
          <MultiSelect
            value={globalFilter.status}
            options={STATUS}
            onChange={(e: any) => onSearch("status", e.value)}
            optionLabel="name"
            placeholder="Status"
            maxSelectedLabels={3}
            className={"w-full"}
          />
        </div>
        <div className="col-12 md:col-3">
          <VUserDropdown
            value={globalFilter.userId}
            setValue={(v) => onSearch("userId", v)}
            className="w-full"
          />
        </div>
        <div className="col-12 md:col-3 ">
          <span className="flex align-items-center m-1">
            {" "}
            {!checked ? "From" : "To"}
            <InputSwitch
              className="ml-2"
              checked={checked}
              onChange={(e) => setChecked(e.value)}
            />
          </span>
        </div>
      </div>
    </Panel>
  );
  const footer = (
    <div>
      {sum.map((s) => {
        return (
          <div>
            <span>
              {" "}
              {s._id} : {formatNumber(Math.abs(s.totalAmount))}
            </span>
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={data}
            header={header}
            footer={footer}
            emptyMessage="No data found."
            dataKey="id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            //panigate
            paginator
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 20, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            //sort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onSort}
          >
            <Column
              frozen
              field="refer_code"
              header="Id"
              style={{ flexGrow: 1, flexBasis: "150px" }}
              body={(rowData) => (
                <span>
                  {rowData.user_id?.refer_code
                    ? rowData.user_id?.refer_code.slice(0, 10)
                    : ""}
                </span>
              )}
            ></Column>
            <Column
              frozen
              field="email"
              header="Email"
              style={{ flexGrow: 1, flexBasis: "350px" }}
              body={(rowData) => <span>{rowData.user_id?.email || ""}</span>}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "120px" }}
              field="type"
              header="Type"
              sortable
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "100px" }}
              field="network"
              header="Network"
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "100px" }}
              field="token"
              header="Stock"
              sortable
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "150px" }}
              field="from"
              header="From"
              body={(rowData) => getFromTo(rowData.from)}
              sortable
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "150px" }}
              field="to"
              header="To"
              sortable
              body={(rowData) => getFromTo(rowData.to)}
            ></Column>

            <Column
              style={{ flexGrow: 1, flexBasis: "300px" }}
              field="description"
              header="Description"
              sortable
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "150px" }}
              field="hash"
              header="Hash"
              sortable
              body={(rowData) => renderHash(rowData.hash)}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "150px" }}
              field="amount"
              header="Amount"
              sortable
              body={(rowData) => renderAmount(rowData.amount)}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="status"
              header="Status"
              sortable
              body={(rowData) => (
                <span
                  className={`v-badge status-${rowData.status.toLowerCase()}`}
                >
                  {rowData.status}
                </span>
              )}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="createdAt"
              header="Created At"
              sortable
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(View, comparisonFn);
