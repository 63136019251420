/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import { FileUpload } from "primereact/fileupload";
import { Button, Image, ProgressBar, Toast } from "primereact";
import { getDateDirectory, getUrlImage, showToast } from "utils/common";
import UploadService from "services/uploads";

const View = ({ urlFile, setUrlFiles, setFile, label, size, folder, accept = "image/*" }: any) => {
  const toast = useRef(null);
  const [isShow, setIsShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const myUploader = async (event) => {
    try {
      setLoading(true);
      const files = event.files;
      if (files) {
        const _files = await Promise.all(
          files.map(async (file) => {
            if (file) {
              const formData = new FormData();
              formData.append("files", file);
              formData.append(
                "folderPath",
                "admin/"
                  .concat(getDateDirectory())
                  .concat(folder ? "/".concat(folder) : "")
              );
              const res: any = await UploadService.uploadFile({
                body: formData,
              });
              if (res && res.filePath) {
                return res;
              }
            }
            return null;
          })
        );
        setUrlFiles(_files.filter((a) => a).map((a) => a.filePath));
        setLoading(false);
        setIsShow(false);
      }
    } catch (error) {
      showToast(error);
      setLoading(false);
    }
  };

  const onUpload = (data) => {};

  const chooseOptions = { label: "Choose", icon: "pi pi-fw pi-plus" };
  const uploadOptions = {
    label: "Upload",
    icon: "pi pi-upload",
    className: "p-button-success",
  };
  const cancelOptions = {
    label: "Cancel",
    icon: "pi pi-times",
    className: "p-button-danger",
  };

  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        <div>
          {chooseButton}
          {uploadButton}
          {cancelButton}
        </div>
        <div className="absolute right-0 ">
          <Button
            className="w-4rem p-button-secondary"
            icon="bx bx-arrow-back"
            onClick={(_) => setIsShow(false)}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="">
      <Toast ref={toast} />
      <div className="text-center mb-2">{label}</div>
      {!isShow ? (
        <div className="flex flex-column align-items-center">
          <div>
            <Image
              src={getUrlImage(urlFile)}
              width={size || 100}
              height={size || 100}
              preview
            />
          </div>
          <div>
            {" "}
            <Button
              icon="pi pi-upload"
              className="p-button-sm"
              aria-label="Bookmark"
              onClick={(_) => setIsShow(true)}
            />
            <Button
              icon="pi pi-times"
              className="p-button-sm p-button-danger"
              aria-label="Delete"
              onClick={(_) => {
                if (setUrlFiles) setUrlFiles([]);
                if (setFile) setFile({});
              }}
            />
          </div>
        </div>
      ) : (
        <div className="field ">
          <FileUpload
            chooseOptions={chooseOptions}
            uploadOptions={uploadOptions}
            cancelOptions={cancelOptions}
            headerTemplate={headerTemplate}
            customUpload={true}
            progressBarTemplate={
              loading && <ProgressBar mode="indeterminate" />
            }
            mode={"advanced"}
            uploadHandler={myUploader}
            onUpload={onUpload}
            multiple={true}
            accept={accept}
            maxFileSize={10000000}
            auto={false}
            emptyTemplate={
              <p className="m-0">Drag and drop files to here to upload.</p>
            }
          />
        </div>
      )}
    </div>
  );
};

export default View;
