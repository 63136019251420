import React, { useEffect, useRef, useState } from "react";
import SettingService from "services/settings";
import { Button, InputText, Toast } from "primereact";
import { InputNumber } from "primereact/inputnumber";
import { nanoid } from "nanoid";
import { showToast } from "utils/common";

const Tokenomics = () => {
  const toast = useRef(null);
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleCancel = async () => {
    try {
      getData();
    } catch (error) {}
  };

  const handleAdd = () => {
    setState((state) => [...state, { index: nanoid() }]);
  };

  const changeHandler = (value, name, index) => {
    const arr = state.map((i) =>
      i.index === index
        ? {
            ...i,
            [name]: value,
          }
        : i
    );
    setState(arr);
  };
  const handleSubIndex = (index) => {
    const newState = [...state].filter((i) => i.index !== index);
    setState(newState);
  };

  const submitHandler = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      await SettingService.updateSetting({
        body: {
          name: "tokenomics",
          value: state,
        },
      });

      await getData();
      showToast(toast, "success", "Update dsuccess!");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const getData = async () => {
    try {
      const res: any = await SettingService.getSettingsByName({
        params: {
          name: "tokenomics",
        },
      });
      if (res) {
        const { setting } = res;
        if (setting) {
          const value = setting["value"] ? setting["value"] : [];
          setState(value);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="grid">
      <Toast ref={toast} />

      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>Tokenomics</h4>
            <Button
              onClick={handleAdd}
              icon="bx bxs-add-to-queue"
              className="p-button-rounded p-button-success mb-3"
              aria-label="Search"
            />
          </div>
          {state.map((item, index) => {
            return (
              <div className="grid pb-3" key={index}>
                <div className="field col-4">
                  <span className="p-float-label">
                    <InputText
                      required
                      value={item.name}
                      className="w-full"
                      onChange={(e) =>
                        changeHandler(e.target.value, "name", item.index)
                      }
                    />
                    <label htmlFor="name">Name</label>
                  </span>
                </div>
                <div className="field col-4">
                  <span className="p-inputgroup">
                    <InputNumber
                      placeholder="Price"
                      value={item.percent}
                      className="w-full"
                      onChange={(e) =>
                        changeHandler(e.value, "percent", item.index)
                      }
                    />
                    <span className="p-inputgroup-addon">%</span>
                  </span>
                </div>

                <div className="field col-4 md:col-1">
                  <Button
                    icon="pi pi-times"
                    className="p-button-rounded p-button-danger"
                    aria-label="Cancel"
                    onClick={(_) => handleSubIndex(item.index)}
                  />
                </div>
              </div>
            );
          })}

          <div className="">
            <Button
              label="Cancel"
              className="p-button-danger mr-2"
              loading={loading}
              onClick={handleCancel}
            />
            <Button label="Submit" loading={loading} onClick={submitHandler} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tokenomics;
