import VUpload from "components/v-upload";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React from "react";
import { v4 as uuidv4 } from "uuid";

type IProps = {
  data: any;
  onChange: any;
};

const MarqueeField = (props: IProps) => {
  const { data, onChange } = props;

  const handleAdd = () => {
    const _marquee = {
      ...data,
      content: [
        ...data.content,
        {
          id: uuidv4(),
          icon: "",
          link: "",
          value: "",
        },
      ],
    };
    onChange(_marquee, "marquee");
  };
  const handleSub = () => {
    const _marquee = {
      ...data,
      content: data.content.slice(0, data.content.length - 1),
    };
    onChange(_marquee, "marquee");
  };

  const handleChange = (value: any, name: string, index?: number) => {
    let _marquee = data;
    if (index) {
      _marquee.content[index] = { ..._marquee.content[index], [name]: value };
    } else {
      _marquee = {
        ...data,
        [name]: value,
      };
    }
    onChange(_marquee, "marquee");
  };

  return (
    <div className="field col-12">
      <div className="field col-12 grid">
        <div className="field col-5">
          <InputText
            placeholder={"Text color"}
            id="text-color"
            value={data?.text_color}
            onChange={(e) => handleChange(e.target.value, "text_color")}
            required
            autoFocus
          />
        </div>
        <div className="field col-1">
          <input
            type={"color"}
            value={data?.text_color}
            onChange={(e) => handleChange(e.target.value, "text_color")}
          />
        </div>
        <div className="field col-5">
          <InputText
            placeholder={"Background color"}
            id="text-color"
            value={data?.background_color}
            onChange={(e) => handleChange(e.target.value, "background_color")}
            required
            autoFocus
          />
        </div>
        <div className="field col-1">
          <input
            type={"color"}
            value={data?.background_color}
            onChange={(e) => handleChange(e.target.value, "background_color")}
          />
        </div>
        <div className="field col-12 flex justify-content-center">
          <Button
            icon="bx bxs-add-to-queue"
            className="p-button-rounded p-button-success"
            aria-label="Search"
            onClick={(_) => handleAdd()}
          />
          <Button
            icon="bx bxs-minus-circle"
            className="p-button-rounded p-button-danger"
            aria-label="Cancel"
            onClick={(_) => handleSub()}
          />
        </div>
        {data.content?.length > 0 &&
          data.content?.map((ct, index) => (
            <div className="field col-12 grid" key={ct.id}>
              <div className="field col-2">
                <VUpload
                  urlFile={ct.icon}
                  setUrlFile={(file) => handleChange(file, "icon", index)}
                />
              </div>
              <div className="field col-10 grid">
                <div className="field col-12">
                  <InputText
                    placeholder="Title"
                    value={ct.value}
                    onChange={(e) =>
                      handleChange(e.target.value, "value", index)
                    }
                  />
                </div>
                <div className="field col-12">
                  <InputText
                    placeholder="Link"
                    value={ct.link}
                    onChange={(e) =>
                      handleChange(e.target.value, "link", index)
                    }
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default MarqueeField;
