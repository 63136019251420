import { parseErrorResponse, request } from './request';

export const getEvents = () =>
  new Promise((resolve, reject) => {
    request()
      .get('/admin/events')
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updateStatusEvent = (id: string, req: any) =>
  new Promise((resolve, reject) => {
    request()
      .put(`/admin/events/status/${id}`, req)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error('Something went error!'));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updateEventById = (req) =>
  new Promise((resolve, reject) => {
    request()
      .put(`/events/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });


const APIs = {
  getEvents,
  updateStatusEvent,
  updateEventById
};

export default APIs;
