/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { checkSrcIsVideo, getUrlImage, showToast } from "utils/common";
import { forwardRef } from "react";
import stickersService from "services/stickers";
import VUpload from "components/v-upload";
import { Dropdown } from "primereact/dropdown";
import CategoryPostService from "services/category-posts";
import { InputNumber, Panel } from "primereact";
import VUploads from "components/v-uploads";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import CategoryFilterDetails from "components/v-filter";
import { Checkbox } from "primereact/checkbox";
import { COMMON_STATUS, STICKER_TYPE } from "utils/enum";
import VEditor from "components/v-editor";

const Details = (props, ref) => {
  const {
    data,
    reload,
    toast,
    onCancel,
    setLoading,
    categoryFilters,
    getCategoryFilters,
  } = props;
  const [options, setOptions] = useState([]);

  const emptyData = {
    icon: "",
    images: [],
    name: "",
    thumbnail: "",
    type: "free",
    price: 0,
    category_id: "",
    category_filter_id: null,
    order: 0,
    is_welcome: false,
    status: COMMON_STATUS.ENABLE,
    description: "",
    sticker_type: STICKER_TYPE.STICKER,
  };

  const [details, setDetails] = useState(emptyData);
  const [isWelcome, setWelcome] = useState(false);
  const refDetailCategory = useRef(null);

  const getCategories = () => {
    CategoryPostService.getCategories({
      query: {
        category: "sticker",
      },
    }).then((res: any) => {
      if (res && res.categories) {
        const data = [...res.categories].map((i) => ({
          label: i.name.toString(),
          value: i._id,
        }));
        setOptions(data);
      }
    });
  };
  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (data) setDetails(data);
    if (data?.is_welcome) setWelcome(data?.is_welcome);
  }, [data]);

  const onChange = (name, value) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };

  const handleWelcomeSelect = (value) => {
    details.is_welcome = !value;
    setDetails(details);
    setWelcome(!value);
  };

  const submit = async () => {
    try {
      setLoading(true);
      let category = null;
      if (!details.category_filter_id) {
        category = await refDetailCategory.current.submit();
        getCategoryFilters();
      }
      if (data) {
        await stickersService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      } else
        await stickersService.create({
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      setLoading(false);
      showToast(toast, "success", "File saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const removeImage = (url) => {
    setDetails((detail) => ({
      ...detail,
      images: detail.images.filter((u) => u !== url),
    }));
  };

  const addImages = (files) => {
    setDetails((detail) => ({
      ...detail,
      images: [...detail.images, ...files],
    }));
  };

  return (
    <div className="grid">
      <div className="field col-12 ">
        <label htmlFor="description">Status</label>
        <Dropdown
          value={details.sticker_type}
          options={Object.keys(STICKER_TYPE).map((k) => ({
            label: k,
            value: STICKER_TYPE[k],
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("sticker_type", e.value)}
        />
      </div>
      <div className="field col-12">
        <label htmlFor="status">Filter</label>
        <Dropdown
          filter
          showClear
          value={details.category_filter_id}
          options={categoryFilters.map((item) => ({
            label: item.name,
            value: item._id,
          }))}
          optionLabel="label"
          optionValue="value"
          placeholder="Select filter"
          onChange={(e) => onChange("category_filter_id", e.value)}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Status</label>
        <Dropdown
          defaultValue={details.status}
          value={details.status}
          options={[
            {
              label: "ENABLE",
              value: "enable",
            },
            {
              label: "DISABLE",
              value: "disable",
            },
          ]}
          onChange={(e) => onChange("status", e.value)}
          optionLabel="label"
          placeholder="Select status"
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Category</label>
        <Dropdown
          value={details.category_id}
          options={options}
          onChange={(e) => onChange("category_id", e.value)}
          optionLabel="label"
          placeholder="Select category"
        />
      </div>
      <div className="field col-6">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange("name", e.target.value)}
          required
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="order">Order</label>
        <InputNumber
          value={details.order}
          onChange={(e) => onChange("order", e.value)}
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Type</label>
        <Dropdown
          defaultValue={details.type}
          value={details.type}
          options={[
            {
              label: "FREE",
              value: "free",
            },
            {
              label: "FEE",
              value: "fee",
            },
          ]}
          onChange={(e) => onChange("type", e.value)}
          optionLabel="label"
          placeholder="Select type"
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="name">Price</label>
        <InputNumber
          id="name"
          value={details.price}
          onChange={(e) => onChange("price", e.value)}
          required
        />
      </div>
      <div className="field col-12 md:col-6">
        <div className="flex mb-2">
          <label htmlFor="key">Welcome message default</label>
          <div className="ml-6">
            <Checkbox
              onChange={() => handleWelcomeSelect(isWelcome)}
              checked={isWelcome}
            ></Checkbox>
          </div>
        </div>
      </div>

      <div className="field col-12">
        <div className="flex justify-content-center ">
          <label htmlFor="name">Thumbnail</label>
        </div>
        <VUpload
          urlFile={details.thumbnail}
          setUrlFile={(file) => onChange("thumbnail", file)}
        />
      </div>
      <div className="field col-12">
        <div className="flex justify-content-center ">
          <label htmlFor="name">Icon</label>
        </div>
        <VUpload
          urlFile={details.icon}
          setUrlFile={(file) => onChange("icon", file)}
        />
      </div>
      <div className="field col-12">
        <div className="flex justify-content-center ">
          <label htmlFor="name">Images / Video</label>
        </div>
        <VUploads
          accept={"image/*, video/*"}
          urlFile={details.images[0]}
          setUrlFiles={(file) => addImages(file)}
        />
        <div className="flex justify-content-center m-2 flex-wrap">
          {details.images.map((url, index) => (
            <div className="mx-2 flex flex-column align-items-center">
              {checkSrcIsVideo(url) ? (
                <video autoPlay src={getUrlImage(url)} width={70} height={70} />
              ) : (
                <Image src={getUrlImage(url)} width="70" height="70" preview />
              )}
              <Button
                icon="pi pi-times"
                className="p-button-sm p-button-rounded p-button-danger p-button-outlined w-2rem h-2rem p-0 "
                aria-label="Cancel"
                onClick={(_) => removeImage(url)}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="field col-12">
        <label htmlFor="description">Description</label>
        <br />
        <VEditor
          value={details.description}
          onChange={(e) => {
            onChange("description", e?.html);
          }}
          haveData={details.description ? true : false}
        />
      </div>

      {!details.category_filter_id && (
        <div className="field col-12">
          <Panel header="Filter" toggleable className="my-2" collapsed={false}>
            <div className="grid">
              <div className="field col-12">
                <CategoryFilterDetails ref={refDetailCategory} />
              </div>
            </div>
          </Panel>
        </div>
      )}
    </div>
  );
};

export default forwardRef(Details);
