/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { Calendar, Panel } from "primereact";
import "../styles.scss";
import VUpload from "components/v-upload";
import VUserDropdown from "components/v-user";
import { COMMON_STATUS } from "utils/enum";
import CategoryFilterDetails from "components/v-filter";
import CampaignService from "services/campaigns";
// import { Editor } from "primereact/editor";
import VEditor from "components/v-editor";

const Details = (props, ref) => {
  const {
    data,
    reload,
    toast,
    setLoading,
    categoryFilters,
    getCategoryFilters,
  } = props;

  const refDetailCategory = useRef(null);

  const emptyData: any = {
    user_id: "",
    name: "",
    status: COMMON_STATUS.ENABLE,
    start_date: null,
    end_date: null,
    info: {},
    category_filter_id: null,
  };

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data)
      setDetails({
        ...data,
        start_date: new Date(data.start_date),
        end_date: new Date(data.end_date),
      });
  }, [data]);

  const onChange = (value, name, field = null) => {
    let _details = { ...details };
    if (field) {
      _details[field][`${name}`] = value;
    } else {
      _details[`${name}`] = value;
    }
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      let category = null;
      if (!details.category_filter_id) {
        category = await refDetailCategory.current.submit();
        getCategoryFilters();
      }
      if (data) {
        await CampaignService.editCampaign({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      } else {
        await CampaignService.createCampaign({
          body: {
            ...details,
            category_filter_id: category
              ? category._id
              : details.category_filter_id,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Saved!");
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <>
      <Panel header="Genaral" toggleable className="my-2">
        <div className="grid">
          <div className="field col-12 ">
            <label htmlFor="user">User</label>
            <VUserDropdown
              value={details.user_id}
              setValue={(user) => onChange(user, "user_id")}
            />
          </div>

          <div className="field col-12">
            <label htmlFor="status">Filter</label>
            <Dropdown
              filter
              showClear
              value={details.category_filter_id}
              options={categoryFilters.map((item) => ({
                label: item.name,
                value: item._id,
              }))}
              optionLabel="label"
              optionValue="value"
              placeholder="Select filter"
              onChange={(e) => onChange(e.value, "category_filter_id")}
            />
            {!details.category_filter_id && (
              <div className="grid">
                <div className="field col-12">
                  <Panel
                    header="Filter"
                    toggleable
                    className="my-2"
                    collapsed={false}
                  >
                    <div className="grid">
                      <div className="field col-12">
                        <CategoryFilterDetails ref={refDetailCategory} />
                      </div>
                    </div>
                  </Panel>
                </div>
              </div>
            )}
          </div>

          <div className="field col-12">
            <label htmlFor="name">Name</label>
            <InputText
              id="name"
              value={details.name}
              onChange={(e) => onChange(e.target.value, "name")}
              required
            />
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="status">Status</label>
            <Dropdown
              value={details.status}
              options={Object.keys(COMMON_STATUS).map((k) => ({
                label: k,
                value: COMMON_STATUS[k],
              }))}
              onChange={(e) => onChange(e.value, "status")}
              optionLabel="label"
              placeholder="Select status"
            />
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="start_date">Start Date</label>
            <Calendar
              className="m-1"
              id="time24"
              placeholder="Start Date"
              value={details.start_date}
              dateFormat="mm-dd-yy"
              onChange={(e: any) => onChange(e.value, "start_date")}
              showTime
              showSeconds
              showButtonBar
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="end_date">End Date</label>
            <Calendar
              className="m-1"
              id="time24"
              placeholder="Start Date"
              value={details.end_date}
              dateFormat="mm-dd-yy"
              onChange={(e: any) => onChange(e.value, "end_date")}
              showTime
              showSeconds
              showButtonBar
            />
          </div>
        </div>
      </Panel>

      <Panel header="Information" toggleable className="my-2" collapsed={true}>
        <div className="grid">
          <div className="field col-12 md:col-12">
            <label htmlFor="website">Website</label>
            <InputText
              id="website"
              value={details.info.website}
              onChange={(e) => onChange(e.target.value, "website", "info")}
              required
            />
          </div>
          <div className="field col-12 md:col-6">
            <VUpload
              label="Image"
              urlFile={details.info.image}
              setUrlFile={(file) => onChange(file, "image", "info")}
            />
          </div>
          <div className="field col-12 md:col-6">
            <VUpload
              label="Background View"
              urlFile={details.info.bg_view}
              setUrlFile={(file) => onChange(file, "bg_view", "info")}
            />
          </div>
          <div className="field col-12 md:col-6">
            <VUpload
              label="Background Success"
              urlFile={details.info.bg_success}
              setUrlFile={(file) => onChange(file, "bg_success", "info")}
            />
          </div>
          <div className="field col-12 md:col-6">
            <VUpload
              label="Video"
              urlFile={details.info.video}
              setUrlFile={(file) => onChange(file, "video", "info")}
            />
          </div>
        </div>
      </Panel>

      <Panel header="Guide" toggleable className="my-2" collapsed={true}>
        <div className="grid">
          <div className="field col-12 md:col-12">
            {/* <Editor
              id="guide"
              className="h-30rem"
              value={details.info.guide}
              onTextChange={(e) => onChange(e.htmlValue, "guide", "info")}
            /> */}
            <VEditor
              id="guide"
              className="h-30rem"
              value={details.info.guide}
              onChange={(e) => {
                onChange(e?.html, "guide", "info")
              }}
              haveData={details.info.guide ? true : false}
            />
          </div>
        </div>
      </Panel>
    </>
  );
};

export default forwardRef(Details);
