/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { initSockets } from "sockets";

const defaultValue = {
  dashboard: {
    user: 0,
    page: 0,
    group: 0,
    business: 0,
    ceo: 0,
    under_18: 0,
    online: 0,
    kyc: 0,
    verified: 0,
    post: 0,
    share: 0,
    comment: 0,
    feel: 0,
  },
};

export const SocketContext = React.createContext(defaultValue);

export const SocketProvider = (props: any) => {
  const [dashboard, setDashboard] = useState(defaultValue.dashboard);

  useEffect(() => initSockets({ setDashboard }), [initSockets]);

  return (
    <SocketContext.Provider value={{ dashboard }}>
      {" "}
      {props.children}
    </SocketContext.Provider>
  );
};
