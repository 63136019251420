/* eslint-disable react-hooks/exhaustive-deps */
import VUpload from "components/v-upload";
import { InputSwitch } from "primereact/inputswitch";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

const View = (props) => {
  const {
    name,
    label,
    details,
    handleAddOrRemoveItem,
    handleChangeItemSlide,
    onChange,
  } = props;

  return (
    <>
      <div className="grid col-12">
        <label>{label}:</label>
      </div>
      <div className="grid col-12 align-items-center">
        <div className="field-radiobutton mr-2">
          <RadioButton
            inputId="slide"
            name="slide"
            value="slide"
            onChange={(e) => onChange(e.value, "type", name)}
            checked={details[name].type === "slide"}
          />
          <label htmlFor="slide">Slide</label>
        </div>
        <div className="field-radiobutton mr-5">
          <RadioButton
            inputId="video"
            name="video"
            value="video"
            onChange={(e) => onChange(e.value, "type", name)}
            checked={details[name].type === "video"}
          />
          <label htmlFor="video">Video</label>
        </div>
        <div className="grid">
          <label htmlFor="is_enable_max_withdraw">Enable</label>
          &ensp;
          <br />
          <InputSwitch
            checked={details[name].enable}
            onChange={(e) => onChange(e.value, "enable", name)}
          />
        </div>
      </div>
      <div className="grid col-12">
        <div className="field col-12">
          {details[name].type === "video" && (
            <>
              <label htmlFor="video">Url</label>
              <InputText
                id="video"
                value={details[name].video}
                onChange={(e) => onChange(e.target.value, "video", name)}
                required
                autoFocus
              />
            </>
          )}
          {details[name].type === "slide" && (
            <>
              <Button
                onClick={() => handleAddOrRemoveItem(name, "add")}
                icon="bx bxs-add-to-queue"
                className="p-button-rounded p-button-success mb-3"
                aria-label="Search"
              />
              <div className="grid">
                {details[name].slide.map((item, index) => {
                  return (
                    <div className="col-12" key={index}>
                      <div className="grid">
                        <div className="col-12 md:col-6">
                          <VUpload
                            label="Image"
                            urlFile={item.src}
                            setUrlFile={(file) =>
                              handleChangeItemSlide(file, item.id, name, "src")
                            }
                          />
                        </div>
                        <div className="col-12 md:col-6">
                          <VUpload
                            label="Image mobile"
                            urlFile={item.src_mobile}
                            setUrlFile={(file) =>
                              handleChangeItemSlide(file, item.id, name, "src_mobile")
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12 md:col-6">
                        <div className="">
                          <label htmlFor="link">Link</label>
                          <InputText
                            id="link"
                            className="w-full"
                            value={item.link}
                            onChange={(e) =>
                              handleChangeItemSlide(
                                e.target.value,
                                item.id,
                                name,
                                "link"
                              )
                            }
                          />
                          <div className="field col-12 md:col-2">
                            <Button
                              icon="pi pi-times"
                              className="p-button-rounded p-button-danger"
                              aria-label="Cancel"
                              onClick={(_) =>
                                handleAddOrRemoveItem(name, "remove", item.id)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default View;
