interface IProps {
  url: string;
  className?: string;
  hidden?: boolean;
  title?: string;
  onLoad?: () => void;
  loading?: boolean;
}

const WebModulesIframe = (props: IProps) => {
  const { url, className, title, onLoad } = props;

  return (
    <>
      {url && (
        <iframe
          onLoad={onLoad}
          className={className}
          src={url}
          title={title}
          allow="clipboard-read; clipboard-write; microphone"
        ></iframe>
      )}
    </>
  );
};
export default WebModulesIframe;
