/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { formatLocalTime, getStringLimit } from "utils/common";
import VDialog from "components/v-dialog";
// import VConfirm from "components/v-confirm";
import Detail from "./components/Detail";
import debounce from "lodash.debounce";
import { Calendar } from "primereact/calendar";
import PostService from "services/posts";
import { Dropdown } from "primereact/dropdown";
import { POST_TYPE } from "utils/enum";
import StatusEditorCell from "components/v-edit-cell/StatusEditorCell";
import { Panel } from "primereact/panel";

const STATUS = [
  { name: "ENABLE", value: "enable" },
  { name: "DISABLE", value: "disable" },
];

const BOOLEAN_OP = [
  { name: "TRUE", value: true },
  { name: "FALSE", value: false },
];

const Crud = () => {
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState(null);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    admin_status: null,
    user_id: null,
    post_type: null,
    have_ban_word: false,
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });
  const [totalRecords, setTotalRecords] = useState(0);

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  // const refDialogDelete = useRef(null);
  const refDetail = useRef(null);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  const loadData = async () => {
    try {
      const res: any = await PostService.search({
        query: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
          limit: lazyParams.rows,
        },
      });
      if (res && res.docs) {
        setData(res.docs);
        setTotalRecords(res.totalDocs);
      } else setData([]);
    } catch (error) {
      setData([]);
    }
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  //CREATE OR NEW
  // const openNew = () => {
  //   setDetail(null);
  //   refDialogDetail.current.show();
  // };

  const editActionPost = (data) => {
    setDetail({ ...data });
    refDialogDetail.current.show();
  };

  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };

  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };

  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  // DELETE
  // const confirmDelete = (quotas) => {
  //   setDetail(quotas);
  //   refDialogDelete.current.show();
  // };
  // const handleDelete = async () => {
  //   try {
  //     await PostService.deleted({
  //       params: {
  //         id: detail._id,
  //       },
  //     });
  //     const _data = data.filter((val) => val._id !== detail._id);
  //     setData(_data);
  //     refDialogDelete.current.close();
  //     setDetail(null);
  //     showToast(toast, "success", "User Deleted");
  //   } catch (error) {
  //     showToast(toast, "error", error.errors);
  //   }
  // };

  const onChangeStatus = async (options, rowData, value) => {
    let updateRow;
    let newData = data.map((e, index) => {
      if (e._id === rowData._id) {
        e[options.field] = value;
        updateRow = e;
      }
      return e;
    });
    setData(newData);

    if (updateRow) {
      await PostService.update({
        params: { id: updateRow._id },
        body: {
          ...updateRow,
        },
      });
    }
  };

  // const statusEditor = (options) => {
  //   return <StatusEditor options={options} onChange={onChangeStatus} />;
  // };

  const view = (data) => {
    window.open(
      (process.env.REACT_APP_CLIENT_DOMAIN || "https://vdiarybook.vn").concat(
        "/posts/".concat(data?._id)
      ),
      "_blank",
      "noopener,noreferrer"
    );
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            disabled
            icon="pi pi-plus"
            className="p-button-success mr-2"
          />

          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled={true}
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editActionPost(rowData)}
        />

        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-sm p-button-info mr-2"
          onClick={() => view(rowData)}
        />

        {/* <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning"
          onClick={() => confirmDelete(rowData)}
        /> */}
      </div>
    );
  };

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="grid px-2 align-items-center">
        <div className="col-12 md:col-4">
          <div className="block  p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e: any) => onSearch("search", e.target.value)}
              placeholder="Search..."
              className="w-full"
            />
          </div>
        </div>

        <div className="col-12 md:col-4">
          <Calendar
            placeholder="Start Date"
            value={globalFilter.start}
            onChange={(e) => onSearch("start", e.value)}
            showTime
            className="w-full"
          />
        </div>
        <div className="col-12 md:col-4">
          <Calendar
            placeholder="End Date"
            value={globalFilter.end}
            onChange={(e) => onSearch("end", e.value)}
            showTime
            className="w-full"
          />
        </div>
        <div className="col-12 md:col-3">
          <Dropdown
            value={globalFilter.post_type}
            options={Object.keys(POST_TYPE).map((k) => ({
              label: k,
              value: POST_TYPE[k],
            }))}
            onChange={(e: any) => onSearch("post_type", e.value)}
            optionLabel="label"
            placeholder="Post type"
            filter
            className={"w-full"}
            showClear
          />
        </div>
        <div className="col-12 md:col-3">
          <Dropdown
            value={globalFilter.admin_status}
            options={STATUS}
            onChange={(e: any) => onSearch("admin_status", e.value)}
            optionLabel="name"
            placeholder="Status"
            className={"w-full"}
            showClear
          />
        </div>
        <div className="col-12 md:col-3">
          <Dropdown
            value={globalFilter.have_ban_word}
            options={BOOLEAN_OP}
            onChange={(e: any) => onSearch("have_ban_word", e.value)}
            optionLabel="name"
            placeholder="Have ban words"
            className={"w-full"}
            showClear
          />
        </div>
      </div>
    </Panel>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={data}
            header={header}
            emptyMessage="No data found."
            dataKey="id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            paginator
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[5, 10, 20]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            //sort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onSort}
          >
            <Column
              frozen
              alignFrozen="left"
              body={actionBodyTemplate}
              style={{ flexGrow: 1, flexBasis: "120px" }}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "100px" }}
              field="post_type"
              header="Post type"
              body={(rowData) => {
                return <span>{rowData.post_type}</span>;
              }}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "300px" }}
              field="user"
              header="User"
              body={(rowData) => String(rowData.profile?.full_name)}
            ></Column>
            <Column
              style={{
                flexGrow: 1,
                flexBasis: "350px",
                maxWidth: "350px",
                overflow: "hidden",
              }}
              field="post_title"
              header="Title"
              body={(rowData) => (
                <span
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {getStringLimit(rowData.post_title || "", 50)}
                </span>
              )}
            ></Column>
            <Column
              style={{
                flexGrow: 1,
                flexBasis: "450px",
                maxWidth: "450px",
                overflow: "hidden",
              }}
              field="post_content"
              header="Content"
              body={(rowData) => (
                <span
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {getStringLimit(rowData.post_content || "", 100)}
                </span>
              )}
            ></Column>
            <Column
              // editor={statusEditor}
              style={{ flexGrow: 1, flexBasis: "180px" }}
              field="admin_status"
              header="Status"
              // body={(rowData) => {
              //   return (
              //     <span
              //       className={`v-badge status-${rowData?.admin_status?.toLowerCase()}`}
              //     >
              //       {rowData.admin_status}
              //     </span>
              //   );
              // }}
              body={(rowData, options) => (
                <StatusEditorCell
                  value={rowData.status}
                  options={options}
                  rowData={rowData}
                  onChange={onChangeStatus}
                />
              )}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "150px", maxWidth: "150px" }}
              field="privacy"
              header="Privacy"
              body={(rowData) => {
                return (
                  <span className={`v-badge privacy-${rowData?.post_privacy}`}>
                    {rowData.post_privacy}
                  </span>
                );
              }}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="createdAt"
              header="Created At"
              sortable
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>
          </DataTable>

          <VDialog
            ref={refDialogDetail}
            header="Detail"
            onSubmit={onSumitDialogDetail}
          >
            <Detail
              ref={refDetail}
              data={detail}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
            />
          </VDialog>

          {/* <VConfirm ref={refDialogDelete} onConfirm={handleDelete} /> */}
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Crud, comparisonFn);
